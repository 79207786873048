import Modal from '../Modal'
import { Trans } from '@lingui/macro'
import { Text } from 'rebass'
import './modal.css'
import styled, { useTheme } from 'styled-components/macro'
import { CloseIcon, CustomLightSpinner } from '../../theme'
import { NotchedButtonFillPrimary, NotchedButtonFill } from '../Button'
import { CheckCircle, AlertCircle, AlertTriangle } from 'react-feather'
import { useGetCustomeImage } from '../../hooks/useGetCustomeImage'
import { ethers } from 'ethers'
import { useContext, useEffect, useRef, useState } from 'react'
import CheckButtonComponent from 'components/Button/CheckButton'
import { Contract } from '@ethersproject/contracts'
import { useWeb3React } from '@web3-react/core'
import { NONFUNGIBLE_POSITION_MANAGER_ADDRESSES, V3STAKER_ADDRESSES_MAP } from '../../constants/addresses'
import { useTokenContract, useContract, useCustomeContract } from 'hooks/useContract'
import v3staker_abi from '../../abis/alpha/V3Staker.json'
import ERC721 from '../../abis/erc721.json'

// image
import { nftImage } from './image'
const Circle = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/blue-loader.svg'
import emptyImage from '../../assets/images/alpha/message-circle-question.svg'
import ContractInfoContext from '../../pages/Alpha/ContractInfoContext'
import { useMultiplePositions } from '../../hooks/useMultiplePositions'
import { useDerivedV3BurnInfo } from '../../state/burn/v3/hooks'
import { fetchPrice } from '../../nft/utils'
import { SupportedChainId } from '../../constants/chains'
import Badge, { BadgeVariant } from '../Badge'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
interface DepositModalInterface {
  isOpen: boolean
  onDismiss: () => void
  userPosition: any
  tokenInfo: any
  poolInfo: any
  handleDataFromChild: (data: any) => void
  setTrigger: (data: boolean) => void
}
const Wrapper = styled.div`
  background-color: #1c1b20;
  outline: none;
  width: 100%;
  padding: 10px 20px;
  height: fit-content;
  margin-bottom: 20px;
`
const WrapperTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    color: #efefe4;
  }
`

const NFTGrid = styled.div`
  display: grid;
  grid-template: 'overlap';
  min-height: 100px;
`
const NFTCanvas = styled.canvas`
  grid-area: overlap;
`
const NFTImage = styled.img`
  grid-area: overlap;
  height: 100px;
  /* Ensures SVG appears on top of canvas. */
  z-index: 1;
`
function getSnapshot(src: HTMLImageElement, canvas: HTMLCanvasElement, targetHeight: number) {
  const context = canvas.getContext('2d')

  if (context) {
    let { width, height } = src

    // src may be hidden and not have the target dimensions
    const ratio = width / height
    height = targetHeight
    width = Math.round(ratio * targetHeight)

    // Ensure crispness at high DPIs
    canvas.width = width * devicePixelRatio
    canvas.height = height * devicePixelRatio
    canvas.style.width = width + 'px'
    canvas.style.height = height + 'px'
    context.scale(devicePixelRatio, devicePixelRatio)

    context.clearRect(0, 0, width, height)
    context.drawImage(src, 0, 0, width, height)
  }
}

function NFT({ image, height: targetHeight }: { image: string; height: number }) {
  const [animate, setAnimate] = useState(false)
  useRef
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)

  return (
    <NFTGrid
      onMouseEnter={() => {
        setAnimate(true)
      }}
      onMouseLeave={() => {
        // snapshot the current frame so the transition to the canvas is smooth
        if (imageRef.current && canvasRef.current) {
          getSnapshot(imageRef.current, canvasRef.current, targetHeight)
        }
        setAnimate(false)
      }}
    >
      <NFTCanvas ref={canvasRef} />
      <NFTImage
        ref={imageRef}
        src={image}
        hidden={!animate}
        onLoad={() => {
          // snapshot for the canvas
          if (imageRef.current && canvasRef.current) {
            getSnapshot(imageRef.current, canvasRef.current, targetHeight)
          }
        }}
      />
    </NFTGrid>
  )
}

const mapData = (
  data: any,
  positions: {
    liquidityValue0?: string
    liquidityValue1?: string
    currency0?: CurrencyAmount<Currency>
    currency1?: CurrencyAmount<Currency>
    outOfRange?: boolean
  }[]
) => {
  return data.map((item: any, index: number) => {
    const position = positions[index]
    return {
      tokenId: item.tokenId,
      formattedTokenId: item.tokenId.toString(),
      fee: item.fee,
      liquidity: Number(ethers.utils.formatUnits(item.liquidity, 18)).toFixed(5).toString(),
      tickLower: item.tickLower.toString(),
      tickUpper: item.tickUpper.toString(),
      token0: position?.currency0 ?? null,
      token1: position?.currency1 ?? null,
      tokensOwed0: Number(ethers.utils.formatUnits(item.tokensOwed0, 18)).toFixed(2).toString(),
      tokensOwed1: Number(ethers.utils.formatUnits(item.tokensOwed1, 18)).toFixed(2).toString(),
      liquidityValue0: position?.liquidityValue0 ?? null,
      liquidityValue1: position?.liquidityValue1 ?? null,
      outOfRange: position?.outOfRange ?? false,
    }
  })
}

const BadgeText = styled.div`
  font-weight: 500;
  font-size: 12px;
`

export default function DepositModal({
  isOpen,
  onDismiss,
  userPosition,
  tokenInfo,
  poolInfo,
  handleDataFromChild,
  setTrigger,
}: DepositModalInterface) {
  const { getContractInfo } = useContext(ContractInfoContext)
  const [ethPrice, setEthPrice] = useState(3000)
  const { account, chainId } = useWeb3React()
  const [customeChainId, setCustomeChainId] = useState(SupportedChainId.BLAST)
  useEffect(() => {
    if (chainId) {
      setCustomeChainId(chainId)
    } else {
      setCustomeChainId(SupportedChainId.BLAST)
    }
  }, [account, chainId])
  const positions = useMultiplePositions(userPosition)
  const mappedData = mapData(userPosition, positions)
  // using custom image
  const imgArray = useGetCustomeImage(mappedData)
  function filterImageArray(data: any) {
    return data?.filter((item: any) => {
      const matchesItems = item.valid === true
      return matchesItems
    })
  }
  let filteredNftImage = filterImageArray(imgArray)
  const [checkedIds, setCheckedIds] = useState<string[]>([])
  const handleCheckChange = (formattedTokenId: string, isChecked: boolean) => {
    if (isChecked) {
      setCheckedIds([formattedTokenId])
    } else {
      setCheckedIds([])
    }
  }

  useEffect(() => {
    fetchPrice().then((price) => {
      setEthPrice(price || 0)
    })
  }, [])

  function mapToDesiredStructure(checkedIds: any, poolInfo: any) {
    return checkedIds.map((id: any, index: any) => {
      return {
        ['Data']: [
          {
            rewardToken: poolInfo.rewardToken,
            pool: poolInfo.pool,
            startTime: ethers.BigNumber.from(poolInfo.startTime),
            endTime: ethers.BigNumber.from(poolInfo.endTime),
            refundee: poolInfo.refundee,
            lockDuration: ethers.BigNumber.from(poolInfo.lockDuration),
          },
          ethers.BigNumber.from(id),
        ],
      }
    })
  }

  const NFT_ADDRESS = NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[customeChainId]
  const STAKING_ADDRESS = V3STAKER_ADDRESSES_MAP[customeChainId]

  const v3staker = useCustomeContract(STAKING_ADDRESS, v3staker_abi, false)
  const v3stakerWithSign = useCustomeContract(STAKING_ADDRESS, v3staker_abi, true)

  const NftContract = useCustomeContract(NFT_ADDRESS, ERC721, false)
  const NftContractWithSign = useCustomeContract(NFT_ADDRESS, ERC721, true)

  const [loadingApproveNft, setLoadingApproveNft] = useState(false)
  const [isApproveNft, setIsApproveNft] = useState(false)
  const [loadingDeposit, setLoadingDeposit] = useState(false)

  const ApproveNFTContract = async () => {
    setLoadingApproveNft(true)
    try {
      const setApproveContract = await NftContractWithSign?.setApprovalForAll(STAKING_ADDRESS, true)
      await setApproveContract.wait()
      setLoadingApproveNft(false)
      return true
    } catch (error) {
      console.log(error)
      setLoadingApproveNft(false)
      return false
    }
  }
  const handleCheckApproveNFTContract = async () => {
    try {
      const isApproved = await NftContractWithSign?.isApprovedForAll(account, STAKING_ADDRESS)

      if (isApproved) {
        setIsApproveNft(true)
      } else {
        setIsApproveNft(false)
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  const handleApproveNFTContract = async () => {
    await ApproveNFTContract()
    handleCheckApproveNFTContract()
  }

  useEffect(() => {
    if (account !== null && account !== undefined) {
      handleCheckApproveNFTContract()
    }
  }, [account, chainId])

  // deposit function
  const depositNft = async () => {
    setLoadingDeposit(true)
    try {
      const depositParams = mapToDesiredStructure(checkedIds, poolInfo)

      const stakeTokenCalldata = v3stakerWithSign?.interface.encodeFunctionData('depositAndStake', [
        depositParams[0]?.Data[0],
        depositParams[0]?.Data[1],
      ])
      const tx = await v3stakerWithSign?.multicall([stakeTokenCalldata])

      await tx.wait()
      if (tx) {
        console.log('tx', tx)
        setLoadingDeposit(false)
        getUserStakeInfo()
        // getContractInfo()
        setTrigger(true)
        setCheckedIds([])
      }
      setLoadingDeposit(false)
    } catch (error) {
      console.log(error)
      setLoadingDeposit(false)
    }
  }
  const getUserStakeInfo = async () => {
    try {
      const tokenIdByUser = await v3stakerWithSign?.getTokensByOwner(account, '0', '100')
      const items = await Promise.all(
        tokenIdByUser?.map(async (item: any) => {
          return {
            tokenId: item.tokenId.toString(),
            poolAddress: item.incentiveKey.pool,
          }
        })
      )
      let filterByPool = items?.filter((item: any) => {
        return item?.poolAddress?.toLowerCase() === poolInfo?.pool?.toLowerCase()
      })
      handleDataFromChild(filterByPool?.length)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>
        <WrapperTop>
          <p>
            Deposit to {tokenInfo[0]?.currencyA?.symbol}-{tokenInfo[0]?.currencyB?.symbol} Farming
          </p>
          <CloseIcon onClick={onDismiss} />
        </WrapperTop>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          {mappedData.length > 0 ? (
            mappedData.map((i: any, index: any) => (
              <div className="deposit-card-container" key={i.formattedTokenId}>
                <div className="deposit-card-container-title">
                  <p>
                    NFT ID: <span className="deposit-card-container-title-span">{i.formattedTokenId}</span>{' '}
                    {i.outOfRange ? (
                      <Badge variant={BadgeVariant.WARNING}>
                        <AlertCircle width={14} height={14} />
                        &nbsp;
                        <BadgeText>
                          <Trans>Out of range</Trans>
                        </BadgeText>
                      </Badge>
                    ) : (
                      <></>
                    )}
                  </p>

                  <CheckButtonComponent
                    onCheckChange={(isChecked) => handleCheckChange(i.formattedTokenId, isChecked)}
                    isChecked={checkedIds.includes(i.formattedTokenId)}
                    formattedTokenId={i.formattedTokenId}
                    disabled={false}
                  />
                </div>
                <div className="deposit-card-info">
                  <div className="deposite-card-img">
                    <NFT image={filteredNftImage[index]?.result?.image} height={100} />
                  </div>
                  <div className="deposite-card-detail">
                    <p>
                      Fee: <span className="deposit-card-container-title-span">{Number(i.fee) / 10000}%</span>
                    </p>
                    <p>
                      {i.token0?.currency?.symbol}:{' '}
                      <span className="deposit-card-container-title-span">{i.liquidityValue0}</span>
                    </p>
                    <p>
                      {i.token1?.currency?.symbol}:{' '}
                      <span className="deposit-card-container-title-span">{i.liquidityValue1}</span>
                    </p>
                    <p>
                      Liquidity: <span className="deposit-card-container-title-span">{i.liquidity}</span>
                    </p>
                    {/*<p>*/}
                    {/*  Value on pool:{' '}*/}
                    {/*  <span className="deposit-card-container-title-span">*/}
                    {/*    $*/}
                    {/*    {i.token1?.currency.symbol !== 'ETH'*/}
                    {/*      ? (Number(i.liquidityValue0) + Number(i.liquidityValue1)).toFixed(2)*/}
                    {/*      : (Number(i.liquidityValue0) + Number(i.liquidityValue1) * ethPrice).toFixed(2)}*/}
                    {/*  </span>*/}
                    {/*</p>*/}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="deposit-card-container">
              <div className="deposit-card-container-img">
                <img src={emptyImage} alt="img" />
                <p>You don&apos;t have any compatible position</p>
              </div>
            </div>
          )}
        </div>
        {isApproveNft ? (
          <NotchedButtonFill onClick={depositNft} style={{ margin: '4px 0 0 0' }} disabled={checkedIds.length === 0}>
            <Text fontWeight={500} fontSize={20}>
              <Trans>{checkedIds.length > 0 ? (loadingDeposit ? 'Depositing...' : 'Deposit') : 'Select'}</Trans>
            </Text>
          </NotchedButtonFill>
        ) : filteredNftImage.length > 0 ? (
          <NotchedButtonFill onClick={handleApproveNFTContract} style={{ margin: '4px 0 0 0' }}>
            <Text fontWeight={500} fontSize={20}>
              <Trans>{loadingApproveNft ? 'Approving...' : 'Approve'}</Trans>
            </Text>
          </NotchedButtonFill>
        ) : (
          ''
        )}
        <NotchedButtonFillPrimary onClick={onDismiss} style={{ margin: '14px 0 0 0' }}>
          <Text fontWeight={500} fontSize={20}>
            <Trans>Close</Trans>
          </Text>
        </NotchedButtonFillPrimary>
      </Wrapper>
    </Modal>
  )
}
