import styled from "styled-components"

import {
    CloseOutlined
} from "@ant-design/icons"
import { useCallback, useEffect, useMemo, useState } from "react"
import { notification } from "antd"
import { BREAKPOINTS } from "theme";
import { NotchedButtonFill } from "components/Button";
import { getMyWonTicketIds, useMonopotStore, WonTicketType } from "hooks/useMonopot";
import Modal from "components/Modal";
import { formatXpNumber, TextLinear } from "pages/Xp/sections/XpTitle";
import useMusd from "hooks/useMusd";
import SuccessClaimedRwModal from "./SuccessClaimedRwModal";
import checkUnsupportChainId from "utils/checkUnsupportChainId";
import { useWeb3React } from "@web3-react/core";

const ViewTicketModalWrapper = styled.div`
    width: 100%;
    max-width: 592px;
    background: #1C1B20;
    box-shadow: 0px 12px 50px 0px #000;
    padding: 24px 32px;
    position: relative;
    overflow: auto;
`

const Text = styled.span`
    color: #E5E5DF;
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 35px */
    span{
        font-family: Urbanist;
    }
`

const TicketList = styled.div`
    display: grid;
    gap: 12px;
`
const TicketItem = styled.div`
   position: relative;
    z-index: 1;
    max-width: 528px;
    height: 72px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10%;
    cursor: pointer;
    background: rgba(239, 239, 228, 0.05);
    clip-path: polygon(20.3px 0, 100% 0, 100% calc(100% - 20.3px), calc(100% - 20.3px) 100%, 0 100%, 0 20.3px);

    img.ticket_bg{
        max-width: 15px;
        position: absolute;
        top: 3px;
        right: 25%;
        width: 100%;
        height: 90%;
        z-index: 1;
    }
    @media screen and (max-width: 350px) {
        padding-left: 5%;
        img.ticket_bg{
            right: 22%;
            max-width: 10px;
        }
    }
`

const TicketItemNumBox = styled.div`
    display: flex;
    position: relative;
    z-index: 1;
    
`
const TicketItemNum = styled.div<{
    hasMatched?: boolean
}>`
    height: 100%;
    text-align: center;
    font-family: Urbanist;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 50.4px */
    border: none;
    outline: none;
    display: block;
    background: none;
    width: 40px;
    @media screen and (max-width: 780px) {
        width: 30px;
    }
    @media screen and (max-width: 708px) {
        width: 40px;
    }
    @media screen and (max-width: 600px) {
        font-size: 15px;
    }
    @media screen and (max-width: 450px) {
        width: 30px;
    }
`
const TicketItemNo = styled.div<{
    hasChose?: boolean
}>`
    position: absolute;
    top: 25%;
    right: 8%;
    z-index: 1;
    display: flex;
    gap: 10px;
    opacity: 0.7;
    span{
        color: ${({ hasChose }) => hasChose ? '#68FF9B' : 'rgba(239, 239, 228, 0.50)'};
        text-align: center;
        font-family: Righteous;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 25px */
        @media screen and (max-width: 600px) {
            font-size: 15px;
        }
    }
    svg{
        color: rgba(239, 239, 228, 0.50);
        font-size: 16px;
        &:hover{
            color: #68FF9B;
        }
    }
    @media screen and (max-width: 600px) {
        top: 35%;
    }
    @media screen and (max-width: 500px) {
        right: 10%;
        gap: 5px;
    }
    @media screen and (max-width: 350px) {
        right: 9%;
        top: 37%;
    }
`

const PrizeBox = styled.div`
    width: 100%;
    padding: 24px 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
    .usd_price{
        font-family: Urbanist;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; 
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        .usd_price{
            font-size: 20px;
        }
    }
`

const PrizeMusd = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 12px;
    height: 100%;
    img{
        max-width: 50px;
        max-height: 50px;
        width: 100%;
        height: 100%;
    }
    .text{
        font-family: Righteous;
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: 0.75; 
    }
    
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        .text{
            font-size: 35px;
        }
    }
`

export default function WonTicketModal() {
    const { chainId } = useWeb3React()
    const { musdPrice } = useMusd()
    const { contractWithSign, currentChestInfo, myBoughtTickets, lastChestInfo, loadMyBoughtTickets } = useMonopotStore()


    const [myWinTicketIds, setMyWinTicketIds] = useState<WonTicketType[]>([])
    const [isOpenWonTicketModal, setisOpenWonTicketModal] = useState<boolean>(false)
    const [isClaiming, setIsClaiming] = useState<boolean>(false)
    const [hasClaimedLastRw, setHasClaimedLastRw] = useState<boolean>(false)

    const [isOpenSucessClaimedRw, setIsOpenSucessClaimedRw] = useState<boolean>(false)
    const [myReward, setMyReward] = useState<{
        hash: string;
        musd: string;
        tax: string;
    }>({
        hash: '',
        musd: '',
        tax: ''
    })

    const remainWonTickets = useMemo(() => myWinTicketIds.filter(i => !i.hasClaimed).slice(0, 100), [myWinTicketIds])

    const firstWonTicketList = useMemo(() => {
        const arr6: WonTicketType[] = myWinTicketIds.filter(i => i.matchedSize == 6)
        const arr5: WonTicketType[] = myWinTicketIds.filter(i => i.matchedSize == 5)
        const arr4: WonTicketType[] = myWinTicketIds.filter(i => i.matchedSize == 4)
        const arr3: WonTicketType[] = myWinTicketIds.filter(i => i.matchedSize == 3)
        const arr2: WonTicketType[] = myWinTicketIds.filter(i => i.matchedSize == 2)
        const arr1: WonTicketType[] = myWinTicketIds.filter(i => i.matchedSize == 1)

        return [
            arr6,
            arr5,
            arr4,
            arr3,
            arr2,
            arr1,
        ];
    }, [remainWonTickets])

    const getMyWonReward = useCallback((index: number, rwIndex: number) => firstWonTicketList[index].length * (lastChestInfo?.monoPerBracket[rwIndex] ?? 0), [firstWonTicketList])

    const myTotalPrize = useMemo(() => {
        const totalRw = getMyWonReward(0, 5) +
            getMyWonReward(1, 4) +
            getMyWonReward(2, 3) +
            getMyWonReward(3, 2) +
            getMyWonReward(4, 1) +
            getMyWonReward(5, 0)
        if (totalRw > 0)
            setMyReward(state => ({
                ...state,
                musd: formatXpNumber(totalRw),
                tax: formatXpNumber(totalRw * 0.15)
            }))
        return totalRw
    }, [firstWonTicketList, currentChestInfo])

    const totalWonTicketCnt = useMemo(() => {
        let total = 0
        for (let index = 0; index < 6; index++) {
            const element = firstWonTicketList[index];
            if (element.length > 0) total++
        }
        return total
    }, [firstWonTicketList])

    const claimWonTickets = async () => {
        checkUnsupportChainId(chainId)
        if (isClaiming || myWinTicketIds.length == 0) return

        setIsClaiming(true)
        try {
            const tx = await contractWithSign?.claimTickets(lastChestInfo?.id, remainWonTickets.map(i => i.id), remainWonTickets.map(i => i.wonNums))
            await tx.wait()
            setMyReward(state => ({
                ...state,
                hash: tx.hash
            }))
            setIsOpenSucessClaimedRw(true)
            notification.success({
                message: 'Claim successfully'
            })
            await loadMyBoughtTickets(lastChestInfo?.id as number)
        }
        catch (err) {
            notification.error({
                message: 'Claim won tickets failed'
            })
            console.log('claim won tickets failed', err)
        }
        finally {
            setIsClaiming(false)
        }
    }


    useEffect(() => {
        if (!lastChestInfo || lastChestInfo.status != 3) return
        const lotteryNum = lastChestInfo.finalNumber.toString().slice(1).split('')
        const {
            wonTicketIds,
            maxMyWonNumLength
        } = getMyWonTicketIds(myBoughtTickets, lotteryNum)

        if (maxMyWonNumLength > 0) {
            setMyWinTicketIds(wonTicketIds)
            if (wonTicketIds.filter(i => i.hasClaimed).length == wonTicketIds.length)
                setHasClaimedLastRw(true)
            else
                setisOpenWonTicketModal(true)
        }
    }, [myBoughtTickets])


    return <>
        <Modal maxWidth={592}
            isOpen={isOpenWonTicketModal}
            onDismiss={() => setisOpenWonTicketModal(false)}>
            <ViewTicketModalWrapper>
                <div
                    style={{
                        position: 'absolute',
                        top: '24px',
                        right: '32px',
                        cursor: 'pointer'
                    }}
                    onClick={() => setisOpenWonTicketModal(false)}>
                    <CloseOutlined rev={undefined} />
                </div>
                <PrizeBox>
                    <PrizeMusd>
                        <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section3/musd-token.png" />

                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <Text color="#EFEFE4" style={{ fontSize: '18px' }}>
                                Prize
                            </Text>
                            <Text className="text" color="#EFEFE4">
                                {formatXpNumber(myTotalPrize, 2)}
                            </Text>
                        </div>
                    </PrizeMusd>

                    <Text className="usd_price" style={{ fontSize: '18px', fontWeight: 500 }} >
                        ${formatXpNumber((myTotalPrize ?? 0) * musdPrice)}
                    </Text>
                </PrizeBox>


                <div>
                    <Text style={{ fontSize: '24px', marginTop: '12px', fontFamily: 'Righteous' }}>
                        Congratulations!
                    </Text>
                    <Text style={{ fontSize: '16px', fontWeight: 400, marginTop: '12px', marginBottom: '21px', display: 'block' }}>
                        Congratulations! You won {totalWonTicketCnt} prizes for:
                    </Text>
                </div>


                {remainWonTickets.length > 0 &&
                    firstWonTicketList.map((tickets, index) => tickets.length > 0 && <div key={index} style={{ maxHeight: '400px', overflow: 'auto', marginTop: '30px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px', paddingRight: '10px' }}>
                            <Text style={{ fontSize: '16px' }}>
                                {tickets.length} First {6 - index} Matched
                            </Text>
                            <Text style={{ fontSize: '16px' }}>
                                <TextLinear>{formatXpNumber(getMyWonReward(index, 6 - index - 1))} MUSD</TextLinear>
                            </Text>
                        </div>
                        <TicketList style={{ paddingRight: '10px' }}>
                            {
                                tickets.map((item, index) => <TicketItem key={index} >
                                    <img className="ticket_bg" src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/lottery/ticket_bg.png" />
                                    <TicketItemNumBox>
                                        <TicketItemNum hasMatched={item.number[0].hasMatched}>
                                            {item.number[0].num}
                                        </TicketItemNum>
                                        <TicketItemNum hasMatched={item.number[1].hasMatched}>
                                            {item.number[1].num}
                                        </TicketItemNum>
                                        <TicketItemNum hasMatched={item.number[2].hasMatched}>
                                            {item.number[2].num}
                                        </TicketItemNum>
                                        <TicketItemNum hasMatched={item.number[3].hasMatched}>
                                            {item.number[3].num}
                                        </TicketItemNum>
                                        <TicketItemNum hasMatched={item.number[4].hasMatched}>
                                            {item.number[4].num}
                                        </TicketItemNum>
                                        <TicketItemNum hasMatched={item.number[5].hasMatched}>
                                            {item.number[5].num}
                                        </TicketItemNum>

                                    </TicketItemNumBox>

                                    <TicketItemNo>
                                        #{item.no}
                                    </TicketItemNo>
                                </TicketItem>)
                            }
                        </TicketList>
                    </div>)

                }

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '30px', }}>
                    <div style={{ display: 'flex', gap: '0', alignItems: 'center' }}>
                        <span style={{ fontSize: '12px' }}>*</span>
                        <p style={{ fontSize: '12px' }}>
                            Claim limit: 100 tickets/txn.
                        </p>
                    </div>

                    <NotchedButtonFill onClick={claimWonTickets} mbFontSize="14px" disabled={hasClaimedLastRw || myWinTicketIds.length == 0}>
                        {
                            isClaiming ? 'CLAIMING' : hasClaimedLastRw ? 'YOU HAVE CLAIMED THE REWARD' : 'CLAIM YOUR REWARD'
                        }
                    </NotchedButtonFill>
                </div>

            </ViewTicketModalWrapper >
        </Modal>
        <Modal isOpen={isOpenSucessClaimedRw}
            onDismiss={() => { setIsOpenSucessClaimedRw(false); if (remainWonTickets.length == 0) setisOpenWonTicketModal(false) }} >
            <SuccessClaimedRwModal onClose={() => setIsOpenSucessClaimedRw(false)} hash={myReward.hash} musd={myReward.musd} tax={myReward.tax} />
        </Modal>
    </>
}