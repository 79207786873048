import { Trace } from '@uniswap/analytics'
import { PageName } from '@uniswap/analytics-events'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ButtonText } from 'theme'
import { useNavigate } from 'react-router-dom'
import { ExternalLink } from 'theme'
const Footers = styled.div`
  font-size: 50px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  height: 131px;
  width: 76%;
  margin: auto;
  @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
    width: 95%;
  }
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    max-width: 700px;
    width: unset;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    max-width: 300px;
    height: unset;
  }
`

const LogoImg = styled.img`
  width: 26px;
  height: 52px;
  margin: 0;
  cursor: pointer;
`

const SocialBox = styled.div`
  display: flex;
  gap: 8px;
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    display: none;
  }
`
const SocialImg = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`

const SocialBoxMobile = styled.div`
  display: flex;
  gap: 8px;
  display: none;
  justify-content: center;
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    display: flex;
  }
  margin-top: 24px;
  margin-bottom: 36px;
`

const ContentBox = styled.div`
  display: flex;
  gap: 29px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    justify-content: center;
  }
`
const Title = styled.h3`
  color: #efefe4;
  font-size: 16px;
  font-family: Urbanist;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  margin: 0;
  margin-bottom: 16px;
`
const RightCopy = styled.h4`
  // © 2024 MonoSwap. All rights reserved.
  color: #666666;
  font-size: 14px;
  font-family: Urbanist;
  font-weight: 500;
  line-height: 22px;
  word-wrap: break-word;
  margin: 0;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    /* display: none; */
  }
`
const RightCopyMobile = styled.h4`
  // © 2024 MonoSwap. All rights reserved.
  color: #666666;
  font-size: 14px;
  font-family: Urbanist;
  font-weight: 500;
  line-height: 22px;
  word-wrap: break-word;
  margin: 0;
  display: none;
  text-align: center;
  margin: 20px 0;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    display: none;
  }
`

const MenuBox = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 16px;
  column-gap: 120px;
  height: 64px;

  @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
    column-gap: 60px;
  }

  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    column-gap: 40px;
    margin-bottom: 40px;
    grid-template-columns: 30% 30%;
    justify-content: center;
    margin: 10px 0;
    text-align: center;
    min-width: 240px;
  }
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    height: unset;
    grid-template-columns: auto auto auto auto;
    justify-content: space-evenly;
    padding-bottom: 24px;
    margin-bottom: 0;
    border-bottom: 1px solid #efefe459;
    min-width: 320px;
  }
`
const MenuItem = styled.div`
  color: #efefe4;
  font-size: 16px;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
  cursor: pointer;
  height: fit-content;
`

const BlastPointImgBox = styled.div`
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    margin-top: 20px;
  }
`

export default function Footer() {
  const navigate = useNavigate()
  return (
    <>
      <Footers>
        <ContentBox>
          <LogoImg
            src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/footer/logo.png"
            alt="monoswap"
            onClick={() => navigate('/home')}
          />
          <div>
            <Title>
              The v3 liquidity hub with <br />
              Native Yield
            </Title>
            <RightCopy>© 2024 MonoSwap. All rights reserved.</RightCopy>
          </div>
        </ContentBox>
        <ExternalLink href="https://blast.io">
          <BlastPointImgBox>
            <img
              style={{
                width: '214px',
                height: '86px',
              }}
              src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/footer/blast-point.png"
            />
          </BlastPointImgBox>
        </ExternalLink>

        {/* <ExternalLink href="https://pyth.network/">
          <BlastPointImgBox>
            <img
              style={{
                width: '210px',
                height: '86px',
              }}
              src="/footer/pyth.png"
            />
          </BlastPointImgBox>
        </ExternalLink> */}

        <SocialBox>
          <ExternalLink href="https://twitter.com/monoswapio">
            <SocialImg
              src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/footer/x-sns.png"
              alt="monoswap"
            />
          </ExternalLink>
          <ExternalLink href="https://discord.gg/monoswap">
            <SocialImg
              src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/footer/discord-sns.png"
              alt="monoswap"
            />
          </ExternalLink>
          <ExternalLink href="https://t.me/monoswapnews">
            <SocialImg
              src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/footer/tele-sns.png"
              alt="monoswap"
            />
          </ExternalLink>
          <ExternalLink href="https://monoswapio.medium.com/">
            <SocialImg
              src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/footer/other-sns.png"
              alt="monoswap"
            />
          </ExternalLink>
        </SocialBox>

        <MenuBox>
          <MenuItem>
            <ButtonText style={{ color: 'white' }} onClick={() => navigate('/swap/')}>
              App
            </ButtonText>
          </MenuItem>
          <MenuItem>
            <ExternalLink href="https://linktr.ee/monoswap">Linktree</ExternalLink>
          </MenuItem>
          <MenuItem>
            <ExternalLink href="https://docs.monoswap.io/">Docs</ExternalLink>
          </MenuItem>
          <MenuItem>
            <ExternalLink href="https://bit.ly/monoswap-media">Media Kit</ExternalLink>
          </MenuItem>
        </MenuBox>

        <SocialBoxMobile>
          <ExternalLink href="https://twitter.com/monoswapio">
            <SocialImg
              src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/footer/x-sns.png"
              alt="monoswap"
            />
          </ExternalLink>
          <ExternalLink href="https://discord.gg/2fPQhuHPyX">
            <SocialImg
              src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/footer/discord-sns.png"
              alt="monoswap"
            />
          </ExternalLink>
          <ExternalLink href="https://t.me/monoswapnews">
            <SocialImg
              src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/footer/tele-sns.png"
              alt="monoswap"
            />
          </ExternalLink>
          <ExternalLink href="https://monoswapio.medium.com/">
            <SocialImg
              src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/footer/other-sns.png"
              alt="monoswap"
            />
          </ExternalLink>
        </SocialBoxMobile>
      </Footers>
      {/* for mobile */}

      <RightCopyMobile>© 2024 MonoSwap. All rights reserved.</RightCopyMobile>
    </>
  )
}
