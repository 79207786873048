import { SupportedChainId } from './chains'

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY
if (typeof INFURA_KEY === 'undefined') {
  throw new Error(`REACT_APP_INFURA_KEY must be a defined environment variable`)
}

/**
 * Fallback JSON-RPC endpoints.
 * These are used if the integrator does not provide an endpoint, or if the endpoint does not work.
 *
 * MetaMask allows switching to any URL, but displays a warning if it is not on the "Safe" list:
 * https://github.com/MetaMask/metamask-mobile/blob/bdb7f37c90e4fc923881a07fca38d4e77c73a579/app/core/RPCMethods/wallet_addEthereumChain.js#L228-L235
 * https://chainid.network/chains.json
 *
 * These "Safe" URLs are listed first, followed by other fallback URLs, which are taken from chainlist.org.
 */
export const FALLBACK_URLS: { [key in SupportedChainId]: string[] } = {
  [SupportedChainId.MAINNET]: [
    // "Safe" URLs
    'https://api.mycryptoapi.com/eth',
    'https://cloudflare-eth.com',
    // "Fallback" URLs
    'https://rpc.ankr.com/eth',
    'https://eth-mainnet.public.blastapi.io',
  ],
  [SupportedChainId.BLAST_SEPOLIA]: [
    // "Safe" URLs
    'https://sepolia.blast.io',
    'https://rpc.ankr.com/blast_testnet_sepolia/f6ad6ca1259a3d3abed706aa830a500c286ce6f5cfef8f7a69fa68099a4183d5',
    'https://blast-sepolia.drpc.org',
    'https://blast-sepolia.infura.io/v3/51902a9fb5a64318ad0b5816ba1e1bd7',
    'https://blastl2-sepolia.public.blastapi.io',
    'https://blastl2-sepolia.blastapi.io/25edaf71-ea42-49fe-8167-2bff77ee20e8',
    'https://lb.drpc.org/ogrpc?network=blast-sepolia&dkey=As36L5eMwkOVmtGMCGK_bSPcX7McJOoR76A7hkHL9tz4',
    'https://go.getblock.io/789213c1c19e48c3aa75ea9280523292'
    // 'https://sepolia.blast.io',
    // 'https://blast-sepolia.drpc.org',
  ],
  [SupportedChainId.BLAST]: [
    // "Safe" URLs
    'https://rpc.blast.io',
    'https://rpc.ankr.com/blast',
    'https://rpc.ankr.com/blast',
    // 'https://rpc.envelop.is/blast',
    'https://blastl2-mainnet.public.blastapi.io',
    'https://rpc.blast.io',
  ],
}

/**
 * Known JSON-RPC endpoints.
 * These are the URLs used by the interface when there is not another available source of chain data.
 */
export const RPC_URLS: { [key in SupportedChainId]: string[] } = {
  [SupportedChainId.MAINNET]: [
    `https://mainnet.infura.io/v3/${INFURA_KEY}`,
    ...FALLBACK_URLS[SupportedChainId.MAINNET],
  ],
  [SupportedChainId.BLAST_SEPOLIA]: FALLBACK_URLS[SupportedChainId.BLAST_SEPOLIA],
  [SupportedChainId.BLAST]: FALLBACK_URLS[SupportedChainId.BLAST],
}
