import { useWeb3React } from '@web3-react/core'
import { NotchedButtonBolderNew } from 'components/Button/NotchedButton'
import useCountdownTimer from 'hooks/useCountdownTimer'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BREAKPOINTS } from 'theme'
import { isMobile } from 'utils/userAgent'

const LabelText = styled.span`
  color: rgba(239, 239, 228, 0.5);
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.5px; /* 17.5px */
  opacity: 0.5;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 13px;
    line-height: 15.5px;
  }
`

const ContentText = styled.span`
  color: #efefe4;
  font-family: Righteous;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 24px */
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 18px;
    line-height: 22.5px;
  }
`
const ItemBox = styled.div`
  display: grid;
  gap: 8px;
`

const ContentBox = styled.div`
  width: 100%;
  height: 100%;
  padding: 32px 40px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 16px 30px;
  }
`

const TextLinear = styled.span`
  background: linear-gradient(90deg, #68FFFF, #68FF9B);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
const RemainCountdown = ({ endTime }: any) => {
  const { days, hours, minutes, seconds } = useCountdownTimer('DAY', endTime)
  return <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
    <TextLinear>
      {days}D : {hours}H : {minutes}M : {seconds}S
    </TextLinear>
    <img
      style={{ width: '16px', height: '16p', cursor: 'pointer' }}
      src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/more-info-icon.png"
    />
  </div>
}

export default function MyNftStats({
  refCode,
  totalInvited,
  totalMinted,
  endTime,
}: {
  refCode: string
  totalInvited: number
  totalMinted: number
  endTime: number
}) {
  const { account, chainId } = useWeb3React();
  const [hasCopied, setHasCopied] = useState<boolean>(false)
  const [refLink, setRefLink] = useState<string>('')
  const handleCopyRefLink = () => {
    navigator.clipboard.writeText(refLink)
    setHasCopied(true)
    setTimeout(() => {
      setHasCopied(false)
    }, 5000)
  }
  useEffect(() => {
    setRefLink(`${window.location.protocol}//${window.location.host}/#/monopass-mint/ref=${refCode}`)
  }, [account, chainId])

  return (
    <div style={{ marginTop: '20px' }}>
      <NotchedButtonBolderNew
        w="100%"
        h="371px"
        borderColor="#efefe447"
        bg="#111015"
        clipPath="polygon(6% 0,100% 0,100% 92%, 95% 100%,0 100%,0 29px)"
      >
        <ContentBox>
          <div style={{ borderBottom: '1px solid rgba(239, 239, 228, 0.12)', paddingBottom: '24px', display: 'flex', justifyContent: 'space-between', alignItems: ' center' }}>
            <ItemBox>
              <LabelText>Invitation Code</LabelText>
              <ContentText>{refCode}</ContentText>
            </ItemBox>

            <RemainCountdown endTime={endTime} />
          </div>

          <div style={{ borderBottom: '1px solid rgba(239, 239, 228, 0.12)', padding: '24px 0' }}>
            <div>
              <ContentText style={{ fontSize: isMobile ? '18px' : '21px', fontWeight: 600, fontFamily: 'Urbanist' }}>
                Invite more to Mint more
              </ContentText>
              <div style={{ marginTop: '16px', marginBottom: '8px' }}>
                <LabelText>Invitation Link</LabelText>
              </div>
              <div
                style={{
                  background: 'rgba(239, 239, 228, 0.05)',
                  padding: '13px 20px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignContent: 'center',
                }}
              >
                <ContentText
                  style={{
                    fontSize: isMobile ? '14px' : '18px',
                    lineHeight: isMobile ? '17.5px' : '22.5px',
                    fontFamily: 'Urbanist',
                  }}
                >
                  {refLink}
                </ContentText>

                {hasCopied ? (
                  <span
                    style={{
                      fontFamily: 'Urbanist',
                      background: 'linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%)',
                      backgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      WebkitBackgroundClip: 'text',
                    }}
                  >
                    Copied
                  </span>
                ) : (
                  <svg
                    onClick={handleCopyRefLink}
                    style={{ cursor: 'pointer' }}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 16C2.9 16 2 15.1 2 14V4C2 2.9 2.9 2 4 2H14C15.1 2 16 2.9 16 4M10 8H20C21.1046 8 22 8.89543 22 10V20C22 21.1046 21.1046 22 20 22H10C8.89543 22 8 21.1046 8 20V10C8 8.89543 8.89543 8 10 8Z"
                      stroke="url(#paint0_linear_25_140807)"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_25_140807"
                        x1="2"
                        y1="11.083"
                        x2="22"
                        y2="11.083"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#68FFFF" />
                        <stop offset="1" stopColor="#68FF9B" />
                      </linearGradient>
                    </defs>
                  </svg>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', gap: '56px', marginTop: '24px' }}>
            <ItemBox>
              <LabelText>Invited</LabelText>
              <ContentText>{totalInvited.toLocaleString('en-US')}</ContentText>
            </ItemBox>

            <ItemBox>
              <LabelText>Minted</LabelText>
              <ContentText>{totalMinted.toLocaleString('en-US')}</ContentText>
            </ItemBox>

            <ItemBox>
              <LabelText>Minting slots left</LabelText>
              <ContentText>{Math.max(0, totalInvited - totalMinted).toLocaleString('en-US')}</ContentText>
            </ItemBox>
          </div>
        </ContentBox>
      </NotchedButtonBolderNew>
    </div>
  )
}
