import { useWeb3React } from '@web3-react/core'
import { getChainInfo } from 'constants/chainInfo'
import { SupportedChainId } from 'constants/chains'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import useSelectChain from 'hooks/useSelectChain'
import useSyncChainQuery from 'hooks/useSyncChainQuery'
import { Box } from 'nft/components/Box'
import { Portal } from 'nft/components/common/Portal'
import { Column, Row } from 'nft/components/Flex'
import { subhead } from 'nft/css/common.css'
import { useIsMobile } from 'nft/hooks'
import { useCallback, useRef, useState } from 'react'
import { AlertTriangle, ChevronDown, ChevronUp } from 'react-feather'
import styled, { useTheme } from 'styled-components/macro'

import * as styles from './ChainSelector.css'
import ChainSelectorRow from './ChainSelectorRow'
import { NavDropdown } from './NavDropdown'

const NETWORK_SELECTOR_CHAINS = [
  SupportedChainId.BLAST,
  // SupportedChainId.BLAST_SEPOLIA,
]

interface ChainSelectorProps {
  leftAlign?: boolean
}

const SelectChainBoxBg = styled.div`
  padding: 20px 0px;
  min-width: 256px;
  border: 2px solid rgba(239, 239, 228, 0.2);
  background-color: rgba(239, 239, 228, 0.1);
  backdrop-filter: blur(4.8px);
  box-shadow: 8px 12px 20px rgba(51, 53, 72, 0.04), 4px 6px 12px rgba(51, 53, 72, 0.02),
    4px 4px 8px rgba(51, 53, 72, 0.04);
`

export const ChainSelector = ({ leftAlign }: ChainSelectorProps) => {
  const { chainId } = useWeb3React()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const isMobile = useIsMobile()
  const { account } = useWeb3React()
  const isAuthenticated = !!account
  const theme = useTheme()

  const ref = useRef<HTMLDivElement>(null)
  const modalRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setIsOpen(false), [modalRef])

  const info = chainId ? getChainInfo(chainId) : undefined

  const selectChain = useSelectChain()
  useSyncChainQuery()

  const [pendingChainId, setPendingChainId] = useState<SupportedChainId | undefined>(undefined)

  const onSelectChain = useCallback(
    async (targetChainId: SupportedChainId) => {
      setPendingChainId(targetChainId)
      await selectChain(targetChainId)
      setPendingChainId(undefined)
      setIsOpen(false)
    },
    [selectChain, setIsOpen]
  )

  // if (!chainId) {
  //   return null
  // }

  // const isSupported = !!info
  // const isSupported = !!info && chainId === SupportedChainId.SCROLL_ALPHA;
  
  const isSupported = !!info && (chainId === SupportedChainId.BLAST || chainId === SupportedChainId.BLAST_SEPOLIA)

  const dropdown = (
    <NavDropdown top="56" left={leftAlign ? '0' : 'auto'} right={leftAlign ? 'auto' : '0'} ref={modalRef}>
      <SelectChainBoxBg>
        <Column paddingX="16">
          {NETWORK_SELECTOR_CHAINS.map((chainId: SupportedChainId) => (
            <ChainSelectorRow
              onSelectChain={onSelectChain}
              targetChain={chainId}
              key={chainId}
              isPending={chainId === pendingChainId}
            />
          ))}
        </Column>
      </SelectChainBoxBg>
    </NavDropdown>
  )

  const chevronProps = {
    height: 25,
    width: 25,
    color: '#EFEFE4',
    opacity: 0.6,
  }

  return (
    <div>
      {isAuthenticated && (
        <Box position="relative" ref={ref}>
          <Row
            as="button"
            gap="8"
            className={styles.ChainSelector}
            background="none"
            onClick={() => setIsOpen(!isOpen)}
          >
            {!isSupported ? (
              <>
                {/* <img
                  src={getChainInfo(SupportedChainId.BLAST_SEPOLIA)?.logoUrl}
                  alt={getChainInfo(SupportedChainId.BLAST_SEPOLIA)?.label}
                  className={styles.Image}
                /> */}

                <Box
                  as="span"
                  className={subhead}
                  display={{ sm: 'flex', lg: 'flex' }}
                  fontSize={{ sm: '10', lg: '16' }}
                  alignItems={'center'}
                  gap={'2'}
                  style={{
                    lineHeight: '20px',
                    whiteSpace: 'nowrap',
                    color: '#efefe4',
                    fontWeight: '400',
                    opacity: 0.6,
                  }}
                >
                  <AlertTriangle color={theme.accentWarning} size={16} display="flex" />
                  UNSUPPORTED
                </Box>
              </>
            ) : (
              <>
                <img src={info.logoUrl} alt={info.label} className={styles.Image} />
                <Box
                  className={subhead}
                  display={{ sm: 'none', xxxl: 'block' }}
                  style={{
                    lineHeight: '20px',
                    whiteSpace: 'nowrap',
                    color: '#efefe4',
                    fontSize: '18px',
                    fontWeight: '400',
                    opacity: '0.6',
                    textTransform: 'uppercase',
                  }}
                >
                  {info.label}
                </Box>
              </>
            )}
            {isOpen ? <ChevronUp {...chevronProps} /> : <ChevronDown {...chevronProps} />}
          </Row>
          {isOpen && (isMobile ? <Portal>{dropdown}</Portal> : <>{dropdown}</>)}
        </Box>
      )}
    </div>
  )
}
