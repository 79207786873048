import { useEffect, useState } from "react"
import { BLAST_MUSD_ADDRESSES, BLAST_USDB_ADDRESSES } from "constants/addresses"
import musd_abi from '../abis/musd/musd.json'
import usdb_abi from '../abis/musd/usdb.json'
import { getContract } from "utils"
import { ethers } from "ethers"
import { RPC_URLS } from "constants/networks"
import { SupportedChainId } from "constants/chains"




export default function useMusd() {
    const [musdPrice, setMusdPrice] = useState<number>(1.24)
    const [usdbPrice, setUsdbPrice] = useState<number>(1)
    const musdContract = getContract(
        BLAST_MUSD_ADDRESSES,
        musd_abi,
        new ethers.providers.JsonRpcProvider(RPC_URLS[SupportedChainId.BLAST][0])
    )
    const usdbContract = getContract(
        BLAST_USDB_ADDRESSES,
        usdb_abi,
        new ethers.providers.JsonRpcProvider(RPC_URLS[SupportedChainId.BLAST][0])
    )

    const loadMusdPrice = async () => {
        const musdPriceRes = await musdContract?.getPrice()
        if(!musdPriceRes) return
        setMusdPrice(Number(ethers.utils.formatUnits(musdPriceRes, 8)))
    }
    const loadUsdbPrice = async () => {
        const usdbPriceRes = await usdbContract?.price()
        if(!usdbPriceRes) return
        setUsdbPrice(Number(ethers.utils.formatUnits(usdbPriceRes, 9)))
    }
    useEffect(() => {
        loadMusdPrice()
        loadUsdbPrice()
    }, [])

    return {
        musdPrice,
        usdbPrice
    }
}