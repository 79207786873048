import { ethers } from 'ethers'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

type MonoPotStoreType = {
  account?: string
  contractWithSign: any
  contractWithNoSign: any
  latestChestInfo?: ChestInfoType
  lastChestInfo?: ChestInfoType
  currentChestInfo?: ChestInfoType
  myCurrentBoughtTickets: TicketType[]
  myBoughtTickets: TicketType[]
  hasClaimedLatestRw: boolean
  hasClaimedLastRw: boolean
  isLoadingCurrentChestInfo: boolean
  isLoadingLatestChestInfo: boolean
  isLoadingLastChestInfo: boolean
  buyTicket: (tickets: number[], isUseTicket: boolean) => Promise<void>
  loadCurrentChest: () => Promise<void>
  loadMyCurrentBoughtTickets: (chestId?: number) => Promise<void>
  loadMyBoughtTickets: (chestId: number) => Promise<void>
  generateTicket: () => number
  loadLatestChestInfo: (chestId: number) => Promise<void>
  loadLastChestInfo: (chestId: number) => Promise<void>
  setContractWithSign: (contract: any) => void
  setContractWithNoSign: (contract: any) => void
  setAccount: (account: any) => void
}

export type TicketType = {
  id: number
  no?: number
  hasChose?: boolean
  num: string
  hasClaimed?: boolean 
}

export type ChestInfoType = {
  id: number
  status: number //0 is pending, 1 is open, 3 is close
  startTime: number
  endTime: number
  priceTicketInMono: number
  discountDivisor: number

  rewardsBreakdown: number[]
  treasuryFee: number
  monoPerBracket: number[]
  countWinnersPerBracket: number[]

  firstTicketId: number
  firstTicketIdNextChest: number
  amountCollectedInMono: number
  finalNumber: number
}

export type WonTicketType = {
  id: number
  no: number
  number: {
    num: number
    hasMatched: boolean
  }[]
  matchedSize: number
  wonNums: number
  hasClaimed?: boolean
}

export const useMonopotStore = create<MonoPotStoreType>()(
  devtools((set, get) => ({
    account: undefined,
    contractWithSign: undefined,
    contractWithNoSign: undefined,
    latestChestInfo: undefined,
    currentChestInfo: undefined,
    myCurrentBoughtTickets: [],
    myBoughtTickets: [],
    hasClaimedLatestRw: true,
    hasClaimedLastRw: true,
    isLoadingCurrentChestInfo: false,
    isLoadingLatestChestInfo: false,
    isLoadingLastChestInfo: false,
    buyTicket: async (tickets: number[], isUseTicket: boolean) => {
      const gasEstimate = await get().contractWithSign?.estimateGas.buyTickets(
        get().currentChestInfo?.id,
        tickets.map((i) => reverseTicketNumber(i.toString())),
        isUseTicket
      )

      const gasLimitCalc = Math.floor(gasEstimate ? gasEstimate.toNumber() * 1.5 : tickets.length * 200000)

      const tx = await get().contractWithSign?.buyTickets(
        get().currentChestInfo?.id,
        tickets.map((i) => reverseTicketNumber(i.toString())),
        isUseTicket,
        {
          gasLimit: gasLimitCalc
        }
      )
      await tx.wait()
      get().loadCurrentChest()
    },
    loadCurrentChest: async () => {
      if (get().isLoadingCurrentChestInfo) return
      try {
        set(() => ({
          isLoadingCurrentChestInfo: true,
        }))
        const currentPotId = Number((await get().contractWithNoSign?.viewCurrentPotId()).toString())
        const chestInfoRes = await get().contractWithNoSign?.viewPot(currentPotId)

        if (!chestInfoRes) return
        set(() => ({
          currentChestInfo: parseChestInfoResponse(currentPotId, chestInfoRes),
        }))
        await get().loadLatestChestInfo(currentPotId)
        if (currentPotId - 1 >= 0) await get().loadLastChestInfo(currentPotId - 1)
      } catch (err) {
      } finally {
        set(() => ({
          isLoadingCurrentChestInfo: false,
        }))
      }
    },
    loadMyCurrentBoughtTickets: async (potId?: number) => {
      const ticketList: TicketType[] = await getUserTickets(
        potId ?? get().currentChestInfo?.id ?? 0,
        get().contractWithNoSign,
        get().account ?? ''
      )
      set(() => ({
        myCurrentBoughtTickets: ticketList,
      }))
    },
    loadMyBoughtTickets: async (potId: number) => {
      const ticketList: TicketType[] = await getUserTickets(potId, get().contractWithNoSign, get().account ?? '')
      set(() => ({
        myBoughtTickets: ticketList,
      }))
    },
    generateTicket: () => {
      let ticketNum = randomTicket()
      while (true)
        if (get().myCurrentBoughtTickets.find(({ num }) => Number(num) == ticketNum)) ticketNum = randomTicket()
        else break
      return ticketNum
    },
    loadLatestChestInfo: async (potId: number) => {
      if (potId <= 0 || get().isLoadingLatestChestInfo) return
      try {
        set(() => ({
          isLoadingLatestChestInfo: true,
        }))
        const chestInfoRes = await get().contractWithNoSign?.viewPot(potId)
        set(() => ({
          latestChestInfo: parseChestInfoResponse(potId, chestInfoRes),
        }))
        await get().loadMyCurrentBoughtTickets(potId)
      } catch (err) {
      } finally {
        set(() => ({
          isLoadingLatestChestInfo: false,
        }))
      }
    },
    loadLastChestInfo: async (potId: number) => {
      if (get().isLoadingLastChestInfo) return
      try {
        set(() => ({
          isLoadingLastChestInfo: true,
        }))

        if (potId <= 0) return
        const chestInfoRes = await get().contractWithNoSign?.viewPot(potId)
        if (!chestInfoRes) return
        set(() => ({
          lastChestInfo: parseChestInfoResponse(potId, chestInfoRes),
        }))
        await get().loadMyBoughtTickets(potId)
      } catch (err) {
      } finally {
        set(() => ({
          isLoadingLastChestInfo: false,
        }))
      }
    },
    setContractWithSign: (contract: any) => {
      set(() => ({ contractWithSign: contract }))
    },
    setContractWithNoSign: (contract: any) => {
      set(() => ({ contractWithNoSign: contract }))
    },
    setAccount: (account: string) => {
      set(() => ({
        account,
      }))
      get().loadMyCurrentBoughtTickets(get().currentChestInfo?.id ?? 0)
    },
  }))
)

async function getUserTickets(chestId: number, contract: any, account?: string) {
  if (!account || chestId == 0) return []
  const ticketData = await contract?.viewUserInfoForPotId(account, chestId, 0, 100000)
  if (!ticketData || ticketData[1].length <= 0) return []
  const ticketIdRes = [...ticketData[0]].reverse()
  const ticketRes = [...ticketData[1]].reverse()
  const hasClaimedTicketRes = [...ticketData[2]].reverse()
  const ticketList: TicketType[] = []
  let tickNo = 1
  ticketRes.forEach((num: any, index: number) => {
    ticketList.push({
      id: Number(ticketIdRes[index]),
      no: tickNo++,
      num: reverseTicketNumber(num.toString()).toString(),
      hasClaimed: hasClaimedTicketRes[index]
    })
  })
  return ticketList
}
function parseChestInfoResponse(chestId: number, chestInfoRes: any): ChestInfoType {
  return {
    id: chestId,
    status: Number(chestInfoRes?.status),
    startTime: chestInfoRes?.startTime.toString(),
    endTime: chestInfoRes?.endTime.toString(),
    priceTicketInMono: Number(ethers.utils.formatUnits(chestInfoRes?.priceTicketInReward)),
    discountDivisor: Number(ethers.utils.formatUnits(chestInfoRes?.discountDivisor)),

    rewardsBreakdown: chestInfoRes?.rewardsBreakdown.map((i: any) => Number(i?.toString())),
    treasuryFee: Number(chestInfoRes.treasuryFee),
    monoPerBracket: chestInfoRes?.rewardPerBracket.map((i: any) => Number(ethers.utils.formatUnits(i))),
    countWinnersPerBracket: chestInfoRes?.countWinnersPerBracket.map((i: any) => Number(i?.toString())),

    firstTicketId: chestInfoRes?.firstTicketId.toString(),
    firstTicketIdNextChest: chestInfoRes?.firstTicketIdNextPot.toString(),
    amountCollectedInMono: Number(ethers.utils.formatUnits(chestInfoRes?.amountCollectedInReward)),
    finalNumber: reverseTicketNumber(chestInfoRes?.finalNumber.toString()),
  }
}

export function getMyWonTicketIds(myTickets: TicketType[], lotteryNumbers: string[]) {
  const wonTicketIds: WonTicketType[] = []
  let myWonNums: string[] = ['-1', '-1', '-1', '-1', '-1', '-1']
  let maxMyWonNumLength = 0

  let wonNo = 1
  for (let i1 = 0; i1 < myTickets.length; ++i1) {
    const ticketNums = myTickets[i1].num.slice(1).split('')
    const wonNums: string[] = ['-1', '-1', '-1', '-1', '-1', '-1']

    if (ticketNums[0] == lotteryNumbers[0]) {
      wonNums[0] = ticketNums[0]
      if (ticketNums[1] == lotteryNumbers[1]) {
        wonNums[1] = ticketNums[1]
        if (ticketNums[2] == lotteryNumbers[2]) {
          wonNums[2] = ticketNums[2]
          if (ticketNums[3] == lotteryNumbers[3]) {
            wonNums[3] = ticketNums[3]
            if (ticketNums[4] == lotteryNumbers[4]) {
              wonNums[4] = ticketNums[4]
              if (ticketNums[5] == lotteryNumbers[5]) {
                wonNums[5] = ticketNums[5]
              }
            }
          }
        }
      }
    }

    let wonNumsCheck = wonNums.filter((i) => i != '-1')

    if (wonNumsCheck.length > 0) {
      wonTicketIds.push({
        id: myTickets[i1].id,
        no: wonNo++,
        number: wonNums.map((i, index) => ({
          num: Number(ticketNums[index]),
          hasMatched: i != '-1',
        })),
        matchedSize: wonNumsCheck.length,
        wonNums: wonNumsCheck.length - 1,
        hasClaimed: myTickets[i1].hasClaimed
      })

      if (wonNumsCheck.length > maxMyWonNumLength) {
        maxMyWonNumLength = wonNumsCheck.length
        myWonNums = wonNums
      }
    }
  }

  return {
    wonTicketIds,
    myWonNums,
    maxMyWonNumLength,
  }
}

const randomTicket = () => {
  const min = 0
  const max = 1000000
  let randomNumber = Math.floor(Math.random() * (max - min + 1)) + min

  if (randomNumber < 1000000) randomNumber += 1000000
  return randomNumber
}

const reverseTicketNumber = (number: string) => {
  if (number.length == 1) return Number(number)
  let firstNum = number.slice(0, 1)
  const lastNum = number.slice(1).split('').reverse().join('')
  return Number(`${firstNum}${lastNum}`)
}
