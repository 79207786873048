import { NotchedButtonFill } from 'components/Button'
import styled from 'styled-components'
import { BREAKPOINTS } from 'theme'
import { useNavigate } from 'react-router-dom'

const FinalSectionWrapper = styled.section`
  width: 100%;
  height: 440px;
  background: linear-gradient(90deg, #68ffff 0%, #68ff9b 100%);
  box-shadow: 0px 20px 80px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
    height: 380px;
  }
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    height: 300px;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    height: 200px;
    position: relative;
    flex-direction: column-reverse;
    justify-content: flex-end;
    height: 496px;
  }
`

const MainContentWrapper = styled.div`
  //   max-width: 390px;
  width: 40%;
  margin-left: 222px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
    margin-left: 100px;
  }
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    margin-left: 50px;
    scale: 0.8;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    scale: 0.7;
    margin-left: 15px;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    scale: 1;
    margin: auto;
    width: 100%;
    padding: 0 15px;
  }
`
const Title = styled.h2`
  color: #0a090f;
  font-size: 48px;
  font-family: Righteous;
  font-weight: 400;
  line-height: 60px;
  word-wrap: break-word;
  margin: 0;
  padding-left: 8px;
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    padding-left: 0;
    max-width: 260px;
  }
`

const SubTitle = styled.h4`
  color: #0b0a10;
  font-size: 18px;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 25.2px;
  word-wrap: break-word;
  margin: 0;
  margin-top: 8px;
  padding-left: 8px;
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 18px;
    font-weight: 600;
    line-height: 25.2px;
    padding-left: 0;
  }
`

const Button = styled.div`
  cursor: pointer;
  position: relative;
  width: fit-content;
  margin-top: 36px;
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    margin-top: 10px;
  }
`

const Background = styled.img`
  width: 50%;
  height: 100%;
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    /* display: none; */
    width: 100%;
    height: 50%;
    padding-left: 15px;
  }
`
const BtnText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #68ffff 0%, #68ff9b 100%), linear-gradient(0deg, #efefe4, #efefe4);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
export default function FinalSection() {
  const navigate = useNavigate()
  return (
    <FinalSectionWrapper>
      <MainContentWrapper>
        <Title>
          Unlock the Power <br /> of Native Yield
        </Title>
        <SubTitle>
          Ready to embark on your journey? <br />
          Swap, add liquidity, and earn now!
        </SubTitle>
        <Button>
          <NotchedButtonFill
            style={{ height: '48px', background: 'black' }}
            mobileMaxW="210px"
            width="220px"
            onClick={() => navigate('/swap/')}
          >
            <BtnText>LAUNCH APP</BtnText>
          </NotchedButtonFill>
        </Button>
      </MainContentWrapper>

      <Background src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/final-section/image-mobile.png" alt="monoswap" />
    </FinalSectionWrapper>
  )
}
