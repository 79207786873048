import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import { Text } from 'rebass'
import './alpha.css'
import { MONO_ADDRESSES_MAP } from '../../constants/addresses'
import { useWeb3React } from '@web3-react/core'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { useCurrency, useToken } from '../../hooks/Tokens'
import { NotchedButtonFill } from '../../components/Button'
import { CustomLightSpinner, ThemedText } from '../../theme'
import { NotchedButtonBolderNew } from 'components/Button/NotchedButton'
import NonfungiblePositionManagerJson from '@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json'
import { SupportedChainId } from '../../constants/chains'
import styled, { css, useTheme } from 'styled-components/macro'
import { AutoColumn } from '../../components/Column'
import { AlertTriangle } from 'react-feather'
import { formatXpNumber } from 'pages/Xp/sections/XpTitle'
import { useAlphaTvlMap } from 'state/alphapool/hooks'
import { PoolV3Info, useAlphaStore } from '../../hooks/useAlphaStore'
import { useToggleWalletModal } from 'state/application/hooks'
import useTokenPrice from 'hooks/useTokenPrice'
import { useAppDispatch } from 'state/hooks'
import { BASE_API } from 'pages/Xp'
//
const { abi: NFTPositionManagerABI } = NonfungiblePositionManagerJson

const maestroImg =
  'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/maestro/createmaestro.svg'
const reload = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/swap/reload.png'
const search = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/maestro/search.svg'
const Circle = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/blue-loader.svg'

export function useMultipleCurrencies(currencyIds: any) {
  const currencies = currencyIds?.map(({ currencyIdA, currencyIdB, incentiveToken }: any) => {
    const currencyA = useCurrency(currencyIdA)
    const currencyB = useCurrency(currencyIdB)
    const incentive = useCurrency(incentiveToken)
    return { currencyA, currencyB, incentive }
  })

  return currencies
}
// get lp of each pool

const Alpha = () => {
  const navigate = useNavigate()
  const [tabSelector, setTabSelector] = useState(1)
  const alphaStore = useAlphaStore()
  const tvlMap = useAlphaTvlMap()

  // state
  const [loadingContractInfo, setLoadingContractInfo] = useState(false)
  const [totalPools, setTotalPools] = useState<PoolV3Info[]>([])
  const [endedPools, setEndedPools] = useState<PoolV3Info[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [poolStatusFilter, setPoolStatusFilter] = useState<'LIVE' | 'ENDED'>('LIVE')

  const getContractInfo = async (force = false) => {
    if (alphaStore.totalV3Pools.length > 0 && !force) return
    if (loadingContractInfo) return
    try {
      setLoadingContractInfo(true)
      await alphaStore.initV3Pools()
    } catch (error) {
      console.log('getContractInfo failed', error)
    }
    finally {
      setLoadingContractInfo(false)
    }
  }

  useEffect(() => {
    if (alphaStore.totalV3Pools.length > 0) {
      setTotalPools(alphaStore.totalV3Pools.filter(item => !item.hasEnded))
      setEndedPools(alphaStore.totalV3Pools.filter(item => item.hasEnded))
    }

  }, [alphaStore.totalV3Pools])
  // search query function
  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value)
  }
  const filteredPools = useMemo(() => {
    if (poolStatusFilter == 'LIVE')
      return totalPools.filter((pool) => {
        return pool?.poolName?.toLowerCase().includes(searchQuery?.toLowerCase())
      })
    else return endedPools.filter((pool) => {
      return pool?.poolName?.toLowerCase().includes(searchQuery?.toLowerCase())
    })
  }, [totalPools, searchQuery, endedPools, poolStatusFilter])

  const reloadFarmingPool = () => {
    getContractInfo(true)
  }

  useEffect(() => {
    getContractInfo()
  }, [])

  return (
    <>
      {(
        <>
          <div className="alpha-top-container-container">
          </div>

          <div className="alpha-container">
            <div className="swap-top-container" style={{ alignItems: 'flex-end' }}>
              <div className="swap-top-left" style={{ alignItems: 'flex-end' }}>
                <img src={maestroImg} alt="swap image" />
                <div className="swap-top-div">
                  <div className='tab-pool-selector-wrapper'>
                    <div className="tab-pool-selector">
                      <NotchedButtonBolderNew w="50px" h="100%" borderColor={'rgba(239, 239, 228, 0.5)'}>
                        <div
                          className={`tab-pool-selector-text ${tabSelector === 1 ? 'tab-pool-active' : ''}`}
                          onClick={() => {
                            setTabSelector(1)
                          }}
                          style={{ cursor: 'not-allowed' }}
                        >
                          V3
                        </div>
                      </NotchedButtonBolderNew>
                      <NotchedButtonBolderNew w="50px" h="100%" borderColor={'rgba(239, 239, 228, 0.5)'}>
                        <div
                          className={`tab-pool-selector-text ${tabSelector === 2 ? 'tab-pool-active' : ''}`}
                          onClick={() => {
                            navigate('/farmingV2')
                            setTabSelector(2)
                          }}
                        // style={{ cursor: 'not-allowed' }}
                        >
                          V2
                        </div>
                      </NotchedButtonBolderNew>
                    </div>

                    <div className='pool-status-filter-box'>
                      <NotchedButtonBolderNew w="80px" h="100%" bg={poolStatusFilter == 'LIVE' ? '#000000' : undefined} disableHover={poolStatusFilter == 'LIVE'} clipPath='unset' borderColor={'linear-gradient(90deg, #68FFFF, #68FF9B)'}>
                        <div className='status-filter-btn' onClick={() => setPoolStatusFilter('LIVE')}>
                          Live
                        </div>
                      </NotchedButtonBolderNew>
                      <NotchedButtonBolderNew w="80px" h="100%" bg={poolStatusFilter == 'ENDED' ? '#000000' : undefined} disableHover={poolStatusFilter == 'ENDED'} clipPath='unset' borderColor={'linear-gradient(90deg, #68FFFF, #68FF9B)'}>
                        <div className='status-filter-btn' onClick={() => setPoolStatusFilter('ENDED')}>
                          Finished
                        </div>
                      </NotchedButtonBolderNew>
                    </div>
                  </div>
                  <p className='page-title'>Pools</p>
                  <p className='page-desc'>Deposit liquidity into Farming pools to earn 3 months of additional xMONO emissions.</p>
                </div>
              </div>
              <div className="swap-top-right" style={{ paddingBottom: '10px' }}>
                <div className="maestro-search">
                  <img src={search} alt="swap image" />
                  <input type="text" onChange={handleSearchChange} />
                </div>
                <div className='alpha-setting'>
                  <img src="/farming/reload_icon.png" alt="monoswap" onClick={reloadFarmingPool} />
                </div>
              </div>
            </div>
          </div>

          <div className="maestro-cover" style={{ marginTop: '24px' }}>
            {loadingContractInfo ? (
              <div style={{ width: '100%', height: '200px', textAlign: 'center', marginTop: '40px' }}>
                <CustomLightSpinner src={Circle} alt="loader" size="32px" />
              </div>
            ) : (
              <div className="alpha-list-card-container">
                <>
                  {filteredPools?.length > 0 &&
                    filteredPools?.map((item: any, index: any) => (<AlphaCardItem alphaInfo={item} key={index} />
                    ))}
                </>
              </div>
            )}
            {
              !loadingContractInfo && filteredPools.length == 0 && <p style={{ textAlign: 'center' }}>
                No pool available
              </p>
            }
          </div>
        </>
      )}
    </>
  )
}

const AlphaCardItem = ({
  alphaInfo
}: any) => {
  const { account } = useWeb3React()
  const toggleWalletModal = useToggleWalletModal()
  const navigate = useNavigate()
  const tvlMap = useAlphaTvlMap()

  const [userTvl, setUserTvl] = useState<number>(0)
  const {
    price: xMonoPriceRes
  } = useTokenPrice(MONO_ADDRESSES_MAP[SupportedChainId.BLAST])

  const xMonoPrice = useMemo(() => xMonoPriceRes == 0 ? 2.1 : xMonoPriceRes, [xMonoPriceRes])

  const poolTvl = useMemo(() => {
    return tvlMap.get(alphaInfo.incentiveId) ?? 0
  }, [tvlMap, alphaInfo])

  const apr = useMemo(() => {
    if (alphaInfo) {
      const aprDay = poolTvl * 90
      const apr = (alphaInfo.totalRewardUnclaimed * xMonoPrice * 365 * 100) / aprDay
      alphaInfo.apr = formatXpNumber(apr)
      return formatXpNumber(apr)
    }
    return 0
  }, [poolTvl, xMonoPrice])


  const tokenA = useToken(alphaInfo.token0)
  const tokenB = useToken(alphaInfo.token1)

  const loadUserTvl = async () => {
    fetch(`${BASE_API}/monoswap/api/v1/pool-xp-multiplier/get-alphapool-tvl?incentive_id=${alphaInfo.incentiveId}&user_address=${account}`)
      .then(res => res.json())
      .then(data => {
        alphaInfo.userTvl = data.userTvl
        setUserTvl(data.userTvl)
      })
  }

  useEffect(() => {
    if (account) loadUserTvl()
  }, [account, alphaInfo])

  return <div className="alpha-list-card" style={{ padding: '20px' }}
  >
    <div className="alpha-list-card-tag"></div>
    <div className="alpha-list-card-detail">
      <div className="alpha-list-card-detail-top">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div className="alpha-list-card-detail-top-img">
            <DoubleCurrencyLogo
              currency0={tokenA ?? undefined}
              currency1={tokenB ?? undefined}
              size={28}
            />
          </div>
          <div>
            <p
              style={{
                color: '#efefe4',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              {tokenA?.symbol}/
              {tokenB?.symbol}{' '}
              <span
                style={{
                  padding: '3px 7px',
                  border: '1px solid #68FF9B',
                  fontSize: '12px',
                }}
              >
                {Number(alphaInfo?.poolFee) / 10000}%
              </span>
            </p>
            {/* <span style={{ color: '#68FFFF', fontSize: '16px' }}>v3</span> */}
          </div>
        </div>

        <div style={{ width: '30%' }}>
          <NotchedButtonFill
            onClick={() => {
              if (account)
                navigate(`/farming/detail/${alphaInfo?.pool}`, { state: alphaInfo })
              else toggleWalletModal()
            }}
            style={{ margin: '4px 0 0 0' }}
            padding={'7px 5px'}
            height={'28px'}
          >
            <Text fontWeight={500} fontSize={18}>
              <Trans>{account ? alphaInfo?.hasEnded ? 'View' : 'Deposit' : 'Connect'}</Trans>
            </Text>
          </NotchedButtonFill>
        </div>
      </div>

      <div className="alpha-list-card-detai--info">
        <div
          className="alpha-list-card-detai--info-child"
          style={{
            borderTop: '1px solid rgba(239,239,228,0.2)',
          }}
        >
          <p>TVL</p>
          <p style={{ marginLeft: '0 !important' }}>
            {' '}
            <span style={{ color: '#efefe4' }}>
              <p>
                ${formatXpNumber(poolTvl)}
              </p>

            </span>
          </p>
        </div>
        <div className="alpha-list-card-detai--info-child">
          <p>APR</p>
          <p>
            <span style={{ color: '#68FF9B' }}>
              {alphaInfo?.hasEnded ? 0 : formatXpNumber(apr)}%
            </span>
          </p>
        </div>

        <div className="alpha-list-card-detai--info-child">
          <p>Incentives</p>
          <div className="alpha-list-card-detai--info-child-right">
            ${formatXpNumber(alphaInfo.totalRewardUnclaimed)}
          </div>
        </div>
        {/* <div className="alpha-list-card-detai--info-child">
                          <p>Requirements</p>
                          <div className="alpha-list-card-detai--info-child-right">
                            <img src={requireIcon} alt="icon" />
                          </div>
                        </div> */}
        <div className="alpha-list-card-detai--info-child">
          <p>Your deposit</p>
          <p>
            $
            {formatXpNumber(userTvl)}
          </p>
        </div>
        {
          alphaInfo?.hasEnded && <div className="alpha-list-card-detai--info-child">
            <p>Status</p>
            <p style={{ padding: 0, color: '#efefe4' }}>
              ENDED
            </p>
          </div>
        }

        {/* <div className="alpha-list-card-detai--info-child">
                          <p>Pool reward</p>
                          <p>{item?.totalRewardUnclaimed}</p>
                        </div> */}
      </div>
    </div>
  </div>
}

const PageWrapper = styled.div`
  padding: 28px 8px 0px;
  width: 80%;

  @media (max-width: 768px) {
    width: 90%;
  }
`

const MainContentWrapper = styled.main`
  background: rgba(239, 239, 228, 0.03);
  border: 1px solid rgba(239, 239, 228, 0.2);
  padding: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
`

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 300px;
  min-height: 25vh;
  color: #efefe4;
`

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`

const NetworkIcon = styled(AlertTriangle)`
  ${IconStyle}
`

export function WrongNetworkCard() {
  const theme = useTheme()

  return (
    <>
      <PageWrapper>
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            <div className="pool-top-container">
              <div className="swap-top-container">
                <div className="swap-top-left">
                  {/*<img src={swaptop} alt="swap image" />*/}
                  <div className="swap-top-div">
                    <p>Farming Pools</p>
                    <p>Deposit liquidity into Farming pools to earn 3 months of additional xMONO emissions.</p>
                  </div>
                </div>
                <div className="swap-top-right">
                  <img src={reload} alt="swap image" />
                </div>
              </div>
            </div>

            <MainContentWrapper>
              <ErrorContainer>
                <ThemedText.DeprecatedBody color={theme.deprecated_text3} textAlign="center">
                  <NetworkIcon strokeWidth={1.2} />
                  <div data-testid="pools-unsupported-err">
                    <Trans>Your connected network is unsupported.</Trans>
                  </div>
                </ThemedText.DeprecatedBody>
              </ErrorContainer>
            </MainContentWrapper>
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
      {/* <SwitchLocaleLink /> */}
    </>
  )
}

export default Alpha
export const numberWithCommas = (number: any) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
