import styled from "styled-components"
import { BREAKPOINTS } from "theme"


const BridgePageWrapper = styled.div`
    width: 100%;
    position: relative;
    min-height: 1751px;
    padding-top: 64px;
    img.bg{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        padding-top: 32px;
    }
`

const BridgePageBody = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
`

const PageTitleBox = styled.div`
    display: grid;
    justify-items: center;
    gap: 20px;
    position: relative;
    z-index: 1;
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        max-width: 90%;
        margin: auto;
    }
`
const PageTitle = styled.h1`
    color: #EFEFE4;
    text-align: center;
    font-family: Righteous;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 60px */
    margin: 0;
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        font-size: 32px;
    }
`
const TextLinear = styled.span`
    background: linear-gradient(90deg, #68FFFF, #68FF9B);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`
const PageDesc = styled.span`
    color: #EFEFE4;
    text-align: center;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        font-size: 14px; 
    }
`

const BridgeList = styled.div`
    max-width: 80%;
    margin: auto;
    margin-top: 109px;
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
    justify-content: center;
    @media screen and (max-width: ${BREAKPOINTS.xl}px){
        max-width: 90%;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        margin-top: 50px;
    }
`

const BridgeItemBox = styled.div`
    width: 100%;
    max-width: 464px;
    height: 176px;
    border: 1px solid #efefe470;
    padding: 24px 20px;
`

const BridgeItemBoxTop = styled.div`
    display: flex;
    justify-content: space-between;
    img.icon{
        width: 25px;
        height: 25px;
        cursor: pointer;
        &:hover{
            scale: 1.05;
            transition: ease-in-out;
            transition-delay: 200ms;
        }
        @media screen and (max-width: ${BREAKPOINTS.xs}px){
            width: 18px;
            height: 18px;
        }
    }
`

const BridgeItemBoxTopLeft = styled.div`
    display: flex;
    gap: 20px;
    width: 100%;
    
    img{
        max-width: 60px;
        max-height: 60px;
        border-radius: 6px;
        @media screen and (max-width: 375px){
            max-width: 50px;
            max-height: 50px;
        }
    }
    h4{
        color: #FFF;
        font-family: Righteous;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 100% */
        margin: 0;
        @media screen and (max-width: 375px){
            font-size: 18px;
        }
    }
    
    &>div{
        width: 100%;
        display: grid;
        gap: 13px;
        @media screen and (max-width: ${BREAKPOINTS.md}px){
            gap: 10px;
        }
        @media screen and (max-width: 375px){
            gap: 7px;
        }
    }
    @media screen and (max-width: 375px){
        gap: 15px;
    }
`
const TagList = styled.div`
    display: flex;
    gap: 15px;
`

const TagItemBorder = styled.div`
    width: fit-content;
    background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
    padding: 1px;
`
const TagItemBorderContent = styled.div`
    height: 100%;
    width: 100%;
    padding: 3px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #111015;
    span{
        font-family: Righteous;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 15px */
        @media screen and (max-width: 375px){
            font-size: 9px;
        }
    }
`

const BridgeItemBoxBottom = styled.div`
    margin-top: 24px;
    span{
        color: #EFEFE4;
        font-family: Urbanist;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 20.8px */
        @media screen and (max-width: 375px){
            font-size: 14px;
        }
    }
`

export default function BridgePage() {
    const BridgeInfoList: {
        name: string
        tags: string[]
        content: string
        link: string
        img: string
    }[] = [
            {
                name: 'Blast',
                tags: ["Official", "Secure", "Airdrop"],
                content: "Bridge to Earn Yield + BLAST POINTS. Airdrop in June",
                link: "https://blast.io/ZA2A1",
                img: "https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/bridge/partners/blast.png"
            },
            {
                name: 'Rhino.fi',
                tags: ["Cheap", "Swap", "Fast"],
                content: "The lightning-fast secure bridge for seamless cross-chain transactions.",
                link: "https://app.rhino.fi?ref=B4C3F56447",
                img: "https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/bridge/partners/rhino.png"
            },
            {
                name: 'Orbiter',
                tags: ["Partner", "Secure", "Fast"],
                content: "Decentralized cross-rollup bridge offers low cost and almost instant transfers.",
                link: "https://dealer.orbiter.finance/?dealerId=0xB4c3f56e271d938B5be2C0a813FC6CABc4C2A4A5",
                img: "https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/bridge/partners/orbit.png"
            },
            {
                name: 'Galaxy Exchange',
                tags: ["Aggregator", "Partner"],
                content: "Swap any token for any token, on any chain.",
                link: "https://galaxy.exchange/swap?affiliate=monoswap",
                img: "https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/bridge/partners/galaxy.png"
            },
            {
                name: 'XY',
                tags: ["Cheap", "Swap"],
                content: "Cross-chain interoperability protocol aggregates swaps and bridges.",
                link: "https://app.xy.finance/",
                img: "https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/bridge/partners/xy.png"
            }, 
            {
                name: 'Bungee',
                tags: ["Aggregator"],
                content: "Bungee aggregates all your favourite bridges and gets you the best routes.",
                link: "https://www.bungee.exchange/",
                img: "https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/bridge/partners/bungee.png"
            },
            {
                name: 'Interport',
                tags: ["Swap"],
                content: "Cross-chain trading platform revolutionizes the way you perform trades.",
                link: "https://app.interport.fi/42161/81457/ETH/ETH",
                img: "https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/bridge/partners/interpot.png"
            },
            {
                name: 'Celer',
                tags: ["ceAssets"],
                content: "Decentralized and non-custodial asset bridge that supports 40+ blockchains.",
                link: "https://cbridge.celer.network/1/81457/WETH",
                img: "https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/bridge/partners/celer.png"
            },
            {
                name: 'Meson',
                tags: ["Stablecoin", "Fast"],
                content: "Fast, safe and costless cross-chain for stablecoins.",
                link: "https://meson.fi/",
                img: "https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/bridge/partners/meson.png"
            },
            {
                name: 'OmniBTC',
                tags: ["Cheap", "BTC"],
                content: "Omnichain DeFi platform connects all the on-chain liquidity.",
                link: "https://app.omnibtc.finance/swap",
                img: "https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/bridge/partners/omni.png"
            },
            {
                name: 'Jumper',
                tags: ["Aggregator", "Swap"],
                content: "Multi-chain liquidity aggregator powered by LI.FI.",
                link: "https://jumper.exchange/?fromChain=1&fromToken=0x0000000000000000000000000000000000000000&toChain=81457&toToken=0x0000000000000000000000000000000000000000",
                img: "https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/bridge/partners/jumper.png"
            },
            {
                name: 'Layerswap',
                tags: ["CEX Cross-Chain"],
                content: "Move crypto across Centralized Exchanges and blockchains.",
                link: "https://layerswap.io/app?to=blast_mainnet",
                img: "https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/bridge/partners/layerswap.png"
            },
            {
                name: 'Rubic',
                tags: ["Aggregator", "Swap"],
                content: "Rubic aggregates the best Web3 cross-chain tech.",
                link: "https://app.rubic.exchange/?fromChain=ETH&toChain=BLAST&to=ETH&from=ETH",
                img: "https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/bridge/partners/rubic.png"
            },
            {
                name: 'Symbiosis',
                tags: ["Swap"],
                content: "Symbiosis pools together liquidity from different networks.",
                link: "https://app.symbiosis.finance/swap?amountIn&chainIn=Ethereum&chainOut=Blast&tokenIn=ETH&tokenOut=ETH",
                img: "https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/bridge/partners/symbiosis.png"
            },
            {
                name: 'Owlto Finance',
                tags: ["Cheap", "Swap"],
                content: "Decentralized cross-rollup bridge focused on L2, providing cheaper and faster transfers.",
                link: "https://owlto.finance/",
                img: "https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/bridge/partners/owito.png"
            },
        ]

    return <BridgePageWrapper>
        <img className="bg" src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/bridge/bg.png" alt="monoswap" />
        <BridgePageBody>
            <PageTitleBox>
                <PageTitle>
                    New to Blast? <TextLinear>MONO</TextLinear> got you
                </PageTitle>
                <PageDesc style={{ maxWidth: '864px' }}>
                    Turn on, tune in & drop out of Blast Network with faster, cheaper and more efficient transactions from our selected bridges.
                </PageDesc>
            </PageTitleBox>

            <BridgeList>
                {
                    BridgeInfoList.map((item, index) => <BridgeItemBox key={index}>
                        <BridgeItemBoxTop>
                            <BridgeItemBoxTopLeft>
                                <img src={item.img} alt="monoswap" />
                                <div>
                                    <h4>{item.name}</h4>
                                    <TagList>
                                        {
                                            item.tags.map((tag, tagIndex) => <TagItemBorder key={tagIndex}>
                                                <TagItemBorderContent>
                                                    <TextLinear>{tag}</TextLinear>
                                                </TagItemBorderContent>
                                            </TagItemBorder>)
                                        }
                                    </TagList>
                                </div>
                            </BridgeItemBoxTopLeft>
                            <img onClick={() => window.open(item.link, '_blank')} className="icon" src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/bridge/external-icon.png" alt="monoswap" />
                        </BridgeItemBoxTop>

                        <BridgeItemBoxBottom>
                            <span>{item.content}</span>
                        </BridgeItemBoxBottom>


                    </BridgeItemBox>)
                }


            </BridgeList>

        </BridgePageBody>
    </BridgePageWrapper>
}