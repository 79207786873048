import { useState, useMemo, useCallback, useEffect } from 'react'
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts'
import styled from 'styled-components'
import { monthDayFormatter } from 'utils/formatChartTimes'
import dayjs from 'dayjs'
export type IdoStatType = {
  price: any
  supply?: any // Made optional
  collateral?: any // Made optional
  createdAt: any
  timestamp: any
  updatedAt: any
}
type TimePeriodType = 'HOUR' | 'DAY' | 'WEEK' | '1MONTH' | 'MONTH' | 'MINUTE'

export const ToolTipBox = styled.div`
  display: flex;
  min-width: 57.949px;
  flex-direction: column;
  align-items: start;
  padding: 12px;
  background: var(--primary-000000, #000);  
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.20);
  
  span {
    color: #EFEFE4;
    text-align: center;
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 160% */
  }
  span:last-child{
    background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

export const useTimestampFormater = (timePeriod: TimePeriodType) =>
  useCallback(
    (timestamp: number) => {
      const dayjsFn = dayjs(new Date(timestamp))
      switch (timePeriod) {
        case 'HOUR':
        case 'DAY': {
          // Format to include minutes
          return dayjsFn.format('DD. HH:mm')
        }
        case 'WEEK':
        case '1MONTH':
        case 'MONTH': {
          // Format to include minutes
          return dayjsFn.format('DD. MMM HH:mm')
        }
        case 'MINUTE': {
          // Format to include seconds
          return dayjsFn.format('HH:mm')
        }
        default:
          return monthDayFormatter('en-US')(timestamp)
      }
    },
    [timePeriod]
  )

export const CustomXAxisTick = ({ x, y, payload, timestampFormat }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" className="musd-chart-axis-text">
        {timestampFormat(payload?.value)}
      </text>
    </g>
  )
}
const CustomYAxisTick = ({ x, y, payload }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" className="musd-chart-axis-text">
        {Number(payload?.value).toFixed(5)}
      </text>
    </g>
  )
}

export const CustomTooltip = ({ active, payload, label, timestampFormat }: any) => {
  if (active && payload && payload.length) {
    return (
      <ToolTipBox>
        <span>{dayjs(label).format('MMM DD, YYYY, HH:mm A')}</span>
        <span>Price: ${Number(payload[0].value).toFixed(5)}</span>
      </ToolTipBox>
    )
  }
  return null
}

export function IdoPriceChart({
  idoStats,
  currentPrice,
}: {
  idoStats: IdoStatType[]
  currentPrice: number
}) {
  const timePeriodType = useMemo(() => {
    if (idoStats.length >= 2 && idoStats[idoStats.length - 1].timestamp - idoStats[0].timestamp >= 86400) return 'HOUR'
    return 'MINUTE'
  }, [idoStats])
  const timestampFormat = useTimestampFormater(timePeriodType)
  const [hasMouseChart, setHasMouseChart] = useState<boolean>(false)
  const [hoverYValue, sethoverYValue] = useState(0)
  const onMouseChart = (item: any, args: any = undefined) => {
    if (Object.keys(item).length > 0 && item.activePayload.length > 0) {
      sethoverYValue(item.activePayload[0].payload.price)
      setHasMouseChart(true)
    }
  }

  const onMouseOn = (item: any) => {
    if (item.activePayload) onMouseChart(item)
  }

  const prices = useMemo(() => {
    const newPrices: any[] = []
    const now = new Date()
    const createdAt = now.toISOString()
    const updatedAt = now.toISOString()
    const timestamp = now.getTime()

    // if(idoStats.length < 10) return idoStats

    let latestChart: IdoStatType = {
      price: currentPrice,
      createdAt: createdAt,
      timestamp: timestamp,
      updatedAt: updatedAt,
      supply: 0,
      collateral: 0,
    }

    if (currentPrice <= 0.0006 && idoStats.length <= 5) {
      let startTime = dayjs.utc().subtract(30, 'minute')

      for (let index = 0; index < 10; index++) {
        newPrices.push({
          price: 0.0006,
          timestamp: startTime.valueOf(),
        })
        startTime = startTime.add(3, 'minute')
      }
      return newPrices
    }

    // Check if currentPrice > 0 before proceeding
    if (currentPrice > 0) {
      if (idoStats.length > 0) {
        // if (timePeriod !== 'HOUR') {
        //   idoStats.unshift(latestChart)
        //   idoStats.sort((a, b) => a.timestamp - b.timestamp)

        //   return idoStats
        // }
        for (let index = 0; index < idoStats.length; index++) {
          const element = idoStats[index]
          let startDate = dayjs.utc(element.createdAt)

          // if (index < idoStats.length - 1) {
          //   const endTime = idoStats[index + 1].timestamp
          //   while (startDate.valueOf() <= endTime) {
          //     newPrices.push({
          //       price: element.price,
          //       timestamp: startDate.valueOf(),
          //     })
          //     // if (timePeriod !== 'HOUR') startDate = startDate.add(10, 'second')
          //     // else 
          //     startDate = startDate.add(30, 'second')
          //   }
          // }
          newPrices.push({
            price: element.price,
            timestamp: startDate.valueOf(),
          })
        }

        if (idoStats.length > 0) {
          newPrices.push({
            price: idoStats[idoStats.length - 1].price,
            timestamp: idoStats[idoStats.length - 1].timestamp,
          })
        }
        if (idoStats[idoStats.length - 1].price < currentPrice)
          newPrices.push({
            price: currentPrice,
            timestamp: Date.now(),
          })
      }
      return newPrices
    }

    return []
  }, [idoStats, currentPrice])

  const priceRange = useMemo(() => {
    let minRange = 0
    let maxRange = 0
    minRange = 0.0001
    maxRange = currentPrice + 0.0003

    return [minRange, maxRange]
  }, [currentPrice])


  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    if (window.screen.width < 768) setIsMobile(true)
  }, [])
  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={prices}
        // @ts-ignore
        // onMouseOver={onMouseChart}
        // onMouseMove={onMouseOn}
        // onMouseOut={() => setHasMouseChart(false)}
        >
          <defs>
            <linearGradient
              id="colorUv"
              x1="276"
              y1="-3.5"
              x2="285"
              y2="296.5"
              opacity={0.1}
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#68ffff83" />
              <stop offset="1" stop-color="#68FF9B" stop-opacity="0" />
            </linearGradient>
          </defs>
          <XAxis
            axisLine={false}
            dataKey="timestamp"
            tickLine={false}
            padding={{ right: isMobile ? 30 : 60 }}
            allowDuplicatedCategory={true}
            interval={'preserveStartEnd'}
            tickCount={12}
            tick={<CustomXAxisTick timestampFormat={timestampFormat} />}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            orientation="right"
            padding={{ top: 20 }}
            type="number"
            domain={priceRange}
            tickCount={8}
            tick={<CustomYAxisTick />}
          />
          <Tooltip content={<CustomTooltip timestampFormat={timestampFormat} />} />

          <ReferenceLine
            style={{ display: hoverYValue > 0 && hasMouseChart ? 'block' : 'none' }}
            y={hoverYValue}
            stroke="#EFEFE4"
            strokeWidth={'1px'}
          />

          <Area
            type="monotone"
            dataKey="price"
            strokeWidth="2px"
            stroke="#68FF9B"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  )
}
