import { useWeb3React } from "@web3-react/core"
import { Dropdown, MenuProps, Pagination, Space } from "antd";
import { BASE_API } from "pages/Xp";
import { PrevNextRender } from "pages/Xp/sections/ClaimedXpHistory";
import { TheadLabel } from "pages/Xp/sections/table"
import { useEffect, useState, useMemo, } from "react";
import styled from "styled-components"
import axios from "axios"
import { BREAKPOINTS, ExternalLink } from "theme";
import { ExplorerDataType, getExplorerLink } from "utils/getExplorerLink";
import { SupportedChainId } from "constants/chains";
import dayjs from "dayjs";
import "../../Xp/sections/leaderboard.css" 
import { getNameChest } from "./ChestOpenAnimationModal";
const outlink = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/outlink.svg'


const ChestHistoryTableWrapper = styled.div`
    max-width: 1224px;
    width: 100%;
    height: 100%;
    margin: auto;
    margin-top: 40px;
    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
        max-width: 95%;
    }
`

const TitleBox = styled.div`
  display: grid;
  max-width: 600px;
  margin: auto;
  gap: 16px;
  justify-content: center;
  align-content: center;
`
const Title = styled.h1`
  color: #efefe4;
  text-align: center;
  font-family: Righteous;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 60px */
  margin: 0;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    justify-content: center;
    font-size: 32px;
    line-height: 40px;
  }
  margin-bottom: 30px;
`

const Text = styled.span`
    color: #FFF;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 20px */
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        font-size: 12px;
    }
`;

const TheadBox = styled.div`
    max-width: 1016px;
    margin: auto;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    padding-left: 40px;
    background: #80808029;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        padding-left: 10px;
    }
`
const TbodyBox = styled.div`
    display: grid;
`
const TbodyColBox = styled(TheadBox)`
    background: rgba(239, 239, 228, 0.03);
    padding: 20px 0;
    padding-left: 40px;
    border-bottom: 1px solid #efefe433;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        padding: 15px 0;
        padding-left: 10px;
    }
`

const TypeText = styled.span`
    color: #EFEFE4;
    font-family: Urbanist !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 17.5px */
`;

const TextLinear = styled.span`
  background: linear-gradient(90deg, #68ffff 0%, #68ff9b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

type ChestLogType = 'buy' | 'open' | 'all'
type ChestLogHistoryType = {
    chestType: number;
    xmono: number;
    usd: number;
    ticketCnt: number;
    totalMonopass: number;
    totalChest: number;
    hash: string;
    createdAt: string;
}

export default function ChestHistoryTable() {
    const { account } = useWeb3React()

    const [totalElement, setTotalElement] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [historyList, setHistoryList] = useState<ChestLogHistoryType[]>([]);
    const [filterType, setFilterType] = useState<ChestLogType>('all');

    const displayData = useMemo(() => {
        return historyList.map(i => ({ ...i }))
    }, [filterType, historyList])

    const getTypeName = (type: ChestLogType) => {
        if (type == 'buy')
            return 'Buy'
        else return 'Open'
    }

    useEffect(() => onCallApiHistory(currentPage), [currentPage])

    const RenderFilterTypeContent = (val: string, type: any) => {
        return <Space align="baseline">
            {
                filterType == type && <div style={{ width: '18px', height: '14px' }}>
                    <svg color="white" width='100%' height={'100%'} strokeWidth={3} stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                    </svg>
                </div>
            }
            <TypeText>
                {val}
            </TypeText>
        </Space>
    }

    const filterTypeItems: MenuProps['items'] = [
        {
            key: 'all',
            label: RenderFilterTypeContent('All', 'all'),
        },
        {
            key: 'open',
            label: RenderFilterTypeContent('Open', 'open'),
        },
        {
            key: 'buy',
            label: RenderFilterTypeContent('Buy', 'buy'),
        },
    ];

    const onClickFilter = ({ key }: any) => {
        setFilterType(key);
    }

    const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false)
    const onCallApiHistory = (page: number) => {
        if (isLoadingTable || !account) return
        setIsLoadingTable(true)
        axios.get(`${BASE_API}/monoswap/api/v1/monochest/get-history?address=${account}&page=${page - 1}&per_page=${10}`)
            .then(({ data }) => {
                setHistoryList(data.data)
                setTotalElement(data.totalElement)
            })
            .finally(() => setIsLoadingTable(false))
    }

    useEffect(() => {
        if (account) onCallApiHistory(currentPage)
    }, [account])

    return <ChestHistoryTableWrapper className="my-claimed-xp" id="my-claimed-xp">
        <TitleBox>
            <Title>
                History
            </Title>
        </TitleBox>

        <div className="" style={{ fontFamily: 'Urbanist', maxHeight: '500px', overflow: 'auto' }}>
            <TheadBox style={{ opacity: 0.7 }}>
                <TheadLabel>
                    <Dropdown
                        trigger={['click', 'hover']}
                        menu={{ items: filterTypeItems, selectedKeys: [filterType], onClick: onClickFilter }}
                        placement="bottomLeft"
                        getPopupContainer={() => document.getElementById('my-claimed-xp') ?? document.body}>
                        <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'start', gap: '5px' }}>
                            <span>TYPE</span>
                            <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4873" width="16" height="16"><path d="M128 192a64 64 0 0 1 64-64h640a64 64 0 0 1 64 64v114.304l-272 160V896h-224V466.304L128 306.304V192z m704 0H192v77.696l272 160V832h96V429.696l272-160V192z" p-id="4874" fill="#8a8a8a"></path></svg>
                        </div>
                    </Dropdown>
                </TheadLabel>

                <TheadLabel >
                    AMOUNT
                </TheadLabel>

                <TheadLabel>
                    DATE
                </TheadLabel>
            </TheadBox>

            <TbodyBox>
                {displayData.map((item, index: number) => (
                    <div key={Math.random() * 999999999999}>
                        {
                            (filterType == 'open' || filterType == 'all') && <>
                                {
                                    Number(item.xmono) > 0 && <TbodyColBox >
                                        <Text>
                                            {getTypeName('open')}
                                        </Text>
                                        <Text>
                                            <TextLinear>+ {Number(item.xmono).toFixed(2)} xMONO</TextLinear>
                                        </Text>
                                        <Text style={{ display: 'flex', gap: '8px' }}>
                                            <ExternalLink
                                                href={getExplorerLink(SupportedChainId.BLAST,
                                                    item.hash,
                                                    ExplorerDataType.TRANSACTION
                                                )}
                                                target="_blank"
                                            >
                                                <img src={outlink} alt="img" />
                                            </ExternalLink> {dayjs(item.createdAt).format('DD/MM/YYYY HH:mm')}
                                        </Text>
                                    </TbodyColBox>
                                }

                                {
                                    Number(item.usd) > 0 && <TbodyColBox>
                                        <Text>
                                            {getTypeName('open')}
                                        </Text>
                                        <Text>
                                            <TextLinear>+ ${Number(item.usd).toFixed(2)} MUSD</TextLinear>
                                        </Text>
                                        <Text style={{ display: 'flex', gap: '8px' }}>
                                            <ExternalLink
                                                href={getExplorerLink(SupportedChainId.BLAST,
                                                    item.hash,
                                                    ExplorerDataType.TRANSACTION
                                                )}
                                                target="_blank"
                                            >
                                                <img src={outlink} alt="img" />
                                            </ExternalLink> {dayjs(item.createdAt).format('DD/MM/YYYY HH:mm')}
                                        </Text>
                                    </TbodyColBox>
                                }

                                {
                                    Number(item.totalMonopass) > 0 && <TbodyColBox>
                                        <Text>
                                            {getTypeName('open')}
                                        </Text>
                                        <Text>
                                            <TextLinear>+ {item.totalMonopass} MonoPass</TextLinear>
                                        </Text>
                                        <Text style={{ display: 'flex', gap: '8px' }}>
                                            <ExternalLink
                                                href={getExplorerLink(SupportedChainId.BLAST,
                                                    item.hash,
                                                    ExplorerDataType.TRANSACTION
                                                )}
                                                target="_blank"
                                            >
                                                <img src={outlink} alt="img" />
                                            </ExternalLink> {dayjs(item.createdAt).format('DD/MM/YYYY HH:mm')}
                                        </Text>
                                    </TbodyColBox>
                                }

                                {
                                    <TbodyColBox>
                                        <Text>
                                            {getTypeName('open')}
                                        </Text>
                                        <Text>
                                            <TextLinear>+ {item.ticketCnt} Ticket{item.ticketCnt > 1 ? 's' : ''}</TextLinear>
                                        </Text>
                                        <Text style={{ display: 'flex', gap: '8px' }}>
                                            <ExternalLink
                                                href={getExplorerLink(SupportedChainId.BLAST,
                                                    item.hash,
                                                    ExplorerDataType.TRANSACTION
                                                )}
                                                target="_blank"
                                            >
                                                <img src={outlink} alt="img" />
                                            </ExternalLink> {dayjs(item.createdAt).format('DD/MM/YYYY HH:mm')}
                                        </Text>
                                    </TbodyColBox>
                                }

                                <TbodyColBox>
                                    <Text>
                                        {getTypeName('open')}
                                    </Text>
                                    <Text>
                                        {item.totalChest} {getNameChest(item.chestType)} Chest{item.totalChest > 1 ? 's' : ''}
                                    </Text>
                                    <Text style={{ display: 'flex', gap: '8px' }}>
                                        <ExternalLink
                                            href={getExplorerLink(SupportedChainId.BLAST,
                                                item.hash,
                                                ExplorerDataType.TRANSACTION
                                            )}
                                            target="_blank"
                                        >
                                            <img src={outlink} alt="img" />
                                        </ExternalLink> {dayjs(item.createdAt).format('DD/MM/YYYY HH:mm')}
                                    </Text>
                                </TbodyColBox>
                            </>
                        }

                        {
                            (filterType == 'buy' || filterType == 'all') && <TbodyColBox>
                                <Text>
                                    {getTypeName('buy')}
                                </Text>
                                <Text>
                                    {item.totalChest} {getNameChest(item.chestType)} Chests
                                </Text>
                                <Text style={{ display: 'flex', gap: '8px' }}>
                                    <ExternalLink
                                        href={getExplorerLink(SupportedChainId.BLAST,
                                            item.hash,
                                            ExplorerDataType.TRANSACTION
                                        )}
                                        target="_blank"
                                    >
                                        <img src={outlink} alt="img" />
                                    </ExternalLink> {dayjs(item.createdAt).format('DD/MM/YYYY HH:mm')}
                                </Text>
                            </TbodyColBox>
                        }



                    </div>
                ))}
            </TbodyBox>
        </div>

        {
            historyList.length > 0 &&
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Pagination
                    current={currentPage}
                    total={totalElement}
                    pageSize={10}
                    itemRender={PrevNextRender}
                    showSizeChanger={false}
                    onChange={(page) => setCurrentPage(page)}
                />
            </div>
        }
    </ChestHistoryTableWrapper>
}