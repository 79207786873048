import { SupportedChainId } from 'constants/chains'
import useHttpLocations from 'hooks/useHttpLocations'
import { useMemo } from 'react'
import { isAddress } from 'utils'
import { isCelo, NATIVE_CHAIN_ID, nativeOnChain } from '../../constants/tokens'

type Network = 'ethereum' | 'blast' | 'blast_sepolia'
const EthereumLogo = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/ethereum-logo.png'

export function chainIdToNetworkName(networkId: SupportedChainId): Network {
  switch (networkId) {
    case SupportedChainId.MAINNET:
      return 'ethereum'
    case SupportedChainId.BLAST:
      return 'blast'
    case SupportedChainId.BLAST_SEPOLIA:
      return 'blast_sepolia'
    default:
      return 'blast'
  }
}

export function getNativeLogoURI(chainId: SupportedChainId = SupportedChainId.MAINNET): string {
  switch (chainId) {
    // case SupportedChainId.POLYGON:
    // case SupportedChainId.POLYGON_MUMBAI:
    //   return MaticLogo
    // case SupportedChainId.CELO:
    // case SupportedChainId.CELO_ALFAJORES:
    //   return CeloLogo
    default:
      return EthereumLogo
  }
}

function getTokenLogoURI(address: string, chainId: SupportedChainId = SupportedChainId.MAINNET): string | void {
  const networkName = chainIdToNetworkName(chainId)
  const networksWithUrls = [SupportedChainId.BLAST, SupportedChainId.BLAST_SEPOLIA]
  if (networksWithUrls.includes(chainId)) {
    return `https://raw.githubusercontent.com/Monoswap-Labs/monoswap-token-list/main/img/${networkName}/assets/${address}/logo.png`
  }
}

export default function useCurrencyLogoURIs(
  currency:
    | {
        isNative?: boolean
        isToken?: boolean
        address?: string
        chainId: number
        logoURI?: string | null
      }
    | null
    | undefined
): string[] {
  const locations = useHttpLocations(currency?.logoURI)
  return useMemo(() => {
    const logoURIs = [...locations]
    if (currency) {
      if (currency.isNative || currency.address === NATIVE_CHAIN_ID) {
        logoURIs.push(getNativeLogoURI(currency.chainId))
      } else if (currency.isToken || currency.address) {
        const checksummedAddress = isAddress(currency.address)
        const logoURI = checksummedAddress && getTokenLogoURI(checksummedAddress, currency.chainId)
        if (logoURI) {
          logoURIs.push(logoURI)
        }
      }
    }
    return logoURIs
  }, [currency, locations])
}
