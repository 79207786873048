import {
  MUSD_BLAST,
  OLE_BLAST,
  PAC_BLAST,
  USDB_BLAST,
  WETH_BLAST,
  BAJA_BLAST,
  ANDY_BLAST,
  ezETH_BLAST,
  JUICE_BLAST,
  YIELD_BLAST,
  ORBIT_BLAST,
  ZKDX_BLAST,
  KID_BLAST,
  BLAST_TOKEN,
  MONOSWAP_TOKEN
} from './tokens'
import blsteIcon from '../assets/images/BLSTE.jpg'
const musdIcon = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/musd.svg'
const usdbIcon = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/usdb.svg'
const tmonoIcon = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/maestro/musd1.svg'
const ethIcon = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/swap/eth.png'
const btcIcon = 'https://assets.coingecko.com/coins/images/1/standard/bitcoin.png'
const usdcIcon = 'https://assets.coingecko.com/coins/images/6319/standard/usdc.png'
const BTC = {
  logo: btcIcon,
  name: 'BTC',
  address: '0xcF749620571A3920a27CD8Eef17Daa1db7C93c4C',
}
const ETH = {
  logo: ethIcon,
  name: 'ETH',
  address: 'ETH',
}
const MONO = {
  logo: tmonoIcon,
  name: 'MONO',
  address: '0x66889602f76E8F7efDC968771F1d2f75bdbB3FDE',
}
const USDB = {
  logo: usdbIcon,
  name: 'USDB',
  address: '0x4300000000000000000000000000000000000003',
}
const MUSD = {
  logo: musdIcon,
  name: 'MUSD',
  address: '0x837fE561e9C5DFa73F607fDa679295DBC2Be5E40',
}
const xMONO = {
  logo: tmonoIcon,
  name: 'xMONO',
  address: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
}
const BLSTE = {
  logo: blsteIcon,
  name: 'BLSTE',
  address: '0x5f49349fB82454d4cA935f3e2e736bD5BE556578',
}
// pool alpha infomation
interface AlphaPoolAddress {
  [chainId: number]: {
    currencyIdA: string
    currencyIdB: string
    pool: string
    incentiveToken: string
    poolName: string
    id: number
  }[]
}
interface AlphaPoolAddressV2 {
  [chainId: number]: {
    poolName: string
    _stakedToken: string
    _rewardToken: string
    _poolAddress: string
    _pairToken: any
    _poolEndTime: any
    currencyIdA: string
    currencyIdB: string
    incentiveToken: string
  }[]
}

export const alphaPoolAddress: AlphaPoolAddress = {
  1: [],
  168587773: [
    {
      currencyIdA: '0x4200000000000000000000000000000000000022',
      currencyIdB: '0x4200000000000000000000000000000000000023',
      pool: '0xA74548b324F5fa5c2bB458f2100d2451ca13FA13',
      incentiveToken: '0xa07aC8cDe2a98B189477b8e41F0c2Ea6CdDbC055',
      poolName: '',
      id: 0,
    },
    {
      currencyIdA: '0x4200000000000000000000000000000000000022',
      currencyIdB: '0x54D12b155dA569aaEa910A778Eb3EC9cd2B26230',
      pool: '0x3002961F2166277060d03cf76f7E7082713cF3B6',
      incentiveToken: '0xa07aC8cDe2a98B189477b8e41F0c2Ea6CdDbC055',
      poolName: '',
      id: 1,
    },
  ],
  //Mainnet
  81457: [
    {
      currencyIdA: USDB_BLAST.address,
      currencyIdB: WETH_BLAST.address,
      pool: '0x0D44F3F22F917cc57368A6D30Ba18D5801789cD9',
      incentiveToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
      poolName: 'USDB/WETH',
      id: 0,
    },
    {
      currencyIdA: USDB_BLAST.address,
      currencyIdB: MUSD_BLAST.address,
      pool: '0x77e149741b4Aa14511324E4B554845063Ed038bd',
      incentiveToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
      poolName: 'USDB/MUSD',
      id: 1,
    },
    {
      currencyIdA: MUSD_BLAST.address,
      currencyIdB: WETH_BLAST.address,
      pool: '0xfb45661dffa7e768c1d03b5aa9cd9e7f55032c1c',
      incentiveToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
      poolName: 'MUSD/WETH',
      id: 2,
    },
    {
      currencyIdA: WETH_BLAST.address,
      currencyIdB: PAC_BLAST.address,
      pool: '0x064be19d55d34dc276ee2a754c26a6ddc7dfb750',
      incentiveToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
      poolName: 'PAC/WETH',
      id: 3,
    },
    {
      currencyIdA: WETH_BLAST.address,
      currencyIdB: OLE_BLAST.address,
      pool: '0xcd5c8702e0f0adbfa0510dfefff1972bc8cb5e66',
      incentiveToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
      poolName: 'WETH/OLE',
      id: 4,
    },
    {
      currencyIdA: WETH_BLAST.address,
      currencyIdB: BAJA_BLAST.address,
      pool: '0x6a96e727D523fF49AcbBa61E09AEB52fc1FD6A2A',
      incentiveToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
      poolName: 'WETH/BAJA',
      id: 5,
    },
    {
      currencyIdA: WETH_BLAST.address,
      currencyIdB: ANDY_BLAST.address,
      pool: '0x14Fa2bc0473B3E9813bD3c15Ab8d38a0f38eDf1e',
      incentiveToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
      poolName: 'WETH/ANDY',
      id: 6,
    },
    {
      currencyIdA: WETH_BLAST.address,
      currencyIdB: ezETH_BLAST.address,
      pool: '0x7B6b3EB3062c7D82DB47235E8Bc91f1F77BA3c4B',
      incentiveToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
      poolName: 'WETH/EZETH',
      id: 7,
    },
    {
      currencyIdA: WETH_BLAST.address,
      currencyIdB: JUICE_BLAST.address,
      pool: '0x60EFb2C12815c4b7b7db15146fe32ca3EF68c8b6',
      incentiveToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
      poolName: 'WETH/JUICE',
      id: 8,
    },
    {
      currencyIdA: WETH_BLAST.address,
      currencyIdB: ORBIT_BLAST.address,
      pool: '0x197E100c52683Cb260abF7f946C7802AF6764741',
      incentiveToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
      poolName: 'WETH/ORBIT',
      id: 9,
    },
    {
      currencyIdA: WETH_BLAST.address,
      currencyIdB: YIELD_BLAST.address,
      pool: '0x5A0108607Dd44458a1f45bcE4dE6c48B3637C670',
      incentiveToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
      poolName: 'WETH/YIELD',
      id: 10,
    },
    {
      currencyIdA: WETH_BLAST.address,
      currencyIdB: KID_BLAST.address,
      pool: '0xf9a88f06218aB4E04E78EdD6EadfA44B27697428',
      incentiveToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
      poolName: 'WETH/KID',
      id: 11,
    },
    {
      currencyIdA: USDB_BLAST.address,
      currencyIdB: WETH_BLAST.address,
      pool: '0x0D44F3F22F917cc57368A6D30Ba18D5801789cD9',
      incentiveToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
      poolName: 'USDB/WETH',
      id: 12,
    },
    {
      currencyIdA: USDB_BLAST.address,
      currencyIdB: MUSD_BLAST.address,
      pool: '0x77e149741b4Aa14511324E4B554845063Ed038bd',
      incentiveToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
      poolName: 'USDB/MUSD',
      id: 13,
    },
    {
      currencyIdA: WETH_BLAST.address,
      currencyIdB: BLAST_TOKEN.address,
      pool: '0x79Fb89437CB97a6BBFFB9c76b0F0dFA102b4C07D',
      incentiveToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
      poolName: 'BLAST/WETH',
      id: 14,
    },
    {
      currencyIdA: WETH_BLAST.address,
      currencyIdB: MONOSWAP_TOKEN.address,
      pool: '0x27c71ba3e6aa2249f4c5517680ed786c560af0d7',
      incentiveToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
      poolName: 'MONO/WETH',
      id: 15,
    },
  ],
}
export const alphaPoolAddressV2: AlphaPoolAddressV2 = {
  1: [],
  168587773: [
    {
      poolName: 'WETH/TEST',
      _stakedToken: '0x55A24858C097a25D123adf21fE45C1ec8F591964',
      _rewardToken: '0x1b71B460e914FEF538C2AE177a954e0Ce5fCe314',
      _poolAddress: '0x6DB5eAA1FddAfFF239F251Bf5269570EF8060335',
      _pairToken: ETH.address,
      _poolEndTime: '1721149200',
      currencyIdA: WETH_BLAST.address,
      currencyIdB: 'TEST_TOKEN',
      incentiveToken: ''
    },
  ],
  //Mainnet
  81457: [
    {
      poolName: 'WETH/ZKDX',
      _stakedToken: ZKDX_BLAST.address,
      _rewardToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
      _poolAddress: '0xC5159fA55C8f06a879D00D521B8654a76EE51C8f',
      _pairToken: ETH.address,
      _poolEndTime: '1721229803',
      currencyIdA: WETH_BLAST.address,
      currencyIdB: ZKDX_BLAST.address,
      incentiveToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
    },
    {
      poolName: 'WETH/USDB',
      _stakedToken: USDB_BLAST.address,
      _rewardToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
      _poolAddress: '0xdc0FFF238B563BA9b2f5832311a72F124E389aAF',
      _pairToken: ETH.address,
      _poolEndTime: '1724166000',
      currencyIdA: WETH_BLAST.address,
      currencyIdB: USDB_BLAST.address,
      incentiveToken: '0x28A922aD2d2A02250473b30F3444E20b138354d1',
    },
  ],

}

// poolist and trending pair infomation

export const PoolList = {
  v3: [
    {
      token1: ETH,
      token2: USDB,
      fee: '0.3',
      apr: '216.38',
      liquidityUsd: '513,608',
      volume24h: '605,704',
      token1liquidity: '75.8',
      token2liquidity: '256,801',
      famPool: {
        status: false,
        address: '0x0d44f3f22f917cc57368a6d30ba18d5801789cd9',
      },
      pairAddress: {
        trending: true,
        address: '0x0d44f3f22f917cc57368a6d30ba18d5801789cd9',
      },
    },

    {
      token1: MUSD,
      token2: USDB,
      fee: '0.3',
      apr: '126.5',
      liquidityUsd: '563,854',
      token1liquidity: '55,780',
      token2liquidity: '56,385',
      volume24h: '789,397',
      famPool: {
        status: false,
        address: '',
      },
      pairAddress: {
        trending: true,
        address: '0x3002961f2166277060d03cf76f7e7082713cf3b6',
      },
    },
    {
      token1: MUSD,
      token2: ETH,
      fee: '0.3',
      apr: '58.33',
      liquidityUsd: '462,869',
      token1liquidity: '42,079 ',
      token2liquidity: '12.39 ',
      volume24h: '601,729.7',
      famPool: {
        status: false,
        address: '',
      },
      pairAddress: {
        trending: true,
        address: '0x6d57bb116b3b64b27dc55e9267e304e7eb77c76d',
      },
    },
  ],
  v2: [
    // {
    //   token1: WIF,
    //   token2: ETH,
    //
    //   apr: '483.62',
    //   liquidityUsd: '253,874',
    //   volume24h: '1,001,000',
    //   token1liquidity: '295,250 ',
    //   token2liquidity: '37.74',
    // },
    // {
    //   token1: ETH,
    //   token2: MONO,
    //   apr: '15.23',
    //   liquidityUsd: '241,531',
    //   token1liquidity: '14.1',
    //   token2liquidity: '51,312',
    //   volume24h: '32,521',
    // },
  ],
}

export const trendingPair = {
  v3: [
    {
      token1: MONO,
      token2: ETH,

      famPool: {
        status: false,
        address: '0x27c71ba3e6aa2249f4c5517680ed786c560af0d7',
      },
      pairAddress: {
        trending: true,
        address: '0x27c71ba3e6aa2249f4c5517680ed786c560af0d7',
      },
      pairSelect1: 'MONOETH',
      pairSelect2: 'ETHMONO',
    },
    {
      token1: ETH,
      token2: USDB,

      famPool: {
        status: false,
        address: '0x0d44f3f22f917cc57368a6d30ba18d5801789cd9',
      },
      pairAddress: {
        trending: true,
        address: '0x0d44f3f22f917cc57368a6d30ba18d5801789cd9',
      },
      pairSelect1: 'ETHUSDB',
      pairSelect2: 'USDBETH',
    },

    {
      token1: MUSD,
      token2: USDB,

      famPool: {
        status: false,
        address: '',
      },
      pairAddress: {
        trending: true,
        address: '0x52b3b26541e5347ba22c17334a12ca759f221d8f',
      },
      pairSelect1: 'MUSDUSDB',
      pairSelect2: 'USDBMUSD',
    },
    {
      token1: MUSD,
      token2: ETH,

      famPool: {
        status: false,
        address: '0xfb45661dffa7e768c1d03b5aa9cd9e7f55032c1c',
      },
      pairAddress: {
        trending: true,
        address: '0xfb45661dffa7e768c1d03b5aa9cd9e7f55032c1c',
      },
      pairSelect1: 'MUSDETH',
      pairSelect2: 'ETHMUSD',
    },
    // {
    //   token1: WIF,
    //   token2: ETH,
    //
    //   famPool: {
    //     status: false,
    //     address: '',
    //   },
    //   pairAddress: {
    //     trending: true,
    //     address: '0x3110ac04d332779b1deaec652361d745da0cc041',
    //   },
    //   pairSelect1: 'WIFETH',
    //   pairSelect2: 'ETHWIF',
    // },
    // {
    //   token1: BLSTE,
    //   token2: ETH,

    //   famPool: {
    //     status: false,
    //     address: '',
    //   },
    //   pairAddress: {
    //     trending: true,
    //     address: '0x75993a51fc9e8b7161c3f90ee5acdb6e418cabcf',
    //   },
    //   pairSelect1: 'BLSTEETH',
    //   pairSelect2: 'ETHBLSTE',
    // },
  ],
}
