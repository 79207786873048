import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { NotchedButtonFill, NotchedButtonFillBase } from '../../components/Button'
import './alpha.css'
import { alphaPoolAddress } from '../../constants/poolListData'
import { useCustomeContract } from 'hooks/useContract'
import v3staker_abi from '../../abis/alpha/V3Staker.json'
import { ExternalLink } from '../../theme'
import { getExplorerLink, ExplorerDataType } from 'utils/getExplorerLink'
import { V3STAKER_ADDRESSES_MAP } from '../../constants/addresses'

import { ethers } from 'ethers'
import DoubleCurrencyLogo, { SingleCurrencyLogo } from 'components/DoubleLogo'

import { useMultipleCurrencies } from './index'
import { useWeb3React } from '@web3-react/core'
import HarvestModal from 'components/Alpha/HarvestModal'
import DepositModal from 'components/Alpha/DepositModal'
import WithdrawModal from 'components/Alpha/WithdrawModal'
import { useV3Positions, useV3PositionsFromTokenIds } from 'hooks/useV3Positions'
import { useMultiplePositions } from '../../hooks/useMultiplePositions'
import { fetchPrice } from '../../nft/utils'
import { SupportedChainId } from '../../constants/chains'
import { defaultAbiCoder } from '@ethersproject/abi'
import { keccak256 } from '@ethersproject/keccak256'
import { useAlphaTvlMap } from 'state/alphapool/hooks'
import { PoolV3Info, useAlphaStore } from '../../hooks/useAlphaStore'
import { formatXpNumber } from 'pages/Xp/sections/XpTitle'
import { BASE_API } from 'pages/Xp'

const outLink = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/maestro/outlink.svg'
const totals =
  'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/maestro/circle-dollar-sign.svg'



const AlphaDetail = () => {
  const navigate = useNavigate()
  const { account, chainId } = useWeb3React()
  const isSupported = chainId === SupportedChainId.BLAST
  if (!isSupported) {
    navigate('/farming')
    return null
  }

  const tvlMap = useAlphaTvlMap()
  const alphaStore = useAlphaStore()

  const [customeChainId, setCustomeChainId] = useState(SupportedChainId.BLAST)
  const [poolStatus, setPoolStatus] = useState('')
  const [yourTotalNft, setYourTotalNft] = useState()
  const [yourPedingReward, setYourPendingReward] = useState()
  const [yourLockedTime, setYourLockedTime] = useState()
  const [userLiquidity, setUserTotalLiquidity] = useState(0)
  const [loadingUserStakeInfo, setLoadingUserStakeInfo] = useState(false)
  const [idNftStake, setIdNftStake] = useState<{ tokenId: string; poolAddress: string }[]>([])
  const [ethPrice, setEthPrice] = useState(3500)

  // context to call function
  const [contractInfo, setContractInfo] = useState(null)
  // modal
  const [isOPenModalDeposit, setIsOPenModalDeposit] = useState(false)
  const [isOPenModalHarvest, setIsOPenModalHarvest] = useState(false)
  const [isOPenModalWithdraw, setIsOPenModalWithdraw] = useState(false)
  // handle call loading new result
  const [trigger, setTrigger] = useState(false)

  const handleDataFromChild = (data: any) => {
    setYourTotalNft(data)
  }
  const handleUserTotalLiquidity = (data: any) => {
    setUserTotalLiquidity(data)
  }
  const handleGetTime = (data2: any) => {
    setYourLockedTime(data2)
  }
  const handleDataFromChildPendingReward = (data: any) => {
    setYourPendingReward(data)
  }
  useEffect(() => {
    if (chainId) {
      setCustomeChainId(chainId)
    } else {
      setCustomeChainId(SupportedChainId.BLAST)
    }
  }, [account, chainId])
  const params = useParams()
  const filteredPools = getPoolByAddress(params?.poolAddress)
  const tokenInfo = useMultipleCurrencies(filteredPools)
  const locationState = useLocation()
  const [poolInfo, setpoolInfo] = useState<PoolV3Info>()


  function calculateTotalRewardValue(data: any) {
    return data?.reduce((total: any, item: any) => {
      const rewardValue = Number(ethers.utils.formatUnits(item?.rewardValue, 18))
      return total + rewardValue
    }, 0)
  }
  function getPoolByAddress(address: any) {
    return alphaPoolAddress[chainId!]?.filter((item) => item.pool.toLowerCase() === address?.toLowerCase()) || []
  }

  function checkStatus(data: any) {
    const startTime = new Date(data?.startTime * 1000)
    const endTime = new Date(data?.endTime * 1000)
    const now = new Date()

    if (now < startTime) {
      setPoolStatus('Incoming')
      return 'Incoming'
    } else if (now > startTime && now < endTime) {
      setPoolStatus('Active')
      return 'Active'
    } else {
      setPoolStatus('End')
      return 'End'
    }
  }
  const STAKING_ADDRESS = V3STAKER_ADDRESSES_MAP[81457]
  const v3stakerWithSign = useCustomeContract(STAKING_ADDRESS, v3staker_abi, true)

  const handleGetUserInfo = async () => {
    setLoadingUserStakeInfo(true)

    try {
      const tokenNftLockedByUser = await v3stakerWithSign?.getTokensByOwner(account, '0', '1000000')
      const items = await Promise.all(
        tokenNftLockedByUser?.map(async (item: any) => {
          const encodedKey = defaultAbiCoder.encode(
            [
              'address',
              'address',
              'uint256',
              'uint256',
              'uint256',
              'address'
            ],
            [
              item.incentiveKey.rewardToken,
              item.incentiveKey.pool,
              item.incentiveKey.startTime,
              item.incentiveKey.endTime,
              item.incentiveKey.lockDuration,
              item.incentiveKey.refundee
            ]
          );
          const incentiveId = ethers.utils.keccak256(encodedKey)

          return {
            tokenId: item.tokenId.toString(),
            poolAddress: item.incentiveKey.pool,
            incentiveId: incentiveId
          }
        })
      )

      const encodedKey = defaultAbiCoder.encode(
        [
          'address',
          'address',
          'uint256',
          'uint256',
          'uint256',
          'address'
        ],
        [
          poolInfo?.rewardToken,
          poolInfo?.pool,
          poolInfo?.startTime,
          poolInfo?.endTime,
          poolInfo?.lockDuration,
          poolInfo?.refundee
        ]
      );
      const poolInfoIncentiveId = ethers.utils.keccak256(encodedKey)

      const filterByPool = items?.filter((item: any) => {
        return (item?.poolAddress?.toLowerCase() === poolInfo?.pool?.toLowerCase())
          && (item?.incentiveId?.toLowerCase() === poolInfoIncentiveId?.toLowerCase())
      })
      setIdNftStake(filterByPool)
      setLoadingUserStakeInfo(true)
    } catch (error) {
      console.log(error)
      setLoadingUserStakeInfo(false)
    }
  }

  const { positions: v3Positions, loading: positionsLoading } = useV3Positions(account)
  const idPositionLocked = idNftStake.map((item) => ethers.BigNumber.from(item.tokenId))
  const { positions: positionsLocked } = useV3PositionsFromTokenIds(idPositionLocked)

  // filter user match with pool
  function filterPosition(data: any, currencyIdA: any, currencyIdB: any) {
    if (!data) return
    return data?.filter((item: any) => {
      const matchesTokens =
        (item.token0 === currencyIdA && item.token1 === currencyIdB) ||
        (item.token0 === currencyIdB && item.token1 === currencyIdA)
      const hasLiquidity = Number(ethers.utils.formatUnits(item.liquidity, 18)) > 0
      const matchesFee = Number(item.fee.toString()) === Number(poolInfo?.poolFee)
      return matchesTokens && hasLiquidity && matchesFee
    })
  }

  const userPosition = filterPosition(v3Positions, filteredPools[0]?.currencyIdA, filteredPools[0]?.currencyIdB) || []
  const userLockedPosition =
    filterPosition(positionsLocked, filteredPools[0]?.currencyIdA, filteredPools[0]?.currencyIdB) || []
  const userPositionsLocked = useMultiplePositions(userLockedPosition)

  const totalValueLock = poolInfo?.userTvl || '0'

  const poolDuration = Math.round((Number(poolInfo?.endTime) - Number(poolInfo?.startTime)) / (24 * 60 * 60))

  const poolTvl = useMemo(() => {
    if (poolInfo?.hasEnded) return 0
    return tvlMap.get(poolInfo?.incentiveId ?? '') ?? 0
  }, [tvlMap, poolInfo])

  useEffect(() => {
    if (poolInfo) {
      checkStatus(poolInfo)
      handleGetUserInfo()
    }
  }, [account, chainId, poolInfo])

  useEffect(() => {
    fetchPrice().then((price) => {
      setEthPrice(price || 0)
    })
    if (locationState?.state && typeof locationState?.state === 'object')
      setpoolInfo(locationState?.state as PoolV3Info)
    else setpoolInfo(alphaStore.totalV3Pools.find(item => item.incentiveId == String(params.poolAddress).toLowerCase()))
  }, [])

  return (
    <>
      {!isSupported && chainId !== 81457 && navigate('/farming')}
      {isSupported && (
        <>
          <DepositModal
            isOpen={isOPenModalDeposit}
            onDismiss={() => setIsOPenModalDeposit(false)}
            userPosition={userPosition}
            tokenInfo={tokenInfo}
            poolInfo={poolInfo}
            handleDataFromChild={handleDataFromChild}
            setTrigger={setTrigger}
          />
          <HarvestModal
            isOpen={isOPenModalHarvest}
            onDismiss={() => setIsOPenModalHarvest(false)}
            poolInfo={poolInfo}
            tokenInfo={tokenInfo}
            userPositionsLocked={userPositionsLocked}
            handleDataFromChildPendingReward={handleDataFromChildPendingReward}
            trigger={trigger}
          />
          <WithdrawModal
            isOpen={isOPenModalWithdraw}
            onDismiss={() => setIsOPenModalWithdraw(false)}
            poolInfo={poolInfo}
            tokenInfo={tokenInfo}
            userPositionsLocked={userPositionsLocked}
            handleDataFromChild={handleDataFromChild}
            trigger={trigger}
            handleGetTime={handleGetTime}
            handleUserTotalLiquidity={handleUserTotalLiquidity}
          />
          <div className="musd-container">
            <div className="maestro-navigate">
              <p
                onClick={() => {
                  navigate('/farming')
                }}
              >
                Farming List
              </p>{' '}
              <p>&gt;</p>{' '}
              <p style={{ color: '#efefe4' }}>
                {' '}
                {tokenInfo[0]?.currencyA?.symbol}-{tokenInfo[0]?.currencyB?.symbol}{' '}
              </p>
            </div>
          </div>
          <div className="maestro-detail-cover">
            <div className="maestro-detail-container">
              <div className="maestro-detail-top">
                <div className="maestro-detail-top-left">
                  <DoubleCurrencyLogo
                    currency0={tokenInfo[0]?.currencyA ?? undefined}
                    currency1={tokenInfo[0]?.currencyB ?? undefined}
                    size={32}
                  />
                  <div>
                    <p>
                      {tokenInfo[0]?.currencyA?.symbol}/{tokenInfo[0]?.currencyB?.symbol}{' '}
                      <span style={{ color: '#68FFFF' }}>v3</span>
                    </p>
                    <p style={{ color: 'rgba(239,239,228,0.5)' }}>#ID-{poolInfo?.poolId}</p>
                  </div>
                </div>
                <div className="maestro-detail-top-right">
                  <div className="maestro-detail-top-right-left">
                    <div className="maestro-detail-top-right-child">
                      <p style={{ color: 'rgba(239,239,228,0.5)', fontSize: '12px' }}>TVL</p>
                      <p>
                        $
                        {formatXpNumber(poolTvl)}
                      </p>
                    </div>
                    <div className="maestro-detail-top-right-child">
                      <p style={{ color: 'rgba(239,239,228,0.5)', fontSize: '12px' }}>APR</p>
                      {
                        poolInfo?.hasEnded ? <p className="maestro-detail-top-right-child-info">0%</p>
                          : <p className="maestro-detail-top-right-child-info">
                            {Number(poolInfo?.apr) > 1000 ? numberWithCommas(Number(poolInfo?.apr)) : poolInfo?.apr}%
                          </p>
                      }
                    </div>
                    <div className="maestro-detail-top-right-child no-border">
                      <p style={{ color: 'rgba(239,239,228,0.5)', fontSize: '12px' }}>Pool reward </p>
                      <p className="maestro-detail-top-right-child-info">
                        {Number(poolInfo?.totalRewardUnclaimed) > 1000
                          ? numberWithCommas(Number(poolInfo?.totalRewardUnclaimed).toFixed(2))
                          : Number(poolInfo?.totalRewardUnclaimed).toFixed(2)}
                        {tokenInfo[0]?.incentive?.symbol}{' '}
                        <span style={{ color: 'rgba(239,239,228,0.5)', fontSize: '12px' }}></span>
                      </p>
                    </div>
                  </div>

                  <div className="maestro-detail-top-right-child-button">
                    <ExternalLink
                      href={getExplorerLink(
                        chainId ? chainId : 81457,
                        V3STAKER_ADDRESSES_MAP[customeChainId],
                        ExplorerDataType.ADDRESS
                      )}
                      target="_blank"
                    >
                      <NotchedButtonFillBase padding={'0px 10px'} fontSize={'14px'} width={'150px'}>
                        <img src={outLink} alt="" style={{ marginRight: '3px' }} /> CONTRACT
                      </NotchedButtonFillBase>
                    </ExternalLink>

                    {/* http://localhost:3000/#/add/ETH/0xa07aC8cDe2a98B189477b8e41F0c2Ea6CdDbC055/3000 */}
                    <NotchedButtonFill
                      padding={'0px 10px'}
                      fontSize={'14px'}
                      width={'150px'}
                      mbFontSize='14px'
                      onClick={() => {
                        navigate(
                          `/add/${filteredPools[0]?.currencyIdA === '0x4200000000000000000000000000000000000023'
                            ? 'ETH'
                            : filteredPools[0]?.currencyIdA
                          }/${filteredPools[0]?.currencyIdB === '0x4200000000000000000000000000000000000023'
                            ? 'ETH'
                            : filteredPools[0]?.currencyIdB
                          }/${poolInfo?.poolFee}`
                        )
                      }}
                    >
                      + NEW POSITION
                    </NotchedButtonFill>
                  </div>
                </div>
              </div>
              <div className="alpha-detail-card-container-container">
                <div className="alpha-detail-card-container">
                  <div className="alpha-detail-card">
                    <p className="alpha-detail-card-title">Status</p>
                    <div className="alpha-detail-card-child">
                      <p>Status</p>
                      {
                        poolInfo?.hasEnded ? <p style={{ color: '#ff0000' }}>Ended</p>
                          : <p className="alpha-detail-card-child-active">{poolStatus}</p>
                      }
                    </div>
                    <div className="alpha-detail-card-child">
                      <p>Duration</p>
                      <p>{poolDuration} days</p>
                    </div>
                    <div className="alpha-detail-card-child">
                      <p>Ends in</p>
                      <p>
                        {
                          poolInfo?.hasEnded ? '0d: 0h: 0m: 0s' : <CountdownTimer target={poolInfo?.endTime} />
                        }
                      </p>
                    </div>
                  </div>
                  <div className="alpha-detail-card">
                    <p className="alpha-detail-card-title">Authorizations</p>
                    <div className="alpha-detail-card-child">
                      <p>Deposits</p>
                      {
                        poolInfo?.hasEnded ? <p style={{ color: '#ff0000' }}>Disabled</p>
                          : <p className="alpha-detail-card-child-active">Enabled</p>
                      }
                    </div>
                    <div className="alpha-detail-card-child">
                      <p>Deposit end time</p>
                      <p className="alpha-detail-card-child-active">-</p>
                    </div>
                    <div className="alpha-detail-card-child">
                      <p>Harvests</p>
                      {
                        poolInfo?.hasEnded ? <p style={{ color: '#ff0000' }}>Disabled</p>
                          : <p className="alpha-detail-card-child-active">Enabled</p>
                      }
                    </div>
                  </div>
                </div>
                <div className="alpha-detail-card-container">
                  <div className="alpha-detail-card">
                    <p className="alpha-detail-card-title">Requirements</p>
                    <div className="alpha-detail-card-child">
                      <p>Minimum amount</p>
                      <p>-</p>
                    </div>
                    <div className="alpha-detail-card-child">
                      <p>Minimum lock</p>
                      <p>{poolInfo?.poolId && (
                        Number(poolInfo?.poolId) === 0 ||
                        Number(poolInfo?.poolId) === 1 ||
                        Number(poolInfo?.poolId) === 15
                      ) ? '15 days' : '-'}</p>
                    </div>
                    <div className="alpha-detail-card-child">
                      <p>Locked until</p>
                      <p>{yourLockedTime ? addSecondsAndFormat(yourLockedTime) : '-'}</p>
                    </div>
                    <div className="alpha-detail-card-child">
                      <p>Whitelist</p>
                      <p>-</p>
                    </div>
                  </div>
                  <div className="alpha-detail-card">
                    <div className="alpha-detail-card-title">
                      <p>Staked positions</p>
                      <div className="alpha-detail-card-title-right">
                        <NotchedButtonFill
                          padding={'3px 10px'}
                          fontSize={'12px'}
                          height={'30px'}
                          mbFontSize='12px'
                          onClick={() => setIsOPenModalHarvest(true)}
                          disabled={poolInfo?.hasEnded}
                        >
                          HARVEST
                        </NotchedButtonFill>
                        <NotchedButtonFill
                          padding={'3px 15px'}
                          fontSize={'12px'}
                          height={'30px'}
                          mbFontSize='12px'
                          onClick={() => setIsOPenModalWithdraw(true)}
                        >
                          UNSTAKE
                        </NotchedButtonFill>
                        <NotchedButtonFill
                          padding={'3px 15px'}
                          fontSize={'12px'}
                          height={'30px'}
                          mbFontSize='12px'
                          // onClick={() => setIsOPenModalDeposit(true)}
                          // disabled={poolInfo?.hasEnded}
                          disabled={true}
                        >
                          DEPOSIT
                        </NotchedButtonFill>
                      </div>
                    </div>
                    <div className="alpha-detail-card-card-container">
                      {/* <div className="alpha-detail-card-card">
                <div className="alpha-detail-card-card-img">
                  <img src={staked} alt="" />
                </div>

                <p style={{ fontSize: '14px' }}>Average APR</p>
                <p style={{ color: '#efefe4' }}>
                  {(Number(poolInfo?.apr) * Number(userLiquidity)) / Number(poolInfo?.totalDepositLiq) > 1000
                    ? numberWithCommas((
                      (Number(poolInfo?.apr) * Number(userLiquidity)) / Number(poolInfo?.totalDepositLiq)).toFixed(2))
                    : ((Number(poolInfo?.apr) * Number(userLiquidity)) / Number(poolInfo?.totalDepositLiq)).toFixed(2)}
                  %
                </p>
              </div> */}
                      <div className="alpha-detail-card-card">
                        <div className="alpha-detail-card-card-img">
                          <img src={totals} alt="" height="18px" />
                        </div>
                        <p style={{ fontSize: '14px' }}>Your deposits</p>
                        <p style={{ color: '#efefe4', fontSize: '14px' }}>
                          ${formatXpNumber(poolInfo?.userTvl)}
                        </p>
                      </div>
                      <div className="alpha-detail-card-card">
                        <div className="alpha-detail-card-card-img">
                          <SingleCurrencyLogo currency0={tokenInfo[0]?.incentive ?? undefined} size={18} />
                        </div>
                        <p style={{ fontSize: '14px' }}>Your pending rewards</p>
                        <p style={{ color: '#efefe4', fontSize: '14px' }}>
                          {yourPedingReward ? Number(yourPedingReward).toFixed(8) : '0.00'}{' '}
                          {tokenInfo[0]?.incentive?.symbol}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default AlphaDetail

export const CountdownTimer = ({ target }: any) => {
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Math.floor(Date.now() / 1000)
      const distance = target - now

      setDays(
        parseInt(
          Math.floor(distance / 86400)
            .toString()
            .padStart(2, '0')
        )
      )
      setHours(
        parseInt(
          Math.floor((distance % 86400) / 3600)
            .toString()
            .padStart(2, '0')
        )
      )
      setMinutes(
        parseInt(
          Math.floor((distance % 3600) / 60)
            .toString()
            .padStart(2, '0')
        )
      )
      setSeconds(parseInt((distance % 60).toString().padStart(2, '0')))
    }, 1000)

    return () => clearInterval(interval)
  }, [target])

  return (
    <div className="count-down-box">
      <p className="count-down-show-box">{days < 0 ? 0 : days.toString().padStart(2, '0')}d :&nbsp;</p>
      <p className="count-down-show-box"> {hours < 0 ? 0 : hours.toString().padStart(2, '0')}h :&nbsp;</p>
      <p className="count-down-show-box"> {minutes < 0 ? 0 : minutes.toString().padStart(2, '0')}m :&nbsp;</p>
      <p className="count-down-show-box"> {seconds < 0 ? 0 : seconds.toString().padStart(2, '0')}s</p>
    </div>
  )
}
function addSecondsAndFormat(seconds: any) {
  const now = Date.now()
  const milliseconds = seconds * 1000
  const futureTimestamp = now + milliseconds
  const futureDate = new Date(futureTimestamp)
  const formattedDate = futureDate.getDate() + '/' + (futureDate.getMonth() + 1) + '/' + futureDate.getFullYear()
  return formattedDate
}
export const numberWithCommas = (number: any) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
