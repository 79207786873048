import { useEffect } from 'react'
import ChestPageSection1 from './sections/ChestPageSection1'
import styled from 'styled-components'


const MonoChestPageWrapper = styled.div`
  position: relative;
`

const PreviewImageBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -99;
  img{
    height: 1px !important;
    width: 1px !important;
  }
`

export default function MonoChestPage() {
  const arr = [0, 1, 2, 3, 4]
 
  return (
    <MonoChestPageWrapper>
      <ChestPageSection1 />

      <PreviewImageBox>
        {
          arr.map((i, index) => <img key={index} src={`https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/animation/test2/${i}-Opened.gif`} />)
        }
        {
          arr.map((i, index) => <img key={index} src={`https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/animation/test2/${i}-Spinning.gif`} />)
        }
        <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/opened-reward.png" />
      </PreviewImageBox>
    </MonoChestPageWrapper>
  )
}
