import { useEffect, useState } from "react"






export default function useCountdownTimer(type: 'DAY' | 'HOUR' | 'MIN' | 'SEC', target?: number) {
    const [days, setDays] = useState(0)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            const now = Math.floor(Date.now() / 1000)
            const distance = ((target == undefined) ? 0 : target) - now

            setDays(
                parseInt(
                    Math.floor(distance / (type == 'DAY' ? 86400 : 0))
                        .toString()
                        .padStart(2, '0')
                )
            )
            if (type == 'HOUR')
                setHours(
                    parseInt(
                        Math.floor(distance / 3600)
                            .toString()
                            .padStart(2, '0')
                    )
                )
            else
                setHours(
                    parseInt(
                        Math.floor((distance % 86400) / 3600)
                            .toString()
                            .padStart(2, '0')
                    )
                )


            if (type == 'MIN')
                setMinutes(
                    parseInt(
                        Math.floor(distance / 60)
                            .toString()
                            .padStart(2, '0')
                    )
                )
            else
                setMinutes(
                    parseInt(
                        Math.floor((distance % 3600) / 60)
                            .toString()
                            .padStart(2, '0')
                    )
                )
            if (type == 'SEC')
                setSeconds(parseInt(distance.toString().padStart(2, '0')))
            else setSeconds(parseInt((distance % 60).toString().padStart(2, '0')))
        }, 1000)

        return () => clearInterval(interval)
    }, [target])


    return {
        days,
        hours,
        minutes,
        seconds
    }
}