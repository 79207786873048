import { Currency, Ether, NativeCurrency, Token, WETH9 } from '@uniswap/sdk-core'
import invariant from 'tiny-invariant'

import {
  BLAST_SEPOLIA_TEST_BTC_ADDRESSES,
  BLAST_SEPOLIA_TEST_MONO_ADDRESSES,
  BLAST_SEPOLIA_TEST_USDC_ADDRESSES,
  UNI_ADDRESS,
} from './addresses'
import { SupportedChainId } from './chains'

export const NATIVE_CHAIN_ID = 'NATIVE'

// When decimals are not specified for an ERC20 token
// use default ERC20 token decimals as specified here:
// https://docs.openzeppelin.com/contracts/3.x/erc20
export const DEFAULT_ERC20_DECIMALS = 18

export const USDC_MAINNET = new Token(
  SupportedChainId.MAINNET,
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  6,
  'USDC',
  'USD//C'
)
export const DAI = new Token(
  SupportedChainId.MAINNET,
  '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const USDT = new Token(
  SupportedChainId.MAINNET,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD'
)

// Blast sepolia
export const WETH_BLAST_SEPOLIA = new Token(
  SupportedChainId.BLAST_SEPOLIA,
  '0x4200000000000000000000000000000000000023',
  18,
  'WETH',
  'Wrapped Ether'
)
export const BTC_BLAST_SEPOLIA = new Token(
  SupportedChainId.BLAST_SEPOLIA,
  BLAST_SEPOLIA_TEST_BTC_ADDRESSES,
  8,
  'BTC',
  'Test Bitcoin'
)
export const USDC_BLAST_SEPOLIA = new Token(
  SupportedChainId.BLAST_SEPOLIA,
  BLAST_SEPOLIA_TEST_USDC_ADDRESSES,
  6,
  'USDC',
  'Test USDC'
)
export const MONO_BLAST_SEPOLIA = new Token(
  SupportedChainId.BLAST_SEPOLIA,
  BLAST_SEPOLIA_TEST_MONO_ADDRESSES,
  18,
  'tMONO',
  'Test Mono'
)
export const MUSD_BLAST_SEPOLIA = new Token(
  SupportedChainId.BLAST_SEPOLIA,
  '0x54D12b155dA569aaEa910A778Eb3EC9cd2B26230',
  18,
  'MUSD',
  'Test MUSD'
)
export const USDB_BLAST_SEPOLIA = new Token(
  SupportedChainId.BLAST_SEPOLIA,
  '0x4200000000000000000000000000000000000022',
  6,
  'USDB',
  'Test USDB'
)

// Blast mainnet
export const WETH_BLAST = new Token(
  SupportedChainId.BLAST,
  '0x4300000000000000000000000000000000000004',
  18,
  'WETH',
  'Wrapped Ether'
)
export const USDB_BLAST = new Token(
  SupportedChainId.BLAST,
  '0x4300000000000000000000000000000000000003',
  18,
  'USDB',
  'Rebasing USD'
)
export const MUSD_BLAST = new Token(
  SupportedChainId.BLAST,
  '0x837fE561e9C5DFa73F607fDa679295DBC2Be5E40',
  18,
  'MUSD',
  'Monoswap USD'
)

export const MONOSWAP_TOKEN = new Token(
  SupportedChainId.BLAST,
  '0xF50D4B6F897313B867C0607a1A95C1771C383d6B',
  18,
  'MONO',
  'MonoSwap Token'
)

export const BLAST_TOKEN = new Token(
  SupportedChainId.BLAST,
  '0xb1a5700fA2358173Fe465e6eA4Ff52E36e88E2ad',
  18,
  'BLAST',
  'Blast'
)

export const PAC_BLAST = new Token(
  SupportedChainId.BLAST,
  '0x5ffd9EbD27f2fcAB044c0f0a26A45Cb62fa29c06',
  18,
  'PAC',
  'PacMoon'
)
export const OLE_BLAST = new Token(
  SupportedChainId.BLAST,
  '0x73c369F61c90f03eb0Dd172e95c90208A28dC5bc',
  18,
  'OLE',
  'OpenLeverage'
)
export const BAJA_BLAST = new Token(
  SupportedChainId.BLAST,
  '0x5fe8534a6f96cb01261bd96e98c17c2c1cab3204',
  18,
  'BAJA',
  'BAJA'
)

export const ANDY_BLAST = new Token(
  SupportedChainId.BLAST,
  '0xd43d8adac6a4c7d9aeece7c3151fca8f23752cf8',
  9,
  'ANDY',
  'Andy'
)

export const JUICE_BLAST = new Token(
  SupportedChainId.BLAST,
  '0x818a92bc81Aad0053d72ba753fb5Bc3d0C5C0923',
  18,
  'JUICE',
  'Juice'
)

export const ORBIT_BLAST = new Token(
  SupportedChainId.BLAST,
  '0x42e12d42b3d6c4a74a88a61063856756ea2db357',
  18,
  'ORBIT',
  'ORBIT'
)

export const YIELD_BLAST = new Token(
  SupportedChainId.BLAST,
  '0x67fa2887914fa3729e9eed7630294fe124f417a0',
  18,
  'YIELD',
  'YIELD'
)

export const ezETH_BLAST = new Token(
  SupportedChainId.BLAST,
  '0x2416092f143378750bb29b79ed961ab195cceea5',
  18,
  'ezETH',
  'ezETH'
)

export const ZKDX_BLAST = new Token(
  SupportedChainId.BLAST,
  '0x71CA0E46434b4dfbd7065aFE508C9A107eB3a522',
  18,
  'zkDX',
  'zkDX'
)

export const KID_BLAST = new Token(
  SupportedChainId.BLAST,
  '0x082E53a6519c5e0EC846D3Dc6eE499011601990A',
  18,
  'KID',
  'KID'
)

export const KALAX_BLAST = new Token(
  SupportedChainId.BLAST,
  '0x2F67F59b3629Bf24962290DB9edE0CD4127e606D',
  18,
  'KALA',
  'Kalax'
)

export const UNI: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(SupportedChainId.MAINNET, UNI_ADDRESS[1], 18, 'UNI', 'Uniswap'),
}

export const WRAPPED_NATIVE_CURRENCY: { [chainId: number]: Token | undefined } = {
  ...(WETH9 as Record<SupportedChainId, Token>),
  [SupportedChainId.BLAST_SEPOLIA]: WETH_BLAST_SEPOLIA,
  [SupportedChainId.BLAST]: WETH_BLAST,
}

export function isCelo(chainId: number) {
  return false
}

export function isBlast(chainId: number): chainId is SupportedChainId.BLAST {
  return chainId === SupportedChainId.BLAST
}

function isMatic(chainId: number) {
  return false
}

class MaticNativeCurrency extends NativeCurrency {
  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId
  }

  get wrapped(): Token {
    if (!isMatic(this.chainId)) throw new Error('Not matic')
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId]
    invariant(wrapped instanceof Token)
    return wrapped
  }

  public constructor(chainId: number) {
    if (!isMatic(chainId)) throw new Error('Not matic')
    super(chainId, 18, 'MATIC', 'Polygon Matic')
  }
}

class ExtendedEther extends Ether {
  public get wrapped(): Token {
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId]
    if (wrapped) return wrapped
    throw new Error('Unsupported chain ID')
  }

  private static _cachedExtendedEther: { [chainId: number]: NativeCurrency } = {}

  public static onChain(chainId: number): ExtendedEther {
    return this._cachedExtendedEther[chainId] ?? (this._cachedExtendedEther[chainId] = new ExtendedEther(chainId))
  }
}

const cachedNativeCurrency: { [chainId: number]: NativeCurrency | Token } = {}
export function nativeOnChain(chainId: number): NativeCurrency | Token {
  if (cachedNativeCurrency[chainId]) return cachedNativeCurrency[chainId]
  let nativeCurrency: NativeCurrency | Token
  if (isMatic(chainId)) {
    nativeCurrency = new MaticNativeCurrency(chainId)
  } else {
    nativeCurrency = ExtendedEther.onChain(chainId)
  }
  return (cachedNativeCurrency[chainId] = nativeCurrency)
}

export const TOKEN_SHORTHANDS: { [shorthand: string]: { [chainId in SupportedChainId]?: string } } = {
  USDC: {
    [SupportedChainId.MAINNET]: USDC_MAINNET.address,
    [SupportedChainId.BLAST_SEPOLIA]: USDC_BLAST_SEPOLIA.address,
    [SupportedChainId.BLAST]: USDB_BLAST.address,
  },
}
