import styled from "styled-components";
import { PageDesc, PageTitle, PageTitleBox, TextLinear } from "../MonoPotPage";
import { useEffect, useMemo, useState, useCallback } from "react";
import { BREAKPOINTS } from "theme";
import { WonTicketType, getMyWonTicketIds, useMonopotStore } from "hooks/useMonopot";
import { NotchedButtonFill } from "components/Button";
import useMusd from "hooks/useMusd";
import { formatXpNumber } from "pages/Xp/sections/XpTitle";
import { notification, Popover } from "antd";
import MonoPotHistoryTable from "./MonoPotHistoryTable";
import MonoPotRoundPicker from "./MonoPotRoundPicker";
import Modal from "components/Modal";
import SuccessClaimedRwModal from "./SuccessClaimedRwModal";
import checkUnsupportChainId from "utils/checkUnsupportChainId";
import { useWeb3React } from "@web3-react/core";

const NumberWrapper = styled.div`
    max-width: 1224px;
    /* max-height: 302px; */
    width: 100%;
    height: 100%;
    margin: auto;
    position: relative;
    display: grid;
    align-items: center;
    gap: 40px;
    padding-top: 80px;
    padding-bottom: 66px;
    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        padding-left: 10px;
        padding-right: 10px;
    }
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        padding: 25px 0;
        gap: 20px;
        width: 90%;
        max-height: 250px;
    }
`

const NumberList = styled.div`
    display: flex;
    justify-content: center;
    gap: 32px;
    max-width: 760px;
    width: 100%;
    height: 100px;
    margin: auto;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        gap: 16px;
        max-width: 80%;
        height: 70px;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        /* gap: 16px;
        max-width: 500px;
        height: 70px; */
        max-width: 90%;
    }
`

const NumberTitle = styled.h4`
    max-width: 234px;
    min-height: 56px;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 25px;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    align-items: center;
    background: linear-gradient(90deg, rgba(104, 255, 255, 0.12) 0%, rgba(104, 255, 155, 0.12) 97.47%);
    clip-path: polygon(17.3px 0, 100% 0, 100% calc(100% - 17.3px), calc(100% - 17.3px) 100%, 0 100%, 0 17.3px);
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        height: fit-content;
        min-height: 40px;
        span {
            font-size: 14px;
        }
    }
    span{
        cursor: pointer;
    }
`


const LotteryNumberWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* -webkit-box-shadow: 0px 0px 50px 10px rgba(84,255,61,0.54);  */
    /* box-shadow: 0px 0px 50px 10px rgba(84,255,61,0.54); */
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        /* gap: 16px;
        max-width: 500px;
        height: 70px; */
        width: 45px;
        height: 45px;
    }
`;

const Img = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const Text = styled.span`
    color: #EFEFE4;
    text-align: center;
    font-family: Righteous;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 60px */
    position: relative;
    z-index: 1;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        font-size: 35px;
    }
    @media screen and (max-width: 640px) {
        font-size: 25px;
    }
`;

export const SeeMoreLessBtn = styled.div`
    text-align: center;
    font-family: Righteous;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 20px */
    letter-spacing: -0.32px;
    cursor: pointer;
`

export type LotteryNumberProps = {
    num: string,
    isMatched?: boolean
}

export function LotteryNumber({ num, isMatched }: LotteryNumberProps) {

    return <LotteryNumberWrapper>
        {
            isMatched ?
                <Img src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/lottery/number/match_number.png' />
                :
                <Img src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/lottery/number/none_match_number.png' />
        }

        <Text>
            {num}
        </Text>
    </LotteryNumberWrapper>
}


const WonPotWrapper = styled.div`
    max-width: 1224px;
    margin: 0 auto;
    margin-top: 115px;
    margin-bottom: 36px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 28px;
    row-gap: 36px;
    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
        width: 90%;
    }

    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        width: 90%;
        justify-content: center;
    }
    
`
const WonPotInfoBox = styled.div<{
    isBold?: boolean
}>`
    max-width: 50%;
    width: calc(50% - 14px);
    /* opacity: 0.06; */
    background:  #efefe410;
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: space-between;
    padding: 40px;
    padding-bottom: 31px;
    position: relative;
    span{
        font-weight: ${({ isBold }) => isBold ? 600 : 400} !important;
    }
    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        /* display: grid;
        justify-content: center; */
        max-width: 100%;
        width: fit-content;
        gap: 40px;
    }
`
const WonPotInfoRound = styled.span`
    position: absolute;
    top: -12px;
    left: 40px;
    width: 189px;
    height: 40px;
    background: linear-gradient(90deg, #68ffff25, #68ff9a22);
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: polygon(10.3px 0, 100% 0, 100% calc(100% - 10.3px), calc(100% - 10.3px) 100%, 0 100%, 0 10.3px);
`
const WonPotInfoLeft = styled.div`
    display: flex;
    gap: 10px;
    img{
        max-width: 36px;
        max-height: 36px;
    }
    span{
        font-family: Righteous;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 50.4px */
    }
`
const WonPotInfoLeftTop = styled.div`
    display: grid;
    span{
        text-align: left;
    }
`

const WonPotInfoRight = styled.div`
    
`


const initLotteryNum = () => ['?', '?', '?', '?', '?', '?']
export default function LotteryNumberList() {
    const { chainId } = useWeb3React()
    const { musdPrice } = useMusd()
    const [currentRound, setCurrentRound] = useState<number>(0)
    const [latestRwRound, setLatestRwRound] = useState<number>(0)

    const [lotteryNumber, setLotteryNumber] = useState<string[]>(initLotteryNum())
    const [myWonNumbers, setMyWonNumbers] = useState<string[]>(initLotteryNum())
    const [myWinTicketIds, setMyWinTicketIds] = useState<WonTicketType[]>([])
    const [claimedTickets, setClaimedTickets] = useState<WonTicketType[]>([]);
    const [isShowMore, setIsShowMore] = useState<boolean>(false)
    const [isClaiming, setIsClaiming] = useState<boolean>(false)
    const [hasClaimedLatestRw, setHasClaimedLatestRw] = useState<boolean>(false)

    const [isOpenSucessClaimedRw, setIsOpenSucessClaimedRw] = useState<boolean>(false)
    const [myReward, setMyReward] = useState<{
        hash: string;
        musd: string;
        tax: string;
    }>({
        hash: '',
        musd: '',
        tax: ''
    })

    const [isOpenRoundPicker, setIsOpenRoundPicker] = useState<boolean>(false)
    const { contractWithSign, currentChestInfo, myCurrentBoughtTickets, latestChestInfo, loadLatestChestInfo, isLoadingLatestChestInfo, loadMyCurrentBoughtTickets } = useMonopotStore()

    const detailRw = useCallback((index: number) => ({
        perRw: latestChestInfo?.monoPerBracket[index] ?? 0,
        winnerCnt: latestChestInfo?.countWinnersPerBracket[index] ?? 0,
    }), [latestChestInfo])


    useEffect(() => {
        if (claimedTickets.length > 0) {
            let totalRw = 0
            const arr6: WonTicketType[] = claimedTickets.filter(i => i.matchedSize == 6)
            const arr5: WonTicketType[] = claimedTickets.filter(i => i.matchedSize == 5)
            const arr4: WonTicketType[] = claimedTickets.filter(i => i.matchedSize == 4)
            const arr3: WonTicketType[] = claimedTickets.filter(i => i.matchedSize == 3)
            const arr2: WonTicketType[] = claimedTickets.filter(i => i.matchedSize == 2)
            const arr1: WonTicketType[] = claimedTickets.filter(i => i.matchedSize == 1)

            totalRw += arr1.length * (latestChestInfo?.monoPerBracket[0] ?? 0)
            totalRw += arr2.length * (latestChestInfo?.monoPerBracket[1] ?? 0)
            totalRw += arr3.length * (latestChestInfo?.monoPerBracket[2] ?? 0)
            totalRw += arr4.length * (latestChestInfo?.monoPerBracket[3] ?? 0)
            totalRw += arr5.length * (latestChestInfo?.monoPerBracket[4] ?? 0)
            totalRw += arr6.length * (latestChestInfo?.monoPerBracket[5] ?? 0)
            if (totalRw > 0)
                setMyReward(state => ({
                    ...state,
                    musd: formatXpNumber(totalRw),
                    tax: formatXpNumber(totalRw * 0.15)
                }))
        }
    }, [claimedTickets, currentChestInfo])

    const remainWonTickets = useMemo(() => myWinTicketIds.filter(i => !i.hasClaimed), [myWinTicketIds])
    const claimWonTickets = async () => {
        checkUnsupportChainId(chainId)
        if (isClaiming || remainWonTickets.length == 0) return
        setIsClaiming(true)
        try {
            const claimTickets = remainWonTickets.slice(0, 100)
            setClaimedTickets(claimTickets)
            const tx = await contractWithSign?.claimTickets(currentRound, claimTickets.map(i => i.id), claimTickets.map(i => i.wonNums))
            await tx.wait()
            setMyReward(state => ({
                ...state,
                hash: tx.hash
            }))
            setIsOpenSucessClaimedRw(true)
            notification.success({
                message: 'Claim successfully'
            })
            setHasClaimedLatestRw(true)
            await loadMyCurrentBoughtTickets(latestChestInfo?.id as number)
        }
        catch (err) {
            notification.error({
                message: 'Claim won tickets failed'
            })
            console.log('claim won tickets failed', err)
        }
        finally {
            setIsClaiming(false)

        }
    }
    useEffect(() => {
        if (currentRound > 0 && !isLoadingLatestChestInfo) {
            setHasClaimedLatestRw(false)
            setMyWinTicketIds([])
            setMyWonNumbers(initLotteryNum())
            setLotteryNumber(initLotteryNum())
            loadLatestChestInfo(currentRound)
            setIsOpenRoundPicker(false)
        }
    }, [currentRound])

    const initLotteryNumber = async () => {
        if (latestChestInfo?.finalNumber) {
            if (latestChestInfo.finalNumber == 0)
                return
            const lotteryNum = latestChestInfo.finalNumber.toString().slice(1).split('')
            setLotteryNumber(lotteryNum)
        }
    }

    useEffect(() => {
        initLotteryNumber()
    }, [latestChestInfo])

    useEffect(() => {
        if (!latestChestInfo || latestChestInfo.finalNumber == 0) return
        const lotteryNum = latestChestInfo.finalNumber.toString().slice(1).split('')
        const {
            wonTicketIds,
            myWonNums,
            maxMyWonNumLength
        } = getMyWonTicketIds(myCurrentBoughtTickets, lotteryNum)

        if (maxMyWonNumLength > 0) {
            setMyWinTicketIds(wonTicketIds)
            setMyWonNumbers(myWonNums)
            if (wonTicketIds.filter(i => i.hasClaimed).length == wonTicketIds.length)
                setHasClaimedLatestRw(true)
        }
    }, [myCurrentBoughtTickets])


    useEffect(() => {
        if (currentChestInfo && currentChestInfo.id > 1) {
            const round = currentChestInfo.id
            setCurrentRound(round)
            setLatestRwRound(round)
        }
    }, [currentChestInfo])

    return <div style={{ position: 'relative', zIndex: 1 }}>
        {
            String(latestChestInfo?.finalNumber ?? '0') != '0' && myWinTicketIds.length > 0 ?
                <PageTitleBox>
                    <PageTitle>
                        {
                            myWinTicketIds.length > 0 ?
                                <>🎉 Your number <TextLinear>matched</TextLinear> 🎉</>
                                :
                                <>Oops!</>
                        }
                    </PageTitle>
                    <PageDesc>
                        {
                            myWinTicketIds.length > 0 ?
                                <>Congratulations! Your numbers matched. You won the prize.</>
                                :
                                <>None of your numbers matched. Better luck next time.</>
                        }
                    </PageDesc>
                </PageTitleBox>
                :
                <PageTitleBox>
                    <PageTitle>
                        <TextLinear>Winning </TextLinear>Number
                    </PageTitle>
                    <PageDesc>
                        Get your tickets and play with the odds for the jackpot prize
                    </PageDesc>
                </PageTitleBox>
        }

        {
            myWinTicketIds.length > 0 &&
            <div style={{ minWidth: '332px', width: 'fit-content', height: '40px', margin: '36px auto' }}>
                <NotchedButtonFill height='100%' mbFontSize="14px" onClick={claimWonTickets} disabled={hasClaimedLatestRw}>
                    {
                        isClaiming ? 'CLAIMING...' : hasClaimedLatestRw ? 'YOU HAVE CLAIMED THE REWARD' : 'CLAIM YOUR PRIZE'
                    }
                </NotchedButtonFill>
            </div>
        }

        <NumberWrapper>
            <Img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/lottery/number_bg.png" />
            <Popover
                open={isOpenRoundPicker}
                overlayInnerStyle={{ padding: 0, borderRadius: 0 }}
                arrow={true}
                color="black"
                placement={'topLeft'}
                content={<MonoPotRoundPicker
                    round={currentRound}
                    setRound={setCurrentRound}
                    onClose={() => setIsOpenRoundPicker(false)}
                />}>
                <NumberTitle>
                    <span onClick={() => {
                        if (currentRound > 1 && !isLoadingLatestChestInfo)
                            setCurrentRound(i => i - 1)
                    }}>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 11.2957L0 7.88771V3.73571L12 0.303711V2.90771L1.824 5.79971L12 8.70371V11.2957Z" fill="#EFEFE4" fillOpacity={currentRound == 1 ? 0.5 : 1} />
                        </svg>
                    </span>

                    <div style={{ cursor: 'pointer' }} onClick={() => setIsOpenRoundPicker(!isOpenRoundPicker)}>
                        {
                            currentRound == latestRwRound ?
                                <span>LATEST ROUND</span>
                                :
                                <span>ROUND {currentRound}/{latestRwRound}</span>
                        }
                    </div>

                    <span onClick={() => {
                        if (currentRound < latestRwRound && !isLoadingLatestChestInfo)
                            setCurrentRound(i => i + 1)
                    }}>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 11.2004V8.60839L10.176 5.80039L0 3.00439V0.400391L12 3.73639V7.88839L0 11.2004Z" fill="#EFEFE4" fillOpacity={currentRound == latestRwRound ? 0.5 : 1} />
                        </svg>

                    </span>
                </NumberTitle>
            </Popover>
            <NumberList >
                <LotteryNumber num={lotteryNumber[0]} isMatched={myWonNumbers[0] == lotteryNumber[0]} />
                <LotteryNumber num={lotteryNumber[1]} isMatched={myWonNumbers[1] == lotteryNumber[1]} />
                <LotteryNumber num={lotteryNumber[2]} isMatched={myWonNumbers[2] == lotteryNumber[2]} />
                <LotteryNumber num={lotteryNumber[3]} isMatched={myWonNumbers[3] == lotteryNumber[3]} />
                <LotteryNumber num={lotteryNumber[4]} isMatched={myWonNumbers[4] == lotteryNumber[4]} />
                <LotteryNumber num={lotteryNumber[5]} isMatched={myWonNumbers[5] == lotteryNumber[5]} />
            </NumberList>
        </NumberWrapper>

        <WonPotWrapper style={{ display: isShowMore ? 'flex' : 'none' }}>
            {[0, 1, 2, 3, 4, 5].map(i => <WonPotInfoBox key={i} isBold={detailRw(i).perRw > 0}>
                <WonPotInfoRound>
                    MATCH {i == 5 ? 'ALL' : 'FIRST'} <TextLinear style={{ marginLeft: '4px' }}> {i + 1}</TextLinear>
                </WonPotInfoRound>

                <WonPotInfoLeft>
                    <img style={{ paddingTop: '10px' }} src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section3/musd-token.png" />
                    <WonPotInfoLeftTop>
                        <Text>{formatXpNumber(detailRw(i).perRw * detailRw(i).winnerCnt)} MUSD</Text>
                        <Text style={{ fontSize: '21px', fontWeight: 400 }}>${formatXpNumber(detailRw(i).perRw * detailRw(i).winnerCnt * musdPrice)}</Text>
                    </WonPotInfoLeftTop>
                </WonPotInfoLeft>

                <WonPotInfoRight>
                    <WonPotInfoLeftTop style={{ gap: '10px', paddingTop: '10px' }}>
                        <Text style={{ fontSize: '21px', fontWeight: 400 }}>{detailRw(i).winnerCnt} Winning Tickets</Text>
                        <Text style={{ fontSize: '21px', fontWeight: 400 }}>{formatXpNumber(detailRw(i).perRw)} MUSD each</Text>
                    </WonPotInfoLeftTop>
                </WonPotInfoRight>
            </WonPotInfoBox>)}
        </WonPotWrapper>

        <SeeMoreLessBtn onClick={() => setIsShowMore(!isShowMore)}>
            <TextLinear>{isShowMore ? 'See Less' : 'See More'}</TextLinear>
        </SeeMoreLessBtn>

        <MonoPotHistoryTable />

        <Modal isOpen={isOpenSucessClaimedRw}
            onDismiss={() => { setIsOpenSucessClaimedRw(false); }} >
            <SuccessClaimedRwModal onClose={() => setIsOpenSucessClaimedRw(false)} hash={myReward.hash} musd={myReward.musd} tax={myReward.tax} />
        </Modal>
    </div>
}