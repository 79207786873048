import { useWeb3React } from "@web3-react/core";
import { RankText, RewardColBox, RewardColBoxItem, RewardColSeperator, Text, TheadLabel } from "pages/Xp/sections/table";
import { useEffect, useState } from "react";
import styled from "styled-components"
import { BREAKPOINTS } from "theme";
import { shortenAddress } from "utils";
import axios from "axios";
import { Dropdown, MenuProps, Pagination, PaginationProps, Space } from "antd";
import { BASE_API, LeaderBoardType } from "..";
import { formatXpNumber } from "./XpTitle";



const ClaimedXpHistorySection = styled.section`
    max-width: 1116px;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
        padding: 0 20px;
    }
`;

const ClaimedXpHistoryTitle = styled.h4`
    color: #EFEFE4;
    font-family: Righteous;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 60px */
    @media screen and (max-width: ${BREAKPOINTS.lg}px) {
        font-size: 40px;
        line-height: 50px;
        width: unset;
        margin-bottom: 12px;
    }
    
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        background-size: cover;
        font-size: 32px;
        line-height: 40px;
    } 
`;

const PrevNextBtnBox = styled.div`
    display: flex;
    width: 32px;
    height: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #EFEFE4;    
    opacity: 0.5;
    color: #EFEFE4;
`;

const TypeText = styled.span`
    color: #EFEFE4;
    font-family: Urbanist !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 17.5px */
`;



export const PrevNextRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
        return <PrevNextBtnBox> {'<'} </PrevNextBtnBox>;
    }
    if (type === 'next') {
        return <PrevNextBtnBox>{'>'}</PrevNextBtnBox>;
    }
    return originalElement;
};

export default function ClaimedXpHistory() {
    const { account } = useWeb3React();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [originDataList, setOriginDataList] = useState<any[]>([]);
    const pageSize = 100;
    const [totalElement, setTotalElement] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [filterType, setFilterType] = useState<LeaderBoardType | 'all' | 'RW'>('all');
    let isCallingApi: boolean = false;
    const onCallApi = (page: number, type: string) => {
        if (isCallingApi) return;
        isCallingApi = true;
        axios.get(`${BASE_API}/monoswap/api/v1/leaderboard/my-claimed-xp-history?address=${account ?? ''}&page=${page - 1}&type=${type}`)
            .then(({ data }: { data: any }) => {
                setOriginDataList(data.data);
                setTotalElement(data.totalElement);
            })
            .catch(err => { })
            .finally(() => isCallingApi = false)
    }

    const onChangePage = (page: number) => { setCurrentPage(page); onCallApi(page, filterType) };

    const getTypeName = (type: string) => {
        switch (type) {
            case 'SWAP': return 'Swap';
            case 'FARM': return 'Farm';
            case 'MUSD': return 'MUSD';
            case 'XMONO': return 'xMONO';
            case 'REF': return 'Referral';
            case 'BONUS': return 'Bonus';
            default:
                if (type.startsWith('RW'))
                    return 'Reward';
                else return '';
        }
    }

    const getBalanceName = (type: string) => {
        switch (type) {
            case 'SWAP': return 'MonoXP';
            case 'FARM': return 'MonoXP';
            case 'MUSD': return 'MonoXP';
            case 'XMONO': return 'MonoXP';
            case 'REF': return 'MonoXP';
            case 'BONUS': return 'MonoXP';
            case 'RW_MUSD': return 'MUSD';
            case 'RW_XMONO': return 'xMONO';
            case 'RW_MONO': return 'MONO';
            case 'RW_POINT': return 'Blast Point';
            case 'RW_GOLD': return 'Blast Gold';
            default: return '';
        }
    }

    const RenderFilterTypeContent = (val: string, type: LeaderBoardType | 'all' | 'RW') => {
        return <Space align="baseline">
            {
                filterType == type && <div style={{ width: '18px', height: '14px' }}>
                    <svg color="white" width='100%' height={'100%'} strokeWidth={3} stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                    </svg>
                </div>
            }

            <TypeText>
                {val}
            </TypeText>
        </Space>
    }
    const filterTypeItems: MenuProps['items'] = [
        {
            key: 'all',
            label: RenderFilterTypeContent('All', 'all'),
        },
        {
            key: 'SWAP',
            label: RenderFilterTypeContent('Swap', 'SWAP'),
        },
        {
            key: 'MUSD',
            label: RenderFilterTypeContent('MUSD', 'MUSD'),
        },
        {
            key: 'REF',
            label: RenderFilterTypeContent('Referral', 'REF'),
        },
        {
            key: 'BONUS',
            label: RenderFilterTypeContent('Bonus', 'BONUS'),
        },
        {
            key: 'RW',
            label: RenderFilterTypeContent('Reward', 'RW'),
        },

    ];

    const onClickFilter = ({ key }: any) => {
        setFilterType(key);
        setCurrentPage(1);
        onCallApi(1, key);
    }

    useEffect(() => {
        if (account) onCallApi(1, 'all');
    }, [account]);

    return <ClaimedXpHistorySection id="my-claimed-xp" className="my-claimed-xp">
        <ClaimedXpHistoryTitle>
            MonoXP History
        </ClaimedXpHistoryTitle>
        <div className="paginate-table" style={{ fontFamily: 'Urbanist', }}>
            <table>
                <thead>
                    <tr >
                        <th>
                            <TheadLabel style={{ paddingLeft: '20px' }}>
                                <Dropdown
                                    trigger={['click', 'hover']}
                                    menu={{ items: filterTypeItems, selectedKeys: [filterType], onClick: onClickFilter }}
                                    placement="bottomLeft"
                                    getPopupContainer={() => document.getElementById('my-claimed-xp') ?? document.body}>
                                    <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'start', gap: '5px' }}>
                                        <span>TYPE</span>
                                        <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4873" width="16" height="16"><path d="M128 192a64 64 0 0 1 64-64h640a64 64 0 0 1 64 64v114.304l-272 160V896h-224V466.304L128 306.304V192z m704 0H192v77.696l272 160V832h96V429.696l272-160V192z" p-id="4874" fill="#8a8a8a"></path></svg>
                                    </div>
                                </Dropdown>
                            </TheadLabel>
                        </th>
                        <th>
                            <TheadLabel>
                                BALANCE
                            </TheadLabel>
                        </th>
                        <th>
                            <TheadLabel>
                                DATE
                            </TheadLabel>
                        </th>
                    </tr>
                </thead>
            </table>
            <table>
                <tbody>
                    {account && originDataList.map((row: any, index: number) => (
                        <tr key={index}>
                            <td>
                                <Text style={{ paddingLeft: '20px' }}> {getTypeName(row?.type)}</Text>
                            </td>
                            <td>
                                <div style={{ display: 'flex', gap: '5px', justifyContent: 'start' }}>
                                    <Text style={{ color: row?.xp > 0 ? '#68FF9B' : 'red' }}>
                                        {(row?.xp > 0 ? '+' : '-') + formatXpNumber(row?.xp)}
                                    </Text>
                                    <Text style={{ color: row?.xp > 0 ? '#68FF9B' : 'red' }}>
                                        {getBalanceName(row?.type)}
                                    </Text>
                                </div>

                            </td>
                            <td>
                                <Text> {row?.date}</Text>
                            </td>
                        </tr>
                    ))}

                </tbody>

            </table>
        </div>
        {
            originDataList.length > 0 &&
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Pagination
                    current={currentPage}
                    total={totalElement}
                    pageSize={pageSize}
                    itemRender={PrevNextRender}
                    showSizeChanger={false}
                    onChange={onChangePage}
                />
            </div>
        }
    </ClaimedXpHistorySection>
}