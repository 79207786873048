import { Connector } from '@web3-react/types'
import { ConnectionType, okxConnection } from 'connection'
import { getConnectionName } from 'connection/utils'
import { useEffect, useState } from 'react'

import { isAndroid, isiOS } from './BikeepWalletOption'
import Option from './Option'
import { useLocation } from 'react-router-dom'

const BASE_PROPS = {
  color: 'white',
  icon: 'https://static.coinall.ltd/cdn/wallet/logo/okb.png',
  id: 'OKX_WALLET',
}

const isOKApp = /OKApp/i.test(navigator.userAgent);


export function OpenOkxWalletOption() {
  const location = useLocation()
  const isActive = okxConnection.hooks.useIsActive()
  const [link, setLink] = useState('https://www.okx.com/download');

  useEffect(() => {
    if (isiOS() || isAndroid()) {
      const encodedUrl = "https://www.okx.com/download?deeplink=" + encodeURIComponent("okx://wallet/dapp/url?dappUrl=" + encodeURIComponent(`https://monoswap.io/#${location.pathname}`));
      setLink(encodedUrl)
    }
  }, [])
  return (
    <Option
      {...BASE_PROPS}
      isActive={isActive}
      link={link}
      header="Open in OKX Wallet"
    />
  )
}

export function OkxWalletOption({ tryActivation }: { tryActivation: (connector: Connector) => void }) {
  const isActive = okxConnection.hooks.useIsActive()
  return (
    <Option
      {...BASE_PROPS}
      isActive={isActive}
      onClick={() => tryActivation(okxConnection.connector)}
      header={getConnectionName(ConnectionType.OKX_WALLET)}
    />
  )
}
