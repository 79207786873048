import styled from 'styled-components'
import './notchedbutton.css'
import { ReactElement } from 'react'

const TextSpan = styled.span<{
  color?: string
}>`
  font-family: Righteous;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 18px */
  text-transform: uppercase;
  color: ${({ color }) => color ?? 'linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%)'};
  background: linear-gradient(90deg, #68ffff 0%, #68ff9b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const BorderButton = styled.button<{
  width: string
}>`
  width: ${({ width }) => width ?? '100%'};

  &:hover ${TextSpan} {
    color: gray;
  }
  &:disabled {
    cursor: not-allowed;
  }
`
export const NotchedButtonBolder = ({ text, onClick, disabled, style, color, width, children }: any) => {
  return (
    <BorderButton style={style} width={width} className="btn-notched" onClick={onClick} disabled={disabled}>
      <div className="btn-text-notched" style={{ height: '45px' }}></div>
      <TextSpan className="btn-notched-content" color={color}>
        {text}
        {children}
      </TextSpan>
    </BorderButton>
  )
}

const BorderButtonNew = styled.div<{
  w: string
  h: string
  borderColor: string
  cursor: string
  clipPath: string
  disableHover?: boolean
}>`
  width: ${({ w }) => w};
  height: ${({ h }) => h};
  background: ${({ borderColor }) => borderColor};
  clip-path: ${({ clipPath }) => clipPath};
  padding: 1px;
  cursor: ${({ cursor }) => cursor};
  &:hover {
    background: ${({ disableHover, borderColor }) => disableHover ? borderColor : '$68ffff'};
  }
`
const BorderButtonNewContent = styled.div<{
  clipPath: string
  hover: string
  bg: string
  disableHover?: boolean
}>`
  width: 100%;
  height: 100%;
  background: ${({ bg }) => bg};
  clip-path: ${({ clipPath }) => clipPath};
  &:hover {
    background: ${({ disableHover, bg, hover }) => disableHover ? bg : hover};
  }
`
export const NotchedButtonBolderNew = ({
  w,
  h,
  borderColor,
  clipPath,
  bg,
  cursor,
  hv,
  disableHover,
  children,
}: {
  w: string
  h: string
  borderColor?: string
  clipPath?: string
  bg?: string
  cursor?: string
  hv?: string
  disableHover?: boolean
  children: ReactElement
}) => {
  const BorderColor = borderColor ?? 'white'
  const ClipPath = clipPath ?? 'polygon(10px 0, 100% 0, 100% 75%, calc(100% - 12px) 100%, 0 100%, 0 25%)'
  const bgHv = hv ? hv : '#000000'
  return (
    <BorderButtonNew className='border-btn' w={w} h={h} borderColor={BorderColor} clipPath={ClipPath} cursor={cursor ?? 'unset'} disableHover={disableHover}>
      <BorderButtonNewContent clipPath={ClipPath} bg={bg ?? '#1C1B20'} hover={bgHv} disableHover={disableHover}>
        {children}
      </BorderButtonNewContent>
    </BorderButtonNew>
  )
}
