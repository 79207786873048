import { useCallback, useEffect, useMemo, useState } from 'react'
import './launchpad.css'
import { NotchedButtonFill } from '../../components/Button'
import { Text } from 'rebass'
import useCopyClipboard from 'hooks/useCopyClipboard'
import { Copy } from 'react-feather'
import { useWeb3React } from '@web3-react/core'
import { useNavigate, useParams } from 'react-router-dom'
import { MONO_LAUNCHPAD } from '../../constants/addresses'
import { useCustomeContract } from 'hooks/useContract'
import { SupportedChainId } from '../../constants/chains'
import mono_launchpad_abi from 'abis/launchpad/monolaunchpad.json'
import { ethers } from 'ethers'
import { BREAKPOINTS, CustomLightSpinner } from '../../theme'
import { NotchedButtonBolderNew } from 'components/Button/NotchedButton'
import axios from 'axios'
// image
import icon1 from 'assets/images/launchpad/Icon.png'
import icon2 from 'assets/images/launchpad/icon2.png'
import icon3 from 'assets/images/launchpad/icon3.png'
import vector from 'assets/images/launchpad/Vector.png'
import ClaimableIcon from 'assets/images/launchpad/claimable.png'
import xMono from 'assets/images/launchpad/xMONO.png'
import {
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer,
} from 'recharts'
import { IdoPriceChart } from './IdoChart'

import styled from 'styled-components'
import useCountdownTimer from 'hooks/useCountdownTimer'
import { useToggleWalletModal } from 'state/application/hooks'
import IdoRoadMap from './IdoRoadMap'
import { getContract } from 'utils'
import { RPC_URLS } from 'constants/networks'
import { formatXpNumber } from 'pages/Xp/sections/XpTitle'
import { notification } from 'antd'
import checkUnsupportChainId from 'utils/checkUnsupportChainId'
import dayjs from 'dayjs'

const Circle = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/blue-loader.svg'

const RemainTimeBoxWrapper = styled.div`
    display: grid;
    justify-items: center;
    gap: 24px;
`
const RemainTimeBox = styled.div`
    display: flex;
    gap: 25px;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        gap: 10px;
    }
    .time_separator{
        margin-top: 11px;
        color: #EFEFE4;
        text-align: center;
        font-family: Urbanist;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%; /* 30px */
        opacity: 0.5;
        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
            margin-top: 7px;
        }
    }
`
const TimeBoxWrapper = styled.div`
    display: grid;
    gap: 12px;
    justify-items: center;
    min-width: 68px;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        min-width: 40px;
    }
`
const TimeBox = styled.div`
    width: 100%;
    max-width: 148px;
    display: grid;
    justify-content: center;
    align-items: center;
    justify-items: center;
    padding: 10px 15px;
    background: rgba(239, 239, 228, 0.15);
    font-weight: 700;
    font-size: 32px;
    font-family: Righteous;
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        padding: 10px 20px;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        span{
            font-size: 16px;
        }
    }
`
const LinearText = styled.span`
    background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const index = () => {
  const navigate = useNavigate()
  const { account, chainId, provider } = useWeb3React()
  const toggleWalletModal = useToggleWalletModal()
  const isSupported = chainId === SupportedChainId.BLAST

  let refCode = useParams()
  // verify ref code
  function isValidAddress(address: any) {
    const regex = /^0x[a-fA-F0-9]{40}$/
    return regex.test(address)
  }
  // state
  const [currentPhase, setCurrentPhase] = useState<'BUY' | 'CLAIM'>('BUY')
  const [reflink, setReflink] = useState('')
  const [isClicked, setIsClicked] = useState(false)
  const [, setCopied] = useCopyClipboard()

  const [loadingBalance, setLoadingBalance] = useState(false)
  const [userETHBalance, setUserETHBalance] = useState('')
  const [userETHMaxBalance, setUserETHMaxBalance] = useState('')
  const [ethValidateMess, setethValidateMess] = useState('')
  const [depositeETHAmount, setDepositeETHAmount] = useState('')
  const [loadingDeposit, setLoadingDeposit] = useState(false)
  const [loadingClaim, setLoadingClaim] = useState(false)
  const [userRefCode, setUserRefCode] = useState('')
  const [usingRefCode, setUsingRefCode] = useState<string | undefined>()
  const [loadingClaimRefReward, setClaimRefReward] = useState(false)
  const [claimTokenAmount, setClaimTokenAmount] = useState('')
  const [ethPrice, setEthPrice] = useState<number>(0)
  const [maxProjectTokenToDistribute, setMaxProjectTokenToDistribute] = useState<number>(150000)
  const [minHardCap, setMinHardCap] = useState<number>(90)
  const totalSupplyMono = 1000000

  const [contractStatus, setContractStatus] = useState({
    status: '',
    coundownTime: 0,
    isCountDown: true,
  })
  const [endTime, setEndTime] = useState<number>(contractStatus.coundownTime)
  const { days, hours, minutes, seconds } = useCountdownTimer('DAY', endTime)
  const hasStarted = useMemo(() => Date.now() / 1000 > 1719234000, [])

  // contract info
  const [loadingContractInfo, setLoadingContractInfo] = useState(false)

  const [isClaimable, setIsClaimable] = useState(false)
  const [totalAllocation, setTotalAllocation] = useState('0')
  const [isRefClaimAble, setRefisClaimAble] = useState(false)
  const [disabledClaimButton, setDisabledClaimButton] = useState(true)


  const [userInfo, setUserInfo] = useState({
    allocation: '0.00',
    claimedRefEarnings: '0.00',
    contribution: '0.00',
    discount: '0.00',
    discountEligibleAmount: '0.00',
    hasClaimed: false,
    refEarnings: '0.00',
    refCount: '0',
  })

  // add data chart
  const [idoStat, setIdoStat] = useState<any>([])
  const [chartLatestUpdate, setChartLatestUpdate] = useState<any>({
    price: '',
    createdAt: '',
    timestamp: '',
    updatedAt: '',
  })

  useEffect(() => { setEndTime(contractStatus.coundownTime) }, [contractStatus])

  const copy = useCallback(() => {
    setCopied(userRefCode ?? '')
    setIsClicked(true)
    setTimeout(() => setIsClicked(false), 5000)
  }, [userRefCode, setCopied])

  const validateInput = (value: any, useBalance: any) => {
    const num = parseFloat(value)
    if (isNaN(num) || num < 0.001) {
      return 'minimum'
    }
    if (num > parseFloat(useBalance)) {
      return 'error'
    }
    return 'valid'
  }
  // contract
  const MONO_LAUNCHPAD_ADDRESS = MONO_LAUNCHPAD[SupportedChainId.BLAST]
  const monoLaunchpadContract = useCustomeContract(MONO_LAUNCHPAD_ADDRESS, mono_launchpad_abi, true)
  const monoLaunchpadContractWithNoSign = getContract(MONO_LAUNCHPAD_ADDRESS, mono_launchpad_abi, new ethers.providers.JsonRpcProvider({
    url: RPC_URLS[SupportedChainId.BLAST][0],
    throttleSlotInterval: 60000
  }))

  const getUserBalance = async () => {
    setLoadingBalance(true)
    try {
      if (account && provider) {
        const balanceETH = await provider.getBalance(account)
        // usdb
        const formmattedUsdbUserBalance = Number(ethers.utils.formatUnits(balanceETH, 18)).toFixed(3).toString()
        const maxETHToken = (Number(formmattedUsdbUserBalance) - Number(formmattedUsdbUserBalance) * 0.0001)
          .toFixed(3)
          .toString()
        setUserETHBalance(formmattedUsdbUserBalance)
        setUserETHMaxBalance(maxETHToken)
        // user contract balance
        const getUserInfo = await monoLaunchpadContract?.userInfo(account)

        setUserInfo({
          allocation: Number(ethers.utils.formatUnits(getUserInfo?.allocation, 18)).toFixed(3).toString(),
          claimedRefEarnings: Number(ethers.utils.formatUnits(getUserInfo?.claimedRefEarnings, 18))
            .toFixed(3)
            .toString(),
          contribution: Number(ethers.utils.formatUnits(getUserInfo?.contribution, 18)).toFixed(3).toString(),
          discount: Number(ethers.utils.formatUnits(getUserInfo?.discount, 18)).toFixed(3).toString(),
          discountEligibleAmount: Number(ethers.utils.formatUnits(getUserInfo?.discountEligibleAmount, 18))
            .toFixed(2)
            .toString(),
          hasClaimed: getUserInfo?.hasClaimed,
          refEarnings: Number(ethers.utils.formatUnits(getUserInfo?.refEarnings, 18)).toFixed(3).toString(),
          refCount: getUserInfo?.refCount.toString(),
        })
        // claim token amount
        const claimAmount = await monoLaunchpadContract?.getExpectedClaimAmount(account)
        const formattedTotalClaimAmount = Number(ethers.utils.formatUnits(claimAmount, 18)).toFixed(3).toString()
        setClaimTokenAmount(formattedTotalClaimAmount)
        // check is claimable ref
        const isClaimableRefReward =
          Number(ethers.utils.formatUnits(getUserInfo?.refEarnings, 18)) -
            Number(ethers.utils.formatUnits(getUserInfo?.claimedRefEarnings, 18)) >
            0
            ? true
            : false

        setRefisClaimAble(true)
        setLoadingBalance(false)
      }
    } catch (error) {
      console.log('error', error)
      setLoadingBalance(false)
    }
  }

  const isOverMinHardCap = useMemo(() => {
    return Number(totalAllocation) > minHardCap
  }, [totalAllocation, minHardCap])

  const monoPrice = useMemo(() => {
    let monoPriceRs = 0
    if (isOverMinHardCap) {
      const overCapPercent = (Number(totalAllocation) - minHardCap) / minHardCap
      monoPriceRs = (90 + (90 * overCapPercent)) / maxProjectTokenToDistribute
    }
    else monoPriceRs = 90 / maxProjectTokenToDistribute
    if (monoPriceRs == Number.NEGATIVE_INFINITY || monoPriceRs == Number.POSITIVE_INFINITY || isNaN(monoPriceRs)) return 0.0006
    return monoPriceRs
  }, [isOverMinHardCap, maxProjectTokenToDistribute])

  // get contract balance
  const getContractInfo = async () => {
    setLoadingContractInfo(true)
    try {
      // contract state
      const dateNow = Math.floor(Date.now() / 1000)
      const getStartTime = 1719234000
      const getEndTime = 1719579600
      const getClaimTime = 1719752400
      // const getMaxProjectTokenToDistribute = await monoLaunchpadContractWithNoSign?.MAX_PROJECT_TOKENS_TO_DISTRIBUTE()
      // const getMinHardCap = await monoLaunchpadContractWithNoSign?.MIN_TOTAL_RAISED_FOR_MAX_PROJECT_TOKEN()
      // const getHasStarted = await monoLaunchpadContractWithNoSign?.hasStarted()
      // const getHasEndded = await monoLaunchpadContractWithNoSign?.hasEnded()

      // setMaxProjectTokenToDistribute(Number(ethers.utils.formatUnits(getMaxProjectTokenToDistribute)))
      // setMinHardCap(Number(ethers.utils.formatUnits(getMinHardCap)))

      const waitingTime = dayjs.utc('2024-06-30 13:00:00').valueOf() / 1000
      console.log('waitingTime', waitingTime);

      // if (Number(dateNow) < Number(getStartTime)) {
      //   setContractStatus({
      //     status: 'Starting in',
      //     coundownTime: getStartTime,
      //     isCountDown: true,
      //   })
      //   setIsClaimable(false)
      //   setDisabledClaimButton(true)
      // } else if (Number(getStartTime) < Number(dateNow) && Number(dateNow) < Number(getEndTime)) {
      //   setContractStatus({
      //     status: 'Ending in',
      //     coundownTime: getEndTime,
      //     isCountDown: true,
      //   })
      //   setIsClaimable(false)
      //   setDisabledClaimButton(true)
      // } else
      if (Number(dateNow) < waitingTime) {
        setContractStatus({
          status: 'Claiming in',
          coundownTime: waitingTime,
          isCountDown: true,
        })
        setIsClaimable(true)
        setDisabledClaimButton(true)
      } else {
        setContractStatus({
          status: '',
          coundownTime: dateNow,
          isCountDown: false,
        })
        setDisabledClaimButton(false)
        setIsClaimable(true)
      }

      // contract info
      const totalAllocations = await monoLaunchpadContractWithNoSign?.totalAllocation()
      const formattedTotalAllocation = Number(ethers.utils.formatUnits(totalAllocations, 18)).toFixed(3).toString()
      setTotalAllocation(formattedTotalAllocation)

      // set chart latest update
      setLoadingContractInfo(false)
    } catch (error) {
      console.log('error', error)
      setLoadingContractInfo(false)
    }
  }

  // function contract status
  const handleClaim = async () => {
    checkUnsupportChainId(chainId)
    setLoadingClaim(true)
    try {
      if (account) {
        const tx = await monoLaunchpadContract?.claim()
        await tx.wait()
        notification.success({
          message: 'Claim successfull'
        })
        setLoadingClaim(false)
        getUserBalance()
        getContractInfo()
      }
    } catch (error) {
      setLoadingClaim(false)
      console.log('error', error)
      notification.error({
        message: 'Claim failed'
      })
    }
  }
  const deposit = async () => {
    setLoadingDeposit(true)
    const formattedDepositAmount = ethers.utils.parseUnits(depositeETHAmount, 'ether').toString()
    try {
      if (account) {
        const gasEstimate = await monoLaunchpadContract?.estimateGas.buyETH(usingRefCode, {
          value: formattedDepositAmount
        })
        const gasLimitCalc = Math.floor(gasEstimate ? gasEstimate.toNumber() * 1.5 : 200000)

        const tx = await monoLaunchpadContract?.buyETH(usingRefCode, {
          value: formattedDepositAmount,
          gasLimit: gasLimitCalc
        })
        await tx.wait()

        notification.success({
          message: 'Buy successful'
        })

        const now = new Date()
        const createdAt = now.toISOString()
        const updatedAt = now.toISOString()
        const timestamp = now.getTime()
        // contract info
        const totalAllocations = await monoLaunchpadContract?.totalAllocation()
        const formattedTotalAllocation = Number(ethers.utils.formatUnits(totalAllocations, 18)).toFixed(3).toString()
        setTotalAllocation(formattedTotalAllocation)

        setLoadingDeposit(false)
        getUserBalance()
        getContractInfo()
        getLatestDocuments()

      }
    } catch (err) {
      setLoadingDeposit(false)
      console.log('buy error', err)
      if (err?.code == 'UNPREDICTABLE_GAS_LIMIT' || (err?.data?.message && String(err?.data?.message).indexOf('insufficient funds')))
        notification.error({
          message: 'Insufficient funds for gas'
        })
      else
        notification.error({
          message: 'Buy failed'
        })
    }
  }
  const claimRefReward = async () => {
    checkUnsupportChainId(chainId)
    setClaimRefReward(true)
    try {
      if (account) {
        const tx = await monoLaunchpadContract?.claimRefEarnings()
        await tx.wait()
        notification.success({
          message: 'Claim successful'
        })
        setClaimRefReward(false)
        getUserBalance()
        getContractInfo()
      }
    } catch (error) {
      setClaimRefReward(false)
      console.log('error', error)
      notification.error({
        message: 'Claim failed'
      })
    }
  }
  const handleDeposite = () => {
    checkUnsupportChainId(chainId)
    const validate = validateInput(depositeETHAmount, userETHMaxBalance)
    if (validate === 'minimum') {
      setethValidateMess('Minimum amount 0.001 ETH.')
      return
    }
    if (validate !== 'valid') {
      setethValidateMess(`Input must less than ${userETHMaxBalance} ETH.`)
      return
    }
    setethValidateMess('')
    deposit()
  }

  function createReferralUrl(accountAddress: any) {
    let baseUrl = window.location.origin
    const referralUrl = `${baseUrl}/#/launchpad/${accountAddress}`
    setUserRefCode(referralUrl)
    return referralUrl
  }

  const fetchTokenPrice = async () => {
    try {
      const res = await axios.get(`https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT`)
      if (res) {
        const price = Number(Number(res.data.price).toFixed(2))
        setEthPrice(price)
      }
    } catch (error) {
      console.log('Error:', error)
    }
  }


  useEffect(() => {
    if (account) {
      createReferralUrl(account)
      let isRefcode = isValidAddress(refCode.id)
      let addressZero = ethers.constants.AddressZero

      if (isRefcode) {
        setUsingRefCode(refCode.id?.toLowerCase())
      } else setUsingRefCode(addressZero)
    }
  }, [account, chainId])

  useEffect(() => {
    if (account) {
      getUserBalance()
    }
  }, [account, chainId])

  // get data from firebase
  const getLatestDocuments = () => {
    try {
      axios
        .get(`https://api.monoswap.io/monoswap/api/v1/mono-launchpad/get-stats`)
        .then(({ data }: { data: any }) => {
          setIdoStat(data)
        })
      return null
    } catch (error) {
      console.error('Error getting documents: ', error)
      return []
    }
  }

  useEffect(() => {
    getContractInfo()
    fetchTokenPrice()
    getLatestDocuments()
  }, [])

  return (
    <div className="launchpad-container-wrapper">
      <img className='launchpad-container-img' src='/launchpad/bg.png' alt='monoswap' />
      <div className="launchpad-container">
        <ul className='launchpad-breadcrum'>
          <li>
            Launchpads
          </li>

          <li>
            {/* <RightOutlined rev={undefined} /> */}
            {'>'}
          </li>

          <li className='active'>
            MonoSwap
          </li>
        </ul>
        <div className="launchpad-header">
          <p className="launchpad-header-title">Mono Auction</p>
          <p className="launchpad-header-des">
            MonoSwap provides builders and users with the unparalleled ability to harness our bespoke infrastructure,
            fostering deep, sustainable, and adaptable liquidity. With MONO on Blast, we refine what real yield is.
          </p>
        </div>

        <div className="launchpad-info-container">
          <div className="launchpad-info">
            <div className="launchpad-info-child">
              <p>Total Raised</p>
              <p>
                {
                  totalAllocation == '0' ? <CustomLightSpinner src={Circle} alt="loader" size="16px" /> : formatXpNumber(totalAllocation, 4)
                }
                {' ETH'}
              </p>
            </div>
            <div className="launchpad-info-child launchpad-midder">
              <p>MONO Price</p>
              <p>{ethPrice == 0 || totalAllocation == '' ? '-' : Number(monoPrice).toFixed(isOverMinHardCap ? 5 : 4)} ETH</p>
            </div>
            <div className="launchpad-info-child launchpad-midder ">
              <p>Circ. Marketcap</p>
              <p>${formatXpNumber((monoPrice * ethPrice) * 287000)}</p>

            </div>
            <div className="launchpad-info-child launchpad-midder">
              <p>FDV</p>
              <p>${formatXpNumber(monoPrice * totalSupplyMono * ethPrice)}</p>
            </div>
          </div>
        </div>
        <div className={`launchpad-buy-title ${isClaimable ? 'claiming' : 'buying'}`}>
          <div className={`launchpad-buy-title-container`}>
            {isClaimable ? (
              <p className="launchpad-buy-title-title">Claiming Phase</p>
            ) : (
              <p className="launchpad-buy-title-title">Buying Phase</p>
            )}

            {contractStatus.isCountDown === true ? (
              <div className="launchpad-counter">
                <RemainTimeBoxWrapper>
                  <Text color="#FFB800">{contractStatus.status}</Text>

                  <RemainTimeBox>
                    <TimeBoxWrapper>
                      <TimeBox>
                        <LinearText>{days < 0 ? '-' : days}</LinearText>
                      </TimeBox>
                      <Text className="time_suffix">day{days > 1 && ''}</Text>
                    </TimeBoxWrapper>
                    <Text className="time_separator">:</Text>
                    <TimeBoxWrapper>
                      <TimeBox>
                        <LinearText>{hours < 0 ? '-' : hours}</LinearText>
                      </TimeBox>
                      <Text className="time_suffix">hour{hours > 1 && 's'}</Text>
                    </TimeBoxWrapper>
                    <Text className="time_separator">:</Text>
                    <TimeBoxWrapper>
                      <TimeBox>
                        <LinearText>{minutes < 0 ? '-' : minutes}</LinearText>
                      </TimeBox>
                      <Text className="time_suffix">minute{minutes > 1 && 's'}</Text>
                    </TimeBoxWrapper>
                    <Text className="time_separator">:</Text>
                    <TimeBoxWrapper>
                      <TimeBox>
                        <LinearText>{seconds < 0 ? '-' : seconds}</LinearText>
                      </TimeBox>
                      <Text className="time_suffix">second{seconds > 1 && 's'}</Text>
                    </TimeBoxWrapper>

                  </RemainTimeBox>
                </RemainTimeBoxWrapper>

              </div>
            ) : (
              <>
                <div className="launchpad-counter-claimTime">
                  <img src={ClaimableIcon} alt="" />
                  <p className="launchpad-counter-top-title-claimTime">
                    Public sale has ended - Contributions are now claimable
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="launchpad-buy-container">
          <div className="launchpad-buy-container-left">
            {!isClaimable ? (
              <>
                <div className="launchpad-buy-input">
                  <div className="launchpad-buy-input-icons">
                    <img
                      src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/swap/eth.png"
                      alt=""
                      width={'32px'}
                    />
                    <p>ETH</p>
                  </div>
                  <input
                    placeholder="0"
                    min={0.00001}
                    type="number"
                    value={depositeETHAmount}
                    onChange={(e) => setDepositeETHAmount(e.target.value)}
                  />
                </div>

                {
                  account && <div className="launchpad-buy-input-max">
                    <div className="launchpad-max-bg-container">
                      <div style={{ color: '#ff1e56' }}>{ethValidateMess && ethValidateMess}</div>
                      <div
                        className="launchpad-max-bg"
                        onClick={() => {
                          setDepositeETHAmount(userETHMaxBalance)
                        }}
                      >
                        <p>MAX</p>
                      </div>
                      <p>
                        <span> Balance:</span>{' '}
                        <span style={{ color: '#EFEFE4' }}>
                          {loadingBalance ? (
                            <CustomLightSpinner src={Circle} alt="loader" size="12px" />
                          ) : Number(userETHBalance) < 1000 ? (
                            userETHBalance
                          ) : (
                            numberWithCommas(userETHBalance)
                          )}{' '}
                        </span>
                        ETH
                      </p>
                    </div>
                  </div>
                }
              </>
            ) : (
              <>
                <div className="launchpad-buy-input">
                  <div className="launchpad-buy-input-icons">
                    <img
                      src="https://raw.githubusercontent.com/Monoswap-Labs/monoswap-token-list/main/img/blast_sepolia/assets/0xa07aC8cDe2a98B189477b8e41F0c2Ea6CdDbC055/logo.png"
                      alt=""
                      width={'32px'}
                    />
                    <p>MONO</p>
                  </div>
                  <input
                    placeholder={claimTokenAmount ? ((Number(claimTokenAmount) * 2) / 3).toFixed(3) : '0.000'}
                    type="number"
                    disabled
                  />
                </div>
                <div className="launchpad-buy-input" style={{ marginTop: '10px' }}>
                  <div className="launchpad-buy-input-icons">
                    <img src={xMono} alt="" width={'32px'} />
                    <p>xMONO</p>
                  </div>
                  <input
                    placeholder={claimTokenAmount ? ((Number(claimTokenAmount) * 1) / 3).toFixed(3) : '0.000'}
                    type="number"
                    disabled
                  />
                </div>
              </>
            )}

            <div className={`launchpad-buy-info ${isClaimable ? 'claiming' : ''}`}>
              <div className="launchpad-buy-info-child">
                <p>Spent</p>
                {
                  account ? <p>
                    {' '}
                    {loadingBalance ? (
                      <CustomLightSpinner src={Circle} alt="loader" size="12px" />
                    ) : Number(userInfo?.contribution) < 1000 ? (
                      userInfo?.contribution
                    ) : (
                      numberWithCommas(userInfo?.contribution)
                    )}{' '}
                    ETH
                  </p>
                    : <p>-</p>
                }
              </div>
              <div className="launchpad-buy-info-child">
                <p>Est. allocation</p>
                {
                  account ?
                    <p>
                      {loadingBalance ? (
                        <CustomLightSpinner src={Circle} alt="loader" size="12px" />
                      ) : Number(claimTokenAmount) < 1000 ? (
                        claimTokenAmount
                      ) : (
                        numberWithCommas(claimTokenAmount)
                      )}{' '}
                      MONO
                    </p>
                    : <p>-</p>
                }
              </div>
              <div className="launchpad-buy-info-child">
                <p>Your referral earnings</p>
                {
                  account ?
                    <p>
                      {' '}
                      {loadingBalance ? (
                        <CustomLightSpinner src={Circle} alt="loader" size="12px" />
                      ) : Number(userInfo?.refEarnings) < 1000 ? (
                        userInfo?.refEarnings
                      ) : (
                        numberWithCommas(userInfo?.refEarnings)
                      )}{' '}
                      ETH
                    </p>
                    : <p>-</p>
                }
              </div>
              <div className="launchpad-buy-info-child">
                <p>Your claimed referral earnings </p>
                {
                  account ? <p>
                    {' '}
                    {loadingBalance ? (
                      <CustomLightSpinner src={Circle} alt="loader" size="12px" />
                    ) : Number(userInfo?.claimedRefEarnings) < 1000 ? (
                      userInfo?.claimedRefEarnings
                    ) : (
                      numberWithCommas(userInfo?.claimedRefEarnings)
                    )}{' '}
                    ETH
                  </p>
                    : <p>-</p>
                }
              </div>
            </div>
            <div style={{ width: '100%' }} className="launchpad-button">
              {
                account ? <>
                  {isClaimable ? (
                    <NotchedButtonFill
                      onClick={handleClaim}
                      width={'100%'}
                      disabled={disabledClaimButton || userInfo.hasClaimed}
                    >
                      {loadingClaim ? 'CLAIMMING...' : 'CLAIM'}
                    </NotchedButtonFill>
                  ) : (
                    <NotchedButtonFill onClick={handleDeposite} width={'100%'} disabled={!hasStarted}>
                      {loadingDeposit ? 'BUYING...' : 'BUY'}
                    </NotchedButtonFill>
                  )}
                </>
                  : <NotchedButtonFill onClick={toggleWalletModal} width={'100%'}>
                    CONNECT
                  </NotchedButtonFill>
              }

            </div>
            {
              account && <div className="laucnpad-claim-reward">
                {isRefClaimAble ? (
                  <NotchedButtonBolderNew w="100%" h="45px" borderColor={'rgba(239, 239, 228, 0.5)'} bg='#0a090f'>
                    <div className="claim-button-launchpad" onClick={claimRefReward}>
                      <>{loadingClaimRefReward ? 'LOADING...' : 'CLAIM REFERRAL EARNINGS'}</>
                    </div>
                  </NotchedButtonBolderNew>
                ) : (
                  <NotchedButtonBolderNew w="100%" h="45px" borderColor={'rgba(239, 239, 228, 0.5)'}>
                    <div className="claim-button-launchpad-disabled">
                      <> CLAIM REFERRAL EARNING</>
                    </div>
                  </NotchedButtonBolderNew>
                )}
              </div>
            }

            {account ? (
              <div className="launchpad-referral">
                <p className="launchpad-referral-title">Referral Link</p>
                <div className="launchpad-referral-link">
                  <span>{userRefCode}</span>
                  <div className="launchpad-copy">
                    {
                      isClicked && <span className='launchpad-copy-text'>Copied</span>
                    }
                    <Copy onClick={copy} className={isClicked ? 'icon-clicked' : ''} />
                  </div>
                </div>
                <div className="launchpad-invited">
                  <p className="launchpad-referral-title">Invited</p>
                  <p>
                    {' '}
                    {loadingBalance ? (
                      <CustomLightSpinner src={Circle} alt="loader" size="12px" />
                    ) : Number(userInfo?.refCount) < 1000 ? (
                      userInfo?.refCount
                    ) : (
                      numberWithCommas(userInfo?.refCount)
                    )}{' '}
                  </p>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="launchpad-buy-container-right">
            <div className="launchpad-buy-container-right-title">
              <div>
                <img
                  src="https://raw.githubusercontent.com/Monoswap-Labs/monoswap-token-list/main/img/blast_sepolia/assets/0xa07aC8cDe2a98B189477b8e41F0c2Ea6CdDbC055/logo.png"
                  alt=""
                  width={'32px'}
                />
              </div>
              <p>
                MONO Sale Price <span>({ethPrice == 0 || totalAllocation == '' ? '-' : Number(monoPrice).toFixed(isOverMinHardCap ? 5 : 4)} ETH) </span>
              </p>
            </div>
            <div className="launchpad-buy-container-right-chart">
              <IdoPriceChart
                idoStats={idoStat}
                currentPrice={monoPrice}
              />
            </div>
          </div>
        </div>

        <div className="launchpad-buy-title roadmap">
          <div className="launchpad-buy-title-container">
            <p className="launchpad-buy-title-title">ROADMAP</p>
          </div>
          <div className='roadmap_desktop'>
            <img width={'100%'} src='/launchpad/step-desktop.png' alt="monoswap" />
          </div>
          <div className='roadmap_mobile'>
            <IdoRoadMap />
          </div>
        </div>

        <div className="launchpad-buy-title guideline">
          <div className="launchpad-buy-title-container">
            <p className="launchpad-buy-title-title">Guidelines</p>
          </div>
        </div>
        <div className="launchpad-guidelines">
          <div className="guildelines-card">
            <div className="guildelines-card-title">
              <div>
                <img src={icon1} alt="" width={'100%'} />
              </div>
              <div className="guildelines-card-body">
                <p>How price is determined</p>
                <p>
                  You can contribute with ETH in exchange for $MONO tokens, which are to be claimed 24-48h after the sale
                  ends.
                  <div className='separator' /> The tokens you will receive will have the exact same $ value to your contribution. <div className='separator' /> 150,000
                  $MONO (of a 1,000,000 total supply) will be allocated to the sale. The final price will therefore be:{' '}
                  <div className='separator' />{' '}
                  <span>
                    Total raised:  {
                      totalAllocation == '0' ? <CustomLightSpinner src={Circle} alt="loader" size="16px" /> : formatXpNumber(totalAllocation, 4)
                    }
                    {' ETH'} / 150,000
                  </span>
                </p>
              </div>
            </div>

          </div>
          <div className="guildelines-card">
            <div className="guildelines-card-title">
              <div>
                <img src={icon2} alt="" width={'100%'} />
              </div>

              <div className="guildelines-card-body">
                <p>Refer to earn</p>
                <p>
                  Every user has access to a referral link that he can publicly share, making him earn 3% (in ETH) of all
                  the referred investments made through it.  <div className='separator' /> This 3% commission will be directly sent to his wallet
                  as soon as the referred deposit is made.  <div className='separator' />
                  This will in no way impact the referred users contribution, who will received the exact same amount of
                  $MONO tokens at the end of the sale, whether they have used a referral link or not.
                </p>
              </div>
            </div>
          </div>
          <div className="guildelines-card">
            <div className="guildelines-card-title">
              <div>
                <img src={icon3} alt="" width={'100%'} />
              </div>
              <div className="guildelines-card-body">
                <p>Claiming process</p>
                <p>
                  The public sale will last until June 28th @ 13pm UTC.  <div className='separator' /> Starting June 30th @ 13pm UTC, the purchased
                  tokens will be entirely and directly claimable from this same page, at the exact same time as trading
                  starts.  <div className='separator' />
                  In order to align the long-term objectives of MonoSwap, 1/3 of the sale will be distributed in xMONO,
                  with 2/3 in MONO.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="launchpad-waring">
          <div className="launchpad-icons-warning">
            <img src={vector} alt="" />
          </div>
          <p>
            MonoSwap is a suite of decentralized contracts built to support Blast native builders. As a permissionless
            protocol, MonoSwap bears no responsibility for any tokens purchased using its contracts. All users are
            taking full responsibility that they are aware of the relevant risks involved, and that they are
            participating for a token that is completely independent and not associated with MonoSwap in any way. Social
            media posts and visible information on the MonoSwap app in no way counts as an endorsement of a protocol by
            the MonoSwap team, and nothing posted or shared in any MonoSwap media is a recommendation or financial
            advice.
          </p>
        </div>
      </div>
    </div>
  )
}

export default index

export const CountdownTimer = ({ target }: any) => {
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Math.floor(Date.now() / 1000)
      const distance = target - now

      setDays(
        parseInt(
          Math.floor(distance / 86400)
            .toString()
            .padStart(2, '0')
        )
      )
      setHours(
        parseInt(
          Math.floor((distance % 86400) / 3600)
            .toString()
            .padStart(2, '0')
        )
      )
      setMinutes(
        parseInt(
          Math.floor((distance % 3600) / 60)
            .toString()
            .padStart(2, '0')
        )
      )
      setSeconds(parseInt((distance % 60).toString().padStart(2, '0')))
    }, 1000)

    return () => clearInterval(interval)
  }, [target])

  return (
    <div className="count-down-box-launchpad">
      <p className="count-down-box-launchpad-show">{days.toString().padStart(2, '0')}d :&nbsp;</p>
      <p className="count-down-box-launchpad-show"> {hours.toString().padStart(2, '0')}h :&nbsp;</p>
      <p className="count-down-box-launchpad-show"> {minutes.toString().padStart(2, '0')}m :&nbsp;</p>
      <p className="count-down-box-launchpad-show"> {seconds.toString().padStart(2, '0')}s</p>
    </div>
  )
}
export const numberWithCommas = (number: any) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const PriceChart = ({ data }: any) => {
  return (
    <div style={{ width: '100%' }}>
      <ResponsiveContainer width="100%" height={500}>
        <AreaChart
          width={500}
          height={400}
          data={data}
          syncId="anyId"
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <XAxis dataKey="name" />

          <YAxis domain={[0, 2.5]} />
          <Tooltip contentStyle={{ backgroundColor: 'rgba(239,239,228,0.5)', border: 'none', outline: 'none' }} />
          <Area type="monotone" dataKey="price" stroke="#68ffa3" fill="#68ffa3" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}
