import styled from 'styled-components'
import { BREAKPOINTS } from 'theme'

const WrapperSection2 = styled.section`
  width: 1224px;
  margin: 0 auto;
  margin-top: 122px;
  @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
    width: 1100px;
  }

  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    width: 1000px;
  }

  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    width: 700px;
    margin-top: 50px;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 90%;
  }
`

const MainTitleWrapper = styled.div`
  display: flex;
  margin-top: 12px;
  gap: 40px;
  align-items: flex-start;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    justify-content: space-between;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    display: grid;
    justify-content: center;
    gap: 20px;
  }
`
const MainTitleLeft = styled.h3`
  display: grid;
  margin: 0;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    text-align: center;
  }
`

const MainTitleRight = styled.h4`
  color: #e5e5df;
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 33.6px;
  max-width: 444px;
  padding-top: 18px;
  margin: 0;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    max-width: 300px;
    padding-top: 0px;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    max-width: 100%;
    text-align: center;
    padding-bottom: 20px;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 25.2px;
    text-align: start;
  }
`

const H2 = styled.h2<{ bg?: string; textFillColor: string }>`
  color: #e5e5df;
  font-family: Righteous;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 70px;
  background: ${(props) => props.bg};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: ${(props) => props.textFillColor};
  margin: 0;
  color: #efefe4;

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    text-align: start;
  }
`

const TradeBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 70px 0;
  height: 368px;
  position: relative;
  padding-left: 45px;
  padding-right: 67px;
  margin-top: 57px;
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    padding: 0;
    height: 250px;
  }
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    display: grid;
    justify-content: center;
    margin-top: 15px;
    height: unset;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    height: 624px;
    padding: 0 15px;
  }
`
const TradeBoxBg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    display: none;
  }
`
const TradeBoxBgMobile = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  display: none;
  @media screen and (max-width: ${BREAKPOINTS.xs}px) {
    display: block;
  }
`
const TradeBoxBgCornerLeft = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.25;
  @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
    rotate: 357deg;
    left: -2px;
  }
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    display: none;
  }
`
const TradeBoxBgCornerLeftMobile = styled.img`
  position: absolute;
  top: 1px;
  left: 2.5px;
  opacity: 0.25;
  display: none;
  width: 42px;
  height: 42px;
  @media screen and (max-width: ${BREAKPOINTS.xs}px) {
    display: block;
  }
`

const TradeBoxBgCornerRight = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.25;
  @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
    rotate: 4deg;
    right: -2px;
  }
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    display: none;
  }
`

const TradeBoxTitleDiv = styled.div<{
  mobileMaxW?: string
}>`
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    height: fit-content;
    margin-top: 50px;
    max-width: ${({ mobileMaxW }) => mobileMaxW ?? '100%'};
  }
`

const TradeBoxTitle = styled.h3`
  color: #efefe4;
  font-family: Righteous;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 50px */
  margin: 0;
  margin-bottom: 16px;
  margin-top: 10px;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    text-align: center;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    text-align: start;
    margin-bottom: 0;
    font-size: 28px;
  }
`

const TradeBoxSubTitle = styled.h4`
  color: #e5e5df;
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.2px;
  margin: 0;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    text-align: center;
    margin-bottom: 20px;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    text-align: start;
    margin-bottom: 0;
  }
`

const TradeBoxDivRight = styled.div<{ mt: string }>`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: ${(props) => props.mt};
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 0;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    display: grid;
    gap: 0;
  }
`
const TradeTypeBox = styled.div<{ w: string; h: string; p: string; isMobile?: boolean }>`
  width: ${(props) => props.w};
  height: ${(props) => props.h};
  flex-shrink: 0;
  background-image: url('https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/trade-type-bg.png');
  background-size: cover;
  display: grid;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: ${(props) => props.p};

  @media screen and (max-width: 1664px) {
    width: calc(100% / 1.9);
    height: 270px;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    width: ${({ isMobile, w }) => (isMobile ? w : '315px')};
    height: ${({ isMobile, h }) => (isMobile ? h : '200px')};
    padding-top: 15px;
    position: relative;
  }
`

const TradeTypeBoxTitle = styled.h4`
  color: #efefe4;
  text-align: center;
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 30px */
  margin: 0;
  margin-top: 24px;
  margin-bottom: 8px;
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    margin-top: 0;
  }
`
const TradeTypeBoxSubTitle = styled.h5`
  color: #e5e5df;
  text-align: center;
  /* Urbanist/Body-3/Med */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px; /* 22.4px */
  margin: 0;
  max-width: 200px;
`

const LastBox = styled.div`
  display: flex;
  gap: 24px;
  height: 534px;
  margin-top: 20px;
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    gap: 45px;
  }
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    display: grid;
    justify-content: center;
    margin-top: 15px;
    height: unset;
    gap: 24px;
  }
`
const EarnBox = styled.div`
  display: grid;
  padding: 70px 45px;
  position: relative;
  width: 50%;
  max-width: 600px;
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    padding: 0;
  }
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    padding: 0 45px;
    width: 100%;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    padding: 0 18px;
    height: 624px;
  }
`

const LauchpadNftBoxRight = styled.div`
  display: grid;
  width: 600px;
  gap: 20px;
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    width: 50%;
  }
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    gap: 24px;
    width: 100%;
    max-width: 600px;
    padding: 0 20px;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    /* display: none; */
    padding: 0;
  }
`

const LauchpadNftBox = styled.div<{ mobileH?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 45px;
  padding-top: 60px;
  padding-bottom: 70px;
  position: relative;
  max-width: 600px;
  height: 255px;
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    background-image: url('https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/trade-type-bg.png');
    background-size: cover;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    display: flex;
    justify-content: space-between;
    height: ${({ mobileH }) => mobileH ?? '204px'};
    padding: 0 25px;
  }
`

const SectionNumber = styled.div`
  display: flex;
  @media screen and(max-width: ${BREAKPOINTS.md}px) {
    justify-content: center;
  }
`

export const MobileDisableBr = styled.span`
  display: inline-block;
  @media screen and(max-width: ${BREAKPOINTS.md}px) {
    display: none;
  }
`

export default function Section1() {
  return (
    <>
      <WrapperSection2>
        <SectionNumber>
          <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M25.7715 25.4189C25.7715 24.0186 25.5371 22.9785 25.0684 22.2988C24.6055 21.6191 23.958 21.2793 23.126 21.2793C22.2998 21.2793 21.6582 21.6162 21.2012 22.29C20.7441 22.9639 20.5156 24.0068 20.5156 25.4189C20.5156 26.8193 20.7441 27.8594 21.2012 28.5391C21.6582 29.2188 22.2998 29.5586 23.126 29.5586C23.542 29.5586 23.9141 29.4766 24.2422 29.3125C24.5703 29.1426 24.8457 28.8877 25.0684 28.5479C25.2969 28.208 25.4697 27.7803 25.5869 27.2646C25.71 26.7432 25.7715 26.1279 25.7715 25.4189ZM28.1709 25.4189C28.1709 26.6143 28.0391 27.625 27.7754 28.4512C27.5117 29.2773 27.1514 29.9453 26.6943 30.4551C26.2373 30.9648 25.7012 31.334 25.0859 31.5625C24.4766 31.7852 23.8232 31.8965 23.126 31.8965C22.4287 31.8965 21.7725 31.7822 21.1572 31.5537C20.5479 31.3193 20.0146 30.9473 19.5576 30.4375C19.1064 29.9219 18.749 29.2539 18.4854 28.4336C18.2217 27.6074 18.0898 26.6025 18.0898 25.4189C18.0898 24.2236 18.2217 23.2158 18.4854 22.3955C18.749 21.5693 19.1064 20.9014 19.5576 20.3916C20.0146 19.8818 20.5479 19.5127 21.1572 19.2842C21.7725 19.0557 22.4287 18.9414 23.126 18.9414C23.8232 18.9414 24.4766 19.0586 25.0859 19.293C25.7012 19.5215 26.2373 19.8936 26.6943 20.4092C27.1514 20.9248 27.5117 21.5928 27.7754 22.4131C28.0391 23.2334 28.1709 24.2354 28.1709 25.4189ZM33.3828 31.7207H30.8604V21.6396H29.6035V19.1172H33.3828V31.7207Z"
              fill="#EFEFE4"
            />
            <path d="M40.1451 50.2001H0.5V11.5755L10.8548 1.2207H50.5L50.5 39.8453L40.1451 50.2001Z" stroke="#EFEFE4" />
          </svg>
        </SectionNumber>

        <MainTitleWrapper>
          <MainTitleLeft>
            <H2
              bg="linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%),
            linear-gradient(0deg, #EFEFE4, #EFEFE4)"
              textFillColor="transparent"
            >
              Yield strategies
            </H2>
            <H2 textFillColor="unset">made diverse</H2>
          </MainTitleLeft>

          <MainTitleRight>
            Mono is a community-driven and yield-focused v3 Decentralized Exchange built to facilitate the Blast
            ecosystem.
          </MainTitleRight>
        </MainTitleWrapper>

        <TradeBox>
          <TradeBoxBg src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/tradebox-full.png" />
          <TradeBoxBgCornerLeft src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/angle.png" />
          <TradeBoxBgMobile src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/tradebox-mobile-bg.png" />
          <TradeBoxBgCornerLeftMobile src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/angle-corner-left-mobile.png" />
          <TradeBoxTitleDiv mobileMaxW="217px">
            <TradeBoxTitle>Trade</TradeBoxTitle>
            <TradeBoxSubTitle>
              Trade on a powerful{' '}
              <MobileDisableBr>
                {' '}
                <br />
              </MobileDisableBr>
              decentralized platform
            </TradeBoxSubTitle>
          </TradeBoxTitleDiv>

          <TradeBoxDivRight mt="0">
            <TradeTypeBox w="240px" h="240px" p="27px 0">
              <img
                style={{ width: '80px' }}
                src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/swap.png"
                alt="monoswap"
              />
              <TradeTypeBoxTitle>Swap</TradeTypeBoxTitle>
              <TradeTypeBoxSubTitle>Seamless swaps with optimized slippage</TradeTypeBoxSubTitle>
            </TradeTypeBox>

            <TradeTypeBox w="240px" h="240px" p="27px 0">
              <img
                style={{ width: '80px' }}
                src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/liquidity.png"
                alt="monoswap"
              />
              <TradeTypeBoxTitle>Liquidity</TradeTypeBoxTitle>
              <TradeTypeBoxSubTitle>
                Fuel the pools, earn trading <br />
                fees
              </TradeTypeBoxSubTitle>
            </TradeTypeBox>
          </TradeBoxDivRight>
        </TradeBox>

        <LastBox>
          <EarnBox>
            <TradeBoxBg src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/earn-bg.png" />
            <TradeBoxBgCornerLeft src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/angle.png" />

            <TradeBoxBgMobile src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/tradebox-mobile-bg.png" />
            <TradeBoxBgCornerLeftMobile src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/angle-corner-left-mobile.png" />
            <TradeBoxTitleDiv mobileMaxW="217px">
              <TradeBoxTitle>Earn</TradeBoxTitle>
              <TradeBoxSubTitle>
                Earn yields by becoming a <br />
                LP or joining the Farming Pools
              </TradeBoxSubTitle>
            </TradeBoxTitleDiv>

            <TradeBoxDivRight mt="40px">
              <TradeTypeBox w="250px" h="250px" p="37px 0">
                <img
                  style={{ width: '80px' }}
                  src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/alpha.png"
                  alt="monoswap"
                />
                <TradeTypeBoxTitle>Farming</TradeTypeBoxTitle>
                <TradeTypeBoxSubTitle>
                  Seed the Farming Pools to <br />
                  earn xMONO emissions
                </TradeTypeBoxSubTitle>
              </TradeTypeBox>

              <TradeTypeBox w="250px" h="250px" p="37px 0">
                <img
                  style={{ width: '80px' }}
                  src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/pool.png"
                  alt="monoswap"
                />
                <TradeTypeBoxTitle>Pool</TradeTypeBoxTitle>
                <TradeTypeBoxSubTitle>
                  Add liquidity to earn fees in <br />
                  MONO and xMONO
                </TradeTypeBoxSubTitle>
              </TradeTypeBox>
            </TradeBoxDivRight>
          </EarnBox>

          <LauchpadNftBoxRight>
            <LauchpadNftBox>
              <TradeBoxBg src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/lauchnft-bg.png" />
              <TradeBoxBgCornerRight src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/angle-corner-right.png" />

              <TradeBoxBgMobile src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/earnbox-mobile-bg.png" />
              <TradeBoxBgCornerLeftMobile src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/angle-corner-left-mobile.png" />
              <TradeBoxTitleDiv mobileMaxW="165px">
                <TradeBoxTitle>Launchpad</TradeBoxTitle>
                <TradeBoxSubTitle>
                  Kickstart the most promising{' '}
                  <MobileDisableBr>
                    {' '}
                    <br />
                  </MobileDisableBr>
                  protocols on Blast
                </TradeBoxSubTitle>
              </TradeBoxTitleDiv>
              <img
                style={{ width: '80px', height: '80px' }}
                src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/launch.png"
                alt="monoswap"
              />
            </LauchpadNftBox>

            <LauchpadNftBox mobileH="255px">
              <TradeBoxBg src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/lauchnft-bg.png" />
              <TradeBoxBgCornerRight src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/angle-corner-right.png" />
              <TradeBoxBgMobile src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/nft-mobile-bg.png" />
              <TradeBoxBgCornerLeftMobile src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/nft-mobile-angle-corner-left.png" />
              <TradeBoxTitleDiv mobileMaxW="165px">
                <TradeBoxTitle>NFT</TradeBoxTitle>
                <TradeBoxSubTitle>
                  Elevate your game with NFT{' '}
                  <MobileDisableBr>
                    {' '}
                    <br />
                  </MobileDisableBr>
                  as Liquidity – it's not just{' '}
                  <MobileDisableBr>
                    {' '}
                    <br />
                  </MobileDisableBr>{' '}
                  groundbreaking; it's a reality!
                </TradeBoxSubTitle>
              </TradeBoxTitleDiv>
              <img
                style={{ width: '80px', height: '80px' }}
                src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1/nft.png"
                alt="monoswap"
              />
            </LauchpadNftBox>
          </LauchpadNftBoxRight>
        </LastBox>
      </WrapperSection2>
    </>
  )
}
