import "../../Xp/sections/leaderboard.css"

import { useWeb3React } from "@web3-react/core"
import { Pagination } from "antd";
import axios from "axios"
import { SupportedChainId } from "constants/chains";
import dayjs from "dayjs";
import { BASE_API } from "pages/Xp";
import { PrevNextRender } from "pages/Xp/sections/ClaimedXpHistory";
import { TheadLabel } from "pages/Xp/sections/table"
import { formatXpNumber } from "pages/Xp/sections/XpTitle";
import { useEffect, useState } from "react";
import styled from "styled-components"
import { BREAKPOINTS, ExternalLink } from "theme";
import { ExplorerDataType, getExplorerLink } from "utils/getExplorerLink";

import { TextLinear } from "../MonoPotPage";

const outlink = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/outlink.svg'


const MonoPotHistoryTableWrapper = styled.div`
    max-width: 1224px;
    width: 100%;
    height: 100%;
    margin: auto;
    margin-top: 40px;
    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
        max-width: 95%;
    }
`

const TabBox = styled.div`
    display: flex;
    gap: 40px;
    margin-bottom: 32px;;
`
const TabItem = styled.span<{
    hasActived?: boolean
}>`
    color: ${({ hasActived }) => hasActived ? '#EFEFE4' : 'rgba(239, 239, 228, 0.50)'};
    font-family: Righteous;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 35px */
    cursor: pointer;
    &:hover{
        color: #EFEFE4;
    }
`

const Text = styled.span`
    color: #FFF;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 20px */
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        font-size: 12px;
    }
`;

const TheadBox = styled.div`
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    padding-left: 40px;
    background: #80808029;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        grid-template-columns: 60px 25% 25% 25%;
        padding-left: 10px;
    }
`
const TbodyBox = styled.div`
    display: grid;
`
const TbodyColBox = styled(TheadBox)`
    background: rgba(239, 239, 228, 0.03);
    padding: 20px 0;
    padding-left: 40px;
    border-bottom: 1px solid #efefe433;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        padding: 15px 0;
        padding-left: 10px;
    }
`

type PotTicketLogType = 'BUY' | 'BUY-TICKET' | 'CLAIM'
type MonoPotHistoryType = {
    id: number;
    potId: number;
    type: PotTicketLogType;
    hash: string;
    ticketSize: number;
    winPrize: number;
    createdAt: string;
}
export default function MonoPotHistoryTable() {
    const { account } = useWeb3React()

    const [currentTab, setCurrentTab] = useState<PotTicketLogType>('CLAIM')
    const [totalElement, setTotalElement] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [historyList, setHistoryList] = useState<MonoPotHistoryType[]>([]);

    const getTypeName = (type: PotTicketLogType) => {
        if (type == 'BUY' || type == 'BUY-TICKET')
            return 'Buy the tickets'
        else return 'Win the prize'
    }
    useEffect(() => onCallApiHistory(), [currentPage, currentTab])

    const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false)
    const onCallApiHistory = () => {
        if (isLoadingTable) return
        setIsLoadingTable(true)
        const reqTypes: PotTicketLogType[] = ['BUY', 'BUY-TICKET']

        axios.get(`${BASE_API}/monoswap/api/v1/monopot/get-history?address=${account}&types=${reqTypes.join(',')}&page=${currentPage - 1}&per_page=${5}`)
            .then(({ data }) => {
                setHistoryList(data.data)
                setTotalElement(data.totalElement)
            })
            .finally(() => setIsLoadingTable(false))
    }

    useEffect(() => {
        if (account)
            setCurrentTab('BUY')
    }, [account])

    return account ? <MonoPotHistoryTableWrapper className="my-claimed-xp">
        <TabBox>
            <TabItem hasActived={true}>Your history</TabItem>
        </TabBox>

        <div className="" style={{ fontFamily: 'Urbanist', }}>
            <TheadBox style={{ opacity: 0.7 }}>
                <TheadLabel>
                    ROUND
                </TheadLabel>

                <TheadLabel>
                    ACTIVITY
                </TheadLabel>

                <TheadLabel>
                    AMOUNT
                </TheadLabel>

                <TheadLabel>
                    DATE
                </TheadLabel>
            </TheadBox>

            <TbodyBox>
                {historyList.map((item, index: number) => (
                    <TbodyColBox key={index}>
                        <Text>
                            #{item?.potId}
                        </Text>
                        <Text>
                            {
                                item.type == 'CLAIM' ?
                                    <TextLinear>
                                        {getTypeName(item.type)}
                                    </TextLinear>
                                    : getTypeName(item.type)
                            }
                        </Text>
                        <Text>
                            {
                                item.type == 'CLAIM' ?
                                    <TextLinear>
                                        {formatXpNumber(item.winPrize)} MUSD
                                    </TextLinear>
                                    :
                                    item.type == 'BUY-TICKET' ?
                                        <>
                                            {item.ticketSize} TICKETS
                                        </>
                                        :
                                        <>
                                            {item.ticketSize * 2} USD
                                        </>
                            }
                        </Text>
                        <Text style={{ display: 'flex', gap: '8px' }}>
                            <ExternalLink
                                href={getExplorerLink(SupportedChainId.BLAST,
                                    item.hash,
                                    ExplorerDataType.TRANSACTION
                                )}
                                target="_blank"
                            >
                                <img src={outlink} alt="img" />
                            </ExternalLink> {dayjs(item.createdAt).format('DD/MM/YYYY HH:mm')}
                        </Text>
                    </TbodyColBox>
                ))}
            </TbodyBox>

        </div>
        {
            historyList.length > 0 &&
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Pagination
                    current={currentPage}
                    total={totalElement}
                    pageSize={10}
                    itemRender={PrevNextRender}
                    showSizeChanger={false}
                    onChange={(page) => setCurrentPage(page)}
                />
            </div>
        }
    </MonoPotHistoryTableWrapper> : <></>
}