import { useEffect, useState } from "react"


export default function useEthPrice() {
    const [ethPrice, setEthPrice] = useState<number>(0)
    const loadEthPrice = async () => {
        try {
            const res = await fetch(`https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT`)
            const data = await res.json()
            if (res) {
                const price = Number(data.price).toFixed(2)
                setEthPrice(Number(price))
            }
        } catch (error) {
            console.log('Error:', error)
        }
    }
    useEffect(() => { loadEthPrice() }, [])
    return {
        ethPrice
    }
}