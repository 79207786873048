import styled from 'styled-components/macro'

export const ToggleWrapper = styled.button<{ width?: string }>`
  display: flex;
  align-items: center;
  background: transparent;
  cursor: pointer;
  width: 100%;
  outline: none;
  border: none;
`

export const ToggleElement = styled.span<{ isActive?: boolean; fontSize?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 0.5rem;
  justify-content: center;
  height: 100%;
  background: ${({ theme, isActive }) => (isActive ? 'linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%)' : 'none')};
  color: ${({ theme, isActive }) => (isActive ? '#000' : '#efefe4')};
  font-size: ${({ fontSize }) => fontSize ?? '1rem'};
  font-weight: 500;
  white-space: nowrap;
  :hover {
    user-select: initial;
    color: ${({ theme, isActive }) => (isActive ? '#000' : '#efefe4')};
  }
`
