import { Connector } from '@web3-react/types'
import { bitgetConnection, ConnectionType } from 'connection'
import { getConnectionName } from 'connection/utils'

import Option from './Option'
import { useEffect, useState } from 'react'

const BASE_PROPS = {
  color: 'red',
  icon: 'https://raw.githubusercontent.com/bitkeepwallet/download/main/logo/svg/bitget%20wallet_logo_512.svg',
  id: 'BITGET_WALLET',
}

export function isiOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
}

// Function to check if the user is using an Android device
export function isAndroid() {
  return /Android/.test(navigator.userAgent);
}

export function OpenBikeepWalletOption() {
  const isActive = bitgetConnection.hooks.useIsActive()
  const [link, setLink] = useState('https://web3.bitget.com/en/wallet-download');

  useEffect(() => {
    if (isiOS())
      setLink('https://apps.apple.com/us/app/bitget-wallet-ex-bitkeep/id1395301115')
    else if (isAndroid()) setLink('https://play.google.com/store/apps/details?id=com.bitkeep.wallet&pli=1')
  }, [])
  return (
    <Option
      {...BASE_PROPS}
      isActive={isActive}
      link={link}
      header="Open in Bitget Wallet"
    />
  )
}

export function BikeepWalletOption({ tryActivation }: { tryActivation: (connector: Connector) => void }) {
  const isActive = bitgetConnection.hooks.useIsActive()

  return (
    <Option
      {...BASE_PROPS}
      isActive={isActive}
      onClick={() => tryActivation(bitgetConnection.connector)}
      header={getConnectionName(ConnectionType.BITGET_WALLET)}
    />
  )
}
