import { Trans } from '@lingui/macro'
import { Trace, TraceEvent } from '@uniswap/analytics'
import { BrowserEvent, ElementName, EventName, PageName } from '@uniswap/analytics-events'
import { useWeb3React } from '@web3-react/core'
import { ButtonGray, ButtonPrimary, ButtonText, NotchedButtonFill, NotchedButtonFillPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { FlyoutAlignment, Menu } from 'components/Menu'
import PositionList from 'components/PositionList'
import { RowBetween, RowFixed } from 'components/Row'
import { SwitchLocaleLink } from 'components/SwitchLocaleLink'
import {isSupportedChain, SupportedChainId} from 'constants/chains'
import { useV3Positions } from 'hooks/useV3Positions'
import { AlertTriangle, BookOpen, ChevronDown, ChevronsRight, Inbox, Layers, PlusCircle } from 'react-feather'
import { Link } from 'react-router-dom'
import { useToggleWalletModal } from 'state/application/hooks'
import { useUserHideClosedPositions } from 'state/user/hooks'
import styled, { css, useTheme } from 'styled-components/macro'
import { HideSmall, ThemedText } from 'theme'
import { PositionDetails } from 'types/position'
import './pool.css'
import { V2_FACTORY_ADDRESSES } from '../../constants/addresses'
import CTACards from './CTACards'
import { LoadingRows } from './styleds'
import SwapHeader from '../../components/swap/SwapHeader'
import { NotchedButtonBolderNew } from 'components/Button/NotchedButton'
import { Text } from 'rebass'

// v2 pools
import V2Pool from '../Pool/v2'
import {useMemo, useState} from 'react'

const swaptop =
  'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/pool/liquidheader.png'
const reload = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/swap/reload.png'

const PageWrapper = styled.div`
  padding: 68px 8px 0px;
  width: 80%;

  @media (max-width: 768px) {
    width: 90%;
  }
`
const TitleRow = styled(RowBetween)`
  color: ${({ theme }) => theme.deprecated_text2};
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  `};
`
const ButtonRow = styled(RowFixed)`
  gap: 8px;
  margin-top: 1rem;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  `};
`
const PoolMenu = styled(Menu)`
  margin-left: 10px;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex: 1 1 auto;
    width: 49%;
    right: 0px;
  `};

  a {
    width: 100%;
  }
`
const PoolMenuItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
  :hover {
    color: #efefe4;
    cursor: pointer;
    text-decoration: none;
  }
`
const MoreOptionsButton = styled(NotchedButtonFill)`
  padding: 6px 8px;
  width: 100%;
  margin-right: 8px;
`

const MoreOptionsText = styled(ThemedText.DeprecatedBody)`
  align-items: center;
  display: flex;
`

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 300px;
  min-height: 25vh;
  color: #efefe4;
`

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`

const NetworkIcon = styled(AlertTriangle)`
  ${IconStyle}
`

const InboxIcon = styled(Inbox)`
  ${IconStyle}
`

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: fit-content;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    width: 48%;
  `};
`

const MainContentWrapper = styled.main`
  background: rgba(239, 239, 228, 0.03);
  border: 1px solid rgba(239, 239, 228, 0.2);
  padding: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
`

function PositionsLoadingPlaceholder() {
  return (
    <LoadingRows>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </LoadingRows>
  )
}

function WrongNetworkCard() {
  const theme = useTheme()

  return (
    <>
      <PageWrapper>
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            <div className="pool-top-container">
              <div className="swap-top-container">
                <div className="swap-top-left">
                  <img src={swaptop} alt="swap image" />
                  <div className="swap-top-div">
                    <p>Positions</p>
                    <p>Add liquidity to the AMM and receive protocol incentives in MUSD</p>
                  </div>
                </div>
                <div className="swap-top-right">
                  <img src={reload} alt="swap image" />
                </div>
              </div>
            </div>

            <MainContentWrapper>
              <ErrorContainer>
                <ThemedText.DeprecatedBody color={theme.deprecated_text3} textAlign="center">
                  <NetworkIcon strokeWidth={1.2} />
                  <div data-testid="pools-unsupported-err">
                    <Trans>Your connected network is unsupported.</Trans>
                  </div>
                </ThemedText.DeprecatedBody>
              </ErrorContainer>
            </MainContentWrapper>
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
      {/* <SwitchLocaleLink /> */}
    </>
  )
}

export default function Pool() {
  const { account, chainId } = useWeb3React()
  const toggleWalletModal = useToggleWalletModal()
  const [tabSelector, setTabSelector] = useState(1)

  const theme = useTheme()
  const [userHideClosedPositions, setUserHideClosedPositions] = useUserHideClosedPositions()

  const { positions, loading: positionsLoading } = useV3Positions(account)

  if (chainId !== SupportedChainId.BLAST_SEPOLIA && chainId !== SupportedChainId.BLAST) {
    return <WrongNetworkCard />;
  }

  if (!isSupportedChain(chainId)) {
    return <WrongNetworkCard />
  }

  const [openPositions, closedPositions] = positions?.reduce<[PositionDetails[], PositionDetails[]]>(
    (acc, p) => {
      acc[p.liquidity?.isZero() ? 1 : 0].push(p)
      return acc
    },
    [[], []]
  ) ?? [[], []]

  const filteredPositions = [...openPositions, ...(userHideClosedPositions ? [] : closedPositions)]
  const showConnectAWallet = Boolean(!account)
  const showV2Features = Boolean(V2_FACTORY_ADDRESSES[chainId])

  const menuItems = [
    {
      content: (
        <PoolMenuItem>
          <Trans>Create a pool</Trans>
          <PlusCircle size={16} />
        </PoolMenuItem>
      ),
      link: '/add/ETH',
      external: false,
    },
    {
      content: (
        <PoolMenuItem>
          <Trans>Migrate</Trans>
          <ChevronsRight size={16} />
        </PoolMenuItem>
      ),
      link: '/migrate/v2',
      external: false,
    },
    {
      content: (
        <PoolMenuItem>
          <Trans>V2 liquidity</Trans>
          <Layers size={16} />
        </PoolMenuItem>
      ),
      link: '/pool',
      external: false,
    },
    {
      content: (
        <PoolMenuItem>
          <Trans>Learn</Trans>
          <BookOpen size={16} />
        </PoolMenuItem>
      ),
      link: 'https://docs.monoswap.io',
      external: true,
    },
  ]

  return (
    <Trace page={PageName.POOL_PAGE} shouldLogImpression>
      <>
        <PageWrapper>
          <div className="tab-pool-selector">
            <NotchedButtonBolderNew w="100%" h="100%" borderColor={'rgba(239, 239, 228, 0.5)'}>
              <div
                className={`tab-pool-selector-text ${tabSelector === 1 ? 'tab-pool-active' : ''}`}
                onClick={() => {
                  setTabSelector(1)
                }}
              >
                Positions V3
              </div>
            </NotchedButtonBolderNew>
            <NotchedButtonBolderNew w="100%" h="100%" borderColor={'rgba(239, 239, 228, 0.5)'}>
              <div
                className={`tab-pool-selector-text ${tabSelector === 2 ? 'tab-pool-active' : ''}`}
                onClick={() => {
                  setTabSelector(2)
                }}
              >
                Positions V2
              </div>
            </NotchedButtonBolderNew>
            {/* <NotchedButtonBolderNew w="100%" h="100%" borderColor={'rgba(239, 239, 228, 0.5)'}>
              <div className={`tab-pool-selector-text ${tabSelector === 3 ? 'tab-pool-active' : ''}`}>
                Positions Vault
              </div>
            </NotchedButtonBolderNew> */}
          </div>
          <div className="pool-container">
            <div className="pool-top-container">
              <div className="swap-top-container">
                <div className="swap-top-left">
                  <img src={swaptop} alt="swap image" />
                  <div className="swap-top-div">
                    <p>Positions</p>
                    <p>Add liquidity to the AMM and receive protocol incentives in MUSD</p>
                  </div>
                </div>
                <div className="swap-top-right">
                  <img src={reload} alt="swap image" />
                </div>
              </div>
            </div>
            {tabSelector === 1 && (
              <>
                <AutoColumn gap="lg" justify="center">
                  <AutoColumn gap="lg" style={{ width: '100%' }}>
                    <TitleRow padding="0">
                      <p></p>
                      <ButtonRow>
                        <ResponsiveButtonPrimary
                          data-cy="join-pool-button"
                          id="join-pool-button"
                          as={Link}
                          to="/add/ETH"
                          padding="6px 8px"
                        >
                          <Text fontWeight={500} fontSize={16}>
                            + <Trans>New Position</Trans>
                          </Text>
                        </ResponsiveButtonPrimary>
                        {/* {showV2Features && (
                          <PoolMenu
                            menuItems={menuItems}
                            flyoutAlignment={FlyoutAlignment.LEFT}
                            ToggleUI={(props: any) => (
                              <MoreOptionsButton {...props}>
                                <MoreOptionsText>
                                  <Text fontWeight={500} fontSize={16}>
                                    <Trans>More</Trans>
                                    <ChevronDown size={15} />
                                  </Text>
                                </MoreOptionsText>
                              </MoreOptionsButton>
                            )}
                          />
                        )} */}
                      </ButtonRow>
                    </TitleRow>

                    <MainContentWrapper>
                      {positionsLoading ? (
                        <PositionsLoadingPlaceholder />
                      ) : filteredPositions && closedPositions && filteredPositions.length > 0 ? (
                        <PositionList
                          positions={filteredPositions}
                          setUserHideClosedPositions={setUserHideClosedPositions}
                          userHideClosedPositions={userHideClosedPositions}
                        />
                      ) : (
                        <ErrorContainer>
                          <ThemedText.DeprecatedBody color={theme.deprecated_text3} textAlign="center">
                            <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
                            <div>
                              <Trans>Your active V3 liquidity positions will appear here.</Trans>
                            </div>
                          </ThemedText.DeprecatedBody>
                          {!showConnectAWallet && closedPositions.length > 0 && (
                            <ButtonText
                              style={{ marginTop: '.5rem' }}
                              onClick={() => setUserHideClosedPositions(!userHideClosedPositions)}
                            >
                              <div style={{ color: 'rgba(239,239,228,0.5)' }}>
                                <Trans>Show closed positions</Trans>{' '}
                              </div>
                            </ButtonText>
                          )}
                          {showConnectAWallet && (
                            <TraceEvent
                              events={[BrowserEvent.onClick]}
                              name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
                              properties={{ received_swap_quote: false }}
                              element={ElementName.CONNECT_WALLET_BUTTON}
                            >
                              <NotchedButtonFill
                                style={{ marginTop: '2em', marginBottom: '2em', padding: '8px 16px' }}
                                onClick={toggleWalletModal}
                              >
                                <Trans>Connect a wallet</Trans>
                              </NotchedButtonFill>
                            </TraceEvent>
                          )}
                        </ErrorContainer>
                      )}
                    </MainContentWrapper>
                    {/* <HideSmall>
                <CTACards />
              </HideSmall> */}
                  </AutoColumn>
                </AutoColumn>
              </>
            )}
            {tabSelector === 2 && (
              <>
                <V2Pool />
              </>
            )}
          </div>
        </PageWrapper>
        {/* <SwitchLocaleLink /> */}
      </>
    </Trace>
  )
}
