import { Trans } from '@lingui/macro'
import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import { Trade as V2Trade } from 'custom-uniswap-v2-sdk'
import { FeeAmount, Trade as V3Trade } from '@uniswap/v3-sdk'
import { AutoColumn } from 'components/Column'
import { LoadingRows } from 'components/Loader/styled'
import RoutingDiagram from 'components/RoutingDiagram/RoutingDiagramV2'
import { AutoRow, RowBetween } from 'components/Row'
import useAutoRouterSupported from 'hooks/useAutoRouterSupported'
import { memo } from 'react'
import { Plus } from 'react-feather'
import { InterfaceTrade } from 'state/routing/types'
import styled from 'styled-components/macro'
import { Separator, ThemedText } from 'theme'
import Badge from 'components/Badge'
import { TYPE } from 'theme'

import { AutoRouterLabel, AutoRouterLogo } from './RouterLabel'
import { Version } from '../../hooks/useToggledVersion'
import { getTradeVersion } from '../../utils/getTradeVersion'

const Wrapper = styled(AutoColumn)<{ darkMode?: boolean; fixedOpen?: boolean }>`
  padding: ${({ fixedOpen }) => (fixedOpen ? '12px' : '12px 8px 12px 12px')};
  border: 1px solid ${({ theme, fixedOpen }) => (fixedOpen ? 'transparent' : 'rgba(239, 239, 228, 0.2)')};
  cursor: pointer;
`
const RouterWrapper = styled.div``

const OpenCloseIcon = styled(Plus)<{ open?: boolean }>`
  margin-left: 8px;
  height: 20px;
  // stroke-width: 2px;
  transition: transform 0.1s;
  transform: ${({ open }) => (open ? 'rotate(45deg)' : 'none')};
  stroke: ${({ theme }) => theme.deprecated_text3};
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`

interface SwapRouteProps extends React.HTMLAttributes<HTMLDivElement> {
  trade: InterfaceTrade<Currency, Currency, TradeType>
  syncing: boolean
  fixedOpen?: boolean // fixed in open state, hide open/close icon
}

export default memo(function SwapRoute({
  trade,
  syncing,
}: {
  trade: V2Trade<Currency, Currency, TradeType> | V3Trade<Currency, Currency, TradeType>
  syncing: boolean
}) {
  const routingAPIEnabled = useAutoRouterSupported()

  return (
    <RouterWrapper>
      <AutoColumn gap="12px">
        <RowBetween>
          <AutoRow gap="4px" width="auto">
            <AutoRouterLogo />
            <AutoRouterLabel />
          </AutoRow>
          {syncing ? (
            <LoadingRows>
              <div style={{ width: '30px', height: '24px' }} />
            </LoadingRows>
          ) : (
            <Badge>
              <TYPE.black fontSize={12}>
                {getTradeVersion(trade) === Version.v2 ? <Trans>V2</Trans> : <Trans>V3</Trans>}
              </TYPE.black>
            </Badge>
          )}
        </RowBetween>
        <Separator />
        {syncing ? (
          <LoadingRows>
            <div style={{ width: '400px', height: '30px' }} />
          </LoadingRows>
        ) : (
          <RoutingDiagram
            currencyIn={trade.inputAmount.currency}
            currencyOut={trade.outputAmount.currency}
            routes={getTokenPath(trade)}
          />
        )}
        {routingAPIEnabled && (
          <TYPE.main fontSize={12} width={400}>
            <Trans>This route optimizes your price by considering split routes, multiple hops, and gas costs.</Trans>
          </TYPE.main>
        )}
      </AutoColumn>
    </RouterWrapper>
  )
})

function getTokenPath(
  trade: V2Trade<Currency, Currency, TradeType> | V3Trade<Currency, Currency, TradeType>
): RoutingDiagramEntry[] {
  // convert V2 path to a list of routes
  if (trade instanceof V2Trade) {
    const { path: tokenPath } = (trade as V2Trade<Currency, Currency, TradeType>).route
    const path = []
    for (let i = 1; i < tokenPath.length; i++) {
      path.push([tokenPath[i - 1], tokenPath[i], V2_DEFAULT_FEE_TIER] as RoutingDiagramEntry['path'][0])
    }
    return [{ percent: new Percent(100, 100), path }]
  }

  return trade.swaps.map(({ route: { tokenPath, pools }, inputAmount, outputAmount }) => {
    const portion =
      trade.tradeType === TradeType.EXACT_INPUT
        ? inputAmount.divide(trade.inputAmount)
        : outputAmount.divide(trade.outputAmount)

    const percent = new Percent(portion.numerator, portion.denominator)

    const path: [Currency, Currency, FeeAmount][] = []
    for (let i = 0; i < pools.length; i++) {
      const nextPool = pools[i]
      const tokenIn = tokenPath[i]
      const tokenOut = tokenPath[i + 1]

      path.push([tokenIn, tokenOut, nextPool.fee])
    }

    return {
      percent,
      path,
    }
  })
}

export interface RoutingDiagramEntry {
  percent: Percent
  path: [Currency, Currency, FeeAmount][]
}

const V2_DEFAULT_FEE_TIER = 3000
