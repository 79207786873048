import { Trace } from '@uniswap/analytics'
import { PageName } from '@uniswap/analytics-events'
import styled from 'styled-components/macro'
import Section0 from './sections/Section0'
import Section1 from './sections/Section1'
import Section3 from './sections/Section3'
import Section4 from './sections/Section4'
import FinalSection from './sections/FinalSection'
import Section2 from './sections/Section2'

/*
 * desktop 1280, 1344, 1440, 1536,
 * tablet 768 1024
 * mobile 360, 390
 */
export default function Landing() {
  return (
    <Trace page={PageName.LANDING_PAGE} shouldLogImpression>
      <Section0 />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <FinalSection />
    </Trace>
  )
}
