import { Connector } from '@web3-react/types'
import WALLET_CONNECT_ICON from 'assets/images/walletConnectIcon.svg'
import { walletConnectConnection, ConnectionType } from 'connection'
import { getConnectionName } from 'connection/utils'
import Option from './Option'

const BASE_PROPS = {
  color: '#315CF5',
  icon: WALLET_CONNECT_ICON,
  id: 'walletconnect',
  themeMode: 'dark',
}

export function OpenWalletConnectOption() {
  const isActive = walletConnectConnection.hooks.useIsActive()
  return (
    <Option
      {...BASE_PROPS}
      isActive={isActive}
      link="https://explorer.walletconnect.com/"
      header="Open in Wallet Connect"
    />
  )
}

export function WalletConnectOption({ tryActivation }: { tryActivation: (connector: Connector) => void }) {
  const isActive = walletConnectConnection.hooks.useIsActive()
  return (
    <Option
      {...BASE_PROPS}
      isActive={isActive}
      onClick={() => tryActivation(walletConnectConnection.connector)}
      header={getConnectionName(ConnectionType.WALLET_CONNECT)}
    />
  )
}
