import styled from "styled-components"
import { TextLinear } from "../MonoPotPage"
import ColorBorderContentBox from "./ColorBorderContentBox"
import { BREAKPOINTS, CustomLightSpinner } from "theme";
import { NotchedButtonBolderNew } from "components/Button/NotchedButton";
import { NotchedButtonFill } from "components/Button";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useMemo, useState } from "react";
import Modal from 'components/Modal'
import { useToggleWalletModal } from "state/application/hooks";
import BuyTicketModal from "./BuyTicketModal";
import ViewTicketModal from "./ViewTicketModal";
import { useMonopotStore } from "hooks/useMonopot";
import useCountdownTimer from "hooks/useCountdownTimer";
import dayjs from "dayjs";
import { formatXpNumber } from "pages/Xp/sections/XpTitle";
import useMusd from "hooks/useMusd";

const Circle = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/blue-loader.svg'


const MonoPotSaleWrapper = styled.div`
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 500px;
    margin: auto;
    margin-top: 118px;
    border: 1px solid #efefe47d;
    padding: 40px 24px;
    padding-top: 122px;
    position: relative;
`

const MonoPotTimeBox = styled.div`
    display: grid;
    gap: 16px;
`
const MonoPotNextTimeBox = styled.div`
    display: flex;
    justify-content: space-between;
`
export const Text = styled.span<{
    color?: string;
    fontSize?: string;
}>`
    color: ${({ color }) => color ?? 'rgba(239, 239, 228, 0.50)'};
    font-family: Urbanist;
    font-size: ${({ fontSize }) => fontSize ?? '16px'};;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 20px */
    letter-spacing: -0.32px;
`
const RemainTimeBox = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        gap: 10px;
    }
`
const TimeBox = styled.div`
    width: 100%;
    max-width: 148px;
    display: grid;
    justify-content: center;
    align-items: center;
    justify-items: center;
    padding: 10px 40px;
    background: linear-gradient(90deg, rgba(104, 255, 255, 0.12) 0%, rgba(104, 255, 155, 0.12) 100%);
    font-weight: 700;
     font-size: 21px;
    font-family: Urbanist;
    .time_suffix{
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        padding: 10px;
    }
`

const TicketBuyBox = styled.div`
    margin-top: 24px;
    display: grid;
    gap: 40px;
`

const TicketBuyButtonBox = styled.div`
    display: flex;
    gap: 34px;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        display: grid;
        gap: 12px;
    }
`

const PrizeBox = styled.div`
    width: 100%;
    padding: 24px 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
    .usd_price{
        font-family: Urbanist;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; 
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        .usd_price{
            font-size: 20px;
        }
    }
`

const PrizeMusd = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 12px;
    height: 100%;
    img{
        max-width: 50px;
        max-height: 50px;
        width: 100%;
        height: 100%;
    }
    .text{
        font-family: Righteous;
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: 0.75; 
    }
    
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        .text{
            font-size: 35px;
        }
    }
`
const PrizeWrapper = styled.div`
    width: 100%;
    padding: 0 50px;

    
    position: absolute;
    top: -15%;
    left: 0;
`
const Test = styled.div`
    background: unset;
    -webkit-box-shadow: 0px 0px 50px -18px rgba(84,255,61,0.54); 
    box-shadow: 0px 0px 50px -18px rgba(84,255,61,0.54);
`

const TimeEndCounter = () => {
    const [endTime, setEndTime] = useState<number>(0)
    const { hours, minutes, seconds } = useCountdownTimer('HOUR', endTime)
    const { currentChestInfo } = useMonopotStore()
    useEffect(() => {
        if (currentChestInfo?.id)
            setEndTime(Number(currentChestInfo.endTime))
    }, [currentChestInfo])

    return <RemainTimeBox>
        <TimeBox>
            <TextLinear>{hours < 0 ? '-' : hours}</TextLinear>
            <TextLinear className="time_suffix">hours</TextLinear>
        </TimeBox>
        <Text>:</Text>
        <TimeBox>
            <TextLinear>{minutes < 0 ? '-' : minutes}</TextLinear>
            <TextLinear className="time_suffix">minutes</TextLinear>
        </TimeBox>
        <Text>:</Text>
        <TimeBox>
            <TextLinear>{seconds < 0 ? '-' : seconds}</TextLinear>
            <TextLinear className="time_suffix">seconds</TextLinear>
        </TimeBox>
    </RemainTimeBox>
}

export default function MonoPotSale() {

    const { account } = useWeb3React();
    const toggleWalletModal = useToggleWalletModal();
    const { isLoadingCurrentChestInfo, currentChestInfo, myCurrentBoughtTickets, loadLatestChestInfo } = useMonopotStore()
    const { musdPrice, usdbPrice } = useMusd()

    const [isOpenBuyTicketModal, setisOpenBuyTicketModal] = useState<boolean>(false);
    const [isOpenViewTicketModal, setisOpenViewTicketModal] = useState<boolean>(false);

    const [isScreenLt600, setIsScreenLt600] = useState<boolean>(false);

    const monoPot = useMemo(() => {
        if (!currentChestInfo) return 0
        // return currentChestInfo.amountCollectedInMono * (10000 - currentChestInfo.treasuryFee) / 10000
        return currentChestInfo.amountCollectedInMono
    }, [currentChestInfo])

    useEffect(() => {
        if (!isOpenViewTicketModal && currentChestInfo)
            loadLatestChestInfo(currentChestInfo.id)
    }, [isOpenViewTicketModal])

    useEffect(() => {
        setIsScreenLt600(window.screen.width <= 600)
    }, [])
    return <div>
        <MonoPotSaleWrapper>

            <PrizeWrapper>
                <ColorBorderContentBox borderColor="linear-gradient(90deg, #68FFFF, #68FF9B)">

                    <PrizeBox>
                        <PrizeMusd>
                            <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section3/musd-token.png" />

                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <Text color="#EFEFE4" fontSize="18px">
                                    Prize Pot
                                </Text>
                                <Text className="text" color="#EFEFE4">
                                    {
                                        isLoadingCurrentChestInfo ? <CustomLightSpinner src={Circle} alt="loader" size="16px" /> : formatXpNumber(monoPot, 2)
                                    }
                                </Text>
                            </div>
                        </PrizeMusd>

                        <Text className="usd_price" fontSize="28px" style={{ fontWeight: 500 }}>
                            {
                                isLoadingCurrentChestInfo ? <CustomLightSpinner src={Circle} alt="loader" size="16px" /> : '$' + formatXpNumber((monoPot ?? 0) * musdPrice)
                            }
                        </Text>
                    </PrizeBox>
                </ColorBorderContentBox>

            </PrizeWrapper>

            <MonoPotTimeBox>
                <MonoPotNextTimeBox>
                    <Text>
                        Next Drawing
                    </Text>

                    <Text color="#EFEFE4">
                        {
                            currentChestInfo?.status == 1 ? dayjs((currentChestInfo?.endTime ?? 1) * 1000).format('ddd, MMM DD, YYYY') : '-'
                        }
                    </Text>
                </MonoPotNextTimeBox>

                <TimeEndCounter />
                {
                    account ? <Text style={{ display: 'block', textAlign: 'center' }}>
                        {myCurrentBoughtTickets.length > 0 ? `You have ${myCurrentBoughtTickets.length} tickets this round` : 'You don’t have any tickets this round.'}
                    </Text>
                        :
                        <Text style={{ display: 'block', textAlign: 'center' }}>
                            Get tickets before the draw
                        </Text>
                }
                <div style={{ width: '100%', height: '1px', background: '#EFEFE4', opacity: 0.2 }} />
            </MonoPotTimeBox>

            <TicketBuyBox>
                <MonoPotNextTimeBox>
                    <Text>
                        Tickets
                    </Text>
                    <Text color="#EFEFE4">
                        {
                            isLoadingCurrentChestInfo ? <CustomLightSpinner src={Circle} alt="loader" size="16px" /> : myCurrentBoughtTickets.length
                        }
                    </Text>
                </MonoPotNextTimeBox>

                <TicketBuyButtonBox>
                    {
                        account ?
                            (
                                myCurrentBoughtTickets.length == 0 ?
                                    <NotchedButtonFill height="40px" onClick={() => {

                                        setisOpenBuyTicketModal(true)
                                    }}>
                                        BUY TICKET NOW
                                    </NotchedButtonFill>
                                    :
                                    <>
                                        <NotchedButtonBolderNew w={isScreenLt600 ? '100%' : '50%'} h="42px" bg="#000000" borderColor="rgba(104, 255, 255, 0.80)">
                                            <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', cursor: 'pointer' }} onClick={() => setisOpenViewTicketModal(true)}>VIEW TICKETS</span>
                                        </NotchedButtonBolderNew>
                                        <NotchedButtonFill width={isScreenLt600 ? '100%' : '50%'} height="40px" onClick={() => setisOpenBuyTicketModal(true)}>
                                            GET MORE
                                        </NotchedButtonFill>
                                    </>
                            )
                            :
                            <NotchedButtonFill height="40px" onClick={toggleWalletModal}>
                                CONNECT WALLET
                            </NotchedButtonFill>
                    }
                </TicketBuyButtonBox>
            </TicketBuyBox>

            <Modal maxWidth={592} isOpen={isOpenBuyTicketModal} onDismiss={() => setisOpenBuyTicketModal(false)}>
                <BuyTicketModal onClose={() => setisOpenBuyTicketModal(false)} musdPrice={musdPrice} usdbPrice={usdbPrice} />
            </Modal>
            <Modal maxWidth={592} isOpen={isOpenViewTicketModal} onDismiss={() => setisOpenViewTicketModal(false)}>
                <ViewTicketModal openBuyTicketModal={setisOpenBuyTicketModal} onClose={() => setisOpenViewTicketModal(false)} />
            </Modal>
        </MonoPotSaleWrapper>
        {/* <div style={{ display: 'flex', gap: '6px', justifyContent: 'center', alignItems: 'center', position: 'relative', zIndex: 9, marginTop: '16px' }}>
            <Text style={{ color: 'rgba(239, 239, 228, 0.70)', fontFamily: 'Righteous', fontWeight: 400, lineHeight: '15px' }} fontSize="12px">POWERED BY</Text>
            <svg width="47" height="16" viewBox="0 0 47 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.8844 9.34865V8.26603H20.4489C20.8411 8.26603 21.1471 8.15458 21.3666 7.9317C21.5861 7.70881 21.6959 7.41499 21.6959 7.05172C21.6959 6.68843 21.5861 6.38883 21.3666 6.17173C21.1471 5.95463 20.8411 5.84608 20.4489 5.84608H17.0678V11.1282H15.9609V4.72656H20.4489C20.8179 4.72656 21.1471 4.78083 21.4373 4.88938C21.7269 4.99794 21.9716 5.15064 22.1709 5.34747C22.3702 5.54431 22.5225 5.78311 22.6279 6.06463C22.7333 6.34613 22.7861 6.6631 22.7861 7.01553C22.7861 7.36796 22.7333 7.67697 22.6279 7.9621C22.5225 8.2465 22.3702 8.49181 22.1709 8.69734C21.9716 8.90286 21.7276 9.0628 21.4373 9.17713C21.1479 9.29148 20.8179 9.34865 20.4489 9.34865H17.8844Z" fill="#E6DAFE" />
                <path d="M26.421 11.1267V8.69662L23.0312 4.72656H24.533L27.0188 7.67552L29.5127 4.72656H30.9357L27.5365 8.69662V11.1267H26.421Z" fill="#E6DAFE" />
                <path d="M34.7812 11.1267V5.84463H31.8438V4.72656H38.8382V5.84463H35.8966V11.1267H34.7812Z" fill="#E6DAFE" />
                <path d="M45.8203 4.72656H46.9315V11.1267H45.8203V4.72656Z" fill="#E6DAFE" />
                <path d="M41.0252 11.1267V8.35648H45.1592V7.36578H41.0252V4.72656H39.9141V11.1267H41.0252Z" fill="#E6DAFE" />
                <path d="M7.98438 6.3993C7.98438 7.2829 7.26958 7.99933 6.38802 7.99933V9.59937C8.15115 9.59937 9.58073 8.16651 9.58073 6.3993C9.58073 4.6321 8.15115 3.19922 6.38802 3.19922C5.8068 3.19922 5.26098 3.35482 4.79166 3.62764C3.83718 4.18052 3.19531 5.21465 3.19531 6.3993V14.3995L4.63066 15.8381L4.79166 15.9995V6.3993C4.79166 5.51569 5.50645 4.79926 6.38802 4.79926C7.26958 4.79926 7.98438 5.51569 7.98438 6.3993Z" fill="#E6DAFE" />
                <path d="M6.38542 0C5.22227 0 4.13204 0.311902 3.1927 0.856827C2.59128 1.20491 2.05266 1.64852 1.59635 2.1674C0.602875 3.29559 0 4.77767 0 6.40015V11.2003L1.59635 12.8003V6.40015C1.59635 4.97886 2.21295 3.70158 3.1927 2.82232C3.65334 2.40983 4.19413 2.08417 4.78907 1.87431C5.28797 1.69628 5.82587 1.60004 6.38542 1.60004C9.03013 1.60004 11.1745 3.74934 11.1745 6.40015C11.1745 9.05095 9.03013 11.2003 6.38542 11.2003V12.8003C9.91241 12.8003 12.7708 9.93456 12.7708 6.40015C12.7708 2.86573 9.91241 0 6.38542 0Z" fill="#E6DAFE" />
            </svg>

        </div> */}
    </div>
}