import { notification } from "antd";
import { SupportedChainId } from "constants/chains";



export default function checkUnsupportChainId(chainId: number = 0) {
    if (chainId != SupportedChainId.BLAST) {
        notification.warning({
            message: 'Please switch chain to Blast Mainnet to use this feature'
        })
        throw `${chainId} is not support`
    }
}