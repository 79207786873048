import Modal from 'components/Modal'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { BREAKPOINTS } from 'theme'
import { BASE_API, LeaderBoardType } from '..'
import { useWeb3React } from '@web3-react/core'
import axios from 'axios'
import { numberWithCommas } from 'pages/Alpha'
import { RewardInfo } from './XpLeaderboard'
import { notification, Popover } from 'antd'
import Tooltip, { TooltipPlacement } from 'antd/es/tooltip'

const XpTitleSectionWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1536px;
  margin: auto;
  margin-top: 56px;
  margin-bottom: 100px;
  @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
    padding: 0 20px;
  }
  @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
`

const XpTitleBoxLeft = styled.div`
  width: 40%;
  @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
    width: 100%;
  }
`

const Title = styled.h3`
  color: #efefe4;
  font-size: 56px;
  font-family: Righteous;
  font-weight: 400;
  line-height: 125%;
  word-wrap: break-word;
  margin: 0;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 40px;
    line-height: 125%;
    width: unset;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin-bottom: 0;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    background-size: cover;
    font-size: 32px;
    line-height: 40px;
  }
`

export const TextLinear = styled.span`
  background: linear-gradient(90deg, #68ffff 0%, #68ff9b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  p {
    background: inherit;
    background-clip: inherit;
    -webkit-background-clip: inherit;
    -webkit-text-fill-color: inherit;
    margin: 0;
    text-transform: lowercase;
  }
`

const XpPointBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  margin-top: 32px;
  width: fit-content;
  @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
`
const XpPointBox = styled.div`
  min-width: 285px;
  height: 80px;
  position: relative;
  background: 'rgba(255, 255, 255, 0.03)';
  backdrop-filter: 'blur(4.76px)';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0 20px;
`

const XpPointBoxLabel = styled.span`
  color: rgba(239, 239, 228, 0.5);
  text-align: center;
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.5px; /* 17.5px */
`
const XpPointBoxContent = styled.span`
  color: #efefe4;
  font-family: Righteous;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-transform: uppercase;
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    background-size: cover;
    font-size: 16px;
    line-height: 18.5px;
  }
`

const ImgBg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`

const XpInfoBoxRight = styled.div`
  width: fit-content;
  padding-top: 83px;
  @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
    padding-top: 64px;
    width: 100%;
  }
`

const TaskBoxItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
  @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
    justify-content: center;
  }
`

const TaskBoxItem = styled.div<{
  bg?: string
}>`
  position: relative;
  width: 264px;
  /* background: ${({ bg }) => bg ?? '#efefe436'}; */
  padding: 1px;
  /* clip-path: polygon(27.3px 0, 100% 0, 100% calc(100% - 27.3px), calc(100% - 27.3px) 100%, 0 100%, 0 27.3px); */
  position: relative;
  box-sizing: border-box;
`
const TaskBoxItemBorderContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px;
  padding-bottom: 34px;
  /* background: #0f0e14; */
  margin: auto;
  /* clip-path: polygon(27.3px 0, 100% 0, 100% calc(100% - 27.3px), calc(100% - 27.3px) 100%, 0 100%, 0 27.3px); */
  position: relative;
  display: flex;
`
const FarmBox = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 8px;
`

const TaskBoxItemTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TaskBoxItemNoticeIcon = ({ open }: any) => {
  return (
    <img
      onClick={open}
      style={{ width: '16px', height: '16p', cursor: 'pointer' }}
      src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/more-info-icon.png"
    />
  )
}

const TaskBoxItemTitle = styled.h5`
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.5px; /* 17.5px */
  display: flex;
  align-items: center;
  gap: 7px;
`
const TaskBoxItemSubTitle = styled.h4`
  color: #efefe4;
  /* Urbanist/Body-2/Semi */
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.2px; /* 25.2px */
  margin: 0;
  margin-top: 10px;
  margin-bottom: 32px;
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 16px;
    line-height: 18.5px;
  }
`

const TaskBoxItemInfoBoxWrapper = styled.div`
  /* padding: 12px auto; */
  display: grid;
  gap: 8px;
`
const TaskBoxItemInfoBox = styled.div`
  width: 100%;
  background: rgba(239, 239, 228, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 0;
`

const TreePopupContent = styled.div`
  background: black;
  width: 200px;
  height: 94px;
  display: inline-flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.2);

  span {
    max-width: 157px;
    color: #efefe4;
    font-family: Urbanist !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 17.5px */
    opacity: 0.6;
  }
`

const TaskItemRewardBox = styled.div`
  display: flex;
  gap: 8px;
`

const TaskItemStatContent = styled.span`
  color: #efefe4;
  /* Righteous/Button */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 18px */
  text-transform: uppercase;
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 14px !important;
    line-height: 125% !important;
  }
`

const TaskItemStatLabel = styled.span`
  color: rgba(239, 239, 228, 0.5);
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%; /* 17.5px */
`

export const TreeIconPopup = ({
  width,
  placement,
  src,
}: {
  width: string
  placement: TooltipPlacement
  src?: string
}) => {
  return (
    <Popover
      overlayInnerStyle={{ padding: 0, borderRadius: 0 }}
      arrow={true}
      color="black"
      placement={placement}
      content={
        <TreePopupContent>
          <span>Harvest the most MonoXP + Blast Points with this sprout.</span>
        </TreePopupContent>
      }
    >
      <img
        style={{ width, cursor: 'pointer' }}
        src={src ?? 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/farm-tree-linear-icon.png'}
      />
    </Popover>
  )
}

const TaskItemReward = ({ point, gold, xmono, musd }: { point?: number; gold?: number; xmono?: number; musd?: number }) => {
  return (
    <div style={{ display: 'grid', gap: '4px', justifyContent: 'start' }}>
      <TaskItemStatLabel style={{ fontSize: '14px', fontWeight: 600, opacity: '0.5', textAlign: 'start' }}>
        Current Rewards
      </TaskItemStatLabel>

      {/* blast point, gold*/}
      <TaskItemRewardBox>
        <TaskItemStatContent
          style={{ fontSize: '16px', fontWeight: 600, display: 'flex', alignItems: 'center', gap: '5px' }}
        >
          {formatXpNumber(point ?? 0)}
          <Tooltip title="Blast Point">
            <img
              style={{
                cursor: 'pointer',
                width: '20px',
                height: '20px',
                borderRadius: '18px',
                border: '1px solid rgba(255, 255, 255, 0.20)',
              }}
              src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chain-logo/blast.png"
            />
          </Tooltip>
        </TaskItemStatContent>
        {/* <TaskItemStatLabel style={{ fontSize: '16px', fontWeight: 600 }}>|</TaskItemStatLabel>
        <TaskItemStatContent
          style={{ fontSize: '16px', fontWeight: 600, display: 'flex', alignItems: 'center', gap: '5px' }}
        >
          {formatXpNumber(gold ?? 0)}
          <Tooltip title="Blast Gold">
            <img
              style={{
                cursor: 'pointer',
                width: '20px',
                height: '20px',
                borderRadius: '18px',
                border: '1px solid rgba(255, 255, 255, 0.20)',
              }}
              src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/blast-gold-icon.png"
            />
          </Tooltip>
        </TaskItemStatContent> */}
      </TaskItemRewardBox>

      {/* musd, xmono */}
      <TaskItemRewardBox>
        <TaskItemStatContent
          style={{ fontSize: '16px', fontWeight: 600, display: 'flex', alignItems: 'center', gap: '5px' }}
        >
          {formatXpNumber(musd ?? 0)}
          <Tooltip title="MUSD">
            <img
              style={{
                cursor: 'pointer',
                width: '20px',
                height: '20px',
                borderRadius: '18px',
                border: '1px solid rgba(255, 255, 255, 0.20)',
              }}
              src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/musd.svg"
            />
          </Tooltip>
        </TaskItemStatContent>
        <TaskItemStatLabel style={{ fontSize: '16px', fontWeight: 600 }}>|</TaskItemStatLabel>
        <TaskItemStatContent
          style={{ fontSize: '16px', fontWeight: 600, display: 'flex', alignItems: 'center', gap: '5px' }}
        >
          {formatXpNumber(xmono ?? 0)}
          <Tooltip title="xMONO">
            <img
              style={{
                cursor: 'pointer',
                width: '20px',
                height: '20px',
                borderRadius: '18px',
                border: '1px solid rgba(255, 255, 255, 0.20)',
              }}
              src="https://raw.githubusercontent.com/Monoswap-Labs/monoswap-token-list/main/img/blast_sepolia/assets/0xa07aC8cDe2a98B189477b8e41F0c2Ea6CdDbC055/logo.png"
            />
          </Tooltip>
        </TaskItemStatContent>
      </TaskItemRewardBox>
    </div>
  )
}

function removeDecimalIfZero(number: number) {
  // Convert the number to a string
  let numberString = number.toString()
  numberString = numberString.replace('.00', '')

  // Check if the number has a decimal part
  if (numberString.includes('.')) {
    let [integerPart, decimalPart] = numberString.split('.')

    // Check if the decimal part is '00'
    if (decimalPart === '00') {
      return parseFloat(integerPart) // Return the integer part only
    } else {
      return parseFloat(numberString) // Return the original number
    }
  }

  // If the number has no decimal part, return the original number
  return number
}


function toFixedNum(value: any, places: number = 2) {
  const numberString = Number(value ?? 0).toString()
  if (numberString == '0') return 0
  let [integerPart, decimalPart] = numberString.split('.')
  return Number(`${integerPart}${decimalPart ? '.' + decimalPart.slice(0, places) : ''}`)
}


export function formatXpNumber(val?: any, fixed: number = 2) {
  const numberVal = toFixedNum(val, fixed)
  if (numberVal == 0) return 0
  else if (numberVal < 1000) return removeDecimalIfZero(numberVal)
  return numberWithCommas(removeDecimalIfZero(numberVal))
}

type ReceivedRewardType = {
  totalMusd: number
  totalMono: number
  totalXmono: number
  totalPoint: number
  totalGold: number
}
type TaskRewardType = {
  swap: ReceivedRewardType
  farm: ReceivedRewardType
  musd: ReceivedRewardType
  ref: ReceivedRewardType
}

export default function XpTitle({ onOpenTaskInfo }: { onOpenTaskInfo: (type: LeaderBoardType) => void }) {
  const { account } = useWeb3React()
  const [myXp, setMyXp] = useState<number>(0)
  const [myReceivedRewards, setMyReceivedRewards] = useState<ReceivedRewardType>()
  const [taskRewards, setTaskRewards] = useState<TaskRewardType>()
  useEffect(() => {
    axios
      .get(`${BASE_API}/monoswap/api/v1/leaderboard/my-xp-info?address=${account ?? ''}`)
      .then(
        ({
          data,
        }: {
          data: {
            xp: number
            receivedRewards: ReceivedRewardType
            taskRewards: TaskRewardType
          }
        }) => {
          setMyXp(data.xp)
          setMyReceivedRewards(data.receivedRewards)
          setTaskRewards(data.taskRewards)
        }
      )
      .catch((err) => { })
  }, [account])

  return (
    <XpTitleSectionWrapper>
      <XpTitleBoxLeft>
        <Title>
          <TextLinear>MonoXP</TextLinear>
        </Title>
        <Title>
          Real Yield <br />
          Real Rewards
        </Title>

        <XpPointBoxWrapper>
          <XpPointBox style={{ width: '100%' }}>
            <ImgBg src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/xp-point-bg.png" />
            <XpPointBoxLabel>XP Balance</XpPointBoxLabel>
            <XpPointBoxContent style={{ fontSize: '24px' }}>
              {myXp > 0 ? <TextLinear>{formatXpNumber(myXp)}</TextLinear> : <>{myXp}</>} XP
            </XpPointBoxContent>
          </XpPointBox>

          <XpPointBox>
            <ImgBg src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/xp-point-bg.png" />
            <XpPointBoxLabel>Rewards Received</XpPointBoxLabel>
            <XpPointBoxContent>
              <RewardInfo
                gap={'16px'}
                isTurnOffSepartor={true}
                musd={myReceivedRewards?.totalMusd}
                xmono={myReceivedRewards?.totalXmono}
                point={myReceivedRewards?.totalPoint}
                goldPoint={myReceivedRewards?.totalGold}
                isShowGoldPoint={true}
              />
            </XpPointBoxContent>
          </XpPointBox>
        </XpPointBoxWrapper>
      </XpTitleBoxLeft>
      <XpInfoBoxRight>
        <XpPointBoxContent>How to get</XpPointBoxContent>
        <TaskBoxItemWrapper>
          {/* swap item */}
          <TaskBoxItem>
            <TaskBoxItemBorderContent>
              <ImgBg src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/task-item-bg.png" />
              <div style={{ position: 'relative', zIndex: 1 }}>
                <TaskBoxItemTitleBox>
                  <TaskBoxItemTitle style={{ margin: 0 }}>
                    <TextLinear>SWAP</TextLinear>
                  </TaskBoxItemTitle>
                  <TaskBoxItemNoticeIcon open={() => onOpenTaskInfo('SWAP')} />
                </TaskBoxItemTitleBox>

                <TaskBoxItemSubTitle>Make a trade worth than $10</TaskBoxItemSubTitle>

                <TaskBoxItemInfoBoxWrapper>
                  <TaskBoxItemInfoBox>
                    <XpPointBoxLabel>Minimum MonoXP</XpPointBoxLabel>
                    <XpPointBoxContent>+3</XpPointBoxContent>
                  </TaskBoxItemInfoBox>

                  <TaskBoxItemInfoBox style={{ cursor: 'pointer', marginBottom: '32px' }}>
                    <Link
                      to={'/swap'}
                      style={{
                        textDecoration: 'none',
                      }}
                    >
                      <TextLinear>SWAP NOW</TextLinear>
                    </Link>
                  </TaskBoxItemInfoBox>
                  {/* <TaskBoxItemInfoBox style={{ cursor: 'pointer', marginBottom: '32px' }}>
                    <TextLinear onClick={() => {
                      let refLink = `${window.location.protocol}//${window.location.host}/#/xp`
                      // @ts-ignore
                      if (window.myXpRefLink)
                        // @ts-ignore
                        refLink += `/ref=${window.myXpRefLink}`
                      navigator.clipboard.writeText(refLink)
                      notification.success({
                        message: 'Referral link copied to your clipboard'
                      })
                    }}>
                      INVITE NOW
                    </TextLinear>
                  </TaskBoxItemInfoBox> */}
                </TaskBoxItemInfoBoxWrapper>

                <TaskItemReward point={taskRewards?.swap.totalPoint} gold={taskRewards?.swap.totalGold} xmono={taskRewards?.swap.totalXmono} musd={taskRewards?.swap.totalMusd} />
              </div>
            </TaskBoxItemBorderContent>
          </TaskBoxItem>

          {/* farm */}
          <TaskBoxItem bg="#fefc055e" style={{ width: '275px' }}>
            <ImgBg
              style={{ scale: '1.05' }}
              src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/task-item-highlight-bg.png"
            />
            <FarmBox>
              <TaskBoxItemBorderContent>
                <div style={{ position: 'relative', zIndex: 1 }}>
                  <TaskBoxItemTitleBox>
                    <div style={{ display: 'flex', gap: '12px' }}>
                      <TaskBoxItemTitle style={{ margin: 0 }}>
                        <TextLinear>FARM</TextLinear>
                        <TreeIconPopup width={'16px'} placement="topLeft" />
                      </TaskBoxItemTitle>
                      <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/farm-best.png" />
                    </div>
                    <TaskBoxItemNoticeIcon open={() => onOpenTaskInfo('FARM')} />
                  </TaskBoxItemTitleBox>

                  <TaskBoxItemSubTitle>Stake at least $10 worth LP tokens in Farming pools</TaskBoxItemSubTitle>

                  <TaskBoxItemInfoBoxWrapper>
                    <TaskBoxItemInfoBox>
                      <XpPointBoxLabel>Minimum MonoXP</XpPointBoxLabel>
                      <XpPointBoxContent style={{ textAlign: 'center' }}>
                        +34 <br />
                        <span style={{ fontSize: '12px' }}>(Earn every 2 hours)</span>
                      </XpPointBoxContent>
                    </TaskBoxItemInfoBox>
                    <TaskBoxItemInfoBox style={{ cursor: 'pointer', marginBottom: '32px' }}>
                      <Link
                        to={'/farming'}
                        style={{
                          textDecoration: 'none',
                        }}
                      >
                        <TextLinear>FARM NOW</TextLinear>
                      </Link>
                    </TaskBoxItemInfoBox>
                  </TaskBoxItemInfoBoxWrapper>
                  <TaskItemReward point={taskRewards?.farm.totalPoint} gold={taskRewards?.farm.totalGold} xmono={taskRewards?.farm.totalXmono} musd={taskRewards?.farm.totalMusd} />
                </div>
              </TaskBoxItemBorderContent>
            </FarmBox>
          </TaskBoxItem>

          {/* musd */}
          <TaskBoxItem>
            <ImgBg src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/task-item-bg.png" />
            <TaskBoxItemBorderContent>
              <div style={{ position: 'relative', zIndex: 1 }}>
                <TaskBoxItemTitleBox>
                  <TaskBoxItemTitle style={{ margin: 0 }}>
                    <TextLinear>MUSD</TextLinear>
                  </TaskBoxItemTitle>
                  <TaskBoxItemNoticeIcon open={() => onOpenTaskInfo('MUSD')} />
                </TaskBoxItemTitleBox>

                <TaskBoxItemSubTitle>Mint MUSD that is worth at least $10</TaskBoxItemSubTitle>

                <TaskBoxItemInfoBoxWrapper>
                  <TaskBoxItemInfoBox>
                    <XpPointBoxLabel>Minimum MonoXP</XpPointBoxLabel>
                    <XpPointBoxContent>+3</XpPointBoxContent>
                  </TaskBoxItemInfoBox>
                  <TaskBoxItemInfoBox style={{ cursor: 'pointer', marginBottom: '32px' }}>
                    <Link
                      to={'/musd'}
                      style={{
                        textDecoration: 'none',
                      }}
                    >
                      <TextLinear>MUSD NOW</TextLinear>
                    </Link>
                  </TaskBoxItemInfoBox>
                </TaskBoxItemInfoBoxWrapper>
                <TaskItemReward point={taskRewards?.musd.totalPoint} gold={taskRewards?.musd.totalGold} xmono={taskRewards?.musd.totalXmono} musd={taskRewards?.musd.totalMusd} />
              </div>
            </TaskBoxItemBorderContent>
          </TaskBoxItem>
        </TaskBoxItemWrapper>
      </XpInfoBoxRight>
    </XpTitleSectionWrapper>
  )
}
