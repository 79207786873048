import { NotchedButtonBolderNew } from "components/Button/NotchedButton";
import { formatXpNumber } from "pages/Xp/sections/XpTitle";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro"
import { BREAKPOINTS } from "theme"


const ChestOpenAnimationWrapper = styled.div`
    width: 100%;
    min-width: 500px;
    max-width: 592px;
    background: #09060f;
    box-shadow: 0px 12px 50px 0px #000;
    padding: 24px 32px;
    position: relative;
    z-index: 1;
    overflow: auto;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        min-width: 95%;
        padding: 20px 24px;
    }
`
const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

const OpenChestWrapper = styled.div`
    width: 100%;
    max-width: 592px;
    padding: 24px 32px;
    background: #1C1B20;
    position: relative;
    z-index: 0;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        padding: 24px 0;
    }
    .total_rw{
        color: #FFF;
        text-align: center;
        font-family: Righteous;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 18px */
        margin: 0;
        margin-bottom: 10px;
        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
            font-size: 18px;
        }
    }
`;

const Title = styled.h3<{
    fontSize?: string
}>`
  color: #efefe4;
  font-family: Righteous;
  font-size: ${({ fontSize }) => fontSize ?? '32px'};
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 60px */
  margin: 0;
  margin-bottom: 64px;
  text-align: center;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 24px;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 20px;
  }
`

const RewardBoxBorder = styled.div<{
    clipPath?: string
    w?: string
    maxW?: string
    h?: string
}>`
  max-width: ${({ maxW }) => maxW ?? '100%'};
  width: ${({ w }) => w ?? '100%'};
  height: ${({ h }) => h ?? '100%'};
  background: #efefe480;
  padding: 1px;
  clip-path: ${({ clipPath }) =>
        clipPath ?? 'polygon(27.3px 0, 100% 0, 100% calc(100% - 27.3px), calc(100% - 27.3px) 100%, 0 100%, 0 27.3px)'};
  position: relative;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    max-width: 95%;
    margin: auto;
  }
`

const RewardBoxBorderContent = styled.div<{
    clipPath?: string
}>`
  width: 100%;
  height: 100%;
  min-height: 150px;
  background: #1c1b20;
  margin: auto;
  clip-path: ${({ clipPath }) =>
        clipPath ?? 'polygon(27.3px 0, 100% 0, 100% calc(100% - 27.3px), calc(100% - 27.3px) 100%, 0 100%, 0 27.3px)'};
  position: relative;
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  gap: 20px;
  padding: 48px 10px 36px 10px;
  @media screen and (max-width: 550px) {
    grid-template-columns: 100%;
  }
`

const YouReveivedRwBox = styled.div`
    position: absolute;
    top: -20px;
    left: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
`
const YouReveivedRwBoxContent = styled.div`
    position: relative;
    padding: 10px 56px;
    span{
        position: relative;
        z-index: 10;
        color: rgba(255, 255, 255, 0.50);
        text-align: center;
        font-family: Urbanist;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 125%; /* 20px */
    }
`

const RewardInfoList = styled.div`
    display: grid; 
    grid-template-columns: repeat(2, calc(50% - 4px));
    row-gap: 4px;
    column-gap: 8px;
    max-width: 80%;
    margin: auto;
    @media screen and (max-width: 600px) {
        max-width: 100%;
    }
`
const RewawardInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 159.643px;
  min-height: 42.709px;
  border-radius: 3.087px;
  border: 0.386px solid #68FFEF;
  background: #1c1b20;
  padding: 7.5px 15.5px;
  @media screen and (max-width: 600px) {
    min-width: 120.643px;
  }
  .box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
  }
  .label{
    color: #FFF;
    font-family: Righteous;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; 
    @media screen and (max-width: 600px) {
        font-size: 9px;
    }
  }
  .content{
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    background: linear-gradient(90deg, #68ffff 0%, #68ff9b 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media screen and (max-width: 600px) {
        font-size: 14px;
    }
  }
  img{
    width: 38px;
    height: 38px;
  }
`

export const getNameChest = (type: number) : string => {
    switch (type) {
        case 0:
            return "Common"
        case 1:
            return "Uncommon"
        case 2:
            return "Rare"
        case 3:
            return "Epic"
        case 4:
            return "Mythical"
        default:
            return "Unknown"
    }
}

export default function ChestOpenAnimationModal({
    amountChest,
    isBuying,
    isBuySuccess,
    chestType,
    rewardList,
    onClose
}: {
    amountChest: number
    isBuying: boolean
    isBuySuccess: boolean
    chestType: number
    rewardList: any[]
    onClose: any
}) {
    const [isShowOpening, setIsShowOpening] = useState<boolean>(true)
    const [maxHeight, setMaxHeight] = useState<string>('600px')

    const rewardCnt = useMemo(() => {
        let totalTicket = 0
        let totalUsd = 0
        let totalXmono = 0
        let totalMonopass = 0
        for (let index = 0; index < rewardList.length; index++) {
            const element = rewardList[index];
            switch (element.type) {
                case 'xmono': totalXmono += element.amount; break;
                case 'musd': totalUsd += element.amount; break;
                case 'ticket': totalTicket += Number(element.amount); break;
                case 'monopass': totalMonopass += element.amount; break;
            }
        }

        return {
            totalTicket,
            totalUsd,
            totalXmono,
            totalMonopass
        }
    }, [rewardList])
    useEffect(() => {
        if (isBuySuccess) {
            setTimeout(() => {
                setIsShowOpening(false)
            }, 2400)
        }

    }, [isBuySuccess])

    useEffect(() => {
        if (window.screen.height <= 600) setMaxHeight('250px')
    }, [])

    return <>

        {isBuying || isShowOpening ?
            <ChestOpenAnimationWrapper>
                <Title fontSize="20px" style={{ marginBottom: 0 }}>
                    Opening {amountChest > 1 ? amountChest : ''} {getNameChest(chestType)} Chest{amountChest > 1 ? 's' : ''}...
                </Title>
                {
                    isBuySuccess ? <Img src={`https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/animation/test2/${chestType}-Opened.gif`} /> : <Img src={`https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/animation/test2/${chestType}-Spinning.gif`} />
                }

            </ChestOpenAnimationWrapper>
            :
            <OpenChestWrapper>
                <Title>
                    Wow! Congratulations!
                </Title>
                {
                    amountChest == 1 && <>
                        <div style={{ position: 'absolute', top: '0', right: '0', padding: '6px' }}>
                            <svg style={{ cursor: 'pointer', width: '24px', height: '24px' }} onClick={onClose} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.6">
                                    <path d="M7.05025 7.35038L16.9497 17.2499M16.9497 7.35038L7.05025 17.2499" stroke="#EFEFE4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                            </svg>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '56px' }}>
                            <img style={{
                                maxWidth: '220px',
                                maxHeight: '288px',
                            }} src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/opened-reward.png" alt="monoswap" />
                        </div>
                    </>
                }


                <div style={{ position: 'relative', width: '100%' }}>
                    <YouReveivedRwBox>
                        <YouReveivedRwBoxContent>
                            <img style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 2 }} src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/you-received-bg.png" />
                            <span>You’ve received</span>
                        </YouReveivedRwBoxContent>
                    </YouReveivedRwBox>

                    <RewardBoxBorder>
                        <RewardBoxBorderContent style={{ maxHeight, overflow: 'auto' }}>
                            {rewardList.map((i, index) => <RewardItem type={i.type} amount={i.amount} key={index} />)}
                        </RewardBoxBorderContent>
                    </RewardBoxBorder>
                </div>

                <div style={{
                    margin: '10px auto',
                    marginBottom: 0,
                    maxWidth: '95%',
                }}>
                    {
                        amountChest > 1 && <>
                            <p className="total_rw">Total Rewards</p>
                            <RewardInfoList style={{ marginBottom: '10px' }}>
                                <RewawardInfoItem>
                                    <div className='box'>
                                        <span className='label'>MUSD</span>
                                        <span className='content'>{formatXpNumber(rewardCnt.totalUsd)}</span>
                                    </div>

                                    <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/rw_icon/musd.png" alt="monoswap" />
                                </RewawardInfoItem>

                                <RewawardInfoItem>
                                    <div className='box'>
                                        <span className='label'>MonoPot Tickets</span>
                                        <span className='content'>{rewardCnt.totalTicket}</span>
                                    </div>

                                    <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/rw_icon/ticket.png" alt="monoswap" />
                                </RewawardInfoItem>

                                <RewawardInfoItem>
                                    <div className='box'>
                                        <span className='label'>Mono Pass</span>
                                        <span className='content'>{rewardCnt.totalMonopass}</span>
                                    </div>

                                    <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/rw_icon/monopass.png" alt="monoswap" />
                                </RewawardInfoItem>

                                <RewawardInfoItem>
                                    <div className='box'>
                                        <span className='label'>xMONO</span>
                                        <span className='content'>{formatXpNumber(rewardCnt.totalXmono)}</span>
                                    </div>

                                    <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/rw_icon/xmono.png" alt="monoswap" />
                                </RewawardInfoItem>
                            </RewardInfoList>
                        </>
                    }

                    <NotchedButtonBolderNew w="100%" h="100%" borderColor="linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%)" >
                        <span
                            style={{ display: 'flex', width: '100%', height: '48px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                            onClick={() => { onClose(); setIsShowOpening(true) }}>
                            DONE
                        </span>
                    </NotchedButtonBolderNew>
                </div>
            </OpenChestWrapper>
        }
    </>
}


const RewardItemBox = styled.div`
    width: 100%; 
    height: 56px;
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: rgba(239, 239, 228, 0.05);
    img{
        width: 32px;
        height: 32px;
    }
    span{
        color: #EFEFE4;
        font-family: Urbanist;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 125%; /* 20px */
        min-width: 80px;
    }
`
function RewardItem({ type, amount }: { type: 'xmono' | 'musd' | 'ticket' | 'monopass', amount: number }) {

    return <RewardItemBox>
        {
            type == 'musd' &&
            <>
                <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/musd.svg" />
                <span>
                    {amount} USD
                </span>
            </>
        }
        {
            type == 'xmono' &&
            <>
                <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/xmono.png" />
                <span>
                    {amount} xMONO
                </span>
            </>
        }

        {
            type == 'ticket' &&
            <>
                <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/ticket.png" />
                <span>
                    {amount}x MonoPot Ticket
                </span>
            </>
        }

        {
            type == 'monopass' &&
            <>
                <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/monopass.png" />
                <span>
                    {amount} MonoPass
                </span>
            </>
        }
    </RewardItemBox>
}