import styled from 'styled-components'
import React, { useEffect, useState } from 'react'

import ChestBox from './ChestBox'
import { BREAKPOINTS, ExternalLink } from 'theme'
import bgBox from '../../../assets/images/monochest/bgBox2.png'
import xmono from '../../../assets/images/monochest/xmono.png'
import musd from '../../../assets/images/monochest/musd.png'
import monopass from '../../../assets/images/monochest/monopass.png'
import monoppot from '../../../assets/images/monochest/monopot.png'
import insideleft from '../../../assets/images/monochest/inside-left.png'
import insideright from '../../../assets/images/monochest/inside-right.png'
import { useTokenContract, useContract, useCustomeContract } from 'hooks/useContract'
import {
  MUSD_ADDRESSES_MAP,
  USDB_ADDRESSES_MAP,
  BLAST_MUSD_ADDRESSES,
  BLAST_USDB_ADDRESSES,
  MONO_CHEST_ADDRESSES_MAP,
  MONO_CHEST_NFT_ADDRESSES_MAP,
} from '../../../constants/addresses'
import musd_abi from '../../../abis/musd/musd.json'
import usdb_abi from '../../../abis/musd/usdb.json'
import monochest_abi from '../../../abis/monochest/monochest.json'
import monoNft_abi from '../../../abis/monochest/mononft.json'

import { ethers } from 'ethers'
import axios from 'axios'
import { useWeb3React } from '@web3-react/core'
import { SupportedChainId } from '../../../constants/chains'
import { CloseIcon, CustomLightSpinner } from '../../../theme'
import ChestHistoryTable from './ChestHistoryTable'
import { getContract, shortenAddress } from 'utils'
import { RPC_URLS } from 'constants/networks'
import { BASE_API } from 'pages/Xp'
import shuffleArray from 'utils/shuffleArray'
import useMusd from 'hooks/useMusd'
import { formatXpNumber } from 'pages/Xp/sections/XpTitle'
import { Link } from 'react-router-dom'
import checkUnsupportChainId from 'utils/checkUnsupportChainId'

const Circle = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/blue-loader.svg'

const ChestPageSectionWrapper = styled.section`
  width: 100%;
  height: 100%;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
`
const ChestPageSectionWrapper2 = styled.section`
  width: 100%;
  height: 100%;
  background: #0a090f url(${bgBox}) no-repeat center center;
  background-size: cover;
  margin-top: 40px;
  padding-bottom: 80px;
`

const InfoBox = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  margin: 40px auto 80px auto;
  /* padding: 0 10px; */
  padding-top: 80px;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    width: 90%;
    margin: 40px auto;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    display: flex;
    flex-direction: column;
  }
`
const TitleBox = styled.div`
  display: grid;
  max-width: 600px;
  margin: auto;
  gap: 16px;
  justify-content: center;
  align-content: center;
`
const Title = styled.h1`
  color: #efefe4;
  text-align: center;
  font-family: Righteous;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 60px */
  margin: 0;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    justify-content: center;
    font-size: 32px;
    line-height: 40px;
  }
`
const SubTitle = styled.h4`
  color: #efefe4;
  text-align: center;

  /* Urbanist/Body-2/Semi */
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 25.2px */
  margin: 0;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin: auto;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
`
export const ChestPageLinearText = styled.span`
  background: linear-gradient(90deg, #68ffff 0%, #68ff9b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const ChestInfoBoxBorder = styled.div<{
  clipPath?: string
  w?: string
  maxW?: string
  h?: string
}>`
  max-width: 1200px;
  width: 100%;
  height: fit-content;
  position: relative; 
  margin-top: 56px;
  z-index: 1;
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    max-width: 90%;
    margin: auto;
    margin-top: 40px;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    background: #efefe480;
    padding: 1.2px;
    clip-path: polygon(27.3px 0, 100% 0, 100% calc(100% - 27.3px), calc(100% - 27.3px) 100%, 0 100%, 0 27.3px); 
  }
`

const ChestInfoBoxBorderContent = styled.div<{
  clipPath?: string
}>`
  width: 100%;
  height: 100%;
  background: #000000;
  margin: auto;
  position: relative;
  display: flex;
  z-index: 12;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    clip-path: polygon(27.3px 0, 100% 0, 100% calc(100% - 27.3px), calc(100% - 27.3px) 100%, 0 100%, 0 27.3px); 
    img{
      display: none;
    }
  }
`
const ChestInfoStatsBox = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  position: relative;
  z-index: 12;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    gap: 10px;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 20px 10px;
    flex-wrap: wrap;
  }
`
const ChestInfoStatsItem = styled.div`
  width: 100%;
  min-width: 140px;
  display: grid;
  gap: 8px;
  justify-content: center;
  align-items: center;
  p{
    margin: 0;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: calc(40% - 2px);
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    min-width: unset;
    /* width: 130px; */
  }
`

const ChestInfoStatsSeparator = styled.div<{
  isShowOnMobile?: boolean
}>`
  width: 1px;
  height: 100px;
  background: #efefe480;
  backdrop-filter: blur(4.800000190734863px);
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    display: ${({ isShowOnMobile }) => (isShowOnMobile ? 'block' : 'none')};
  }
`

const LabelText = styled.span`
  color: rgba(239, 239, 228, 0.5);
  text-align: center;
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%; /* 17.5px */
  opacity: 0.5;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
  }
`

const ContentText = styled.span`
  color: #fff;
  text-align: center;
  font-family: Righteous;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 24px */
  text-transform: uppercase;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 18px;
    line-height: 22.5px;
  }
`

const ChestBoxWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  margin-top: 120px;
  background: black;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 56px;
    /* display: grid; */
    /* justify-items: center; */
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    padding: 0 10px;
  }
`

const IntroduceBox = styled.div`
  width: 100%; 
  display: grid;
  gap: 56px;
  align-content: center;
  background: black;
  @media screen and (max-width: ${BREAKPOINTS.lg}px){
    gap: 24px;
  }
`
const IntroduceList = styled.div`
  width: 1016px;
  margin: auto;
  position: relative;
  display: grid;
  gap: 24px;
  justify-content: center;
  padding: 36px 40px;
  @media screen and (max-width: ${BREAKPOINTS.lg}px){
    width: fit-content;
    padding: 24px;
    .bg-img{
      display: none;
    }
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    justify-items: center;
  }
`

const IntroduceItemBox = styled.div`
  position: relative;
  width: 100%;
  @media screen and (max-width: ${BREAKPOINTS.lg}px){
    max-width: 350px;
  }
`

const IntroduceTitle = styled.div`
    color: #E5E5DF;
    font-family: Urbanist;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 40px */  
`

const IntroduceItemList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
`
const IntroduceItem = styled.div`
  width: 100%;
  max-width: 440px;
  display: flex;
  align-items: center;
  gap: 16px; 
`
const IntroduceSubTitle = styled.div`
  color: #e5e5df;
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
`

const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
const InsideBox = styled.div`
  height: 296px;
  width: 1028px;
  margin: 40px auto;
  position: relative;
  @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
    height: 296px;
    width: 850px;
  }
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    width: 350px;
    height: fit-content;
  }
`
const InsideCard = styled.div`
  height: 192px;
  width: 1028px;
  margin-top: 40px;
  background-color: #0a090f;
  position: relative;
  padding: 15px 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
    height: 210px;
    width: 850px;
  }
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    height: fit-content;
    width: 350px;
    grid-template-columns: repeat(1, 1fr);
    margin: 0px auto;
    gap: 30px;
  }
`
const InsideImgLeft = styled.div`
  position: absolute;
  top: -70px;
  left: 70px;
  z-index: 2;
  width: 220px;
  height: 296px;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    height: 40px;
    width: 40px;
    top: 20%;
    left: 85%;
    transform: translate(-50%, -50%);
  }
`
const InsideImgRight = styled.div`
  position: absolute;
  top: -70px;
  right: 70px;
  z-index: 2;
  width: 220px;
  height: 296px;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    height: 40px;
    width: 40px;
    top: 20%;
    right: 5%;
    transform: translate(-50%, -50%);
  }
`
const InsideInfo = styled.div``
const InsideCardTop = styled.div`
  height: 40px;
  width: 100%;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`
const CardInsideIconRight = styled.div`
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 0;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`
const CardInsideIconLeft = styled.div`
  position: absolute;
  top: -15px;
  left: -15px;
  z-index: 0;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`
const CardTitle = styled.p`
  font-size: 28px;
  color: #fff;
  margin: 10px 0px;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 18px;
  }
`
const CardSubTitle = styled.p`
  font-size: 18px;
  font-family: Urbanist;
  color: rgba(239, 239, 228, 0.5);
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 14px;
  }
`
const CardTextDetail = styled.span`
  text-decoration-line: underline;

  background: linear-gradient(90deg, #68ffff 0%, #68ff9b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 14px;
  }
`
const TextEarned = styled.p`
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  p {
    margin: 3px 0px;
  }
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 16px;
  }
  .pass-buy-box-wrapper{
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
      display: grid;
      gap: 3px;
    }
  }
  .pass-buy-box{
    min-height: 25px;
    display: flex;
    align-items: center;
    gap: 8px;
    img{
      display: block;
      width: 20px;
      height: 20px;
      &:hover{
        scale: 1.01;
        transition: ease-in-out;
        transition-delay: 20ms;
      }
    }
  }
`
const TextEarned2 = styled.p`
  font-size: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  line-height: 125%;
  p {
    margin: 3px 0px;
  }
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 16px;
  }
`

const OpenHistoryMarqueeBox = styled.div<{
  maxWMobile?: string;
}>`
  max-width: 1200px;
  margin: 10px auto;
  display: flex;
  width: 100%;
  height: 42px;
  align-items: center;
  gap: 16px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0.06) 51.01%, rgba(255, 255, 255, 0.02) 100%);
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    width: 90%;
  }
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    max-width: ${({ maxWMobile }) => maxWMobile ?? '100%'};
  }
`
const OpenHistoryMarqueeList = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`
const OpenHistoryMarqueeItem = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  gap: 16px;
  span{
    font-family: Urbanist;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }
`

export type ChestType = {
  level: number
  usdbPrice: number
  nftId: number
  chestName: string
  openFee: number
  earnedReward?: {
    usd: number,
    xmono: number,
    monopass: number
  }
  rwInfo: {
    ticket: string;
    monopass: number;
    xmono: string;
    musd: string
  }
}
const INSIDE_INFO = [
  {
    imagePositionLeft: true,
    imagePositionRight: false,
    image: xmono,
    title: 'xMONO',
    subtitle: 'Earn real yield weekly by simply staking xMONO',
    link: 'https://docs.monoswap.io/tokenomics/xmono',
  },
  {
    imagePositionLeft: false,
    imagePositionRight: true,
    image: musd,
    title: 'MUSD',
    subtitle: 'Next time you get MUSD, its price will definitely be higher',
    link: 'https://monoswap.io/#/musd',
  },
  {
    imagePositionLeft: true,
    imagePositionRight: false,
    image: monopass,
    title: 'MonoPass',
    subtitle: 'Your key to unlocking a world of exclusive privileges and secret perks, soon to be revealed.',
    link: 'https://monoswap.io/#/monopass',
  },
  {
    imagePositionLeft: false,
    imagePositionRight: true,
    image: monoppot,
    title: 'MonoPot Matching',
    subtitle:
      'In the mood for a game of luck? MonoPot is here to turn your anticipation into thrilling wins with every ticket!',
    link: 'https://www.monoswap.io/#/lottery',
  },
]

const getCalcMarqueeItemWith = (type: string, amountLength: number) => {
  if (type == 'usd') {
    if (amountLength == 1) return '185px'
    else if (amountLength == 2) return '195px'
    else if (amountLength == 3) return '200px'
    else if (amountLength == 4) return '205px'
    else if (amountLength == 5) return '210px'
    else if (amountLength == 6) return '215px'
    else if (amountLength == 7) return '220px'
    else if (amountLength == 8) return '225px'
    else return '235px'
  }
  else if (type == 'xmono') {
    if (amountLength == 1) return '200px'
    else if (amountLength == 2) return '208px'
    else if (amountLength == 3) return '216px'
    else if (amountLength == 4) return '224px'
    else if (amountLength == 5) return '232px'
    else if (amountLength == 6) return '240px'
    else if (amountLength == 7) return '248px'
    else if (amountLength == 8) return '256px'
    else return '262px'
  }
  else if (type == 'monopass') {
    if (amountLength == 1) return '210px'
    else if (amountLength == 2) return '216px'
    else if (amountLength == 3) return '226px'
    else if (amountLength == 4) return '236px'
    else if (amountLength == 5) return '246px'
    else if (amountLength == 6) return '256px'
    else return '266px'
  }
  else if (type == 'ticket') {
    if (amountLength == 1) return '197px'
    else if (amountLength == 2) return '202px'
    else if (amountLength == 3) return '212px'
    else if (amountLength == 4) return '217px'
    else if (amountLength == 5) return '222px'
    else if (amountLength == 6) return '227px'
    else if (amountLength == 7) return '232px'
    else if (amountLength == 8) return '237px'
    else return '250px'
  }
  return 0
}
export default function ChestPageSection1() {
  // state
  const { account, chainId } = useWeb3React()

  const [screenW, setScreenW] = useState<string>();

  const [chestBoxes, setChestBoxes] = useState<ChestType[]>([
    {
      level: 1,
      usdbPrice: 1,
      chestName: 'Common Chest',
      nftId: 2,
      openFee: ethers.utils.parseEther('0.000025').toNumber(),
      rwInfo: {
        ticket: 'x1',
        monopass: 1,
        xmono: '0.1 - 0.2',
        musd: '$0.1 - $10'
      }
    },
    {
      level: 2,
      usdbPrice: 2,
      chestName: 'Uncommon Chest',
      nftId: 3,
      openFee: ethers.utils.parseEther('0.00005').toNumber(),
      rwInfo: {
        ticket: 'x2',
        monopass: 1,
        xmono: '0.1 - 0.5',
        musd: '$0.1 - $100'
      }
    },
    {
      level: 3,
      usdbPrice: 3,
      chestName: 'Rare Chest',
      nftId: 4,
      openFee: ethers.utils.parseEther('0.000075').toNumber(),
      rwInfo: {
        ticket: 'x3',
        monopass: 1,
        xmono: '0.1 - 1',
        musd: '$0.1 - $200'
      }
    },
    {
      level: 4,
      usdbPrice: 4,
      chestName: 'Epic Chest',
      nftId: 5,
      openFee: ethers.utils.parseEther('0.0001').toNumber(),
      rwInfo: {
        ticket: 'x4',
        monopass: 1,
        xmono: '0.1 - 1',
        musd: '$0.1 - $400'
      }
    },
    {
      level: 5,
      usdbPrice: 5,
      chestName: 'Mythical Chest',
      nftId: 6,
      openFee: ethers.utils.parseEther('0.00012').toNumber(),
      rwInfo: {
        ticket: 'x5',
        monopass: 1,
        xmono: '0.1 - 1',
        musd: '$0.1 - $600'
      }
    },
  ])
  const [loadingMusdPrice, setLoadingMusdPrice] = useState(false)
  const [loadingUsdbPrice, setLoadingUsdbPrice] = useState(false)
  const { musdPrice, usdbPrice } = useMusd()
  const [loadingGetContractInfo, setLoadingGetContractInfo] = useState(false)
  const [totalRewardEarned, setTotalRewardEarned] = useState({
    totalXMono: '0',
    totalUSD: '0',
    totalMonoPass: '0',
  })
  const [openHistoryList, setOpenHistoryList] = useState<any[]>([])
  const [totalOpennedAmount, setTotalOpennedAmount] = useState(0)
  const [totalUserOpennedAmount, setTotalUserOpennedAmount] = useState(0)
  const [totalParticipant, setTotalParticipant] = useState(0)
  const [userChestOwnByType, setUserChestOwnByType] = useState<any>([])

  const isSupported = chainId === SupportedChainId.BLAST

  const rpcProvider = new ethers.providers.JsonRpcProvider(RPC_URLS[SupportedChainId.BLAST][0])
  const chestContract = getContract(MONO_CHEST_ADDRESSES_MAP[SupportedChainId.BLAST], monochest_abi, rpcProvider)
  const [totalUserNftBalance, setTotalUserNftBalance] = useState<number>(0)
  const monoNftContract = useCustomeContract(MONO_CHEST_NFT_ADDRESSES_MAP[SupportedChainId.BLAST], monoNft_abi, true)

  // get contract info
  const getContractInfo = async () => {
    setLoadingGetContractInfo(true)
    try {
      axios.get(`${BASE_API}/monoswap/api/v1/monochest/get-chest-info?address=${String(account ?? '').toLowerCase()}`)
        .then(({ data }) => {
          setTotalOpennedAmount(data.totalOpened)
          setTotalParticipant(data.totalOpener)
          setTotalRewardEarned({
            totalMonoPass: data?.earned?.monoPass,
            totalUSD: data?.earned?.usd,
            totalXMono: data?.earned?.xMono
          })
          setTotalUserOpennedAmount(data?.earned?.totalChest)
        })
      setLoadingGetContractInfo(false)
    } catch (error) {
      console.log('error', error)
      setLoadingGetContractInfo(false)
    }
  }
  const getChestOpenFee = async () => {
    try {
      const feeOpenChest1 = await chestContract?.txFees(0)
      const feeOpenChest2 = await chestContract?.txFees(1)
      const feeOpenChest3 = await chestContract?.txFees(2)
      const feeOpenChest4 = await chestContract?.txFees(3)
      const feeOpenChest5 = await chestContract?.txFees(4)

      setChestBoxes(prev => {
        for (let index = 0; index < prev.length; index++) {
          const element = prev[index];
          if (element.level == 1)
            element.openFee = feeOpenChest1.toNumber()
          if (element.level == 2)
            element.openFee = feeOpenChest2.toNumber()
          if (element.level == 3)
            element.openFee = feeOpenChest3.toNumber()
          if (element.level == 4)
            element.openFee = feeOpenChest4.toNumber()
          if (element.level == 5)
            element.openFee = feeOpenChest5.toNumber()
        }
        return prev;
      })
    }
    catch (err) { }
  }

  const loadTotalUserNftBalance = async () => {
    if (!account) return
    try {
      let totalNftBalance = 0
      for (let index = 0; index < chestBoxes.length; index++) {
        const element = chestBoxes[index];
        const userNftBalance = await monoNftContract?.balanceOf(account, element.nftId)
        const formattedUserBalance = parseInt(userNftBalance._hex, 16)
        totalNftBalance += formattedUserBalance
      }
      setTotalUserNftBalance(totalNftBalance)
    }
    catch (err) { }
  }

  const [loaddingApproveNftContract, setLoadingApproveNftContract] = useState(false)
  const [checkApproveNftContract, setCheckApproveNftContract] = useState(false)

  // approve NFT
  const approveContractNft = async () => {
    checkUnsupportChainId(chainId)
    setLoadingApproveNftContract(true)
    try {
      const setApproveContract = await monoNftContract?.setApprovalForAll(MONO_CHEST_ADDRESSES_MAP[SupportedChainId.BLAST], true)
      await setApproveContract.wait()

      setLoadingApproveNftContract(false)
      handleCheckApproveNftContract()

      return true
    } catch (error) {
      console.log(error)
      setLoadingApproveNftContract(false)
      handleCheckApproveNftContract()

      return false
    }
  }

  const handleCheckApproveNftContract = async () => {
    try {
      const isApproved = await monoNftContract?.isApprovedForAll(account, MONO_CHEST_ADDRESSES_MAP[SupportedChainId.BLAST])
      if (isApproved) {
        setCheckApproveNftContract(true)
      } else {
        setCheckApproveNftContract(false)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleApproveNftContract = async () => {
    await approveContractNft()
    await handleCheckApproveNftContract()
  }

  useEffect(() => {
    getContractInfo()
    // getChestOpenFee()
    if (account) {
      handleCheckApproveNftContract()

      axios.get(`${BASE_API}/monoswap/api/v1/monochest/get-earned-reward?address=` + account.toLowerCase())
        .then(({ data }) => {
          setChestBoxes(prev => {
            if (data.chest0) prev[0].earnedReward = data.chest0
            if (data.chest1) prev[1].earnedReward = data.chest1
            if (data.chest2) prev[2].earnedReward = data.chest2
            if (data.chest3) prev[3].earnedReward = data.chest3
            if (data.chest4) prev[4].earnedReward = data.chest4
            return prev
          })
        })
    }
  }, [account, chainId])


  useEffect(() => {
    setScreenW(window.screen.width.toString())
    axios.get(`${BASE_API}/monoswap/api/v1/monochest/get-history?page=0&per_page=10`)
      .then(({ data }) => {
        const arr: any[] = []
        for (let index = 0; index < data.data.length; index++) {
          const { xmono, usd, ticketCnt, totalMonopass, address } = data.data[index]
          try {
            const userAddress = shortenAddress(address, 4)
            if (xmono > 0)
              arr.push({
                address: userAddress,
                amount: xmono,
                type: 'xMONO',
                width: getCalcMarqueeItemWith('xmono', String(xmono).length)
              })
            if (usd > 0)
              arr.push({
                address: userAddress,
                amount: usd,
                type: 'USD',
                width: getCalcMarqueeItemWith('usd', String(usd).length)
              })
            arr.push({
              address: userAddress,
              amount: ticketCnt,
              type: 'Ticket' + (ticketCnt > 1 ? 's' : ''),
              width: getCalcMarqueeItemWith('ticket', String(ticketCnt).length)
            })
            if (totalMonopass > 0)
              arr.push({
                address: userAddress,
                amount: totalMonopass,
                type: 'MonoPass',
                width: getCalcMarqueeItemWith('monopass', String(totalMonopass).length)
              })
          }
          catch (err) { }
        }
        setOpenHistoryList(shuffleArray(arr))
      })
  }, [])

  return (
    <>
      <ChestPageSectionWrapper>
        <InfoBox>
          <TitleBox>
            <Title>
              Unlock <ChestPageLinearText>MonoChest</ChestPageLinearText>
              <br />
              Unveil your Fortune
            </Title>
            <SubTitle>
              Open your Chest, take a deep breath, <br /> & wait for your luck to strike.
            </SubTitle>
          </TitleBox>

          <ChestInfoBoxBorder >
            <ChestInfoBoxBorderContent>
              <img style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 9 }} src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/chest-info-border.png' />
              <ChestInfoStatsBox>
                <ChestInfoStatsItem>
                  <LabelText>Total Opened</LabelText>
                  <TextEarned2>
                    {loadingGetContractInfo ? (
                      <CustomLightSpinner src={Circle} alt="loader" size="10px" />
                    ) : Number(totalOpennedAmount) > 1000 ? (
                      numberWithCommas(totalOpennedAmount)
                    ) : (
                      totalOpennedAmount
                    )}
                  </TextEarned2>
                </ChestInfoStatsItem>
                <ChestInfoStatsSeparator isShowOnMobile={true} />

                <ChestInfoStatsItem>
                  <LabelText>Total Opener</LabelText>
                  <TextEarned2>
                    {loadingGetContractInfo ? (
                      <CustomLightSpinner src={Circle} alt="loader" size="10px" />
                    ) : totalParticipant}
                  </TextEarned2>
                </ChestInfoStatsItem>

                <ChestInfoStatsSeparator isShowOnMobile={false} />

                {/* <ChestInfoStatsItem>
                  <LabelText>Total Opener</LabelText>
                  <TextEarned2>{}</TextEarned2>
                </ChestInfoStatsItem> */}

                {/* <ChestInfoStatsSeparator /> */}

                <ChestInfoStatsItem>
                  <LabelText>Total Earned</LabelText>
                  <TextEarned>
                    <p >
                      xMono:{' '}
                      {loadingGetContractInfo ? (
                        <CustomLightSpinner src={Circle} alt="loader" size="10px" />
                      ) : formatXpNumber(totalRewardEarned?.totalXMono)}
                    </p>
                    <p>
                      {' '}
                      USD: $
                      {loadingGetContractInfo ? (
                        <CustomLightSpinner src={Circle} alt="loader" size="10px" />
                      ) : formatXpNumber(totalRewardEarned?.totalUSD)}
                    </p>

                    <p className='pass-buy-box-wrapper' style={{ cursor: 'pointer' }}>
                      MonoPass:{' '}
                      {loadingGetContractInfo ? (
                        <CustomLightSpinner src={Circle} alt="loader" size="10px" />
                      ) : formatXpNumber(totalRewardEarned?.totalMonoPass)}

                      <div className='pass-buy-box'>
                        <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/sales/opensea.png" alt="monoswap" onClick={() => window.open('https://opensea.io/collection/mono-pass', '_blank')} />
                        <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/sales/element.png" alt="monoswap" onClick={() => window.open('https://element.market/collections/mono-pass', '_blank')} />
                      </div>
                    </p>

                  </TextEarned>
                </ChestInfoStatsItem>

                <ChestInfoStatsSeparator isShowOnMobile={true} />

                <ChestInfoStatsItem>
                  <LabelText>You Opened</LabelText>
                  <TextEarned2>
                    {loadingGetContractInfo ? (
                      <CustomLightSpinner src={Circle} alt="loader" size="10px" />
                    ) : totalUserOpennedAmount}
                  </TextEarned2>
                </ChestInfoStatsItem>
              </ChestInfoStatsBox>
            </ChestInfoBoxBorderContent>
          </ChestInfoBoxBorder>

          {
            openHistoryList.length > 0 && <>
              {
                screenW && <OpenHistoryMarqueeBox className='marquee-container' maxWMobile={`${screenW}px`}>
                  <OpenHistoryMarqueeList className='marquee-content'>
                    {
                      openHistoryList.map((i, index) => <OpenHistoryMarqueeItem key={index} style={{ width: i.width }}>
                        <span>{i.address} just won <ChestPageLinearText>{i.amount} {i.type}</ChestPageLinearText></span>
                        <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="4.24219" y="0.757324" width="6" height="6" transform="rotate(45 4.24219 0.757324)" fill="#EFEFE4" fillOpacity="0.5" />
                        </svg>
                      </OpenHistoryMarqueeItem>)
                    }
                  </OpenHistoryMarqueeList>
                  <OpenHistoryMarqueeList className='marquee-content'>
                    {
                      openHistoryList.map((i, index) => <OpenHistoryMarqueeItem key={index} style={{ width: i.width }}>
                        <span>{i.address} just won <ChestPageLinearText>{i.amount} {i.type}</ChestPageLinearText></span>
                        <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="4.24219" y="0.757324" width="6" height="6" transform="rotate(45 4.24219 0.757324)" fill="#EFEFE4" fillOpacity="0.5" />
                        </svg>
                      </OpenHistoryMarqueeItem>)
                    }
                  </OpenHistoryMarqueeList>
                </OpenHistoryMarqueeBox>
              }
            </>
          }

        </InfoBox>

        <ChestBoxWrapper>
          {chestBoxes?.map((item: ChestType, index: number) => (
            <ChestBox
              rpcProvider={rpcProvider}
              data={item}
              key={index}
              musdPrice={musdPrice}
              userChestOwnByType={userChestOwnByType[index]}
              account={account}
              getContractInfo={getContractInfo}
              loadingGetContractInfo={loadingGetContractInfo}
              loaddingApproveNftContract={loaddingApproveNftContract}
              checkApproveNftContract={checkApproveNftContract}
              handleApproveNftContract={handleApproveNftContract}
              loadTotalUserNftBalance={loadTotalUserNftBalance}
            />
          ))}
        </ChestBoxWrapper>

        <div style={{ margin: '100px auto', background: 'black' }}>
          <img
            style={{ margin: 'auto', display: 'block', width: '100%', maxWidth: '267px' }}
            src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/hr-icon.png"
          />
        </div>

        {
          account && <>
            <ChestHistoryTable />

            <div style={{ margin: '100px auto', background: 'black' }}>
              <img
                style={{ margin: 'auto', display: 'block', width: '100%', maxWidth: '267px' }}
                src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/hr-icon.png"
              />
            </div>
          </>
        }

        <IntroduceBox>
          <TitleBox>
            <Title>
              What is  <ChestPageLinearText>MonoChest</ChestPageLinearText>?
            </Title>
          </TitleBox>

          <IntroduceList>
            <Img className='bg-img' src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/introduce-item-bg.png' />
            <IntroduceTitle style={{ textAlign: 'center' }}>
              A brief intro
            </IntroduceTitle>

            <IntroduceItemBox>
              <IntroduceItemList>
                <IntroduceItem>
                  <img style={{ width: '24px', height: '24px' }} src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/introduce-icon.png' />
                  <IntroduceSubTitle>
                    Everybody's Treasure Trove: xMONO, MUSD,
                    MonoPass, & MonoPot matching opportunities.
                  </IntroduceSubTitle>
                </IntroduceItem>

                <IntroduceItem>
                  <img style={{ width: '24px', height: '24px' }} src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/introduce-icon.png' />
                  <IntroduceSubTitle>
                    With five rarity tiers, from Common to Mythical, MonoChest offers a range of rewards to suit every player.
                  </IntroduceSubTitle>
                </IntroduceItem>

                <IntroduceItem>
                  <img style={{ width: '24px', height: '24px' }} src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/introduce-icon.png' />
                  <IntroduceSubTitle>
                    Unleash your chest-cracking skills without daily restrictions! While reward limits keep things secure, expect them to rise as we conquer exploits.
                  </IntroduceSubTitle>
                </IntroduceItem>

                <IntroduceItem>
                  <img style={{ width: '24px', height: '24px' }} src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/introduce-icon.png' />
                  <IntroduceSubTitle>
                    Engage with a vibrant community as you collect MonoChests and compete for weekly MonoPot spins, fostering a sense of camaraderie and excitement.
                  </IntroduceSubTitle>
                </IntroduceItem>
              </IntroduceItemList>
            </IntroduceItemBox>


          </IntroduceList>
        </IntroduceBox>

      </ChestPageSectionWrapper>

      <ChestPageSectionWrapper2>
        <InfoBox>
          <TitleBox>
            <Title>
              What’s <ChestPageLinearText>inside</ChestPageLinearText> ?
            </Title>
            <SubTitle>
              Prepare for the thrill as each chest holds a mystery reward waiting to be unveiled! Dive in, hold your
              breath, and let luck reveal its secrets!
            </SubTitle>
          </TitleBox>
        </InfoBox>
        {INSIDE_INFO.map((item, index) => (
          <div key={index}>
            <InsideBox>
              <InsideCard>
                {item.imagePositionLeft && (
                  <InsideImgLeft>
                    <img style={{ width: '100%', height: '100%' }} src={item.image} />
                  </InsideImgLeft>
                )}

                {item.imagePositionLeft && (
                  <>
                    <InsideCardTop></InsideCardTop>
                    <InsideInfo>
                      <CardTitle>{item.title}</CardTitle>
                      <CardSubTitle>{item.subtitle}</CardSubTitle>
                      <ExternalLink href={item.link} target='_blank'>
                        <CardTextDetail>DETAILS</CardTextDetail>
                      </ExternalLink>
                    </InsideInfo>
                  </>
                )}
                {item.imagePositionRight && (
                  <>
                    <InsideInfo>
                      <CardTitle>{item.title}</CardTitle>
                      <CardSubTitle>{item.subtitle}</CardSubTitle>
                      <ExternalLink href={item.link} target='_blank'>
                        <CardTextDetail>DETAILS</CardTextDetail>
                      </ExternalLink>
                    </InsideInfo>
                    <InsideCardTop></InsideCardTop>
                  </>
                )}
                {item.imagePositionRight && (
                  <InsideImgRight>
                    <img style={{ width: '100%', height: '100%' }} src={item.image} />
                  </InsideImgRight>
                )}
                {item.imagePositionLeft ? (
                  <CardInsideIconRight>
                    <img style={{ width: '100%', height: '100%' }} src={insideright} />
                  </CardInsideIconRight>
                ) : (
                  <CardInsideIconLeft>
                    <img style={{ width: '100%', height: '100%' }} src={insideleft} />
                  </CardInsideIconLeft>
                )}
              </InsideCard>
            </InsideBox>
          </div>
        ))}
      </ChestPageSectionWrapper2>
    </>
  )
}
export const numberWithCommas = (number: any) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
