import styled from "styled-components";
import { BREAKPOINTS } from "theme";

const ContentBoxBorder = styled.div<{
    clipPath?: string;
    w?: string;
    maxW?: string;
    h?: string;
    borderColor?: string;
}>`
    max-width: ${({ maxW }) => maxW ?? '100%'} ;
    width: ${({ w }) => w ?? '100%'} ;
    height: ${({ h }) => h ?? '100%'} ;
    background: ${({ borderColor }) => borderColor ?? '#efefe480'};
    clip-path: 100%;
    padding: 1px;
    clip-path: ${({ clipPath }) => clipPath ?? 'polygon(27.3px 0, 100% 0, 100% calc(100% - 27.3px), calc(100% - 27.3px) 100%, 0 100%, 0 27.3px)'};
    position: relative;
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        margin: auto;
    }
  `;

const BoxBorderContent = styled.div<{
    clipPath?: string;
}>`
    width: 100%;
    height: 100%;
    background: #000000;
    margin: auto;
    clip-path: ${({ clipPath }) => clipPath ?? 'polygon(27.3px 0, 100% 0, 100% calc(100% - 27.3px), calc(100% - 27.3px) 100%, 0 100%, 0 27.3px)'};
    position: relative;
    display: flex;
`;

export default function ColorBorderContentBox({
    children,
    clipPath,
    w,
    maxW,
    h,
    borderColor
}: {
    children: any;
    clipPath?: string;
    w?: string;
    maxW?: string;
    h?: string;
    borderColor?: string;
}) {
    return <ContentBoxBorder clipPath={clipPath}
        w={w}
        maxW={maxW}
        h={h}
        borderColor={borderColor}>
        <BoxBorderContent clipPath={clipPath}>
            {children}
        </BoxBorderContent>
    </ContentBoxBorder>
}