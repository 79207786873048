import { NotchedButtonBolderNew } from "components/Button/NotchedButton"
import { TextLinear } from "pages/Xp/sections/XpTitle"
import { useState } from "react"
import styled from "styled-components"
import {
    CloseOutlined
} from "@ant-design/icons"
import { SupportedChainId } from "constants/chains"
import { ExplorerDataType, getExplorerLink } from "utils/getExplorerLink"
import { notification } from "antd"


const SuccessMintMonopassModalWrapper = styled.div`
    width: 700px;
    max-width: 700px;
    background: #1C1B20;
    box-shadow: 0px 12px 50px 0px #000;
    padding: 24px 32px;
    position: relative;
    overflow: auto;

    @media screen and (max-width: 900px) {
        min-width: unset;
    }
`

const Text = styled.span`
    color: #E5E5DF;
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 35px */
    span{
        font-family: Urbanist;
    }
`

export default function SuccessMintMonopassModal({ onClose, hash, id, monopassContext }: any) {
    const {
        hasApprovedMonopass, isApprovingMonopass, approveMonopass,
        isStaking, stakeMonoPass
    } = monopassContext

    const stake = async () => {
        if (isStaking.status || isApprovingMonopass) return
        console.log('stake nft id: ', id);

        if (!hasApprovedMonopass) {
            notification.warning({
                message: "You need approve NFT first before stake"
            })
            await approveMonopass()
        }
        await stakeMonoPass({ id }, () => {
            onClose()
        })
    }

    return <SuccessMintMonopassModalWrapper>
        <div style={{ position: 'absolute', top: '24px', right: '32px', cursor: 'pointer' }} onClick={onClose}>
            <CloseOutlined rev={undefined} onClick={onClose} />
        </div>
        <Text style={{ fontFamily: 'Righteous', textAlign: 'center', display: 'block' }}>
            Successful
        </Text>

        <div style={{ display: 'grid', gap: '12px', marginBottom: '10px', marginTop: '30px' }}>
            <Text style={{ fontSize: '18px', textAlign: 'center' }}>
                You’ve successfully minted <TextLinear>a MonoPass</TextLinear>
            </Text>
            <Text style={{ fontSize: '16px', textAlign: 'center', cursor: 'pointer' }} onClick={() => window.open(getExplorerLink(SupportedChainId.BLAST_SEPOLIA,
                hash,
                ExplorerDataType.TRANSACTION
            ), '_blank')}>
                View on Blastscan
            </Text>
        </div>

        <NotchedButtonBolderNew w={'100%'} h="42px" bg="#000000" borderColor="rgba(104, 255, 255, 0.80)">
            <TextLinear style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', cursor: 'pointer' }} onClick={stake}>
                {
                    isApprovingMonopass ? 'APPROVING...' : isStaking.status ? 'STAKING...' : 'STAKE NOW'
                }
            </TextLinear>
        </NotchedButtonBolderNew>

        <Text style={{ fontSize: '11px', display: 'block', marginTop: '10px' }}>
            * Your pass will be locked for 10   days following the stake
        </Text>
    </SuccessMintMonopassModalWrapper>
}