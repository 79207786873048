import { useWeb3React } from "@web3-react/core";
import { notification } from "antd";
import { NotchedButtonFill } from "components/Button";
import { NotchedButtonBolderNew } from "components/Button/NotchedButton";
import dayjs from "dayjs";
import useCountdownTimer from "hooks/useCountdownTimer";
import useEthPrice from "hooks/useEthPrice";
import { DepositedMonopassType } from "hooks/useMonoPass";
import { formatXpNumber } from "pages/Xp/sections/XpTitle";
import { useCallback, useMemo, useState } from "react";
import { useToggleWalletModal } from "state/application/hooks";
import styled from "styled-components"
import { BREAKPOINTS } from "theme";
import checkUnsupportChainId from "utils/checkUnsupportChainId";


const Section3ContentWrapper = styled.section`
  margin-top: 55px;
  margin-bottom: 131px;
  max-width: 1224px;
`;


const TitleWrapper = styled.div`
  color: white; 
  display: grid;
  justify-items: center;
`;

const Title = styled.h2`
  text-align: center;
  font-family: Righteous;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px; 
  margin-bottom: 15px;
  span{
    background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 35.17%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 32px;
    line-height: 40px;
  }
  `;
const SubTitle = styled.h4`
  max-width: 370px;
  margin: auto;
  text-align: center;
  color: '#EFEFE4';
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.2px;
  margin: 0;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 14px;
    line-height: 17.5px;
  }
`;

const StatsBoxWrapper = styled.div`
  display: grid;
  gap: 20px;
  padding: 66px 100px;
  background: rgb(18, 17, 22);
  position: relative;
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    padding: 50px 59px;
  }
  @media screen and (max-width: 450px) {
    padding: 50px 25px;
  }
`;
const StatsContentRow = styled.div`
  width: 100%;
  position: relative;
  z-index: 10;
  display: flex;
  gap: 40px;
  justify-content: space-between;

  .middle-col{
    min-width: 300px;
  }
  @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
    justify-content: space-between;
    gap: 0;
  }

  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    .middle-col{
      min-width: 200px;
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    display: grid;
    justify-content: center;
    row-gap: 24px;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    display: grid;
    gap: 20px;
  }
  @media screen and (max-width: 500px) {
    justify-content: start;
  }
`;

const StatsItemBox = styled.div<{
  flexOnMobile?: boolean
}>`
  display: grid;
  gap: 40px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 500px) {
    display: ${({ flexOnMobile }) => flexOnMobile ? 'flex' : 'grid'};
    gap: 24px;
  }

`
const StatsItem = styled.div`
  display: grid;
  gap: 8px;
  width: 100%;
  max-width: 370px;
  height: fit-content;
  justify-content: start;
  img.stake-info-icon{
    width: 43px;
    height: 43px;
    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
      width: 24px;
      height: 24px;
    }
  }
  img.reward{
    scale: 1.1;
  }
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    max-width: 250px;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    gap: 8px !important;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    max-width: 100%;
  }
`;
const StatsLabel = styled.span`
  color: rgba(239, 239, 228, 0.50);
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.5px; /* 17.5px */
`;
const StatsValue = styled.span`
  color: #EFEFE4;
  font-family: Righteous;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 24px */
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 8px;
  img{
    width: 24px;
    height: 24px;
  }

  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    font-size: 18px !important;
    line-height: 20.5px !important;
  }
`;

const StatsAction = styled.span<{
  hoverColor?: boolean
}>`
  font-family: Righteous;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.5px; /* 17.5px */
  text-decoration-line: underline;
  background:  linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: 1px solid linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  width: fit-content;
  cursor: pointer;
  &:hover{
    scale: ${({ hoverColor }) => hoverColor ? 1.05 : 1}
  }
`;

const StakeActionBox = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    padding-left: 30px;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    padding-left: 0;
    display: grid;
    justify-items: center;
    gap: 24px;
  }
`


const StakeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 40px 56px;
  background: #121116;
  border: 1px solid #efefe480;
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    padding: 40px 28px;
  }
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    padding: 15px 15px;
  }
`;

const MyStakeBox = styled.div`
  display: flex;
  gap: 0;
  position: relative;
  z-index: 10;
`;
const MyStakeSeparatorBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 24px;
  z-index: 1;
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    display: none;
  }
`
const MyStakeSeparator = styled.div`
  height: 110px;
  width: 1px;
  opacity: 0.15;
  background: #EFEFE4;
`
const MyStakeBoxContentLeft = styled.div`
  width: 49.9%;
  clip-path: polygon(0% 0%, calc(100% - 28px) 0%, 100% 28px, 100% 100%, 0% 100%);
  background: #0A090F;
  padding: 33px 44px;
  @media screen and (max-width: 450px){
    padding: 33px 10px;
  }
`;
const MyStakeBoxContentRight = styled.div`
  width: 49.9%;
  clip-path: polygon(0 28px, 28px 0, 100% 0, 100% 100%, 0% 100%);
  background: #0A090F;
  padding: 33px 44px;
  display: flex;
  gap: 36px;
  padding-left: 60px;
  position: relative;
  z-index: 2;
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    padding-left: 35px;
    display: grid;
    gap: 25px;
    grid-template-columns: 50% 50%;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px){
    grid-template-columns: 100%;
    padding: 33px 22px;
    padding-left: 35px;
  }
  @media screen and (max-width: 450px){
    padding-left: 22px;
  }
`;

const StakeListBox = styled.div`
  margin-top: 12px;
  background: #0A090F;
  padding: 33px 44px;
  @media screen and (max-width: ${BREAKPOINTS.xs}px){
    padding: 33px 10px;
  }
`;
const StakeListTopRow = styled.div`
  display:  flex;
  justify-content: space-between;
  margin-bottom: 32px;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    display: grid;
    gap: 20px;
    justify-content: center;
    justify-items: center;
  }
`;

const MyNftStats = styled.div`
  display: flex;
  gap: 40px;
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    display: grid;
    gap: 20px;
    justify-items: center;
  }
`;

const NFTList = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: 23% 23% 23% 23%;
  gap: 20px;
  position: relative;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;
const NFTListLoading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  fill: rgba(10, 9, 15, 0.70);
  backdrop-filter: blur(10px);
  background: #0A090FB2;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
`;
const NFTBox = styled.div`
  display: grid;
  gap: 20px;
  width: 241px;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    width: calc(100%/3 - 20px);
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: calc(50% - 20px);
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    width: 100%;
    max-width: 300px;
  }
`;
const NFTImgContent = styled.div`
  width: 100%;
  position: relative;
`;
const NFTInfo = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 21px 24px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);;
`;

const HorizontalDivBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`
const HorizontalDiv = styled.div`
  height: 1px;
  width: 100%;
  background: #EFEFE4;
  opacity: 0.2;
  position: relative;
  z-index: 10;
`;

const BorderBox = styled.div<{
  clipPath: string
}>`
  width: 100%;
  height: 100%;
  background: #efefe480;
  clip-path: 100%;
  padding: 1px;
  clip-path: ${({ clipPath }) => clipPath};
  position: relative;
`;
const BorderBoxContent = styled.div<{
  clipPath: string
}>`
  width: 100%;
  height: 100%;
  background: black;
  clip-path: ${({ clipPath }) => clipPath};
  position: relative;
`;

const ArrowBorderIcon = styled.div`
  position: absolute;
  top: -18px;
  left: 0;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  z-index: 99;
`

const PrimaryTexy = styled.span`
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(180deg, rgb(103.96, 255, 255) 0%, rgb(103.96, 255, 155.31) 100%);
  background-clip: text;
  color: transparent;
  font-family: "Righteous";
`;

// type for variables
type StatsItemType = {
  label: string;
  content: string;
}

const WeeklyChestInfoBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  row-gap: 8px;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {}
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    display: grid;
    grid-template-columns: repeat(3, 30%);
    gap: 12px;
  }
  @media screen and (max-width: 500px) {
    display: flex;
  }
`
const WeeklyChestInfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  img{
    width: 37px;
    height: 26px;
  }
`

const TextLinear = styled.span`
  background: linear-gradient(90deg, #68FFFF, #68FF9B);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const Text = styled.span`
    color: #E5E5DF;
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 35px */
    span{
        font-family: Urbanist;
    }
`

const RemainCountdown = ({ endTime }: any) => {
  const { days, hours, minutes, seconds } = useCountdownTimer('DAY', endTime)
  return <TextLinear style={{ minWidth: '182px' }}>
    {days < 0 ? 0 : days}d : {hours < 0 ? 0 : hours}h : {minutes < 0 ? 0 : minutes}m : {seconds < 0 ? 0 : seconds}s
  </TextLinear>
}

function PassLockCountDown({ endTime }: { endTime: number }) {
  const { hours, minutes, seconds } = useCountdownTimer('HOUR', endTime)

  function checkNegativeNum(num: number) {
    return Math.sign(num) <= 0 ? 0 : num;
  }

  return <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <StatsLabel>Lock time:</StatsLabel>
    <StatsValue style={{ fontSize: '14px', color: 'white', fontStyle: 'normal', display: 'block' }} >
      {checkNegativeNum(hours)}h : {checkNegativeNum(minutes)}m : {checkNegativeNum(seconds)}s
    </StatsValue>
  </div>
}

export default function MonoPassSection3({ monopassContext }: any) {
  const { account, chainId } = useWeb3React()
  const toggleWalletModal = useToggleWalletModal()
  const { ethPrice } = useEthPrice()
  const {
    monoPassStakeContractWithSign,
    startWhitelistTime,
    stakeInfo, userStakeInfo, totalMinted,
    isApprovingMonopass, hasApprovedMonopass, approveMonopass,
    isStaking, stakeMonoPass
  } = monopassContext

  const totalSold = 666
  const [isShowNftLoading, setIsShowNftLoading] = useState<boolean>(false)

  const [isClaiming, setIsClaiming] = useState<boolean>(false)
  const [isUnstaking, setIsUnstaking] = useState<{
    id: number
    status: boolean
  }>({
    id: 0,
    status: false
  })

  const stakeNftList = useMemo(() => {
    const nftList = []

    if (userStakeInfo.totalStaked?.length > 0)
      nftList.push(...userStakeInfo.totalStaked)
    if (userStakeInfo.monopassCnt?.length > 0)
      nftList.push(...userStakeInfo.monopassCnt)

    return nftList
  }, [userStakeInfo])

  const currentDate = dayjs()
  const isCanUnstake = useCallback((depositedTime: number) => dayjs.utc(depositedTime * 1000).add(stakeInfo.lockTime, 'second').isBefore(currentDate), [stakeNftList])

  const unstakeMonoPass = async (item: DepositedMonopassType) => {
    checkUnsupportChainId(chainId)
    if (isUnstaking.status || !isCanUnstake(item.depositedTime)) return
    try {
      setIsUnstaking({
        id: item.id,
        status: true
      })
      const gasEstimate = await monoPassStakeContractWithSign?.estimateGas.withdraw(item.id)
      const gasLimitCalc = Math.floor(gasEstimate ? gasEstimate.toNumber() * 1.5 : 200000)
      const tx = await monoPassStakeContractWithSign?.withdraw(item.id, { gasLimit: gasLimitCalc })
      await tx.wait()

      monopassContext?.loadUserStakeInfo()
      monopassContext?.loadStakeInfo()
      notification.success({
        message: 'Unstake successfull'
      })
    }
    catch (err) {
      notification.error({
        message: 'Unstake failed'
      })
    }
    finally {
      setIsUnstaking({
        id: 0,
        status: false
      })
    }
  }

  const whitelistStartTime = useMemo(() => dayjs.utc(1718643600 * 1000), [])
  const hasStarted = useMemo(() => whitelistStartTime.isBefore(dayjs.utc()), [whitelistStartTime])
  const endTimeToday = useMemo(() => hasStarted ? Math.floor(dayjs.utc().hour(23).minute(59).second(59).valueOf() / 1000) : whitelistStartTime.valueOf() / 1000, [hasStarted])

  const myStakingPower = useMemo(() => userStakeInfo.totalStaked.length == 0 ? 0 : userStakeInfo.totalStaked.length * 100 / stakeInfo.totalStaked, [stakeInfo, userStakeInfo])

  const myEstimateApr = useMemo(() => {
    if (userStakeInfo.totalStaked.length == 0) return 0
    const rs = ((myStakingPower / 100) * 5000 * 365 * 100) / (userStakeInfo.totalStaked.length * (0.001 * ethPrice) * 90)
    return rs == Number.POSITIVE_INFINITY || rs == Number.NEGATIVE_INFINITY ? 0 : rs;
  }, [stakeInfo, userStakeInfo, ethPrice])

  const maxApr = useMemo(() => {
    const passStakedCnt = stakeInfo.totalStaked <= 0 ? 1 : stakeInfo.totalStaked
    return (5000 * 365 * 100) / (passStakedCnt * (0.001 * ethPrice) * 90)
  }, [stakeInfo, ethPrice])

  const totalDistributedReward = useMemo(() => hasStarted ? ((Date.now() / 1000 - stakeInfo.startTime) * stakeInfo.rewardPerSec) : 0, [stakeInfo, hasStarted])

  const hasStakedAllMonopass = useMemo(() => userStakeInfo.monopassCnt.length == 0 && userStakeInfo.totalStaked.length > 0, [userStakeInfo])

  const claimReward = async () => {
    checkUnsupportChainId(chainId)
    if (isClaiming) return
    try {
      setIsClaiming(true)
      const tx = await monoPassStakeContractWithSign?.claim()
      await tx.wait()
      monopassContext?.loadUserStakeInfo()
      notification.success({
        message: 'Claim successfull'
      })
    }
    catch (err) {
      notification.error({
        message: 'Claim failed'
      })
    } finally {
      setIsClaiming(false)
    }
  }

  const unstakePassList = useMemo(() => userStakeInfo.totalStaked.filter((item: DepositedMonopassType) => isCanUnstake(item.depositedTime)), [userStakeInfo])

  const stakeOrUnstakeAll = async () => {
    checkUnsupportChainId(chainId)
    if (isShowNftLoading) return

    if (hasStakedAllMonopass) { // unstake
      if (unstakePassList.length == 0) {
        notification.error({
          message: "Your pass is still locked"
        })
        return
      }
      try {
        setIsShowNftLoading(true)
        const withdrawList = unstakePassList.map((item: DepositedMonopassType) => monoPassStakeContractWithSign?.interface.encodeFunctionData('withdraw', [item.id]))

        const gasEstimate = await monoPassStakeContractWithSign?.estimateGas.multicall(withdrawList)
        const gasLimitCalc = Math.floor(gasEstimate ? gasEstimate.toNumber() * 1.5 : 200000)

        const tx = await monoPassStakeContractWithSign?.multicall(withdrawList, {
          gasLimit: gasLimitCalc
        })
        await tx.wait()

        monopassContext?.loadUserStakeInfo()
        monopassContext?.loadStakeInfo()

        notification.success({
          message: 'Unstake all successfull'
        })
      }
      catch (err) {
        console.log('Unstake all err: ', err);
        if (err?.code == 'UNPREDICTABLE_GAS_LIMIT' || (err?.data?.message && String(err?.data?.message).indexOf('insufficient funds')))
          notification.error({
            message: 'Insufficient funds for gas'
          })
        else
          notification.error({
            message: 'Unstake all successfull'
          })
      }
      finally {
        setIsShowNftLoading(false)
      }
    }
    else if (userStakeInfo.monopassCnt.length > 0) {
      try {
        setIsShowNftLoading(true)
        if (!hasApprovedMonopass) {
          notification.warning({
            message: "You need approve NFT first before stake"
          })
          await approveMonopass()
        }
        const depositList = userStakeInfo.monopassCnt.map((item: DepositedMonopassType) => monoPassStakeContractWithSign?.interface.encodeFunctionData('deposit', [item.id]))

        const gasEstimate = await monoPassStakeContractWithSign?.estimateGas.multicall(depositList)
        const gasLimitCalc = Math.floor(gasEstimate ? gasEstimate.toNumber() * 1.5 : 200000)

        const tx = await monoPassStakeContractWithSign?.multicall(depositList, {
          gasLimit: gasLimitCalc
        })
        await tx.wait()

        monopassContext?.loadUserStakeInfo()
        monopassContext?.loadStakeInfo()

        notification.success({
          message: 'Stake all successfull'
        })
      }
      catch (err) {
        console.log('Stake all err: ', err);

        notification.error({
          message: 'Stake all failed'
        })
      }
      finally {
        setIsShowNftLoading(false)
      }
    }
  }

  return <Section3ContentWrapper>
    <TitleWrapper style={{ marginBottom: '56px' }}>
      <Title >
        <span >Elevate  </span> your Earnings
      </Title>
      <SubTitle>
        Stake your MonoPass - Sit back & Earn
      </SubTitle>
    </TitleWrapper>


    <BorderBox clipPath="polygon( 45px 0, calc(100% - 45px) 0, 100% 45px, 100% 100%, 0 100%, 0 45px)">
      <BorderBoxContent clipPath="polygon( 45px 0, calc(100% - 45px) 0, 100% 45px, 100% 100%, 0 100%, 0 45px)">
        <StatsBoxWrapper>
          <StatsContentRow>

            <StatsItemBox>
              <StatsItem>
                <StatsLabel>
                  MonoChest Airdrop Cooldown {!hasStarted ? 'starting in' : ''}
                </StatsLabel>
                <StatsValue>
                  <RemainCountdown endTime={endTimeToday} />
                </StatsValue>
              </StatsItem>

              <StatsItem>
                <StatsLabel>
                  Weekly Chest
                </StatsLabel>
                <WeeklyChestInfoBox>

                  <WeeklyChestInfoItem>
                    <StatsValue>{stakeInfo.weeklyChest[0]}</StatsValue>
                    <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/chest/chest1.gif" alt="monoswap" />
                  </WeeklyChestInfoItem>
                </WeeklyChestInfoBox>
              </StatsItem>

            </StatsItemBox>

            <StatsItemBox flexOnMobile={true} className="middle-col">
              <StatsItem >
                <StatsLabel style={{ textAlign: 'center' }}>
                  Staked NFT
                </StatsLabel>
                <StatsValue style={{ textAlign: 'center' }}>
                  {stakeInfo.totalStaked}/{totalSold}
                </StatsValue>
              </StatsItem>

              <StatsItem>
                <StatsLabel>
                  Max APR
                </StatsLabel>
                <StatsValue>
                  {formatXpNumber(maxApr)}%
                </StatsValue>
              </StatsItem>
            </StatsItemBox>

            <StatsItemBox flexOnMobile={true}>
              <StatsItem >
                <StatsLabel >
                  Total Distributed
                </StatsLabel>
                <StatsValue>
                  {formatXpNumber(Math.max(totalDistributedReward, 0))}
                  <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/musd.svg" alt="monoswap" />
                </StatsValue>
              </StatsItem>

              <StatsItem>
                <StatsLabel>
                  Total Earned
                </StatsLabel>
                <StatsValue>
                  {formatXpNumber(userStakeInfo.totalEarned)}
                  <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/musd.svg" alt="monoswap" />
                </StatsValue>
              </StatsItem>
            </StatsItemBox>

            <HorizontalDivBox >
              <HorizontalDiv />
            </HorizontalDivBox>
          </StatsContentRow>

        </StatsBoxWrapper>
      </BorderBoxContent>
      <ArrowBorderIcon style={{ bottom: '-18px', top: 'unset' }}>
        <div style={{
          width: '34px', height: '34px',
          border: '1px solid #efefe480',
          rotate: '45deg',
          margin: 'auto',
          background: '#0a090f'
        }}>
        </div>
      </ArrowBorderIcon>

    </BorderBox>

    <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/monopass/section3/hr-bg.png" style={{ width: '100%', height: '100%', minHeight: '30px' }} />

    <StakeWrapper>
      <ArrowBorderIcon>
        <div style={{
          width: '34px', height: '34px',
          border: '1px solid #efefe480',
          rotate: '45deg',
          margin: 'auto',
          background: '#0a090f',
          clipPath: 'polygon(100% 100%, 0 100%, 100% 0)'
        }}>
        </div>
      </ArrowBorderIcon>
      <MyStakeBox>

        <MyStakeSeparatorBox>
          <MyStakeSeparator />
        </MyStakeSeparatorBox>
        <MyStakeBoxContentLeft>
          <StatsItem style={{ gap: '12px' }}>
            <StatsLabel>
              Your Staked Power
            </StatsLabel>
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <img className="stake-info-icon" src="/monopass/section3/power-icon.png" alt="monoswap" />
              <StatsValue style={{ fontSize: '44px', lineHeight: '55px' }}>
                {formatXpNumber(myStakingPower)}%
              </StatsValue>
            </div>
            <StatsLabel>
              Est. your APR: <span style={{ color: '#EFEFE4', marginLeft: '5px', display: 'inline-block' }}>{formatXpNumber(myEstimateApr)}%</span>
            </StatsLabel>
          </StatsItem>
        </MyStakeBoxContentLeft>

        <MyStakeBoxContentRight>
          {
            account && <StatsItem style={{ gap: '11px' }}>
              <StatsLabel>
                Pending Rewards
              </StatsLabel>

              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <img className="stake-info-icon reward" src="/monopass/section3/reward-icon.png" alt="monoswap" />
                <StatsValue style={{ fontSize: '44px', lineHeight: '55px' }}>
                  {formatXpNumber(userStakeInfo.pendingReward)}
                </StatsValue>
                <img className="stake-info-icon" src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/musd/musd.svg" alt="monoswap" />
              </div>

              <StatsAction onClick={claimReward} hoverColor={true} style={{
                borderBottom: '1px solid #68FFFF',
                position: 'relative',
                zIndex: 2
              }}>
                {
                  isClaiming ? 'CLAIMING...' : 'CLAIM'
                }
              </StatsAction>
            </StatsItem>
          }
        </MyStakeBoxContentRight>
      </MyStakeBox>
      <StakeListBox id="stake-list-box">
        <StakeListTopRow>
          <MyNftStats>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
              <StatsLabel>Your Pass</StatsLabel>
              <StatsValue>{userStakeInfo.monopassCnt?.length ?? 0}</StatsValue>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
              <StatsLabel>Your Staked Pass</StatsLabel>
              <StatsValue>{userStakeInfo.totalStaked?.length ?? 0}</StatsValue>
            </div>
          </MyNftStats>

          <StakeActionBox>
            <StatsAction hoverColor={true} style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '120px'
            }} onClick={() => window.open('https://element.market/collections/mono-pass', '_blank')}>
              GET MONO PASS
            </StatsAction>
            {
              account ? <NotchedButtonFill style={{ maxWidth: '270px', padding: '20px 30px' }} disabled={hasStakedAllMonopass && unstakePassList.length == 0 || !hasStarted} onClick={stakeOrUnstakeAll}>
                {
                  hasStakedAllMonopass ? 'UNSTAKE ALL NFT' : 'STAKE ALL NFT'
                }
              </NotchedButtonFill>
                : <NotchedButtonFill style={{ maxWidth: '270px', padding: '20px 30px' }} onClick={toggleWalletModal}>
                  CONNECT WALLET
                </NotchedButtonFill>
            }
          </StakeActionBox>
        </StakeListTopRow>
        <HorizontalDiv />

        <Text style={{ fontSize: '11px', display: 'block', marginTop: '20px' }}>
          * Your pass will be locked for 10 days following the stake
        </Text>
        <NFTList>
          {
            isShowNftLoading &&
            <NFTListLoading>
              <img style={{ width: '60px', height: '100px' }} src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/common/mobile-logo.png" />
            </NFTListLoading>
          }

          {
            stakeNftList.map((i: DepositedMonopassType, index: number) => <NFTBox key={index}>
              <NFTImgContent>
                <img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/monopass/mint/nft-high.gif" alt="monoswap-nft" style={{ width: '100%' }} />

                <NFTInfo>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <StatsLabel>Number</StatsLabel>
                    <StatsValue style={{ fontSize: '14px', color: 'white', fontStyle: 'normal', display: 'block' }} >#{i.id}</StatsValue>
                  </div>

                  {
                    i.depositedTime > 0 && <PassLockCountDown endTime={i.depositedTime + stakeInfo.lockTime} />
                  }

                </NFTInfo>
              </NFTImgContent>
              {
                i.depositedTime > 0 ? <NotchedButtonBolderNew w="100%" h="45px"
                  borderColor="#EFEFE4"
                  bg="#0a090f"
                  cursor="pointer"
                  clipPath="polygon(10px 0,100% 0,100% 75%,calc(100% - 12px) 100%,0 100%,0 25%)"
                >
                  <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', height: '100%', width: '100%', opacity: isCanUnstake(i.depositedTime ?? 0) ? 1 : 0.6, cursor: isCanUnstake(i.depositedTime ?? 0) ? 'pointer' : 'no-drop' }} onClick={() => unstakeMonoPass(i)}>
                    <span style={{ fontSize: '18px', fontWeight: 700, display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#EFEFE4' }}>
                      {
                        isUnstaking.status && isUnstaking.id == i.id ? 'UNSTAKING...' : 'UNSTAKE'
                      }
                    </span>
                  </div>
                </NotchedButtonBolderNew>
                  :
                  hasApprovedMonopass ?
                    <NotchedButtonBolderNew w="100%" h="45px"
                      borderColor="linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%)"
                      bg="#0a090f"
                      cursor={!hasStarted ? 'no-drop' : "pointer"}
                      clipPath="polygon(10px 0,100% 0,100% 75%,calc(100% - 12px) 100%,0 100%,0 25%)"
                    >
                      <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', height: '100%', width: '100%' }} onClick={() => {
                        if (hasStarted)
                          stakeMonoPass(i)
                      }}>
                        <PrimaryTexy style={{ fontSize: '18px', fontWeight: 700, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          {
                            isStaking.status && isStaking.id == i.id ? 'STAKING...' : 'STAKE'
                          }
                        </PrimaryTexy>
                      </div>
                    </NotchedButtonBolderNew>
                    :
                    <NotchedButtonBolderNew w="100%" h="45px"
                      borderColor="linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%)"
                      bg="#0a090f"
                      cursor="pointer"
                      clipPath="polygon(10px 0,100% 0,100% 75%,calc(100% - 12px) 100%,0 100%,0 25%)"
                    >
                      <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', height: '100%', width: '100%' }} onClick={approveMonopass}>
                        <PrimaryTexy style={{ fontSize: '18px', fontWeight: 700, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          {
                            isApprovingMonopass ? 'APPROVING...' : 'APPROVE'
                          }
                        </PrimaryTexy>
                      </div>
                    </NotchedButtonBolderNew>
              }

            </NFTBox>)
          }
        </NFTList>


      </StakeListBox>
    </StakeWrapper>
  </Section3ContentWrapper >
}