import styled from "styled-components"

import {
    LeftOutlined,
    RightOutlined,
    VerticalLeftOutlined,
    CloseOutlined
} from "@ant-design/icons"
import { useEffect, useMemo, useState } from "react"
import { Pagination } from "antd" 
import { NotchedButtonFill } from "components/Button";
import { getMyWonTicketIds, useMonopotStore, WonTicketType } from "hooks/useMonopot";
import { LoadingOutlined } from '@ant-design/icons'

const ViewTicketModalWrapper = styled.div`
    width: 100%;
    max-width: 592px;
    background: #1C1B20;
    box-shadow: 0px 12px 50px 0px #000;
    padding: 24px 32px;
    position: relative;
    overflow: auto;
`
const LoadingBox = styled.div`
    display: flex;
    margin: 30px 0;
    justify-content: center;
    font-size: 50px;
`
const ViewTicketModalTitleBox = styled.div`
    display: flex;
    gap: 12px;
    button{
        background: unset;
        padding: 0;
        outline: none;
        border: none;
        &:hover{
            scale: 1.1;
            transition: ease-in-out 200ms;
        }
    }
`

const Text = styled.span`
    color: #E5E5DF;
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 35px */
    span{
        font-family: Urbanist;
    }
`

const TicketList = styled.div`
    display: grid;
    gap: 12px;
`
const TicketItem = styled.div`
    position: relative;
    z-index: 1;
    max-width: 528px;
    height: 72px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10%;
    cursor: pointer;
    background: rgba(239, 239, 228, 0.05);
    clip-path: polygon(20.3px 0, 100% 0, 100% calc(100% - 20.3px), calc(100% - 20.3px) 100%, 0 100%, 0 20.3px);

    img.ticket_bg{
        max-width: 15px;
        position: absolute;
        top: 3px;
        right: 25%;
        width: 100%;
        height: 90%;
        z-index: 1;
    }
    @media screen and (max-width: 350px) {
        padding-left: 5%;
        img.ticket_bg{
            right: 22%;
            max-width: 10px;
        }
    }
`

const TicketItemNumBox = styled.div`
    display: flex;
    position: relative;
    z-index: 1;
    
`
const TicketItemNum = styled.div<{
    hasMatched?: boolean
}>`
    height: 100%;
    text-align: center;
    font-family: Urbanist;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 50.4px */
    border: none;
    outline: none;
    display: block;
    background: none;
    width: 40px;
    @media screen and (max-width: 780px) {
        width: 30px;
    }
    @media screen and (max-width: 708px) {
        width: 40px;
    }
    @media screen and (max-width: 600px) {
        font-size: 15px;
    }
    @media screen and (max-width: 450px) {
        width: 30px;
    }
`
const TicketItemNo = styled.div<{
    hasChose?: boolean
}>`
    position: absolute;
    top: 25%;
    right: 8%;
    z-index: 1;
    display: flex;
    gap: 10px;
    opacity: 0.7;
    span{
        color: ${({ hasChose }) => hasChose ? '#68FF9B' : 'rgba(239, 239, 228, 0.50)'};
        text-align: center;
        font-family: Righteous;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 25px */
        @media screen and (max-width: 600px) {
            font-size: 15px;
        }
    }
    svg{
        color: rgba(239, 239, 228, 0.50);
        font-size: 16px;
        &:hover{
            color: #68FF9B;
        }
    }
    @media screen and (max-width: 600px) {
        top: 35%;
    }
    @media screen and (max-width: 500px) {
        right: 10%;
        gap: 5px;
    }
    @media screen and (max-width: 350px) {
        right: 9%;
        top: 37%;
    }
`
export function LoadingBoxComponent() {
    return <LoadingBox>
        <LoadingOutlined rev={undefined} />
    </LoadingBox>
}
export default function ViewTicketModal({ onClose, openBuyTicketModal }: {
    onClose: Function,
    openBuyTicketModal: Function
}) {
    const [currentRound, setCurrentRound] = useState<number>(0)
    const { isLoadingLatestChestInfo, myCurrentBoughtTickets, currentChestInfo, latestChestInfo, loadLatestChestInfo } = useMonopotStore()
    const [currentPage, setCurrentPage] = useState<number>(1)

    const [myWinTicketIds, setMyWinTicketIds] = useState<WonTicketType[]>([])
    const [currentWinPotPage, setCurrentWinPotPage] = useState<number>(1)
    const hasClosedPot = useMemo(() => latestChestInfo?.status == 3, [latestChestInfo])

    const displayTickets = useMemo(() => {
        const start = (currentPage - 1) * 5
        return myCurrentBoughtTickets.slice(start, start + 5)
    }, [currentPage, myCurrentBoughtTickets])

    const displayWonTickets = useMemo(() => {
        const start = (currentWinPotPage - 1) * 5
        return myWinTicketIds.slice(start, start + 5)
    }, [currentWinPotPage, myWinTicketIds])

    useEffect(() => {
        if (currentRound > 0)
            loadLatestChestInfo(currentRound)
        setMyWinTicketIds([])
    }, [currentRound])

    useEffect(() => {
        if (currentChestInfo)
            setCurrentRound(currentChestInfo.id)
    }, [currentChestInfo])

    useEffect(() => {
        if (!latestChestInfo || latestChestInfo.status != 3) return
        const lotteryNum = latestChestInfo.finalNumber.toString().slice(1).split('')
        const {
            wonTicketIds,
            maxMyWonNumLength
        } = getMyWonTicketIds(myCurrentBoughtTickets, lotteryNum)

        if (maxMyWonNumLength > 0)
            setMyWinTicketIds(wonTicketIds)
    }, [myCurrentBoughtTickets])

    const isCanNextRound = useMemo(() => currentRound < (currentChestInfo?.id ?? 0), [currentRound, currentChestInfo])
    const isCanPrevRound = useMemo(() => (currentRound <= (currentChestInfo?.id ?? 0)) && currentRound > 1, [currentRound, currentChestInfo])

    return <>
        <ViewTicketModalWrapper>
            <div style={{ position: 'absolute', top: '24px', right: '32px', cursor: 'pointer' }} onClick={() => onClose()}>
                <CloseOutlined rev={undefined} />
            </div>
            <ViewTicketModalTitleBox>
                <Text>
                    Round {currentRound}/{currentChestInfo?.id}
                </Text>
                <button style={{ color: '#EFEFE4', marginLeft: '4px', cursor: 'pointer' }} onClick={() => { if (isCanPrevRound) setCurrentRound(i => i - 1) }}>
                    <LeftOutlined rev={undefined} />
                </button>
                <button style={{ color: '#EFEFE4', cursor: 'pointer' }} onClick={() => { if (isCanNextRound) setCurrentRound(i => i + 1) }}>
                    <RightOutlined rev={undefined} />
                </button>
                <button style={{ color: '#EFEFE4', cursor: 'pointer' }} onClick={() => { if (isCanNextRound) setCurrentRound(currentChestInfo?.id ?? 0) }}>
                    <VerticalLeftOutlined rev={undefined} />
                </button>
            </ViewTicketModalTitleBox>

            <Text style={{ fontSize: '18px', fontWeight: 400, marginTop: '12px', marginBottom: '21px', display: 'block' }}>
                You have <span style={{ fontWeight: 600 }}>{myCurrentBoughtTickets.length} tickets</span> this round
            </Text>


            {
                hasClosedPot && myWinTicketIds.length > 0 &&
                <>
                    <Text style={{ fontSize: '21px', display: 'block', marginBottom: '21px' }}>
                        {myWinTicketIds.length} Winning Tickets
                    </Text>
                    <TicketList>
                        {
                            displayWonTickets.map((item, index) => <TicketItem key={index} >
                                <img className="ticket_bg" src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/lottery/ticket_bg.png" />
                                <TicketItemNumBox>
                                    <TicketItemNum hasMatched={item.number[0].hasMatched}>
                                        {item.number[0].num}
                                    </TicketItemNum>
                                    <TicketItemNum hasMatched={item.number[1].hasMatched}>
                                        {item.number[1].num}
                                    </TicketItemNum>
                                    <TicketItemNum hasMatched={item.number[2].hasMatched}>
                                        {item.number[2].num}
                                    </TicketItemNum>
                                    <TicketItemNum hasMatched={item.number[3].hasMatched}>
                                        {item.number[3].num}
                                    </TicketItemNum>
                                    <TicketItemNum hasMatched={item.number[4].hasMatched}>
                                        {item.number[4].num}
                                    </TicketItemNum>
                                    <TicketItemNum hasMatched={item.number[5].hasMatched}>
                                        {item.number[5].num}
                                    </TicketItemNum>

                                </TicketItemNumBox>

                                <TicketItemNo>
                                    #{item.no}
                                </TicketItemNo>
                            </TicketItem>)
                        }
                    </TicketList>
                    <div className="my-claimed-xp" style={{ display: 'flex', justifyContent: 'center', margin: '30px 0' }}>
                        <Pagination current={currentWinPotPage}
                            total={myWinTicketIds.length}
                            pageSize={5}
                            showSizeChanger={false}
                            onChange={(page) => setCurrentWinPotPage(page)} />
                    </div>
                </>
            }

            <Text style={{ fontSize: '21px', display: 'block', marginBottom: '21px' }}>
                All Tickets
            </Text>


            {
                isLoadingLatestChestInfo ?
                    <LoadingBoxComponent />
                    :
                    <>
                        <TicketList>
                            {
                                displayTickets.map((item, index) => <TicketItem key={index} >
                                    <img className="ticket_bg"  src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/lottery/ticket_bg.png" />
                                    <TicketItemNumBox>
                                        <TicketItemNum>
                                            {item.num[1]}
                                        </TicketItemNum>
                                        <TicketItemNum>
                                            {item.num[2]}
                                        </TicketItemNum>
                                        <TicketItemNum>
                                            {item.num[3]}
                                        </TicketItemNum>
                                        <TicketItemNum>
                                            {item.num[4]}
                                        </TicketItemNum>
                                        <TicketItemNum>
                                            {item.num[5]}
                                        </TicketItemNum>
                                        <TicketItemNum>
                                            {item.num[6]}
                                        </TicketItemNum>
                                    </TicketItemNumBox>

                                    <TicketItemNo hasChose={item.hasChose}>
                                        #{item.no}
                                    </TicketItemNo>
                                </TicketItem>)
                            }
                        </TicketList>
                        {
                            myCurrentBoughtTickets.length > 0 && <div className="my-claimed-xp" style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                                <Pagination current={currentPage}
                                    total={myCurrentBoughtTickets.length}
                                    pageSize={5}
                                    showSizeChanger={false}
                                    onChange={(page) => setCurrentPage(page)} />
                            </div>
                        }
                    </>
            }

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', }}>
                <NotchedButtonFill onClick={() => { onClose(); openBuyTicketModal(true) }}>
                    GET MORE TICKET
                </NotchedButtonFill>
            </div>

        </ViewTicketModalWrapper >

    </>
}