import { NotchedButtonBolderNew } from "components/Button/NotchedButton"
import { TextLinear } from "pages/Xp/sections/XpTitle"
import { useState } from "react"
import styled from "styled-components"
import {
    CloseOutlined
} from "@ant-design/icons"
import { SupportedChainId } from "constants/chains"
import { ExplorerDataType, getExplorerLink } from "utils/getExplorerLink"


const SuccessClaimedRwModalWrapper = styled.div`
    width: 100%;
    max-width: 592px;
    max-height: 686px;
    background: #1C1B20;
    box-shadow: 0px 12px 50px 0px #000;
    padding: 24px 32px;
    position: relative;
    overflow: auto;
`

const Text = styled.span`
    color: #E5E5DF;
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 35px */
    span{
        font-family: Urbanist;
    }
`


export default function SuccessClaimedRwModal({ onClose, hash, musd, tax }: any) {
    return <SuccessClaimedRwModalWrapper>
        <div style={{ position: 'absolute', top: '24px', right: '32px', cursor: 'pointer' }} onClick={onClose}>
            <CloseOutlined rev={undefined} onClick={onClose}/>
        </div>
        <Text style={{ fontFamily: 'Righteous', textAlign: 'center', display: 'block' }}>
            Successful
        </Text>

        <div style={{ display: 'grid', gap: '12px', marginBottom: '30px', marginTop: '20px' }}>
            <Text style={{ fontSize: '18px', textAlign: 'center' }}>
                You’ve successfully claimed <TextLinear>{musd} MUSD</TextLinear>
            </Text>

            <div style={{ display: 'flex', gap: '4px', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: '15px', opacity: 0.5 }}>
                    MonoTax (15%):
                </Text>
                <Text style={{ fontSize: '15px' }}>
                    {tax}
                </Text>
                <img style={{ maxWidth: '16px', maxHeight: '16px' }} src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section3/musd-token.png" />
            </div>
        </div>

        <NotchedButtonBolderNew w={'100%'} h="42px" bg="#000000" borderColor="rgba(104, 255, 255, 0.80)">
            <TextLinear style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', cursor: 'pointer' }} onClick={() => window.open(getExplorerLink(SupportedChainId.BLAST_SEPOLIA,
                hash,
                ExplorerDataType.TRANSACTION
            ), '_blank')}>SEE TX</TextLinear>
        </NotchedButtonBolderNew>
    </SuccessClaimedRwModalWrapper>
}