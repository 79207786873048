import { NotchedButtonFill } from 'components/Button'
import { NotchedButtonBolder } from 'components/Button/NotchedButton'
import styled from 'styled-components/macro'
import { BREAKPOINTS } from 'theme'
import { ExternalLink } from 'theme'
import { useNavigate } from 'react-router-dom'
import './section0.css'
const WrapperSection1 = styled.section`
  background-image: url('https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1_bg.gif');
  background-size: cover;
  width: 100%;
  margin: 0px auto;
  height: 807px;
  @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
    height: 797px;
  }
  @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
    height: 663px;
  }
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    height: 530px;
  }
  @media screen and (max-width: 800px) {
    height: 450px;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    background-image: unset;
    height: fit-content;
    margin-top: 0;
  }
`

const WrapperContent = styled.div`
  width: 80%;
  height: 100%;
  margin: 0px auto;
  position: relative;
  @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
    width: 100%;
  }
`
const BgImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    opacity: 0.7;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    display: none;
  }
`
const BgImageMobileWrapper = styled.div`
  background-image: url('https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section0_bg.gif');
  background-size: cover;
  display: none;
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    display: flex;
    justify-content: center;
    padding: 64px 0;
  }
`
const BgImageMobile = styled.img`
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    width: 315px;
    height: 308px;
  }
`
const DivLeft = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  z-index: 2;
  @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
    padding-left: 50px;
  }
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    position: relative;
    justify-content: flex-end;
    gap: 20px;
    padding: 0;
  }
  @media screen and (max-width: ${BREAKPOINTS.xs}px) {
    // padding-left: 20px;
    width: 95%;
    margin: 0 auto;
  }
`

const Title = styled.h3`
  color: #efefe4;
  font-size: 72px;
  font-family: Righteous;
  font-weight: 400;
  line-height: 90px;
  word-wrap: break-word;
  width: 770px;
  height: 270px;
  margin: 0;
  margin-bottom: 50px;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 50px;
    height: 200px;
    width: unset;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin-bottom: 0;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    background-image: url('https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section0_bg.gif');
    background-size: cover;
    margin-left: 45px;
    height: 150px;
    font-size: 32px;
    line-height: 40px;
  }
  @media screen and (max-width: ${BREAKPOINTS.xs}px) {
    margin-left: 5px;
  }
`
const Subtitle = styled.span`
  display: block;
  color: #68ffff;
  font-size: 72px;
  font-family: Righteous;
  font-weight: 400;
  line-height: 90px;
  word-wrap: break-word;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 40px;
    line-height: 57px;
  }
`

const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 126px;
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } ;
`
const WrapSlider = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  } ;
`

export default function Section0() {
  const navigate = useNavigate()
  return (
    <>
      <WrapperSection1>
        <BgImageMobileWrapper>
          <BgImageMobile src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section0/image-bg.png" alt="Monoswap" />
        </BgImageMobileWrapper>
        <WrapperContent>
          <DivLeft>
            <Title>
              The v3 liquidity hub
              <br />
              That auto compounds
              <WrapSlider>
                <div className={'slider_wrapper'}>
                  <div className={'slider_container'}>
                    <div className={'slider_text_1'}>
                      <Subtitle>Yield</Subtitle>
                    </div>
                    <div className={'slider_text_2'}>
                      <Subtitle>ETH</Subtitle>
                    </div>
                    <div className={'slider_text_3'}>
                      <Subtitle>USD</Subtitle>
                    </div>
                  </div>
                </div>
              </WrapSlider>
            </Title>
            <WrapperButton>
              <NotchedButtonFill
                padding="15px 30px"
                height="48px"
                width="210px"
                mobileMaxW="345px"
                onClick={() => navigate('/swap/')}
              >
                TRADE NOW
              </NotchedButtonFill>
              <ExternalLink href="https://docs.monoswap.io/">
                <NotchedButtonFill
                  padding="15px 30px"
                  height="48px"
                  width="210px"
                  mobileMaxW="345px"
                  onClick={() => { }}
                >
                  LEARN MORE
                </NotchedButtonFill>
              </ExternalLink>
            </WrapperButton>
          </DivLeft>

          <div>
            <BgImage loading='lazy' src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section1_bg.gif" alt="Monoswap" />
          </div>
        </WrapperContent>
      </WrapperSection1>
    </>
  )
}
