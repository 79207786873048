import Modal from '../Modal'
import { Trans } from '@lingui/macro'
import { Text } from 'rebass'


import styled, { useTheme } from 'styled-components/macro'
import { CloseIcon, CustomLightSpinner } from '../../theme'
import { AutoColumn, ColumnCenter } from '../Column'
import { ButtonLight, ButtonPrimary, NotchedButtonFillPrimary, NotchedButtonFill } from '../Button'
import { CheckCircle, AlertCircle, AlertTriangle } from 'react-feather'
import AnimatedConfirmation from '../TransactionConfirmationModal/AnimatedConfirmation'

const Circle = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/blue-loader.svg'
interface ClaimTokenModalInterface {
  isOpen: boolean
  onDismiss: () => void
  loading: boolean
  errorClaimEth: boolean
  errMess: string
}
const Wrapper = styled.div`
  background-color: #1c1b20;
  outline: none;
  width: 100%;
  padding: 10px 20px;
`
const WrapperTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    color: #efefe4;
  }
`
const WrapperMid = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default function ClaimETHModal({
  isOpen,
  onDismiss,
  loading,
  errorClaimEth,
  errMess,
}: ClaimTokenModalInterface) {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>
        <WrapperTop>
          <p>Claim token</p>
          <CloseIcon onClick={onDismiss} />
        </WrapperTop>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {errorClaimEth ? (
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <WrapperMid>
                  <AlertTriangle color={'red'} style={{ strokeWidth: 1 }} size={90} />
                </WrapperMid>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#efefe4',
                    padding: '20px 0px',
                    textAlign: 'center',
                  }}
                >
                  <Trans>{errMess}! </Trans>
                </div>
              </div>
            </>
          ) : (
            <>
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <WrapperMid>
                    <CustomLightSpinner src={Circle} alt="loader" size={'90px'} />
                  </WrapperMid>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: '#efefe4',
                      padding: '20px 0px',
                    }}
                  >
                    <Trans>Loading...</Trans>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <WrapperMid>
                    <AnimatedConfirmation />
                  </WrapperMid>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: '#efefe4',
                      padding: '20px 0px',
                    }}
                  >
                    <Trans>Successfully claimed 0.01 ETH!</Trans>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <NotchedButtonFillPrimary onClick={onDismiss} style={{ margin: '4px 0 0 0' }}>
          <Text fontWeight={500} fontSize={20}>
            <Trans>Close</Trans>
          </Text>
        </NotchedButtonFillPrimary>
      </Wrapper>
    </Modal>
  )
}
