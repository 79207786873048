import { Box, BoxProps } from 'nft/components/Box'
import { useIsMobile } from 'nft/hooks'
import { ForwardedRef, forwardRef } from 'react'

export const NavDropdown = forwardRef((props: BoxProps, ref: ForwardedRef<HTMLElement>) => {
  const isMobile = useIsMobile()
  return <Box ref={ref} marginTop={{ sm: '16', lg: '0' }} position={isMobile ? 'fixed' : 'absolute'} {...props} />
})

NavDropdown.displayName = 'NavDropdown'
