import { loadingAnimation } from 'components/Loader/styled'
import styled from 'styled-components/macro'

/* Loading state bubbles (animation style from: src/components/Loader/styled.tsx) */
export const LoadingBubble = styled.div`
  height: 24px;
  width: 50%;
  animation: ${loadingAnimation} 1.5s infinite;
  animation-fill-mode: both;
  background: linear-gradient(to left, #68fffe 25%, #68ffd0 50%, #68ff9d 75%);
  will-change: background-position;
  background-size: 400%;
`
