

import { NotchedButtonFill } from "components/Button";
import styled from "styled-components"
import { BREAKPOINTS } from "theme";


const Section2ContentWrapper = styled.section`
  margin-top: 180px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin-top: 100px;
  }
`;


const TitleWrapper = styled.div`
color: white;
line-height: 60px; /* 60px */
`;

const Title = styled.h2`
  text-align: center;
  font-family: Righteous;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px; 
  margin-bottom: 0;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

const BenefitList = styled.div`
  margin-top: 93px;
  margin-bottom: 120px;
  display: flex;
  gap: 24px;
  justify-content: center;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    display: grid;
    margin-top: 50px;
  }
`;

const BenefitBox = styled.div`
  width: 392px;
  height: 450px;
  position: relative;
  padding-top: 38px;
  padding-bottom: 40px;
  padding-left: 36px;
  padding-right: 36px;
  display: flex;
  flex-direction: column;
  gap: 56px;
  justify-items: start;
  justify-content: center;

  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 0 !important;
    width: 100%;
    width: 300px;
    max-height: 378px;
    align-items: center;
  }
`
const BenefitImg = styled.img<{
  isFirst?: boolean
}>`
  width: ${({ isFirst }) => isFirst ? '230px' : '200px'};
  height: ${({ isFirst }) => isFirst ? '230px' : '200px'};
  position: relative;
  z-index: 10;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: ${({ isFirst }) => isFirst ? '148px' : '128px'};
    height: ${({ isFirst }) => isFirst ? '148px' : '128px'};
  }
`;

const BenefitTextBox = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
`;
const BenefitTitle = styled.h4`
  color: #EFEFE4;
  font-family: Righteous;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;  /* 35px */
  margin: 0;
  margin-bottom: 10px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 18px;
    line-height: 22.5px;
  }
`;
const BenefitSubTitle = styled.h5`
  max-width: 328px;
  color: rgba(239, 239, 228, 0.70);
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22,5; /* 22.5px */
  margin: 0;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 14px;
    line-height: 17.5px;
  }
`;

const BgImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const IconImgBox = styled.div`
  display: flex;
  justify-content: center;
`
const IconImg = styled.img`
  max-width: 265.446px;
  margin: auto;
  height: 28px;
  opacity: 0.7;
`

export default function MonoPassSection2() {

  return <Section2ContentWrapper>
    <TitleWrapper>
      <Title >
        MonoPass <span style={{ color: '#68FFFF' }}>Privileges</span>
      </Title>
    </TitleWrapper>

    <BenefitList>
      <BenefitBox >
        <BgImg loading="lazy" src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/monopass/section2/benefit-bg.png" />
        <BenefitImg isFirst={true} src="/monopass/section2/benefit1.png" alt="monoswap" />

        <BenefitTextBox>
          <BenefitTitle>
            Infinite MonoChest
          </BenefitTitle>
          <BenefitSubTitle>
            Open free MonoChest all day every day!
            Stand your chance for that juicy rewards.
          </BenefitSubTitle>
        </BenefitTextBox>
      </BenefitBox>

      <BenefitBox
        style={{
          marginTop: '60px'
        }}>
        <BgImg loading="lazy" src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/monopass/section2/benefit-bg.png" />
        <BenefitImg src="/monopass/section2/benefit2.png" alt="monoswap" />

        <BenefitTextBox>
          <BenefitTitle>
            Real Yield Access
          </BenefitTitle>
          <BenefitSubTitle>
            Be a part of MonoSwap. Get a share of protocol earning in ETH!
          </BenefitSubTitle>
        </BenefitTextBox>
      </BenefitBox>

      <BenefitBox >
        <BgImg loading="lazy" src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/monopass/section2/benefit-bg.png" />
        <BenefitImg style={{width: '220px'}} src="/monopass/section2/benefit3.png" alt="monoswap" />

        <BenefitTextBox>
          <BenefitTitle>
            Exclusive Governance
          </BenefitTitle>
          <BenefitSubTitle>
            Let your voice be heard! Cast your vote on Mono’s key decisions
          </BenefitSubTitle>
        </BenefitTextBox>
      </BenefitBox>
    </BenefitList>

    <IconImgBox>
      <IconImg src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/monopass/section2/icon-img.png" />
    </IconImgBox>
  </Section2ContentWrapper>
}