import { useEffect } from 'react';
import styled from 'styled-components';
import MonoPassSection1 from './sections/Section1';
import MonoPassSection2 from './sections/Section2';
import MonoPassSection3 from './sections/Section3';
import { BREAKPOINTS } from 'theme';
import useMonoPass from 'hooks/useMonoPass';

const MonoPassWrapper = styled.div`
  background-image: url('https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/monopass/fullsceen-bg.png');
  background-size: cover;
  height: 100%;
  width: 100%;
`;
const MonoPassBody = styled.div`
  width: 1224px;
  margin: 0 auto;
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    width: 100%;
    padding: 0 20px;
  }
`;

export default function MonoPass() {
  const monopassContext = useMonoPass()
  useEffect(() => {
  }, [])

  return <MonoPassWrapper>
    <MonoPassBody>
      <MonoPassSection1 monopassContext={monopassContext} />
      <MonoPassSection2 />
      <MonoPassSection3 monopassContext={monopassContext}/>
    </MonoPassBody>
  </MonoPassWrapper>

}