import styled from 'styled-components'
import { BREAKPOINTS } from 'theme'
import { ExternalLink } from 'theme'

const Section4Wrapper = styled.section`
  width: 1224px;
  height: 600px;
  margin: 0 auto;
  display: flex;
  margin-top: 122px;
  @media screen and (max-width: ${BREAKPOINTS.xxl}px) {
    width: 1100px;
  }
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    height: 500px;
  }
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    max-width: 90%;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    justify-content: center;
    display: block;
    height: unset;
    margin-top: 50px;
  }
`

const MainContentBox = styled.div`
  width: 50%;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
  }
`

const Title = styled.h2`
  color: #efefe4;
  font-size: 56px;
  font-family: Righteous;
  font-weight: 400;
  line-height: 70px;
  word-wrap: break-word;
  margin: 0;
  margin-top: 11px;
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    font-size: 40px;
    line-height: 50px;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    text-align: center;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 32px;
    line-height: 40px;
    font-weight: 400;
    text-align: start;
  }
`
const SubTitle = styled.h2`
  color: #e5e5df;
  font-size: 24px;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 33.6px;
  word-wrap: break-word;
  margin: 40px 0;
  @media screen and (max-width: ${BREAKPOINTS.xl}px) {
    font-size: 20px;
    line-height: 26px;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    text-align: center;
    margin: 20px 0;
    margin-bottom: 24px;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 18px;
    line-height: 22px;
    text-align: start;
  }
`

const SocialBox = styled.div`
  display: flex;
  gap: 24px;
  cursor: pointer;

  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    justify-content: center;
  }

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    justify-content: flex-start;
    gap: 5px;
  }
`;
const SocialBoxImg = styled.img`
  max-width: 64px;
  max-height: 64px;
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    scale: 0.8;
  }
`;

const ImageBox = styled.img`
  width: 50%;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    height: 90%;
  }
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 315px;
    height: 315px;
    display: block;
    margin: 55px auto;
  }
`
const SectionNumber = styled.div`
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    justify-content: flex-start;
  }
`

export default function Section4() {
  return (
    <Section4Wrapper>
      <MainContentBox>
        <SectionNumber>
          <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M22.459 26.1981C22.459 24.7977 22.2246 23.7577 21.7559 23.078C21.293 22.3983 20.6455 22.0585 19.8135 22.0585C18.9873 22.0585 18.3457 22.3954 17.8887 23.0692C17.4316 23.743 17.2031 24.786 17.2031 26.1981C17.2031 27.5985 17.4316 28.6385 17.8887 29.3182C18.3457 29.9979 18.9873 30.3378 19.8135 30.3378C20.2295 30.3378 20.6016 30.2557 20.9297 30.0917C21.2578 29.9218 21.5332 29.6669 21.7559 29.327C21.9844 28.9872 22.1572 28.5594 22.2744 28.0438C22.3975 27.5223 22.459 26.9071 22.459 26.1981ZM24.8584 26.1981C24.8584 27.3934 24.7266 28.4042 24.4629 29.2303C24.1992 30.0565 23.8389 30.7245 23.3818 31.2343C22.9248 31.744 22.3887 32.1132 21.7734 32.3417C21.1641 32.5643 20.5107 32.6757 19.8135 32.6757C19.1162 32.6757 18.46 32.5614 17.8447 32.3329C17.2354 32.0985 16.7021 31.7264 16.2451 31.2167C15.7939 30.701 15.4365 30.0331 15.1729 29.2128C14.9092 28.3866 14.7773 27.3817 14.7773 26.1981C14.7773 25.0028 14.9092 23.995 15.1729 23.1747C15.4365 22.3485 15.7939 21.6805 16.2451 21.1708C16.7021 20.661 17.2354 20.2919 17.8447 20.0634C18.46 19.8348 19.1162 19.7206 19.8135 19.7206C20.5107 19.7206 21.1641 19.8378 21.7734 20.0721C22.3887 20.3007 22.9248 20.6727 23.3818 21.1884C23.8389 21.704 24.1992 22.3719 24.4629 23.1923C24.7266 24.0126 24.8584 25.0145 24.8584 26.1981ZM36.2051 29.9774H34.9482V32.4999H32.4258V29.9774H26.124L27.5303 19.8964H30.0527L28.9541 27.4637H32.4258V21.162H34.9482V27.4637H36.2051V29.9774Z"
              fill="#EFEFE4"
            />
            <path d="M40.1451 50H0.5V11.3592L10.8549 1H50.5L50.5 39.6408L40.1451 50Z" stroke="#EFEFE4" />
          </svg>
        </SectionNumber>

        <Title>
          Join the Elite <br />
          <span style={{ color: '#68FFFF' }}>Blasters'</span> Society
        </Title>
        <SubTitle>
          Connect with kindred spirits who <br />
          share an unbridled passion for the <br />
          Blast ecosystem.
        </SubTitle>
        <SocialBox>
          <ExternalLink href="https://twitter.com/monoswapio">
            <SocialBoxImg src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section4/x-sns.png" alt="monoswap" />
          </ExternalLink>
          <ExternalLink href="https://discord.gg/monoswap">
            <SocialBoxImg src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section4/discord-sns.png" alt="monoswap" />
          </ExternalLink>
          <ExternalLink href="https://t.me/monoswapnews">
            <SocialBoxImg src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section4/tele-sns.png" alt="monoswap" />
          </ExternalLink>
          <ExternalLink href="https://monoswapio.medium.com/">
            <SocialBoxImg src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section4/other-sns.png" alt="monoswap" />
          </ExternalLink>
        </SocialBox>
      </MainContentBox>
      <ImageBox src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section4/image.png" alt="monoswap" />
    </Section4Wrapper>
  )
}
