import { Trans } from '@lingui/macro'
import { TraceEvent } from '@uniswap/analytics'
import { BrowserEvent, ElementName, EventName } from '@uniswap/analytics-events'
import { useWeb3React } from '@web3-react/core'
import WalletDropdown from 'components/WalletDropdown'
import {getConnection, getIsMetaMask} from 'connection/utils'
import { Portal } from 'nft/components/common/Portal'
import { useIsNftClaimAvailable } from 'nft/hooks/useIsNftClaimAvailable'
import { getIsValidSwapQuote } from 'pages/Swap'
import { darken } from 'polished'
import {useEffect, useMemo, useRef} from 'react'
import { AlertTriangle, ChevronDown, ChevronUp } from 'react-feather'
import { useAppSelector } from 'state/hooks'
import { useDerivedSwapInfo } from 'state/swap/hooks'
import styled, { useTheme } from 'styled-components/macro'
import { colors } from 'theme/colors'
import { flexRowNoWrap } from 'theme/styles'

import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import {
  useCloseModal,
  useModalIsOpen,
  useToggleWalletDropdown,
  useToggleWalletModal,
} from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/reducer'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/types'
import { shortenAddress } from '../../utils'
import { ButtonSecondary } from '../Button'
import StatusIcon from '../Identicon/StatusIcon'
import Loader from '../Loader'
import { RowBetween } from '../Row'
import WalletModal from '../WalletModal'
import { NotchedButtonFill } from '../../components/Button/index'
import { NotchedButtonBolder } from 'components/Button/NotchedButton'
import { Box } from 'nft/components/Box'
// https://stackoverflow.com/a/31617326
const FULL_BORDER_RADIUS = 9999

const ChevronWrapper = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 10px 16px 10px 4px;

  :hover {
    color: #68ff9b;
  }
  :hover,
  :active,
  :focus {
    border: none;
  }
`

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${flexRowNoWrap};
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  user-select: none;
  height: 36px;
  margin-right: 2px;
  margin-left: 2px;
  :focus {
    outline: none;
  }
`
const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.deprecated_red1};
  border: 1px solid ${({ theme }) => theme.deprecated_red1};
  color: ${({ theme }) => theme.deprecated_white};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.1, theme.deprecated_red1)};
  }
`

const Web3StatusConnectWrapper = styled.div<{ faded?: boolean }>`
  ${flexRowNoWrap};
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0;
  height: 40px;

  :hover,
  :active,
  :focus {
    border: none;
  }
`

const Web3StatusConnected = styled.div<{
  pending?: boolean
  isClaimAvailable?: boolean
}>`
  color: #0a090f;
  font-size: 18px;
  font-family: Righteous;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 18px;
  word-wrap: break-word;
  border: unset;
  :hover,
  :active,
  :focus {
    border: unset;
  }
`

const AddressAndChevronContainer = styled.div`
  display: flex;
`

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
`

const NetworkIcon = styled(AlertTriangle)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

const VerticalDivider = styled.div`
  height: 30px;
  margin: 0px 5px 0px 10px;
  width: 1px;
  background-color: #efefe4;
`

const StyledConnectButton = styled.button`
  background-color: transparent;
  border: none;
  border-top-left-radius: ${FULL_BORDER_RADIUS}px;
  border-bottom-left-radius: ${FULL_BORDER_RADIUS}px;
  color: ${({ theme }) => theme.accentAction};
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 8px 10px 12px;

  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => `${duration.fast} color ${timing.in}`};

  :hover,
  :active,
  :focus {
    border: none;
  }
  :hover {
    color: ${({ theme }) => theme.accentActionSoft};
  }
`

const CHEVRON_PROPS = {
  height: 20,
  width: 20,
}

function Web3StatusInner() {
  const { account, connector, chainId, ENSName } = useWeb3React()
  const connectionType = getConnection(connector).type
  // get version from the url
  const {
    trade: { state: tradeState, trade },
    inputError: swapInputError,
  } = useDerivedSwapInfo()
  const validSwapQuote = getIsValidSwapQuote(trade, tradeState, swapInputError)
  const theme = useTheme()
  const toggleWalletDropdown = useToggleWalletDropdown()
  const toggleWalletModal = useToggleWalletModal()
  const walletIsOpen = useModalIsOpen(ApplicationModal.WALLET_DROPDOWN)
  const isClaimAvailable = useIsNftClaimAvailable((state) => state.isClaimAvailable)

  const error = useAppSelector((state) => state.connection.errorByConnectionType[connectionType])
  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)

  const hasPendingTransactions = !!pending.length
  const toggleWallet = toggleWalletDropdown

  // if (!chainId) {
  //   return null
  // } else
  if (error) {
    return (
      <Web3StatusError onClick={toggleWallet}>
        <NetworkIcon />
        <Text>
          <Trans>Error</Trans>
        </Text>
      </Web3StatusError>
    )
  } else if (account) {
    const chevronProps = {
      ...CHEVRON_PROPS,
      color: theme.textSecondary,
    }

    return (
      <Web3StatusConnected
        data-testid="web3-status-connected"
        onClick={toggleWallet}
        pending={hasPendingTransactions}
        isClaimAvailable={isClaimAvailable}
      >
        <Box display={{ sm: 'none', xxl: 'block' }}>
          <NotchedButtonFill padding="15px 30px" height="45px" width="165px">
            {!hasPendingTransactions && <StatusIcon size={24} connectionType={connectionType} />}
            {hasPendingTransactions ? (
              <RowBetween>
                <Text>
                  <Trans>{pending?.length} Pending</Trans>
                </Text>{' '}
                <Loader stroke="black" />
              </RowBetween>
            ) : (
              <AddressAndChevronContainer>
                <Text>{ENSName || shortenAddress(account, 2)}</Text>
                {/* {walletIsOpen ? <ChevronUp {...chevronProps} /> : <ChevronDown {...chevronProps} />
              } */}
              </AddressAndChevronContainer>
            )}
          </NotchedButtonFill>
        </Box>

        <Box display={{ sm: 'block', xxl: 'none' }}>
          <NotchedButtonFill padding="7px 12px">
            {!hasPendingTransactions && <StatusIcon size={24} connectionType={connectionType} />}
            {hasPendingTransactions ? (
              <RowBetween>
                <Text>
                  <Trans>{pending?.length} Pending</Trans>
                </Text>{' '}
                <Loader stroke="black" />
              </RowBetween>
            ) : (
              <AddressAndChevronContainer>
                <Text>{ENSName || shortenAddress(account, 2)}</Text>
                {/* {walletIsOpen ? <ChevronUp {...chevronProps} /> : <ChevronDown {...chevronProps} />
              } */}
              </AddressAndChevronContainer>
            )}
          </NotchedButtonFill>
        </Box>
      </Web3StatusConnected>
    )
  } else {
    const chevronProps = {
      ...CHEVRON_PROPS,
      color: '#68ff9b',
      'data-testid': 'navbar-wallet-dropdown',
    }
    return (
      <TraceEvent
        events={[BrowserEvent.onClick]}
        name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
        properties={{ received_swap_quote: validSwapQuote }}
        element={ElementName.CONNECT_WALLET_BUTTON}
      >
        <Web3StatusConnectWrapper faded={!account}>
          <Box display={{ sm: 'none', xxl: 'block' }}>
            <NotchedButtonFill
              data-testid="navbar-connect-wallet"
              padding="15px 30px"
              height="45px"
              width="220px"
              onClick={toggleWalletModal}
            >
              CONNECT WALLET
            </NotchedButtonFill>
          </Box>

          <Box display={{ sm: 'block', xxl: 'none' }}>
            <NotchedButtonFill
              data-testid="navbar-connect-wallet"
              padding="15px 30px"
              height="45px"
              width="165px"
              onClick={toggleWalletModal}
            >
              CONNECT
            </NotchedButtonFill>
          </Box>

          {/* <VerticalDivider />
          <ChevronWrapper onClick={toggleWalletDropdown}>
            {walletIsOpen ? <ChevronUp {...chevronProps} /> : <ChevronDown {...chevronProps} />}
          </ChevronWrapper> */}
        </Web3StatusConnectWrapper>
      </TraceEvent>
    )
  }
}

export default function Web3Status() {
  const { ENSName } = useWeb3React()

  const allTransactions = useAllTransactions()
  const ref = useRef<HTMLDivElement>(null)
  const walletRef = useRef<HTMLDivElement>(null)
  const closeModal = useCloseModal(ApplicationModal.WALLET_DROPDOWN)
  const isOpen = useModalIsOpen(ApplicationModal.WALLET_DROPDOWN)

  useOnClickOutside(ref, isOpen ? closeModal : undefined, [walletRef])

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)
  const confirmed = sortedRecentTransactions.filter((tx) => tx.receipt).map((tx) => tx.hash)

  return (
    <span ref={ref}>
      <Web3StatusInner />
      <WalletModal ENSName={ENSName ?? undefined} pendingTransactions={pending} confirmedTransactions={confirmed} />
      <Portal>
        <span ref={walletRef}>
          <WalletDropdown />
        </span>
      </Portal>
    </span>
  )
}
