import { LP_POOL_V2_ADDRESSES_MAP, V3STAKER_ADDRESSES_MAP } from 'constants/addresses'
import { SupportedChainId } from 'constants/chains'
import { RPC_URLS } from 'constants/networks'
import { ethers } from 'ethers'
import { getContract } from 'utils'
import create from 'zustand'
import { devtools } from 'zustand/middleware'
import v3staker_abi from '../abis/alpha/V3Staker.json'
import v2PoolFactory_abi from '../abis/alpha/V2PoolFactory.json'
import { alphaPoolAddress, alphaPoolAddressV2 } from 'constants/poolListData'
import dayjs from 'dayjs'

export type PoolV3Info = {
    poolId: string
    poolName: string
    incentiveId: string
    token0: string
    token1: string
    pool: string
    startTime: string
    endTime: string
    lockDuration: string
    numberOfStakes: string
    rewardToken: string
    totalRewardUnclaimed: number
    totalSecondsClaimedX128: string
    refundee: string
    poolFee: string
    tvl: string
    userTvl: string
    apr: string
    totalLiquidity: any
    totaStakeValue: any
    totalDepositLiq: any
    tvlRate: any
    hasEnded: boolean
}
export type PoolV2Info = {
    poolId: number
    poolName: string
    incentiveId: string
    _stakedToken: string
    _rewardToken: string
    _poolAddress: string
    _bonusEndBlock: string
    _maxBoostingMultiplier: string
    _maxLockTime: string
    _minLockTime: string
    _poolLimitPerUser: string
    _rewardPerBlock: string
    _totalStaked: string
    _virtualTotalStake: string
    _poolEnd: any
    _poolReward: any
    userTvl: any
    apr: any
    tvl: any
    currencyIdA?: string
    currencyIdB?: string
    hasEnded: boolean
    currentBlockNumber: number
}

type AlphaStoreType = {
    totalV3Pools: PoolV3Info[]
    totalV2Pools: PoolV2Info[]
    v3stakerWithNoSign: ethers.Contract
    v2PoolFactoryContractNoSign: ethers.Contract
    setTotalV3Pools: (pools: PoolV3Info[]) => void
    initV3Pools: () => Promise<void>
    setTotalV2Pools: (pools: PoolV2Info[]) => void
    initV2Pools: () => Promise<void>
}

const provider = new ethers.providers.JsonRpcProvider(RPC_URLS[SupportedChainId.BLAST][2])
export const useAlphaStore = create<AlphaStoreType>()(
    devtools((set, get) => ({
        totalV3Pools: [],
        totalV2Pools: [],
        v3stakerWithNoSign: getContract(V3STAKER_ADDRESSES_MAP[81457], v3staker_abi, provider),
        v2PoolFactoryContractNoSign: getContract(LP_POOL_V2_ADDRESSES_MAP[81457], v2PoolFactory_abi, provider),
        setTotalV3Pools: (pools: PoolV3Info[]) => {
            set({
                totalV3Pools: pools
            })
        },
        initV3Pools: async () => {
            const totalPool = await get().v3stakerWithNoSign?.getIncentiveKeys('0', '100')
            const incentiveIds = await get().v3stakerWithNoSign?.getIncentiveIds()
            let formattedPools: any = []
            let formattedEndPools: any = []
            let topPools: any = []
            for (let i = 0; i < totalPool.length; i++) {
                let poolItem = totalPool[i]
                let formattedPoolItem = {
                    poolId: i.toString(),
                    incentiveId: String(incentiveIds[i]).toLowerCase(),
                    token0: poolItem.token0.toString(),
                    token1: poolItem.token1.toString(),
                    pool: poolItem.pool.toString().toLowerCase(),
                    startTime: poolItem.startTime.toString(),
                    endTime: poolItem.endTime.toString(),
                    numberOfStakes: poolItem.numberOfStakes.toString(),
                    rewardToken: poolItem.rewardToken.toString(),
                    refundee: poolItem.refundee.toString(),
                    lockDuration: poolItem.lockDuration.toString(),
                    poolFee: poolItem.fee.toString(),
                    totalDepositLiq: Number(ethers.utils.formatUnits(poolItem.totalLiquidity, 18)).toFixed(2),
                    totalRewardUnclaimed: Number(ethers.utils.formatUnits(poolItem.totalRewardUnclaimed, 18))
                        .toFixed(2)
                        .toString(),
                    totalSecondsClaimedX128: Number(ethers.utils.formatUnits(poolItem.totalSecondsClaimedX128, 18))
                        .toFixed(2)
                        .toString(),
                    poolName: alphaPoolAddress[81457!][i]?.poolName,
                    hasEnded: (Date.now() / 1000) > Number(poolItem.endTime.toString())
                }
                if (formattedPoolItem.hasEnded)
                    formattedEndPools.push(formattedPoolItem)
                else {
                    const TOPPOOLS = ['0x79fb89437cb97a6bbffb9c76b0f0dfa102b4c07d', '0x0d44f3f22f917cc57368a6d30ba18d5801789cd9', '0x77e149741b4aa14511324e4b554845063ed038bd', '0x27c71ba3e6aa2249f4c5517680ed786c560af0d7']
                    if (TOPPOOLS.includes(formattedPoolItem.pool))
                        topPools.push(formattedPoolItem)
                    else formattedPools.push(formattedPoolItem)
                }
            }

            set({
                totalV3Pools: [...topPools, ...formattedPools, ...formattedEndPools]
            })
        },
        setTotalV2Pools: (pools: PoolV2Info[]) => {
            set({
                totalV2Pools: pools
            })
        },
        initV2Pools: async () => {
            // get current block
            const currentBlockNumber = await provider?.getBlockNumber()
            const totalV2Pool = await get().v2PoolFactoryContractNoSign?.getPools('0', '100')

            let poolInput = alphaPoolAddressV2[81457!]
            let formattedPools: any[] = []
            for (let i = 0; i < totalV2Pool?.length; i++) {
                let poolItem = totalV2Pool[i]

                let formattedPoolItem = {
                    poolId: i.toString(),
                    poolName: alphaPoolAddressV2[81457!][i]?.poolName ?? '',
                    pool: poolItem?._poolAddress.toString(),
                    incentiveId: String(poolItem?._stakedToken).toLowerCase(),
                    _stakedToken: String(poolItem?._stakedToken).toLowerCase(),
                    _rewardToken: poolItem?._rewardToken.toString(),
                    _poolAddress: poolItem?._poolAddress.toString(),
                    _bonusEndBlock: poolItem?._bonusEndBlock.toString(),
                    _maxBoostingMultiplier: poolItem?._maxBoostingMultiplier.toString(),
                    _maxLockTime: poolItem?._maxLockTime.toString(),
                    _minLockTime: poolItem?._minLockTime.toString(),
                    _poolLimitPerUser: ethers.utils.formatUnits(poolItem?._poolLimitPerUser, 18),
                    _rewardPerBlock: ethers.utils.formatUnits(poolItem?._rewardPerBlock, 18),
                    _totalStaked: ethers.utils.formatUnits(poolItem?._totalStaked, 18),
                    _virtualTotalStake: ethers.utils.formatUnits(poolItem?._virtualTotalStake, 18),
                    _startBlock: poolItem?._startBlock.toString(),
                    currentBlockNumber: currentBlockNumber?.toString(),
                    _poolReward:
                        (Number(poolItem?._bonusEndBlock.toString()) - Number(currentBlockNumber?.toString())) *
                        Number(ethers.utils.formatUnits(poolItem?._rewardPerBlock, 18)),
                    _poolEnd: poolInput[i]?._poolEndTime,
                    hasEnded: dayjs.utc().isAfter(dayjs.utc(Number(poolInput[i]._poolEndTime) * 1000))
                }
                formattedPools.push(formattedPoolItem)
            }

            set({
                totalV2Pools: [...formattedPools]
            })
        }
    }))
)