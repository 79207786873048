import { Timeline } from "antd"
import { useEffect, useState } from "react"
import styled from "styled-components"


const MonoStepDot = styled.div`
    width: 32px;
    height: 32px;
    transform: rotate(45deg);
    background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
    position: relative;
    z-index: 1;
    span{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        rotate: 316deg;
        color: #0A090F;
        font-family: Righteous;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 20px */
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

const RoadMapStep = styled.div`
    position: relative;
    width: 240px;
    padding: 24px;
    img.bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`
const RoadMapStepLine = styled.div`
    position: absolute;
    top: 0.5px;
    left: -79px;
    width: 319px;
    height: 1px;
    background:  linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
    @media screen and (max-width: 450px) {
        left: -29px;
        width: 269px;
    }
`

const RoadMapContent = styled.div`
    display: grid;
    gap: 12px;
`
const RoadMapTitle = styled.div`
    text-align: left;
    font-family: Righteous;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 29.4px */
    background:  linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`
const RoadMapDesc = styled.div`
    ul{
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        gap: 6px;
    }
    li{
        display: flex;
        align-items: center;
        gap: 8px;
    }
    li .dot{
        width: 6px;
        height: 6px;
        transform: rotate(45deg);
        background: #EFEFE4;
    }
    li span{
        color: #EFEFE4;
        font-family: Urbanist;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
    }
    
`

const CustomDot = ({ index }: any) => (
    <MonoStepDot    >
        <span>
            {index}
        </span>
    </MonoStepDot>
)

export default function IdoRoadMap() {
    const [isTablet, setIsTablet] = useState<boolean>(false)
    useEffect(() => {
        setIsTablet(window.screen.width <= 1280)
    }, [])

    return <div className='roadmap-steps'>
        <Timeline
            style={{ width: 'fit-content', margin: 'auto' }}
            items={[
                {
                    children: <RoadMapStep>
                        <img className="bg" src="/launchpad/step-bg.png" alt="monoswap" />
                        <RoadMapStepLine />
                        <RoadMapContent>
                            <RoadMapTitle>Feb 2024</RoadMapTitle>
                            <RoadMapDesc>
                                <ul>
                                    <li>
                                        <div className="dot" />
                                        <span>Community Setup</span>
                                    </li>
                                    <li>
                                        <div className="dot" />
                                        <span>V2 + V3 AMM Testnet</span>
                                    </li>
                                    <li>
                                        <div className="dot" />
                                        <span>MonoPass Testnet Minting</span>
                                    </li>
                                    <li>
                                        <div className="dot" />
                                        <span>MUSD Testnet</span>
                                    </li>
                                </ul>
                            </RoadMapDesc>
                        </RoadMapContent>
                    </RoadMapStep>,
                    dot: <CustomDot index="01" />,
                },
                {
                    children: <RoadMapStep>
                        <img className="bg" src="/launchpad/step-bg.png" alt="monoswap" />
                        <RoadMapStepLine />
                        <RoadMapContent>
                            <RoadMapTitle>Mar 2024</RoadMapTitle>
                            <RoadMapDesc>
                                <ul>
                                    <li>
                                        <div className="dot" />
                                        <span>V2 + V3 AMM Mainnet</span>
                                    </li>
                                    <li>
                                        <div className="dot" />
                                        <span>MUSD Mainnet</span>
                                    </li>
                                    <li>
                                        <div className="dot" />
                                        <span>MonoXP</span>
                                    </li>
                                </ul>
                            </RoadMapDesc>
                        </RoadMapContent>
                    </RoadMapStep>,
                    dot: <CustomDot index="02" />,
                },

                {
                    children: <RoadMapStep>
                        <img className="bg" src="/launchpad/step-bg.png" alt="monoswap" />
                        <RoadMapStepLine />
                        <RoadMapContent>
                            <RoadMapTitle>Apr 2024</RoadMapTitle>
                            <RoadMapDesc>
                                <ul>
                                    <li>
                                        <div className="dot" />
                                        <span>V3 Farming pools</span>
                                    </li>
                                    <li>
                                        <div className="dot" />
                                        <span>V2 Farming pools</span>
                                    </li>
                                </ul>
                            </RoadMapDesc>
                        </RoadMapContent>
                    </RoadMapStep>,
                    dot: <CustomDot index="03" />,
                },

                {
                    children: <RoadMapStep>
                        <img className="bg" src="/launchpad/step-bg.png" alt="monoswap" />
                        <RoadMapStepLine />
                        <RoadMapContent>
                            <RoadMapTitle>May 2024</RoadMapTitle>
                            <RoadMapDesc>
                                <ul>
                                    <li>
                                        <div className="dot" />
                                        <span>MonoChest</span>
                                    </li>
                                    <li>
                                        <div className="dot" />
                                        <span>MonoPot</span>
                                    </li>
                                    <li>
                                        <div className="dot" />
                                        <span>MonoTrading Tournament</span>
                                    </li>
                                    <li>
                                        <div className="dot" />
                                        <span>xMONO Staking</span>
                                    </li>
                                </ul>
                            </RoadMapDesc>
                        </RoadMapContent>
                    </RoadMapStep>,
                    dot: <CustomDot index="04" />,
                },
                {
                    children: <RoadMapStep>
                        <img className="bg" src="/launchpad/step-bg.png" alt="monoswap" />
                        <RoadMapStepLine />
                        <RoadMapContent>
                            <RoadMapTitle>Jun 2024</RoadMapTitle>
                            <RoadMapDesc>
                                <ul>
                                    <li>
                                        <div className="dot" />
                                        <span>MONO Launch</span>
                                    </li>
                                    <li>
                                        <div className="dot" />
                                        <span>CEX Listing</span>
                                    </li>
                                    <li>
                                        <div className="dot" />
                                        <span>MonoPad</span>
                                    </li>
                                </ul>
                            </RoadMapDesc>
                        </RoadMapContent>
                    </RoadMapStep>,
                    dot: <CustomDot index="05" />,
                },
                {
                    children: <RoadMapStep>
                        <img className="bg" src="/launchpad/step-bg.png" alt="monoswap" />
                        <RoadMapStepLine />
                        <RoadMapContent>
                            <RoadMapTitle>Q3 2024</RoadMapTitle>
                            <RoadMapDesc>
                                <ul>
                                    <li>
                                        <div className="dot" />
                                        <span>iNFT</span>
                                    </li>
                                    <li>
                                        <div className="dot" />
                                        <span>Maestro Pools</span>
                                    </li>
                                    <li>
                                        <div className="dot" />
                                        <span>Limit Order</span>
                                    </li>
                                </ul>
                            </RoadMapDesc>
                        </RoadMapContent>
                    </RoadMapStep>,
                    dot: <CustomDot index="06" />,
                },
                {
                    children: <RoadMapStep>
                        <img className="bg" src="/launchpad/step-bg.png" alt="monoswap" />
                        <RoadMapStepLine />
                        <RoadMapContent>
                            <RoadMapTitle>Q4 2024</RoadMapTitle>
                            <RoadMapDesc>
                                <ul>
                                    <li>
                                        <div className="dot" />
                                        <span>Cross-chain Swap</span>
                                    </li>
                                    <li>
                                        <div className="dot" />
                                        <span>V4 AMM</span>
                                    </li>
                                    <li>
                                        <div className="dot" />
                                        <span>Zap</span>
                                    </li>
                                </ul>
                            </RoadMapDesc>
                        </RoadMapContent>
                    </RoadMapStep>,
                    dot: <CustomDot index="07" />,
                },
                {
                    children: <RoadMapStep>
                        <img className="bg" src="/launchpad/step-bg.png" alt="monoswap" />
                        <RoadMapStepLine />
                        <RoadMapContent>
                            <RoadMapTitle>2025</RoadMapTitle>
                            <RoadMapDesc>
                                <ul>
                                    <li>
                                        <div className="dot" />
                                        <span>MonoAggregator</span>
                                    </li>
                                    <li>
                                        <div className="dot" />
                                        <span>MLP Token</span>
                                    </li>
                                    <li>
                                        <div className="dot" />
                                        <span>MonoPerp</span>
                                    </li>
                                </ul>
                            </RoadMapDesc>
                        </RoadMapContent>
                    </RoadMapStep>,
                    dot: <CustomDot index="08" />,
                },

            ]}
        />
    </div>
}