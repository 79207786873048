function shuffleArray(arr: any[]): any[] {
    // Start from the last element and swap
    // one by one. We don't need to run for
    // the first element that's why i > 0
    for (let i = arr.length - 1; i > 0; i--) {
        // pick a random index from 0 to i inclusive
        const j = Math.floor(Math.random() * (i + 1)); // at random index
        // Swap arr[i] with the element
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr
}

export default shuffleArray