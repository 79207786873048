import { useWeb3React } from '@web3-react/core'
import { NotchedButtonFill } from 'components/Button'
import Loader from 'components/Loader'
import { getChainInfo } from 'constants/chainInfo'
import { SupportedChainId } from 'constants/chains'
import { CheckMarkIcon } from 'nft/components/icons'
import styled, { useTheme } from 'styled-components/macro'

const LOGO_SIZE = 32

const Container = styled.button`
  height: 100%;
  display: grid;
  background: none;
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
  text-align: left;
  line-height: 24px;
  padding: 5px 10px;
  border: none;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 5px;
  color: #efefe4;
  width: 240px;
  transition: ${({ theme }) => theme.transition.duration.medium} ${({ theme }) => theme.transition.timing.ease}
    background-color;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    width: 100%;
  }
/* 
  &:hover {
    color: black;
    background: linear-gradient(90deg,#68ffff 0%,#68ff9b 100%);
  } */
`

const Label = styled.div`
  color: black;
  font-family: Righteous;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.5px;
  grid-column: 2;
  grid-row: 1;
`

const Status = styled.div`
  grid-column: 3;
  grid-row: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${LOGO_SIZE}px;
`

const ApproveText = styled.div`
  color: black;
  font-family: Righteous;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  grid-column: 2;
  grid-row: 2;
`

const Logo = styled.img`
  height: ${LOGO_SIZE}px;
  width: ${LOGO_SIZE}px;
  margin-right: 12px;
`;

const ActiveChainIcon = styled.div`
  width: 10px;
  height: 10px;
  background-color: yellow;
  border-radius: 50%;
`
const ChainItem = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

export default function ChainSelectorRow({
  targetChain,
  onSelectChain,
  isPending,
}: {
  targetChain: SupportedChainId
  onSelectChain: (targetChain: number) => void
  isPending: boolean
}) {
  const { chainId } = useWeb3React()
  const active = chainId === targetChain
  const { label, logoUrl } = getChainInfo(targetChain)

  const theme = useTheme()

  return (

    <Container style={{ display: 'block' }} onClick={() => onSelectChain(targetChain)}>
      <NotchedButtonFill as="div" style={{ display: 'block' }} width="220px" height="40px" mobileMaxW='200px'>
        <ChainItem style={{ display: 'flex' }}>
          <Logo src={logoUrl} alt={label} />
          {isPending ? <ApproveText>Approve in wallet</ApproveText> : <Label>{label}</Label>}
          {
            (active || isPending) && <Status>
              {active && <ActiveChainIcon />}
              {isPending && <Loader width={LOGO_SIZE} height={LOGO_SIZE} stroke="black" />}
            </Status>
          }
        </ChainItem>

      </NotchedButtonFill>
    </Container>
  )
}
