import { useEffect } from "react"
import { AppState } from "state"
import { useAppDispatch, useAppSelector } from "state/hooks"
import { updateTvlMap } from "./reducer"


export function useAlphaTvlMap() {
    const tvlMap = useAppSelector((state: AppState) => state.alphapool.tvlMap) 
    return tvlMap
}
 