import { Currency } from '@uniswap/sdk-core'
import styled from 'styled-components/macro'

import CurrencyLogo from '../Logo/CurrencyLogo'

const Wrapper = styled.div<{ margin: boolean; sizeraw: number }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-left: ${({ sizeraw, margin }) => margin && (sizeraw / 3 + 8).toString() + 'px'};
`

interface DoubleCurrencyLogoProps {
  margin?: boolean
  size?: number
  currency0?: Currency
  currency1?: Currency
}

const HigherLogo = styled(CurrencyLogo)`
  z-index: 1;
`
const CoveredLogo = styled(CurrencyLogo)<{ sizeraw: number }>`
  position: absolute;
  left: ${({ sizeraw }) => '-' + (sizeraw / 1.2).toString() + 'px'} !important;
  z-index: 2;
`

export default function DoubleCurrencyLogo({
  currency0,
  currency1,
  size = 16,
  margin = false,
}: DoubleCurrencyLogoProps) {
  return (
    <Wrapper sizeraw={size} margin={margin}>
      {currency0 && <HigherLogo currency={currency0} size={size.toString() + 'px'} />}
      {currency1 && <CoveredLogo currency={currency1} size={(size + 4).toString() + 'px'} sizeraw={size} />}
    </Wrapper>
  )
}
export function SingleCurrencyLogo({ currency0, size = 16, margin = false }: DoubleCurrencyLogoProps) {
  return (
    <Wrapper sizeraw={size} margin={margin}>
      {currency0 && <HigherLogo currency={currency0} size={size.toString() + 'px'} />}
    </Wrapper>
  )
}

// double logo input img url
interface DoubleLogoProps {
  margin?: boolean
  size?: number
  img1?: string
  img2?: string
}
const Higher = styled.div<{ sizeraw: number }>`
  z-index: 1;
  width: ${({ sizeraw }) => (sizeraw ? sizeraw : 16)}!important;
  height: ${({ sizeraw }) => (sizeraw ? sizeraw : 16)} !important;
  border-radius: 50%;
`
const Covered = styled.div<{ sizeraw: number }>`
  position: absolute;
  left: ${({ sizeraw }) => '-' + (sizeraw / 1.2).toString() + 'px'} !important;
  width: ${({ sizeraw }) => (sizeraw ? sizeraw : 16)}!important;
  height: ${({ sizeraw }) => (sizeraw ? sizeraw : 16)}!important;
  border-radius: 50%;
  z-index: 2;
`
export function DoubleLogo({ img1, img2, size, margin = false }: DoubleLogoProps) {
  return (
    <Wrapper sizeraw={size ? size : 16} margin={margin}>
      <Higher sizeraw={size ? size : 16}>
        <div style={{ overflow: 'hidden' }}>
          <img src={img2} alt="logo2" width={size} style={{ borderRadius: '50%' }} />
        </div>
      </Higher>
      <Covered sizeraw={size ? size : 16}>
        <div style={{ overflow: 'hidden' }}>
          <img src={img1} alt="logo1" width={size ? size + 4 : 20} style={{ borderRadius: '50%' }} />
        </div>
      </Covered>
    </Wrapper>
  )
}

const HigherLogoV2 = styled(CurrencyLogo)`
  z-index: 2;
`
const CoveredLogoV2 = styled(CurrencyLogo)<{ sizeraw: number }>`
  position: absolute;
  left: ${({ sizeraw }) => '-' + (sizeraw / 1.2).toString() + 'px'} !important;
  z-index: 1;
`

export function DoubleCurrencyLogoV2({ currency0, currency1, size = 16, margin = false }: DoubleCurrencyLogoProps) {
  return (
    <Wrapper sizeraw={size} margin={margin}>
      {currency0 && <HigherLogoV2 currency={currency0} size={(size - 4).toString() + 'px'} />}
      {currency1 && <CoveredLogoV2 currency={currency1} size={(size + 10).toString() + 'px'} sizeraw={size} />}
    </Wrapper>
  )
}
