import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NotchedButtonBolderNew } from 'components/Button/NotchedButton'
import { NotchedButtonFill } from 'components/Button'
import DoubleCurrencyLogo, { DoubleLogo, SingleCurrencyLogo } from 'components/DoubleLogo'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from '../../state/swap/hooks'
import './poolsInfo.css'
import SettingsTab from '../../components/Settings'
import { Currency, CurrencyAmount, Percent } from '@uniswap/sdk-core'
import { MouseoverTooltip } from 'components/Tooltip'
import { AlertTriangle, ArrowDown, CheckCircle, HelpCircle, Info, PlusSquare, Activity, Gift } from 'react-feather'
import { PoolList } from '../../constants/poolListData'
import axios, { Method } from 'axios'
import { CustomLightSpinner } from 'theme'
import { formatXpNumber } from 'pages/Xp/sections/XpTitle'
import { useWeb3React } from '@web3-react/core'
import { useToken } from 'hooks/Tokens'
import styled from 'styled-components'
import { Trans } from '@lingui/macro'
import { useAlphaTvlMap } from 'state/alphapool/hooks'
import { useAlphaStore } from 'hooks/useAlphaStore'

// img
const search = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/maestro/search.svg'
const Circle = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/blue-loader.svg'

interface RowType {
  id: string
  attributes: {
    fdv_usd: string
    base_token_price_usd: string
    transactions: {
      h24: {
        buys: string
        sells: string
      }
    }
    price_change_percentage: {
      m5: number
      h1: number
      h6: number
      h24: number
    }
    volume_usd: {
      h24: string
    }
    reserve_in_usd: string
    name: string
  }
  relationships: {
    base_token: {
      data: {
        id: string
      }
    }
    quote_token: {
      data: {
        id: string
      }
    }
    dex: {
      data: {
        id: string
      }
    }
  }
  token0: string
  token1: string
  liquidity: number
}

const TextLinear = styled.span`
  font-family: Righteous;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 17.5px */
  background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

// trim token address
function trimId(id: any) {
  return String(id).replace('blast_', '').toLowerCase()
}

const PoolsInfo = () => {
  const navigate = useNavigate()
  const alphaStore = useAlphaStore()
  const {
    trade: { state: tradeState, trade },
    allowedSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo()
  const [tabSelector, setTabSelector] = useState(1)
  const DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)
  // v3 pool info
  // get lp of each pool
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false)
  const [v3DataList, setV3DataList] = useState<RowType[]>([])
  const [v2DataList, setV2DataList] = useState<RowType[]>([])
  const [totalReserveInUsdV3, setTotalReserveInUsdV3] = useState<any>()
  const [totalVolumeUsdH24V3, setTotalVolumeUsdH24V3] = useState<any>()
  const [totalTransactionsV3, seTtotalTransactionsV3] = useState<any>()
  const [totalReserveInUsdV2, setTotalReserveInUsdV2] = useState<any>()
  const [totalVolumeUsdH24V2, setTotalVolumeUsdH24V2] = useState<any>()
  const [totalTransactionsV2, seTtotalTransactionsV2] = useState<any>()
  const headers = {
    'Content-Type': 'application/json',
  }
  function filterPoolsWithH24Transactions(pools: any) {
    return pools?.filter((pool: any) => {
      const h24Transactions = pool.attributes.transactions.h24
      return h24Transactions.buys > 0 || h24Transactions.sells > 0
    })
  }

  async function getV3PoolList() {
    if (isLoadingData || tabSelector == 2) return
    let url = `https://api.geckoterminal.com/api/v2/networks/blast/dexes/monoswap-v3-blast/pools?include=monoswap-v3-blast&page=1`
    let method: Method = 'get'
    const config = { method, url, headers }
    try {
      setIsLoadingData(true)
      const res = await axios(config)
      if (res) {
        // return res?.data?.data[0].attributes?.reserve_in_usd
        const poolRes = filterPoolsWithH24Transactions(res?.data.data) as RowType[]
        let totalLiquid = 0
        let totalVolumh24 = 0
        let totalTransh24 = 0
        const filteredPools = poolRes.filter(i => i?.attributes?.name?.includes('USDB / WETH 0.3') || i?.attributes?.name?.includes('BLAST / WETH 0.3'))
        filteredPools.forEach((pool: RowType) => {
          if (pool.attributes?.name?.includes('BLAST / WETH 0.3')) {
            totalLiquid += (1225.12)
            totalVolumh24 += (212.7)
            totalTransh24 += Number(pool.attributes.transactions.h24.buys) + Number(pool.attributes.transactions.h24.sells)

            pool.token0 = String(pool.relationships.base_token.data.id).replace('blast_', '')
            pool.token1 = String(pool.relationships.quote_token.data.id).replace('blast_', '')
            pool.liquidity = 1225.12
          } else {
            totalLiquid += (parseFloat(pool.attributes.reserve_in_usd) / 10)
            totalVolumh24 += (parseFloat(pool.attributes.volume_usd.h24) / 10)
            totalTransh24 += Number(pool.attributes.transactions.h24.buys) + Number(pool.attributes.transactions.h24.sells)

            pool.token0 = String(pool.relationships.base_token.data.id).replace('blast_', '')
            pool.token1 = String(pool.relationships.quote_token.data.id).replace('blast_', '')
            pool.liquidity = Number(pool.attributes.reserve_in_usd) / 10
          }
        })
        setTotalReserveInUsdV3(totalLiquid)
        setTotalVolumeUsdH24V3(totalVolumh24/10)
        seTtotalTransactionsV3(Math.floor(totalTransh24/10))

        setV3DataList(filteredPools.sort((a, b) => -(a.liquidity - b.liquidity)))
      } else {
        setTotalReserveInUsdV3(0)
        setTotalVolumeUsdH24V3(0)
        seTtotalTransactionsV3(0)
      }
    } catch (error) {
      console.log('error', error)
      setTotalReserveInUsdV3(0)
      setTotalVolumeUsdH24V3(0)
      seTtotalTransactionsV3(0)
    }
    setIsLoadingData(false)
  }

  async function getV2PoolList() {
    if (isLoadingData || tabSelector == 1) return
    let url = `https://api.geckoterminal.com/api/v2/networks/blast/dexes/monoswap-V2-blast/pools?include=monoswap-V2-blast&page=1`
    let method: Method = 'get'
    const config = { method, url, headers }
    try {
      setIsLoadingData(true)
      const res = await axios(config)
      if (res) {
        const poolRes = filterPoolsWithH24Transactions(res?.data.data) as RowType[]
        let totalLiquid = 0
        let totalVolumh24 = 0
        let totalTransh24 = 0

        const filteredPools = poolRes.filter(i => i?.attributes?.name?.includes('USDB / WETH') || i?.attributes?.name?.includes('BLAST / WETH'))
        filteredPools.forEach((pool: any) => {
          totalLiquid += (parseFloat(pool.attributes.reserve_in_usd) / 10)
          totalVolumh24 += (parseFloat(pool.attributes.volume_usd.h24) / 10)
          totalTransh24 += pool.attributes.transactions.h24.buys + pool.attributes.transactions.h24.sells
          pool.token0 = String(pool.relationships.base_token.data.id).replace('blast_', '')
          pool.token1 = String(pool.relationships.quote_token.data.id).replace('blast_', '')
          pool.liquidity = Number(pool.attributes.reserve_in_usd) / 10
        })
        setTotalReserveInUsdV2(totalLiquid)
        setTotalVolumeUsdH24V2(totalVolumh24)
        seTtotalTransactionsV2(Math.floor(totalTransh24/10))

        setV2DataList(filteredPools.sort((a, b) => -(a.liquidity - b.liquidity)))

      } else {
        setTotalReserveInUsdV2(0)
        setTotalVolumeUsdH24V2(0)
        seTtotalTransactionsV2(0)
      }
    } catch (error) {
      console.log('error', error)
      setTotalReserveInUsdV2(0)
      setTotalVolumeUsdH24V2(0)
      seTtotalTransactionsV2(0)
    }
    setIsLoadingData(false)
  }

  const [searchValue, setSearchValue] = useState<string>('')
  const handleSearchChange = (value: string) => setSearchValue(value)

  const filteredV3Pools = useMemo(() => v3DataList.filter(item => item.attributes.name.includes(searchValue.toUpperCase())), [searchValue, v3DataList])
  const filteredV2Pools = useMemo(() => v2DataList.filter(item => item.attributes.name.includes(searchValue.toUpperCase())), [searchValue, v2DataList])

  const reloadFarmingPool = async () => {
    if (tabSelector == 1) {
      getV3PoolList()
      alphaStore.initV3Pools()
    }
    else {
      alphaStore.initV2Pools()
      getV2PoolList()
    }
  }

  useEffect(() => {
    getV3PoolList()
    getV2PoolList()
    if (alphaStore.totalV3Pools.length <= 0)
      alphaStore.initV3Pools()
    if (alphaStore.totalV2Pools.length <= 0)
      alphaStore.initV2Pools()
  }, [])

  useEffect(() => {
    if (tabSelector == 1 && v3DataList.length == 0) getV3PoolList()
    else if (tabSelector == 2 && v2DataList.length == 0) getV2PoolList()
  }, [tabSelector])


  return (
    <>
      <div className="alpha-container" style={{ marginTop: '50px' }}>
        <div className="swap-top-container" style={{ alignItems: 'flex-end' }}>
          <div className="swap-top-left" style={{ alignItems: 'flex-end' }}>
            {/* <img src={maestroImg} alt="swap image" /> */}
            <div className="swap-top-div">
              <div className='tab-pool-selector-wrapper'>
                <div className="tab-pool-selector">
                  <NotchedButtonBolderNew w="50px" h="100%" borderColor={'rgba(239, 239, 228, 0.5)'}>
                    <div
                      className={`tab-pool-selector-text ${tabSelector === 1 ? 'tab-pool-active' : ''}`}
                      onClick={() => {
                        setTabSelector(1)
                      }}
                    >
                      V3
                    </div>
                  </NotchedButtonBolderNew>
                  <NotchedButtonBolderNew w="50px" h="100%" borderColor={'rgba(239, 239, 228, 0.5)'}>
                    <div
                      className={`tab-pool-selector-text ${tabSelector === 2 ? 'tab-pool-active' : ''}`}
                      onClick={() => {
                        setTabSelector(2)
                      }}
                    >
                      V2
                    </div>
                  </NotchedButtonBolderNew>
                </div>

              </div>
              <p className='page-title'>All Pools</p>
              <p className='page-desc'>Add liquidity to the AMM and receive protocol incentives in MUSD</p>
            </div>
          </div>
          <div className="swap-top-right" style={{ paddingBottom: '10px' }}>
            <div className="maestro-search">
              <img src={search} alt="swap image" />
              <input type="text" onChange={e => handleSearchChange(e.target.value)} />
            </div>
            <div className='alpha-setting'>
              <img src="/farming/reload_icon.png" alt="monoswap" onClick={reloadFarmingPool} />
            </div>
          </div>
        </div>
      </div>

      <div className="alpha-summary">
        <div className='alpha-summary_item'>
          <img className='desc_icon' src="/farming/lock_tvl_icon.png" alt="monoswap" />
          <div className='summary_item_content'>
            <span>Total TVL</span>
            {
              isLoadingData ? <CustomLightSpinner src={Circle} alt="loader" size="16px" />
                :
                <h5>
                  ${tabSelector == 1 ? formatXpNumber(totalReserveInUsdV3) : formatXpNumber(totalReserveInUsdV2)}
                </h5>
            }
          </div>
        </div>

        <div className='alpha-summary-separator' />

        <div className='alpha-summary_item'>
          <img src="/farming/24h_vol_icon.png" alt="monoswap" />
          <div className='summary_item_content'>
            <span>24h Volume</span>
            {
              isLoadingData ? <CustomLightSpinner src={Circle} alt="loader" size="16px" />
                :
                <h5>
                  ${tabSelector == 1 ? formatXpNumber(totalVolumeUsdH24V3) : formatXpNumber(totalVolumeUsdH24V2)}
                </h5>
            }
          </div>
        </div>

        <div className='alpha-summary-separator' />

        <div className='alpha-summary_item'>
          <img src="/farming/exchange_icon.png" alt="monoswap" />
          <div className='summary_item_content'>
            <span>24h Transactions</span>
            {
              isLoadingData ? <CustomLightSpinner src={Circle} alt="loader" size="16px" />
                :
                <h5>
                  {tabSelector == 1 ? formatXpNumber(totalTransactionsV3) : formatXpNumber(totalTransactionsV2)}
                </h5>
            }
          </div>
        </div>

      </div>

      <div className="alpha-list-card-container">
        {
          tabSelector === 1 && v3DataList &&
          filteredV3Pools?.map((row: any, index: number) => <AlphaV3CardItem key={index} alphaInfo={row} />)
        }
      </div>

      <div className="alpha-list-card-container">
        {tabSelector === 2 && v2DataList && (
          <>
            {
              filteredV2Pools?.map((row, index) => <AlphaV2CardItem key={index} alphaInfo={row} />)
            }
          </>
        )}
      </div>
      {
        isLoadingData && <div style={{ width: '100%', height: '200px', textAlign: 'center', marginTop: '40px' }}>
          <CustomLightSpinner src={Circle} alt="loader" size="32px" />
        </div>
      }
    </>
  )
}


function extractPoolFee(inputString: string) {
  const regex = /(\d+(\.\d+)?%)/;
  let match = inputString.match(regex);
  if (match) {
    return Number(match[0].replace('%', '')); // Return the first match found
  } else {
    return 0; // Return null if no match found
  }
}

const AlphaV3CardItem = ({
  alphaInfo,
}: {
  alphaInfo: RowType
}) => {
  const { account } = useWeb3React()
  const navigate = useNavigate()
  const tvlMap = useAlphaTvlMap()
  const alphaStore = useAlphaStore()

  const [poolFee, setPoolFee] = useState<number>(0)
  const poolInfoV3 = useMemo(() => {
    if (alphaStore.totalV3Pools.length == 0) return undefined
    else return alphaStore.totalV3Pools.find(item => item.pool == trimId(alphaInfo.id))
  }, [alphaStore.totalV3Pools])

  const poolTvl = useMemo(() => {
    if (alphaInfo) return tvlMap.get(poolInfoV3?.incentiveId ?? '') ?? 0
    else return 0
  }, [poolInfoV3, tvlMap])

  const vol24h = useMemo(() => Number(alphaInfo.attributes.volume_usd.h24) / 10, [alphaInfo])

  const fee24h = useMemo(() => vol24h * poolFee, [vol24h, poolFee])

  const apr = useMemo(() => {
    if (poolInfoV3) {
      const aprDay = poolTvl * 90
      const apr = (poolInfoV3.totalRewardUnclaimed * 2 * 365 * 100) / aprDay
      return formatXpNumber(apr)
    }
    else return 0
  }, [poolTvl])

  const tokenA = useToken(alphaInfo.token0)
  const tokenB = useToken(alphaInfo.token1)

  useEffect(() => {
    setPoolFee(extractPoolFee(alphaInfo.attributes.name) / 100)
  }, [])

  return <div className="alpha-list-card"
  >
    <div className='alpha-list-card-gift-box'>
      <img src="/farming/v2/icon1.png" alt="monoswap" />
      <img src="/farming/v2/icon2.png" alt="monoswap" />
      <img src="/farming/v2/icon3.png" alt="monoswap" />
      <img src="/farming/v2/icon4.png" alt="monoswap" />
    </div>
    <div className="alpha-list-card-tag"></div>
    <div className="alpha-list-card-detail">
      <div className="alpha-list-card-detail-top">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div className="alpha-list-card-detail-top-img">
            <DoubleCurrencyLogo
              currency0={tokenA ?? undefined}
              currency1={tokenB ?? undefined}
              size={28}
            />
          </div>
          <div>
            <p
              style={{
                color: '#efefe4',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              {alphaInfo.attributes.name.replace(`${poolFee * 100}%`, '')}{' '}
              <span
                style={{
                  color: '#68FF9B'
                }}
              >
                {
                  poolFee * 100
                }%
              </span>
            </p>
            {/* <span style={{ color: '#68FFFF', fontSize: '16px' }}>v3</span> */}
          </div>
        </div>

        <div>
          <NotchedButtonBolderNew
            w='63px'
            h={'24px'}
            borderColor='linear-gradient(90deg, #68ffff 0%, #68ff9b 100%)'
            clipPath='unset'
          >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', cursor: 'pointer' }} onClick={() => {
              navigate(
                `/add/${trimId(alphaInfo?.relationships?.base_token?.data?.id)}/${trimId(
                  alphaInfo?.relationships?.quote_token?.data?.id
                )}`
              )
            }
            }>
              <TextLinear style={{ lineHeight: 'unset' }}>
                <Trans>Add</Trans>
              </TextLinear>
            </div>
          </NotchedButtonBolderNew>
        </div>
      </div>

      <div className="alpha-list-card-detai--info">
        <div
          className="alpha-list-card-detai--info-child"
          style={{
            borderTop: '1px solid rgba(239,239,228,0.2)',
          }}
        >
          <p>Liquidity</p>
          <p style={{ marginLeft: '0 !important' }}>
            {' '}
            <span style={{ color: '#efefe4' }}>
              <p>
                ${formatXpNumber(alphaInfo.liquidity)}
              </p>
            </span>
          </p>
        </div>
        <div className="alpha-list-card-detai--info-child">
          <p>APR</p>
          <p>
            <span style={{ color: '#68FF9B' }}>
              {apr}%
            </span>
          </p>
        </div>

        <div className="alpha-list-card-detai--info-child">
          <p>24h Volume</p>
          <div className="alpha-list-card-detai--info-child-right">
            ${formatXpNumber(vol24h)}
          </div>
        </div>
        {/* <div className="alpha-list-card-detai--info-child">
                          <p>Requirements</p>
                          <div className="alpha-list-card-detai--info-child-right">
                            <img src={requireIcon} alt="icon" />
                          </div>
                        </div> */}
        <div className="alpha-list-card-detai--info-child">
          <p>Fee (24h)</p>
          <p>
            $
            {formatXpNumber(fee24h)}
          </p>
        </div>


        {/* <div className="alpha-list-card-detai--info-child">
                          <p>Pool reward</p>
                          <p>{item?.totalRewardUnclaimed}</p>
                        </div> */}
      </div>
    </div>
  </div>
}

const AlphaV2CardItem = ({
  alphaInfo,
}: {
  alphaInfo: RowType
}) => {
  const navigate = useNavigate()
  const tvlMap = useAlphaTvlMap()
  const alphaStore = useAlphaStore()

  const poolInfoV2 = useMemo(() => {
    if (alphaStore.totalV2Pools.length == 0) return undefined
    else return alphaStore.totalV2Pools.find(item => item.incentiveId == trimId(alphaInfo.id))
  }, [alphaStore.totalV2Pools])

  const poolTvl = useMemo(() => {
    if (!poolInfoV2) return 0
    const tvl = tvlMap.get(poolInfoV2._stakedToken) ?? 0
    return tvl
  }, [tvlMap, poolInfoV2])

  const vol24h = useMemo(() => {
    if (!alphaInfo) return 0
    return Number(alphaInfo.attributes.volume_usd.h24) / 10
  }, [alphaInfo])

  const fee24h = useMemo(() => vol24h * 0.003, [vol24h])

  const apr = useMemo(() => {
    if (poolTvl && poolInfoV2) {
      const apr =
        (Number(Number(poolInfoV2?._bonusEndBlock.toString()) - Number(poolInfoV2?.currentBlockNumber)) *
          Number(poolInfoV2?._rewardPerBlock) *
          2 *
          365 *
          100) /
        (90 * poolTvl)
      return formatXpNumber(apr)
    }
    return 0
  }, [poolInfoV2, poolTvl])

  const tokenA = useToken(alphaInfo.token0)
  const tokenB = useToken(alphaInfo.token1)

  return <div className="alpha-list-card"
  >
    <div className='alpha-list-card-gift-box'>
      <img src="/farming/v2/icon1.png" alt="monoswap" />
      <img src="/farming/v2/icon2.png" alt="monoswap" />
      <img src="/farming/v2/icon3.png" alt="monoswap" />
      <img src="/farming/v2/icon4.png" alt="monoswap" />
    </div>
    <div className="alpha-list-card-tag"></div>
    <div className="alpha-list-card-detail">
      <div className="alpha-list-card-detail-top">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div className="alpha-list-card-detail-top-img">
            <DoubleCurrencyLogo
              currency0={tokenA ?? undefined}
              currency1={tokenB ?? undefined}
              size={28}
            />
          </div>
          <div style={{ marginLeft: '10px', textAlign: 'left' }}>
            <p
              style={{
                color: '#efefe4',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              {alphaInfo.attributes.name}{' '}
            </p>
          </div>
        </div>

        <div>
          <NotchedButtonBolderNew
            w='63px'
            h={'24px'}
            borderColor='linear-gradient(90deg, #68ffff 0%, #68ff9b 100%)'
            clipPath='unset'
          >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', cursor: 'pointer' }} onClick={() => {
              navigate(`/add/v2/${trimId(alphaInfo?.relationships?.base_token?.data?.id)}/${trimId(alphaInfo?.relationships?.quote_token?.data?.id)}`)
            }
            }>
              <TextLinear style={{ lineHeight: 'unset' }}>
                <Trans>
                  Add
                </Trans>
              </TextLinear>
            </div>
          </NotchedButtonBolderNew>
        </div>
      </div>

      <div className="alpha-list-card-detai--info">
        <div
          className="alpha-list-card-detai--info-child"
          style={{
            borderTop: '1px solid rgba(239,239,228,0.2)',
          }}
        >
          <p>Liquidity</p>
          <p style={{ marginLeft: '0 !important' }}>
            {' '}
            <span style={{ color: '#efefe4' }}>
              <p>
                ${formatXpNumber(alphaInfo.liquidity)}
              </p>
            </span>
          </p>
        </div>
        <div className="alpha-list-card-detai--info-child">
          <p>APR</p>
          <p>
            <span style={{ color: '#68FF9B' }}>
              {formatXpNumber(apr)}%</span>
          </p>
        </div>

        <div className="alpha-list-card-detai--info-child">
          <p>24h Volume</p>
          <div className="alpha-list-card-detai--info-child-right">
            ${formatXpNumber(vol24h)}
          </div>
        </div>
        {/* <div className="alpha-list-card-detai--info-child">
                          <p>Requirements</p>
                          <div className="alpha-list-card-detai--info-child-right">
                            <img src={requireIcon} alt="icon" />
                          </div>
                        </div> */}
        <div className="alpha-list-card-detai--info-child">
          <p>Fee (24h)</p>
          <p>
            $
            {formatXpNumber(fee24h)}
          </p>
        </div>
      </div>
    </div>
  </div>
}
export default PoolsInfo
export const numberWithCommas = (number: any) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
