import styled from "styled-components"
import { PageTitleBox, PageTitle, PageDesc, TextLinear } from "../MonoPotPage"
import { Steps, StepsProps } from "antd";
import { Cell, Pie, PieChart } from "recharts"
import { useEffect, useState } from "react"
import { BREAKPOINTS } from "theme";

const MonotPotGuideWrapper = styled.section`
    max-width: 1224px;
    margin: auto;
    position: relative;
    z-index: 1;
    @media screen and (max-width: ${BREAKPOINTS.xl}px) {
        max-width: 90%;
    }
    
`

const MonoStepBox = styled.div`
    display: grid;
    max-width: 1000px;
    margin: auto;
    margin-top: 50px;
    @media screen and (max-width: 1280px) {
        max-width: 450px;
        height: 700px;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        max-width: 90%;
        height: 650px;
    }
`

const MonoStep = styled.div`
    margin-top: 20px;
    max-width: 392px;
    min-height: 141px;
    width: 100%;
    background: rgba(239, 239, 228, 0.05);
    position: relative;
    min-width: 392px;
    @media screen and (max-width: 1300px) {
        margin-top: 0;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        min-width: unset;
    }
`
const MonoStepTop = styled.div`
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    border: 5px dashed #efefe424;
`

const MonoStepContent = styled.div`
    display: grid;
    gap: 8px;
    padding: 24px 20px;
    padding-top: 46px;
    text-align: left;
`
const MonoStepTitle = styled.h5`
    color: #E5E5DF;
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 22.5px */
    margin: 0;
`
const MonoStepDesc = styled.span`
    color: rgba(229, 229, 223, 0.50);
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 20px */
`

const MonoStepDot = styled.div`
    width: 36px;
    height: 36px;
    transform: rotate(45deg);
    background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
    position: relative;
    z-index: 1;
    span{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        rotate: 316deg;
        color: #0A090F;
        text-align: left;
        font-family: Urbanist;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 125%; /* 35px */
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

const WinningCriteriaBox = styled.div`
    margin-top: 64px ;
`

const WinningCriteriaTitleBox = styled.div`
    display: grid;
    gap: 12px;
`

export const Text = styled.span`
    color: #E5E5DF;
    font-family: Urbanist;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 35px */
`
const WinningCriteriaInfoBox = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;
    margin-top: 48px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        display: grid;
    }
`
const WinningCriteriaInfoItem = styled.div`
    display: grid;
    gap: 25px;
    width: 100%;
    max-width: 50%;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        max-width: 100%;
        justify-items: center;
        span.win_info{
            padding: 0px !important;
            width: 95%;
        }
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        span{
            font-size: 12px !important;
        }
    }
`

const PrizeFundBox = styled.div`
    display: flex;
    margin-top: 56px;
    gap: 10px;
    @media screen and (max-width: 1100px){
        display: grid;
    }
`
const PrizeFundInfo = styled.div`
    display: grid;
    width: 100%;
    max-width: 50%;
    ul{
        display: grid;
        gap: 16px;
        
    }
    ul span{
        font-size: 16px;
    }
    @media screen and (max-width: 1100pxpx){
        max-width: 100%;
    }
   
`
const PrizeFundChartInfo = styled.div`
    width: 100%;
    max-width: 50%;
    background: rgba(239, 239, 228, 0.08);
    display: flex;
    align-items: center;
    gap: 30px;
    @media screen and (max-width: ${BREAKPOINTS.xl}px){
        gap: 10px;
    }
    @media screen and (max-width: 1100px){
        max-width: 100%;
        justify-content: center;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        display: grid;
        justify-items: center;
        padding-bottom: 15px;
    }
`
const PrizeFundChartInfoContent = styled.div`
    display: flex;
    gap: 51px;
    @media screen and (max-width: ${BREAKPOINTS.xl}px){
        gap: 15px;
    }
    
`
export default function MonotPotGuide() {
    const data02 = [
        {
            "name": "Matches first 1",
            "value": 2,
            "fill": "#68FFFF"
        },
        {
            "name": "Matches first 2",
            "value": 3,
            "fill": "#0075FF"
        },
        {
            "name": "Matches first 3",
            "value": 5,
            "fill": "#68FF9B"
        },
        {
            "name": "Matches first 4",
            "value": 15,
            "fill": "#00BF41"
        },
        {
            "name": "Matches first 5",
            "value": 25,
            "fill": "#D3B7F5"
        },
        {
            "name": "Matches first 6",
            "value": 50,
            "fill": "#6850F0"
        },
    ];
    const [isTablet, setIsTablet] = useState<boolean>(false)
    useEffect(() => {
        setIsTablet(window.screen.width <= 1280)
    }, [])
    return <MonotPotGuideWrapper>
        <PageTitleBox>
            <PageTitle>
                <TextLinear>How to </TextLinear>Play
            </PageTitle>
            <PageDesc>
                Nail the right order, hit the winning numbers, and boom! You’re in for a share of the prize pool.
            </PageDesc>
        </PageTitleBox>

        <MonoStepBox>
            <Steps
                direction={isTablet ? 'vertical' : 'horizontal'}
                labelPlacement="vertical"
                items={[
                    {
                        description: <MonoStep>
                            <MonoStepTop />
                            <MonoStepContent>
                                <MonoStepTitle>Buy ticket</MonoStepTitle>
                                <MonoStepDesc>
                                    When the round kicks off, each ticket is priced at
                                    just 2 USD, payable in MUSD.
                                </MonoStepDesc>
                            </MonoStepContent>
                        </MonoStep>,
                        icon: <CustomDot index="1" />,
                    },
                    {
                        description: <MonoStep>
                            <MonoStepTop />
                            <MonoStepContent>
                                <MonoStepTitle>Wait for the Draw</MonoStepTitle>
                                <MonoStepDesc>
                                    Draws are held daily, alternating between 0 AM
                                    UTC and 12 PM UTC.
                                </MonoStepDesc>
                            </MonoStepContent>
                        </MonoStep>,
                        icon: <CustomDot index="2" />,
                    },
                    {
                        description: <MonoStep>
                            <MonoStepTop />
                            <MonoStepContent>
                                <MonoStepTitle>Check for Prizes</MonoStepTitle>
                                <MonoStepDesc>
                                    After the round ends, don't forget to revisit the
                                    page to see if you're a winner!
                                </MonoStepDesc>
                            </MonoStepContent>
                        </MonoStep>,
                        icon: <CustomDot index="3" />,
                    },
                ]}
            />
        </MonoStepBox>

        <WinningCriteriaBox>


            <WinningCriteriaTitleBox>
                <Text style={{ marginBottom: '6px' }}>
                    Winning criteria
                </Text>
                <TextLinear style={{ fontSize: '16px' }}>
                    Your ticket numbers need to be in the exact sequence as the winning numbers to qualify for a prize.
                </TextLinear>
                <Text style={{ color: 'rgba(239, 239, 228, 0.70)', fontSize: '16px' }}>
                    Non-Stacking Prizes: Prizes are specific to each bracket: matching the first three digits qualifies you for the “Match 3” prize only, not the “Match 1” or “Match 2” prizes.
                </Text>
            </WinningCriteriaTitleBox>

            <WinningCriteriaInfoBox>
                <WinningCriteriaInfoItem>
                    <img style={{ width: '100%', maxWidth: '600px', maxHeight: '230px' }} src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/lottery/winning_result_info.png" />
                    <Text className="win_info" style={{ fontSize: '15px', paddingLeft: '10px', paddingRight: '10px' }}>
                        <Text style={{ fontSize: '15px', color: 'rgba(239, 239, 228, 0.50' }}>Ticket A:</Text> This ticket matches the first 3 digits and the last 2 digits, but not the 4th digit. Therefore, it only wins a prize for matching the first 3 digits.
                    </Text>
                </WinningCriteriaInfoItem>
                <WinningCriteriaInfoItem>
                    <img style={{ width: '100%', maxWidth: '600px', maxHeight: '230px' }} src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/lottery/winning_result_info2.png" />
                    <Text className="win_info" style={{ fontSize: '15px', paddingLeft: '10px', paddingRight: '50px' }}>
                        <Text style={{ fontSize: '15px', color: 'rgba(239, 239, 228, 0.50' }}>Ticket B:</Text> This ticket gets the last 5 digits right but the first digit is wrong, so it doesn't win any prize.
                    </Text>
                </WinningCriteriaInfoItem>
            </WinningCriteriaInfoBox>

        </WinningCriteriaBox>

        <PrizeFundBox>
            <PrizeFundInfo>
                <Text>
                    Prize Funds
                </Text>
                <ul>
                    <li>
                        <Text style={{ fontWeight: 400 }}>
                            <Text>Ticket Sales:</Text> All of the MUSD collected from ticket sales in a particular round is allocated to that round's prize pools.
                        </Text>
                    </li>
                    <li>
                        <Text style={{ fontWeight: 400 }}>
                            <Text>MonoChest Sales:</Text> 10% of the amount used to purchase MonoChest during the round will be added to the prize pool.
                        </Text>
                    </li>
                    <li>
                        <Text style={{ fontWeight: 400 }}>
                            <Text>Rollover Prizes:</Text> If there are no winners in a specific prize bracket at the end of a round, the unclaimed MUSD for that bracket is rolled over to the next round's prize pools.
                        </Text>
                    </li>
                </ul>
            </PrizeFundInfo>

            <PrizeFundChartInfo>
                <PieChart width={isTablet ? 200 : 250} height={isTablet ? 200 : 250}>
                    <Pie data={data02} dataKey="value" nameKey="name" cx="50%" cy="50%" blendStroke={false}>
                        {data02.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.fill} startOffset={100} />
                        ))}
                    </Pie>

                </PieChart>

                <PrizeFundChartInfoContent>
                    <div>
                        <Text style={{ color: 'rgba(239, 239, 228, 0.50)', fontSize: '14px' }}>
                            Digits matched
                        </Text>
                        <div style={{ display: 'grid', gap: '6px', marginTop: '15px' }}>
                            {
                                data02.map((item, index) => <div key={index} style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
                                    <div style={{
                                        borderRadius: '50%',
                                        background: item.fill,
                                        width: '16px',
                                        height: '16px'
                                    }} />
                                    <Text style={{ fontSize: '15px' }}>
                                        {item.name}
                                    </Text>
                                </div>)
                            }
                        </div>
                    </div>

                    <div>
                        <Text style={{ color: 'rgba(239, 239, 228, 0.50)', fontSize: '14px' }}>
                            Prize pool allocation
                        </Text>
                        <div style={{ display: 'grid', gap: '6px', marginTop: '15px' }}>
                            {
                                data02.map((item, index) => <div key={index} style={{ display: 'flex', gap: '6px', justifyContent: 'flex-end' }}>
                                    <Text style={{ fontSize: '15px' }}>
                                        {item.value}%
                                    </Text>
                                </div>)
                            }
                        </div>
                    </div>
                </PrizeFundChartInfoContent>

            </PrizeFundChartInfo>
        </PrizeFundBox>

    </MonotPotGuideWrapper>
}

const CustomDot = ({ index }: any) => (
    <MonoStepDot    >
        <span>
            {index}
        </span>
    </MonoStepDot>
)