import Modal from '../Modal'
import { Trans } from '@lingui/macro'
import { Text } from 'rebass'

import styled, { useTheme } from 'styled-components/macro'
import { CloseIcon, CustomLightSpinner } from '../../theme'
import { AutoColumn, ColumnCenter } from '../Column'
import { ButtonLight, ButtonPrimary, NotchedButtonFillPrimary, NotchedButtonFill } from '../Button'
import { CheckCircle, AlertCircle, AlertTriangle } from 'react-feather'
import AnimatedConfirmation from '../TransactionConfirmationModal/AnimatedConfirmation'
import { ethers } from 'ethers'
import { useEffect, useRef, useState } from 'react'
import CheckButtonComponent from 'components/Button/CheckButton'
import { Contract } from '@ethersproject/contracts'
import { useWeb3React } from '@web3-react/core'
import { NONFUNGIBLE_POSITION_MANAGER_ADDRESSES, V3STAKER_ADDRESSES_MAP } from '../../constants/addresses'
import { useTokenContract, useContract, useCustomeContract } from 'hooks/useContract'
import v3staker_abi from '../../abis/alpha/V3Staker.json'
import ERC721 from '../../abis/erc721.json'
import { AnyCnameRecord } from 'dns'
const Circle = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/blue-loader.svg'
import emptyImage from '../../assets/images/alpha/message-circle-question.svg'
import { useGetCustomeImage } from '../../hooks/useGetCustomeImage'
import { useMultiplePositions } from '../../hooks/useMultiplePositions'
import Badge, { BadgeVariant } from '../Badge'
import { defaultAbiCoder } from '@ethersproject/abi'

interface HarvestModalInterface {
  isOpen: boolean
  onDismiss: () => void
  poolInfo: any
  tokenInfo: any
  userPositionsLocked: any
  handleDataFromChild: (data: any) => void
  handleGetTime: (data: any) => void
  handleUserTotalLiquidity: (data: any) => void
  trigger: boolean
}
const Wrapper = styled.div`
  background-color: #1c1b20;
  outline: none;
  width: 100%;
  padding: 10px 20px;
`
const WrapperTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    color: #efefe4;
  }
`
const WrapperMid = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const NFTGrid = styled.div`
  display: grid;
  grid-template: 'overlap';
  min-height: 100px;
`
const NFTCanvas = styled.canvas`
  grid-area: overlap;
`
const NFTImage = styled.img`
  grid-area: overlap;
  height: 100px;
  /* Ensures SVG appears on top of canvas. */
  z-index: 1;
`
function getSnapshot(src: HTMLImageElement, canvas: HTMLCanvasElement, targetHeight: number) {
  const context = canvas.getContext('2d')

  if (context) {
    let { width, height } = src

    // src may be hidden and not have the target dimensions
    const ratio = width / height
    height = targetHeight
    width = Math.round(ratio * targetHeight)

    // Ensure crispness at high DPIs
    canvas.width = width * devicePixelRatio
    canvas.height = height * devicePixelRatio
    canvas.style.width = width + 'px'
    canvas.style.height = height + 'px'
    context.scale(devicePixelRatio, devicePixelRatio)

    context.clearRect(0, 0, width, height)
    context.drawImage(src, 0, 0, width, height)
  }
}

function NFT({ image, height: targetHeight }: { image: string; height: number }) {
  const [animate, setAnimate] = useState(false)
  useRef
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)

  return (
    <NFTGrid
      onMouseEnter={() => {
        setAnimate(true)
      }}
      onMouseLeave={() => {
        // snapshot the current frame so the transition to the canvas is smooth
        if (imageRef.current && canvasRef.current) {
          getSnapshot(imageRef.current, canvasRef.current, targetHeight)
        }
        setAnimate(false)
      }}
    >
      <NFTCanvas ref={canvasRef} />
      <NFTImage
        ref={imageRef}
        src={image}
        hidden={!animate}
        onLoad={() => {
          // snapshot for the canvas
          if (imageRef.current && canvasRef.current) {
            getSnapshot(imageRef.current, canvasRef.current, targetHeight)
          }
        }}
      />
    </NFTGrid>
  )
}

const mapData = (data: any) => {
  return data.map((item: any) => {
    return {
      tokenId: item.tokenId,
    }
  })
}

function calculateTotalLiquidity(data: any) {
  return data?.reduce((total: any, item: any) => {
    const totalValue = Number(item?.userLiquidity)
    return total + totalValue
  }, 0)
}

export default function WithdrawModal({
  isOpen,
  onDismiss,
  poolInfo,
  tokenInfo,
  userPositionsLocked,
  handleDataFromChild,
  handleGetTime,
  trigger,
  handleUserTotalLiquidity,
}: HarvestModalInterface) {
  const { account, chainId } = useWeb3React()
  const [customeChainId, setCustomeChainId] = useState(81457)
  useEffect(() => {
    if (chainId) {
      setCustomeChainId(chainId)
    } else {
      setCustomeChainId(81457)
    }
  }, [account, chainId])
  // state
  const [loadingUnstake, setLoadingUnstake] = useState(false)
  const [loadingUserStakeInfo, setLoadingUserStakeInfo] = useState(false)
  const [stakeNFTList, setStakeUserList] = useState<any>([])
  // select to harvest
  const [checkedIds, setCheckedIds] = useState<string[]>([])
  const handleCheckChange = (formattedTokenId: string, isChecked: boolean) => {
    if (isChecked) {
      setCheckedIds([formattedTokenId])
    } else {
      setCheckedIds([])
    }
  }

  // mapped params
  function mapClaimRewardCalldata(poolInfo: any) {
    const rewardToken = poolInfo.rewardToken
    const refundee = poolInfo.refundee
    const result = [rewardToken, refundee, ethers.constants.MaxUint256]
    return result
  }
  function mapStakeAndUnstakeCallData(checkedIds: any, poolInfo: any) {
    return checkedIds.map((id: any, index: any) => {
      return {
        ['Data']: [
          {
            rewardToken: poolInfo.rewardToken,
            pool: poolInfo.pool,
            startTime: ethers.BigNumber.from(poolInfo.startTime),
            endTime: ethers.BigNumber.from(poolInfo.endTime),
            lockDuration: ethers.BigNumber.from(poolInfo.lockDuration),
            refundee: poolInfo.refundee,
          },
          ethers.BigNumber.from(id),
        ],
      }
    })
  }
  function mapGetData(poolInfo: any) {
    const rewardToken = poolInfo.rewardToken
    const refundee = poolInfo.refundee
    const pool = poolInfo.pool
    const startTime = ethers.BigNumber.from(poolInfo.startTime)
    const endTime = ethers.BigNumber.from(poolInfo.endTime)
    const lockDuration = ethers.BigNumber.from(poolInfo.lockDuration)

    const result = [rewardToken, pool, startTime, endTime, lockDuration, refundee]
    return result
  }
  // using custom image

  const mappedData = mapData(stakeNFTList)
  const imgArray = useGetCustomeImage(mappedData)
  function filterImageArray(data: any) {
    return data?.filter((item: any) => {
      const matchesItems = item.valid === true
      return matchesItems
    })
  }
  let filteredNftImage = filterImageArray(imgArray)
  // contract
  const STAKING_ADDRESS = V3STAKER_ADDRESSES_MAP[chainId!]

  const v3stakerWithSign = useCustomeContract(STAKING_ADDRESS, v3staker_abi, true)

  // get user stake info
  const handleGetUserInfo = async () => {
    setLoadingUserStakeInfo(true)
    try {
      const tokenIdByUser = await v3stakerWithSign?.getTokensByOwner(account, '0', '100000')
      const items = await Promise.all(
        tokenIdByUser?.map(async (item: any, index: number) => {
          const userPosition = userPositionsLocked[index]
          const paramsGetRewardInfo = mapGetData(poolInfo)
          const userRewardByTokenId = await v3stakerWithSign?.getRewardInfos([item.tokenId])
          let timeNowStampInSeconds = Math.floor(Date.now() / 1000)
          let lockedUntil = item.deposit.lockUntil.toString()
          let isUnstake = Number(timeNowStampInSeconds) < Number(lockedUntil) ? false : true
          let countdownTime =
            Number(lockedUntil) - Number(timeNowStampInSeconds) > 0
              ? Number(lockedUntil) - Number(timeNowStampInSeconds)
              : 0

          const encodedKey = defaultAbiCoder.encode(
            [
              'address',
              'address',
              'uint256',
              'uint256',
              'uint256',
              'address'
            ],
            [
              item.incentiveKey.rewardToken,
              item.incentiveKey.pool,
              item.incentiveKey.startTime,
              item.incentiveKey.endTime,
              item.incentiveKey.lockDuration,
              item.incentiveKey.refundee
            ]
          );
          const incentiveId = ethers.utils.keccak256(encodedKey)

          return {
            tokenId: item.tokenId.toString(),
            userReward: paramsGetRewardInfo,
            // rewardValue: Number(userRewardByTokenId[0]?.reward),
            rewardValue: ethers?.utils?.formatUnits(userRewardByTokenId[0]?.reward),
            lockedTime: paramsGetRewardInfo[4].toString(),
            lockedUntil: lockedUntil,
            isUnstake: isUnstake,
            countdownTime: countdownTime,
            poolAddress: item.incentiveKey.pool,
            userLiquidity: ethers?.utils?.formatUnits(item?.liquidity),
            outOfRange: userPosition?.outOfRange ?? false,
            incentiveId: incentiveId
          }
        })
      )

      const encodedKey = defaultAbiCoder.encode(
        [
          'address',
          'address',
          'uint256',
          'uint256',
          'uint256',
          'address'
        ],
        [
          poolInfo?.rewardToken,
          poolInfo?.pool,
          poolInfo?.startTime,
          poolInfo?.endTime,
          poolInfo?.lockDuration,
          poolInfo?.refundee
        ]
      );
      const poolInfoIncentiveId = ethers.utils.keccak256(encodedKey)


      let filterByPool = items?.filter((item: any) => {
        return item?.poolAddress?.toLowerCase() === poolInfo?.pool?.toLowerCase() &&
          (item?.incentiveId?.toLowerCase() === poolInfoIncentiveId?.toLowerCase())
      })
      let totalLiq = calculateTotalLiquidity(filterByPool).toFixed(2)
      setStakeUserList(filterByPool)
      handleDataFromChild(filterByPool?.length)
      handleGetTime(filterByPool[0]?.countdownTime)
      handleUserTotalLiquidity(totalLiq)
      setLoadingUserStakeInfo(false)
    } catch (error) {
      console.log(error)
      setLoadingUserStakeInfo(false)
    }
  }

  const handleUnstake = async () => {
    setLoadingUnstake(true)
    try {
      const paramStakeAndUnstakeCalldata = mapStakeAndUnstakeCallData(checkedIds, poolInfo)
      let formmattedTokenId = paramStakeAndUnstakeCalldata[0].Data[1]
      if (account) {
        const claimCalldata = v3stakerWithSign?.interface.encodeFunctionData(
          'claimReward',
          [poolInfo.rewardToken, account, ethers.constants.MaxUint256]
        )
        const unstakeCalldata = v3stakerWithSign?.interface.encodeFunctionData(
          'unstakeToken',
          paramStakeAndUnstakeCalldata[0].Data
        )
        const withdraw = v3stakerWithSign?.interface.encodeFunctionData('withdrawToken', [
          formmattedTokenId,
          account?.toString(),
          '0x',
        ])
        const tx = await v3stakerWithSign?.multicall([unstakeCalldata, claimCalldata, withdraw])
        await tx.wait()
        if (tx) {
          console.log('tx', tx)
          setLoadingUnstake(false)
          handleGetUserInfo()
          setCheckedIds([])
        }
      }
    } catch (error) {
      console.log(error)
      setLoadingUnstake(false)
    }
  }
  // useEffect(() => {
  //   if (account) {
  //     handleGetUserInfo()
  //   }
  // }, [account, chainId])
  useEffect(() => {
    if (isOpen) {
      handleGetUserInfo()
      setCheckedIds([])
    }
  }, [isOpen])
  // useEffect(() => {
  //   if (userPositionsLocked) {
  //     handleGetUserInfo()
  //   }
  // }, [userPositionsLocked])
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>
        <WrapperTop>
          <p>
            {' '}
            Unstake NFT from {tokenInfo[0]?.currencyA?.symbol}-{tokenInfo[0]?.currencyB?.symbol} Farming
          </p>
          <CloseIcon onClick={onDismiss} />
        </WrapperTop>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          {loadingUserStakeInfo ? (
            <div className="deposit-card-container" style={{ height: '134px' }}>
              <CustomLightSpinner src={Circle} alt="loader" size="22px" />
            </div>
          ) : (
            <>
              {stakeNFTList?.length > 0 ? (
                stakeNFTList?.map((i: any, index: any) => (
                  <div className="deposit-card-container" key={i.tokenId}>
                    <div className="deposit-card-container-title">
                      <p>
                        NFT ID: <span className="deposit-card-container-title-span">{i?.tokenId}</span>{' '}
                        {i.outOfRange ? (
                          <Badge variant={BadgeVariant.WARNING}>
                            <AlertCircle width={14} height={14} />
                            &nbsp;
                            <BadgeText>
                              <Trans>Out of range</Trans>
                            </BadgeText>
                          </Badge>
                        ) : (
                          <></>
                        )}
                      </p>
                      <CheckButtonComponent
                        onCheckChange={(isChecked) => handleCheckChange(i.tokenId, isChecked)}
                        isChecked={checkedIds.includes(i.tokenId)}
                        formattedTokenId={i?.tokenId}
                        disabled={!i.isUnstake}
                      />
                    </div>
                    <div className="deposit-card-info">
                      <div className="deposite-card-img">
                        <NFT image={filteredNftImage[index]?.result?.image} height={100} />
                      </div>
                      <div className="deposite-card-detail">
                        <p> {tokenInfo[0]?.incentive?.symbol} reward: </p>
                        <span className="deposit-card-container-title-span" style={{ fontSize: '22px' }}>
                          {Number(i?.rewardValue).toFixed(8).toString()}
                        </span>
                        <p>
                          Lock until:&nbsp;
                          {/* <CountdownLocktime initialNumber={i?.countdownTime} />s */}
                          {addSecondsAndFormat(i?.countdownTime)}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="deposit-card-container">
                  <div className="deposit-card-container-img">
                    <img src={emptyImage} alt="img" />
                    <p>You don&apos;t have any compatible position</p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <NotchedButtonFill onClick={handleUnstake} style={{ margin: '4px 0 0 0' }} disabled={checkedIds.length === 0}>
          <Text fontWeight={500} fontSize={20}>
            <Trans>{checkedIds.length > 0 ? (loadingUnstake ? 'Unstaking...' : 'Unstake') : 'Select'}</Trans>
          </Text>
        </NotchedButtonFill>
        <NotchedButtonFillPrimary onClick={onDismiss} style={{ margin: '14px 0 0 0' }}>
          <Text fontWeight={500} fontSize={20}>
            <Trans>Close</Trans>
          </Text>
        </NotchedButtonFillPrimary>
      </Wrapper>
    </Modal>
  )
}

interface CountdownLocktimeProps {
  initialNumber: number
}

const CountdownLocktime: React.FC<CountdownLocktimeProps> = ({ initialNumber }) => {
  const [number, setNumber] = useState(initialNumber)
  const intervalRef = useRef<number | null>(null)

  useEffect(() => {
    if (number > 0) {
      intervalRef.current = window.setInterval(() => setNumber(number - 1), 1000)
    }

    return () => {
      if (intervalRef.current !== null) {
        window.clearInterval(intervalRef.current)
      }
    }
  }, [number])

  return (
    <>
      <>{number}</>
    </>
  )
}
function addSecondsAndFormat(seconds: any) {
  const now = Date.now()
  const milliseconds = seconds * 1000
  const futureTimestamp = now + milliseconds
  const futureDate = new Date(futureTimestamp)
  const formattedDate = futureDate.getDate() + '/' + (futureDate.getMonth() + 1) + '/' + futureDate.getFullYear()
  return formattedDate
}

const BadgeText = styled.div`
  font-weight: 500;
  font-size: 12px;
`
