import React from 'react'
import styled from 'styled-components'
import { NotchedButtonBolderNew } from './NotchedButton'
import { NotchedButtonFill, NotchedButtonFillPrimary, NotchedButtonFillBase } from './index'
import VectorCheck from '../../assets/images/alpha/Vector.svg'

interface CheckButtonProps {
  onCheckChange: (isChecked: boolean, formattedTokenId: any) => void
  formattedTokenId: any
  isChecked: boolean // Add this prop to receive the checked state from the parent
  disabled: boolean
}

export const WrapperButton = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Selected = ({ text, onClick, disabled, style, color, width, children }: any) => {
  return (
    <NotchedButtonFill width={'32px'} height={'32px'} padding={'15px   25px'} disabled={disabled}>
      <WrapperButton>
        <img src={VectorCheck} alt="" />
      </WrapperButton>
    </NotchedButtonFill>
  )
}

export const NotSelected = ({ text, onClick, disabled, style, color, width, children }: any) => {
  return (
    <NotchedButtonFillBase width={'32px'} height={'32px'} padding={'15px   25px'} disabled={disabled}>
      <WrapperButton></WrapperButton>
    </NotchedButtonFillBase>
  )
}

const CheckButtonComponent: React.FC<CheckButtonProps> = ({ onCheckChange, formattedTokenId, isChecked, disabled }) => {
  const toggleChecked = () => {
    onCheckChange(true, formattedTokenId) // Always pass true here, indicating this button is being checked
  }

  return (
    <div onClick={toggleChecked}>
      {isChecked ? <Selected disabled={disabled} /> : <NotSelected disabled={disabled} />}
    </div>
  )
}

export default CheckButtonComponent
