import { Currency, CurrencyAmount, TradeType } from '@uniswap/sdk-core'
import { Trade } from '@uniswap/v3-sdk'
import { V3TradeState } from 'state/routing/types'
import { useRoutingAPITrade } from 'state/routing/useRoutingAPITradeV2'
import {useClientSideRouter} from 'state/user/hooks'
import { useClientSideV3Trade } from './useClientSideV3TradeV2'
import useDebounce from './useDebounce'
import useIsWindowVisible from './useIsWindowVisible'
import {useWeb3React} from "@web3-react/core";
import {SupportedChainId} from "../constants/chains";
import {RouterPreference} from "../state/routing/slice";
import {useMemo} from "react";

/**
 * Returns the best v3 trade for a desired swap.
 * Uses optimized routes from the Routing API and falls back to the v3 router.
 * @param tradeType whether the swap is an exact in/out
 * @param amountSpecified the exact amount to swap in/out
 * @param otherCurrency the desired output/payment currency
 */
export function useBestV3Trade(
  tradeType: TradeType,
  amountSpecified?: CurrencyAmount<Currency>,
  otherCurrency?: Currency
): {
  state: V3TradeState
  trade: Trade<Currency, Currency, typeof tradeType> | null
} {
  const autoRouterSupported = false
  const isWindowVisible = useIsWindowVisible()
  const [clientSideRouter] = useClientSideRouter()

  const [debouncedAmount, debouncedOtherCurrency] = useDebounce(
    useMemo(() => [amountSpecified, otherCurrency], [amountSpecified, otherCurrency]),
    200
  )

  const routingAPITrade = useRoutingAPITrade(
    tradeType,
    autoRouterSupported && isWindowVisible ? debouncedAmount : undefined,
    debouncedOtherCurrency,
    clientSideRouter ? RouterPreference.CLIENT : RouterPreference.API
  )

  const debouncing =
    routingAPITrade.trade &&
    amountSpecified &&
    (tradeType === TradeType.EXACT_INPUT
      ? !routingAPITrade.trade.inputAmount.equalTo(amountSpecified) ||
      !amountSpecified.currency.equals(routingAPITrade.trade.inputAmount.currency) ||
      !otherCurrency?.equals(routingAPITrade.trade.outputAmount.currency)
      : !routingAPITrade.trade.outputAmount.equalTo(amountSpecified) ||
      !amountSpecified.currency.equals(routingAPITrade.trade.outputAmount.currency) ||
      !otherCurrency?.equals(routingAPITrade.trade.inputAmount.currency))

  const isLoading = amountSpecified !== undefined && debouncedAmount === undefined
  const useFallback = !autoRouterSupported || (!debouncing && routingAPITrade.state === V3TradeState.NO_ROUTE_FOUND)


  // only use client side router if routing api trade failed
  const bestV3Trade = useClientSideV3Trade(
    tradeType,
    useFallback ? debouncedAmount : undefined,
    useFallback ? otherCurrency : undefined
  )

  return {
    ...(useFallback ? bestV3Trade : routingAPITrade),
    ...(debouncing ? { state: V3TradeState.SYNCING } : {}),
    ...(isLoading ? { state: V3TradeState.LOADING } : {}),
  }
}

export function useRoutingAPIEnabled(): boolean {
  const { chainId } = useWeb3React()
  const [clientSideRouter] = useClientSideRouter()

  return chainId === SupportedChainId.MAINNET && !clientSideRouter
}
