// a list of tokens by chain
import { Currency, Token } from '@uniswap/sdk-core'

import { SupportedChainId } from './chains'
import {
  BTC_BLAST_SEPOLIA,
  DAI,
  MONO_BLAST_SEPOLIA,
  MUSD_BLAST_SEPOLIA,
  USDB_BLAST_SEPOLIA,
  nativeOnChain,
  USDC_BLAST_SEPOLIA,
  USDC_MAINNET,
  USDT,
  WETH_BLAST_SEPOLIA,
  WRAPPED_NATIVE_CURRENCY,
  USDB_BLAST,
  WETH_BLAST,
  MUSD_BLAST,
  PAC_BLAST,
  OLE_BLAST,
  BAJA_BLAST,
  ANDY_BLAST,
  JUICE_BLAST,
  ORBIT_BLAST,
  YIELD_BLAST,
  ezETH_BLAST,
  ZKDX_BLAST,
  KID_BLAST,
  KALAX_BLAST,
  BLAST_TOKEN,
  MONOSWAP_TOKEN
} from './tokens'

type ChainTokenList = {
  readonly [chainId: number]: Token[]
}

type ChainCurrencyList = {
  readonly [chainId: number]: Currency[]
}

const WRAPPED_NATIVE_CURRENCIES_ONLY: ChainTokenList = Object.fromEntries(
  Object.entries(WRAPPED_NATIVE_CURRENCY)
    .map(([key, value]) => [key, [value]])
    .filter(Boolean)
)

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WRAPPED_NATIVE_CURRENCIES_ONLY,
  [SupportedChainId.MAINNET]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.MAINNET], DAI, USDC_MAINNET, USDT],
  [SupportedChainId.BLAST_SEPOLIA]: [
    ...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.BLAST_SEPOLIA],
    USDC_BLAST_SEPOLIA,
    MONO_BLAST_SEPOLIA,
    USDB_BLAST_SEPOLIA,
    MUSD_BLAST_SEPOLIA,
  ],
  [SupportedChainId.BLAST]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.BLAST], USDB_BLAST, MUSD_BLAST],
}
export const ADDITIONAL_BASES: { [chainId: number]: { [tokenAddress: string]: Token[] } } = {
  [SupportedChainId.MAINNET]: {
    // '0xF16E4d813f4DcfDe4c5b44f305c908742De84eF0': [ETH2X_FLI],
    // [rETH2.address]: [sETH2],
    // [SWISE.address]: [sETH2],
    // [FEI.address]: [TRIBE],
    // [TRIBE.address]: [FEI],
    // [FRAX.address]: [FXS],
    // [FXS.address]: [FRAX],
    // [WBTC.address]: [renBTC],
    // [renBTC.address]: [WBTC],
  },
}
/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId: number]: { [tokenAddress: string]: Token[] } } = {
  [SupportedChainId.MAINNET]: {
    // [AMPL.address]: [DAI, WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token],
  },
}

/**
 * Shows up in the currency select for swap and add liquidity
 */
export const COMMON_BASES: ChainCurrencyList = {
  [SupportedChainId.MAINNET]: [
    nativeOnChain(SupportedChainId.MAINNET),
    WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token,
  ],
  [SupportedChainId.BLAST_SEPOLIA]: [
    nativeOnChain(SupportedChainId.BLAST_SEPOLIA),
    WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST_SEPOLIA] as Token,
    MONO_BLAST_SEPOLIA,
    USDC_BLAST_SEPOLIA,
    BTC_BLAST_SEPOLIA,
    MUSD_BLAST_SEPOLIA,
    USDB_BLAST_SEPOLIA,
  ],
  [SupportedChainId.BLAST]: [
    nativeOnChain(SupportedChainId.BLAST),
    WRAPPED_NATIVE_CURRENCY[SupportedChainId.BLAST] as Token,
    USDB_BLAST,
    // MONOSWAP_TOKEN,
    MUSD_BLAST,
    BLAST_TOKEN,
    PAC_BLAST,
    OLE_BLAST,
    KALAX_BLAST,
    ezETH_BLAST,
    JUICE_BLAST,
    ZKDX_BLAST,
    ORBIT_BLAST,
    YIELD_BLAST,
    BAJA_BLAST,
    ANDY_BLAST,
  ],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WRAPPED_NATIVE_CURRENCIES_ONLY,
  [SupportedChainId.MAINNET]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.MAINNET], DAI, USDC_MAINNET, USDT],
  [SupportedChainId.BLAST_SEPOLIA]: [USDC_BLAST_SEPOLIA, MONO_BLAST_SEPOLIA],
  [SupportedChainId.BLAST]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.BLAST], USDB_BLAST, MUSD_BLAST],
}

// Pinned V2 pair lp
export const PINNED_PAIRS: { readonly [chainId: number]: [Token, Token][] } = {
  [SupportedChainId.MAINNET]: [],
  [SupportedChainId.BLAST_SEPOLIA]: [
    [WETH_BLAST_SEPOLIA, USDC_BLAST_SEPOLIA],
    [USDC_BLAST_SEPOLIA, MONO_BLAST_SEPOLIA],
  ],
  [SupportedChainId.BLAST]: [
    [WETH_BLAST, USDB_BLAST],
    [WETH_BLAST, ZKDX_BLAST],
  ],
}

// custome list token selected
/**
 * Shows up in the stable coin currency select for swap and add liquidity
 */
export const STABLE_COIN: ChainCurrencyList = {
  [SupportedChainId.MAINNET]: [
    nativeOnChain(SupportedChainId.MAINNET),
    WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token,
  ],
  [SupportedChainId.BLAST_SEPOLIA]: [MUSD_BLAST_SEPOLIA, USDB_BLAST_SEPOLIA],
  [SupportedChainId.BLAST]: [USDB_BLAST],
}

// meme
export const MEME_TOKEN: ChainCurrencyList = {
  [SupportedChainId.MAINNET]: [
    nativeOnChain(SupportedChainId.MAINNET),
    WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token,
  ],
  [SupportedChainId.BLAST_SEPOLIA]: [MUSD_BLAST_SEPOLIA, USDB_BLAST_SEPOLIA],
  [SupportedChainId.BLAST]: [PAC_BLAST, BAJA_BLAST, ANDY_BLAST, KID_BLAST],
}

// defi

export const DEFI_TOKEN: ChainCurrencyList = {
  [SupportedChainId.MAINNET]: [
    nativeOnChain(SupportedChainId.MAINNET),
    WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token,
  ],
  [SupportedChainId.BLAST_SEPOLIA]: [MUSD_BLAST_SEPOLIA, USDB_BLAST_SEPOLIA],
  [SupportedChainId.BLAST]: [
    // MONOSWAP_TOKEN,
    MUSD_BLAST, ezETH_BLAST,
    ORBIT_BLAST, OLE_BLAST  ,
    JUICE_BLAST, YIELD_BLAST,
    ZKDX_BLAST, KALAX_BLAST,
  ],
}
