import type { Actions, AddEthereumChainParameter, Provider, WatchAssetParameters } from '@web3-react/types';
import { Connector } from '@web3-react/types';


declare type BitgetProvider = Provider & {
    isBitget?: boolean;
    isConnected?: () => boolean;
    providers?: BitgetProvider[];
};
export class NoBitgetError extends Error {
    constructor() {
        super('MetaMask not installed');
        this.name = NoBitgetError.name;
    }
}
/**
 * @param options - Options to pass to `@Bitget/detect-provider`
 * @param onError - Handler to report errors thrown from eventListeners.
 */
export interface BitgetConstructorArgs {
    actions: Actions;
    options?: any;
    onError?: (error: Error) => void;
}
export class Bitget extends Connector {
    /** {@inheritdoc Connector.provider} */
    provider?: BitgetProvider;
    private readonly options?: any;
    // private eagerConnection?: any;
    constructor({ actions, options, onError }: BitgetConstructorArgs) {
        super(actions, onError);
        this.options = options
        this.provider = window.bitkeep && window.bitkeep.ethereum;
    }
    /** {@inheritdoc Connector.connectEagerly} */
    async connectEagerly() {
        const cancelActivation = this.actions.startActivation();
        if (!this.provider)
            return cancelActivation();
        return Promise.all([
            this.provider.request({ method: 'eth_chainId' }),
            this.provider.request({ method: 'eth_accounts' }),
        ])
            .then(([chainId, accounts]: any) => {
                if (accounts.length) {
                    this.actions.update({ chainId: parseChainId(chainId), accounts });
                }
                else {
                    throw new Error('No accounts returned');
                }
            })
            .catch((error) => {
                console.debug('Could not connect eagerly', error);
                // we should be able to use `cancelActivation` here, but on mobile, metamask emits a 'connect'
                // event, meaning that chainId is updated, and cancelActivation doesn't work because an intermediary
                // update has occurred, so we reset state instead
                this.actions.resetState();
            });
    }
    /**
     * Initiates a connection.
     *
     * @param desiredChainIdOrChainParameters - If defined, indicates the desired chain to connect to. If the user is
     * already connected to this chain, no additional steps will be taken. Otherwise, the user will be prompted to switch
     * to the chain, if one of two conditions is met: either they already have it added in their extension, or the
     * argument is of type AddEthereumChainParameter, in which case the user will be prompted to add the chain with the
     * specified parameters first, before being prompted to switch.
     */
    async activate(desiredChainIdOrChainParameters?: number | AddEthereumChainParameter): Promise<void> {
        if (!this.provider)
            throw new NoBitgetError();
        
        const cancelActivation = this.actions.startActivation();
        return Promise.all([
            this.provider.request({ method: 'eth_chainId' }),
            this.provider.request({ method: 'eth_requestAccounts' }),
        ]).then(([chainId, accounts]: any) => {
            if (!this.provider)
                throw new NoBitgetError();

            const receivedChainId = parseChainId(chainId);
            const desiredChainId = typeof desiredChainIdOrChainParameters === 'number'
                ? desiredChainIdOrChainParameters
                : desiredChainIdOrChainParameters === null || desiredChainIdOrChainParameters === void 0 ? void 0 : desiredChainIdOrChainParameters.chainId;
            // if there's no desired chain, or it's equal to the received, update
            if (!desiredChainId || receivedChainId === desiredChainId)
                return this.actions.update({ chainId: receivedChainId, accounts });
            const desiredChainIdHex = `0x${desiredChainId.toString(16)}`;
            // if we're here, we can try to switch networks
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return this.provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: desiredChainIdHex }],
            })
                .catch((error) => {
                    if (error.code === 4902 && typeof desiredChainIdOrChainParameters !== 'number') {
                        // if we're here, we can try to add a new network
                        if (!this.provider)
                            throw new NoBitgetError();
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        return this.provider.request({
                            method: 'wallet_addEthereumChain',
                            params: [Object.assign(Object.assign({}, desiredChainIdOrChainParameters), { chainId: desiredChainIdHex })],
                        });
                    }
                    throw error;
                })
                .then(() => this.activate(desiredChainId));
        });
    }

    async watchAsset({ address, symbol, decimals, image }: WatchAssetParameters): Promise<true> {
        if (!this.provider)
            throw new Error('No provider');
        return this.provider
            .request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address,
                        symbol,
                        decimals,
                        image, // A string url of the token logo
                    },
                },
            })
            .then((success) => {
                if (!success)
                    throw new Error('Rejected');
                return true;
            });
    }
}

function parseChainId(chainId: string) {
    return Number.parseInt(chainId, 16);
}

export { };


