import styled from "styled-components"

import {
    CloseOutlined,
} from "@ant-design/icons"
import { KeyboardEvent, useCallback, useEffect, useMemo, useState } from "react"
import { TextLinear } from "../MonoPotPage"
import { message, notification, Pagination, Popover, Switch, ConfigProvider } from "antd"
import ERC20 from "../../../abis/erc20.json"
import ERC1155 from "../../../abis/erc1155.json"
import { useCustomeContract } from "hooks/useContract"
import { BLAST_MONOPOT_ADDRESSES, BLAST_MONOPOT_TICKET_ADDRESSES, MUSD_ADDRESSES_MAP } from "constants/addresses"
import { ethers } from "ethers"
import { useWeb3React } from "@web3-react/core"
import { NotchedButtonFill } from "components/Button"
import { formatXpNumber } from "pages/Xp/sections/XpTitle"
import { TicketType, useMonopotStore } from "hooks/useMonopot"
import { SupportedChainId } from "constants/chains"
import checkUnsupportChainId from "utils/checkUnsupportChainId"

const ViewTicketModalWrapper = styled.div`
    width: 100%;
    max-width: 592px;
    max-height: 686px;
    background: #1C1B20;
    box-shadow: 0px 12px 50px 0px #000;
    padding: 24px 32px;
    position: relative;
    overflow: auto;
    @media screen and (max-width: 450px) {
        padding: 12px 24px;
    }
`
const ViewTicketModalTitleBox = styled.div`
    display: flex;
    gap: 12px;
    button{
        background: unset;
        padding: 0;
        outline: none;
        border: none;
        &:hover{
            scale: 1.1;
            transition: ease-in-out 200ms;
        }
    }
`

const UseTicketBox = styled.div`
    margin-top: 20px;
    margin-bottom: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 600px) {
        span{
            font-size: 14px !important;
        }
    }
`

const Text = styled.span`
    color: #E5E5DF;
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 35px */
    span{
        font-family: Urbanist;
    } 
    @media screen and (max-width: 600px){
        font-size: 16px !important;
    }
`

const TicketList = styled.div`
    display: grid;
    gap: 12px;
`
const TicketItem = styled.div`
    position: relative;
    z-index: 1;
    max-width: 528px;
    height: 72px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10%;
    cursor: pointer;
    background: rgba(239, 239, 228, 0.05);
    clip-path: polygon(20.3px 0, 100% 0, 100% calc(100% - 20.3px), calc(100% - 20.3px) 100%, 0 100%, 0 20.3px);

    img.ticket_bg{
        max-width: 15px;
        position: absolute;
        top: 3px;
        right: 25%;
        width: 100%;
        height: 90%;
        z-index: 1;
    }
    @media screen and (max-width: 350px) {
        padding-left: 5%;
        img.ticket_bg{
            right: 22%;
            max-width: 10px;
        }
    }
`

const TicketItemNumBox = styled.div<{
    idDuplicatedNum?: boolean
}>`
    position: relative;
    z-index: 99;
    display: flex;
    input {
        color: ${({ idDuplicatedNum }) => idDuplicatedNum ? 'red' : '#EFEFE4'} !important;
        -webkit-text-fill-color: ${({ idDuplicatedNum }) => idDuplicatedNum ? 'red' : '#EFEFE4'} !important; 
        -webkit-opacity: 1; 
        opacity: 1; 
    }
`
const TicketItemNum = styled.input<{
    hasMatched?: boolean
}>`
    height: 100%;
    text-align: center;
    font-family: Urbanist;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 50.4px */
    border: none;
    outline: none;
    display: block;
    background: none;
    width: 40px;
    @media screen and (max-width: 780px) {
        width: 30px;
    }
    @media screen and (max-width: 708px) {
        width: 40px;
    }
    @media screen and (max-width: 600px) {
        font-size: 15px;
    }
    @media screen and (max-width: 450px) {
        width: 30px;
    }
`
const TicketItemNo = styled.div<{
    hasChose?: boolean
}>`
    position: absolute;
    top: 25%;
    right: 8%;
    z-index: 1;
    display: flex;
    gap: 10px;
    span{
        color: ${({ hasChose }) => hasChose ? '#68FF9B' : 'rgba(239, 239, 228, 0.50)'};
        text-align: center;
        font-family: Righteous;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 25px */
        @media screen and (max-width: 600px) {
            font-size: 15px;
        }
    }
    svg{
        color: rgba(239, 239, 228, 0.50);
        font-size: 16px;
        &:hover{
            color: #68FF9B;
        }
    }
    @media screen and (max-width: 600px) {
        top: 35%;
    }
    @media screen and (max-width: 500px) {
        right: 10%;
        gap: 5px;
    }
    @media screen and (max-width: 350px) {
        right: 9%;
        top: 37%;
    }
`
 
const AmountBoxWrapper = styled.div`
    margin-bottom: 46px;

    @media screen and (max-width: 600px){
        .balance_text{
            font-size: 12px !important;
        }
    }
    
`;
const AmountInputBox = styled.div`
    display: flex;
    height: 56px;
`;
const AmountInputInDecrease = styled.div`
    width: 56px;
    height: 100%;
    background: rgba(239, 239, 228, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;

    color: rgba(239, 239, 228, 0.50);
    text-align: center;
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 30px */
    cursor: pointer;
`;


const AmountBoxTitle = styled.h5`
    color: #EFEFE4;
    font-family: Urbanist;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 15px */
    text-transform: uppercase;
    opacity: 0.7;
`;

const TicketBox = styled.div`
    margin-top: 24px;
    display: grid;
    gap: 18px;
`
const TicketBoxTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 450px) {
        span{
            font-size: 15px !important;
        }
    }
`

const EstimatePaymentBox = styled.div`
    margin-top: 24px;
    display: grid;
    gap: 16px;
`

const AmountInput = styled.input`
  color: #efefe4;
  font-family: Urbanist;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%; /* 26.25px */
  background: #efefe405;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  padding-right: 24px;
  text-align: center;
`


export default function BuyTicketModal({ onClose, usdbPrice, musdPrice }: any) {
    const TICKET_PRICE = (2 * usdbPrice) / musdPrice
    const { account, chainId } = useWeb3React()
    const { buyTicket, generateTicket, myCurrentBoughtTickets } = useMonopotStore()

    const musdAddress = MUSD_ADDRESSES_MAP[SupportedChainId.BLAST]
    const musdContract = useCustomeContract(musdAddress, ERC20, false)
    const musdContractWithSign = useCustomeContract(musdAddress, ERC20, true)
    const [allowanceMusd, setAllowanceMusd] = useState<number>(0)

    const monoTicketNftContract = useCustomeContract(BLAST_MONOPOT_TICKET_ADDRESSES, ERC1155, false)
    const monoTicketNftContractWithSign = useCustomeContract(BLAST_MONOPOT_TICKET_ADDRESSES, ERC1155, true)
    const [ticketNftBalance, setTicketNftBalance] = useState<number>(0)

    const [musdBalance, setMusdBalance] = useState<number>(0)
    const [loadingApproveContract, setLoadingApproveContract] = useState<boolean>(false)
    const [hasApprovedContract, setHasApprovedContract] = useState<boolean>(false)

    const [amount, setAmount] = useState<number>(0)
    const [loadingBuyTicket, setLoadingBuyTicket] = useState<boolean>(false)
    const [editTicketNum, setEditTicketNum] = useState<{
        id: number;
        num: string;
        originalNum: number;
    }>({
        id: -1,
        num: '',
        originalNum: -1
    })

    const [isUseTicket, setIsUseTicket] = useState<boolean>(false)

    const [hasApprovedNftContract, setHasApprovedNftContract] = useState<boolean>(false)

    const [isScreentLt450, setIsScreentLt450] = useState<boolean>(false)
    const [sellTickets, setSellTickets] = useState<TicketType[]>([])
    const [currentPage, setCurrentPage] = useState<number>(1)

    const displayTickets = useMemo(() => {
        const start = (currentPage - 1) * 3
        return sellTickets.slice(start, start + 3)
    }, [currentPage, sellTickets])

    const selectedCnt = useMemo(() => sellTickets.filter(i => i.hasChose).length, [sellTickets, amount])
    const ticketCost = useMemo(() => {
        if (isUseTicket)
            return 0
        return selectedCnt * TICKET_PRICE
    }, [sellTickets, selectedCnt])

    const discountPercent = useMemo(() => {
        if (isUseTicket)
            return 0
        if (selectedCnt == 100) return 4.95
        else if (selectedCnt > 50) return 2.45 + (selectedCnt - 50) * 0.05
        else if (selectedCnt == 50) return 2.45
        else if (selectedCnt > 2) return 0.05 + (selectedCnt - 2) * 0.05
        else if (selectedCnt == 2) return 0.05
        else return 0
    }, [selectedCnt])

    const discountPrice = useMemo(() => {
        return ticketCost * (discountPercent / 100)
    }, [discountPercent, ticketCost])

    let isManualRandoming = false
    const manualRandom = () => {
        if (isManualRandoming) return
        try {
            isManualRandoming = true
            const newTicketNums: number[] = []

            for (let index = 0; index < amount; index++) {
                let ticketNumRandom = generateTicket()
                while (true) {
                    if (newTicketNums.includes(ticketNumRandom))
                        ticketNumRandom = generateTicket()
                    else break
                }
                newTicketNums.push(ticketNumRandom)
            }

            setSellTickets(newTicketNums.map((num, index) => ({
                id: num,
                no: index + 1,
                num: num.toString(),
                hasChose: false
            })))
        }
        catch (err) { }
        finally {
            isManualRandoming = false
        }

    }
    const onClickDeOrSelectAll = () => {
        if (selectedCnt > 0)
            setSellTickets(sellTickets.map(i => ({ ...i, hasChose: false })))
        else setSellTickets(sellTickets.map(i => ({ ...i, hasChose: true })))
    }
    const onClickChooseTicket = (id: number, ticketNum: string) => {
        if (loadingBuyTicket || isDuplicatingEditNum(ticketNum)) return
        setSellTickets(sellTickets.map(item => {
            if (item.id == id)
                item.hasChose = !item.hasChose
            return item
        }))
    }

    const onUserTypeNumber = (event: KeyboardEvent<HTMLInputElement>, numeberIndex: number, numberId: number) => {
        // const el = event.target
        // if (event.key == 'Delete' || event.key == 'Backspace') {
        //     if (numeberIndex > 1)  // prev input
        //         (el.previousElementSibling as HTMLInputElement)?.focus()
        // }
    }
    const onUserChangeNumber = (el: HTMLInputElement, numeberIndex: number, numberId: number) => {
        let value = el.value.slice(-1)
        let isDeleteKey = false
        if (!value) {
            isDeleteKey = true
            value = '0'
        }
        el.value = value
        setSellTickets(prev => {
            return prev.map(e => {
                if (e.id == numberId) {
                    let numberVal = e.num.split('')
                    numberVal[numeberIndex] = value
                    e.num = numberVal.join('')
                }
                return e;
            })
        })

        if (numeberIndex < 6 && !isDeleteKey)// next input
            (el.nextElementSibling as HTMLInputElement)?.focus()
    }


    const onClickBuyTicket = async () => {
        checkUnsupportChainId(chainId)
        try {
            if (loadingBuyTicket) return;
            if (selectedCnt == 0 && !isUseTicket) {
                message.warning("Please select tickets to buy.")
                return
            }
            if (isUseTicket && ticketNftBalance < amount) return
            else if (musdBalance < ticketCost) return

            setLoadingBuyTicket(true)
            if (!isUseTicket && allowanceMusd < ticketCost) {
                notification.warning({
                    message: 'The MUSD allowance is too low. You will need to approve first'
                })
                await ApproveMusdContract()
            }
            await buyTicket(sellTickets.filter(i => i.hasChose || isUseTicket).map(i => Number(i.num)), isUseTicket)

            notification.success({
                message: 'Buy successfully'
            })
            if (isUseTicket)
                loadTicketNftBalance()
            else
                loadMusdInfo()
            onClose()
        }
        catch (err) {
            console.log('buy error: ', err);

            notification.error({
                message: 'Buy failed'
            })
        }
        finally {
            setLoadingBuyTicket(false)
        }
    }

    const inCreAmount = () => {
        if (amount > 100) return
        setAmount(i => i + 1)

        const ticketNum: number = getGenerateTicket()
        setSellTickets([...sellTickets, {
            id: ticketNum,
            no: amount + 1,
            num: ticketNum.toString(),
            hasChose: false
        }])
    }
    const deCreAmount = () => {
        if (amount <= 0) return
        setAmount(i => i - 1)
        setSellTickets(sellTickets.slice(0, sellTickets.length - 1))
    }

    const getGenerateTicket = () => {
        let ticketNum: number = generateTicket()
        while (true) {
            if (sellTickets.find(i => i.id == ticketNum))
                ticketNum = generateTicket()
            else break
        }
        return ticketNum
    }

    useEffect(() => {
        if (amount < sellTickets.length)
            setSellTickets(sellTickets.slice(0, amount))
        else if (amount > sellTickets.length) {
            const missTickets = []
            for (let index = sellTickets.length; index < amount; index++) {
                const ticketNum: number = getGenerateTicket()
                missTickets.push({
                    id: ticketNum,
                    no: index + 1,
                    num: ticketNum.toString(),
                    hasChose: false
                })
            }
            setSellTickets([...sellTickets, ...missTickets])
        }
    }, [amount])

    const onTypeEditTicketNum = (value: string) => {
        setEditTicketNum({
            ...editTicketNum,
            num: value.slice(0, 6)
        })
    }

    const isDuplicatingEditNum = useCallback((number: string) => {
        if (sellTickets.filter(item => item.num == number).length > 1) return true
        else if (myCurrentBoughtTickets.find(item => item.num == number)) return true
        else return false
    }, [sellTickets, myCurrentBoughtTickets])



    const ApproveMusdContract = async () => {
        checkUnsupportChainId(chainId)
        if (loadingApproveContract) return;
        setLoadingApproveContract(true)
        try {
            const setApproveContract = await musdContractWithSign?.approve(BLAST_MONOPOT_ADDRESSES, ethers.constants.MaxUint256)
            await setApproveContract.wait()
            setLoadingApproveContract(false)
            await handleCheckApproveMusdContract()
            return true
        } catch (error) {
            console.log(error)
            setLoadingApproveContract(false)
            return false
        }
    }
    const handleCheckApproveMusdContract = async () => {
        try {
            const allowance = await musdContract?.allowance(account, BLAST_MONOPOT_ADDRESSES)
            setAllowanceMusd(Number(ethers.utils.formatUnits(allowance)))
            if (Number(allowance) > 0) {
                setHasApprovedContract(true)
            } else {
                setHasApprovedContract(false)
            }
        } catch (error) {
            console.log('error', error)
        }

    }
    const loadMusdInfo = async () => {
        const userMusd = await musdContractWithSign?.balanceOf(account)
        // musd
        const formmattedMusdUserBalance = Number(ethers.utils.formatUnits(userMusd, 18)).toFixed(2).toString()
        setMusdBalance(Number(formmattedMusdUserBalance))
        handleCheckApproveMusdContract()
    }

    const ApproveNftContract = async () => {
        checkUnsupportChainId(chainId)
        if (loadingApproveContract) return;
        setLoadingApproveContract(true)
        try {
            const tx = await monoTicketNftContractWithSign?.setApprovalForAll(BLAST_MONOPOT_ADDRESSES, true)
            await tx.wait()
            setHasApprovedNftContract(true)
        } catch (error) {
            console.log(error)
        }
        finally {
            setLoadingApproveContract(false)
        }
    }
    const handleCheckApproveNftContract = async () => {
        try {
            const isApproved = await monoTicketNftContract?.isApprovedForAll(account, BLAST_MONOPOT_ADDRESSES)
            if (isApproved)
                setHasApprovedNftContract(true)
            else
                setHasApprovedNftContract(false)
        } catch (error) {
            console.log('error', error)
        }
    }

    const loadTicketNftBalance = async () => {
        if (!account) return
        const balanceRes = await monoTicketNftContract?.balanceOf(account, 1)
        setTicketNftBalance(Number(balanceRes))
    }

    useEffect(() => {
        if (account) {
            loadMusdInfo()
            loadTicketNftBalance()
            handleCheckApproveNftContract()
        }

        setIsScreentLt450(window.screen.width <= 450)
    }, [account, chainId])


    return <>
        <ViewTicketModalWrapper>
            <div style={{ position: 'absolute', top: '24px', right: '32px', cursor: 'pointer' }} onClick={onClose}>
                <CloseOutlined rev={undefined} />
            </div>
            <ViewTicketModalTitleBox>
                <Text style={{ fontFamily: 'Righteous' }}>
                    Buy Tickets
                </Text>

            </ViewTicketModalTitleBox>

            <Text style={{ fontSize: '18px', fontWeight: 400, marginTop: '12px', marginBottom: '12px', display: 'block' }}>
                Select 6 numbers between 0 and 9. Make sure there is no duplicates among your tickets.
                <a href="https://docs.monoswap.io/protocol/or-monopot"
                    target="_blank"
                    style={{ textDecoration: 'none', fontWeight: 600, marginLeft: '3px' }}
                ><TextLinear>Learn more</TextLinear></a>
            </Text>

            <UseTicketBox>
                <Text style={{ fontSize: '18px' }}>
                    Redeem MonoPot Tickets ({ticketNftBalance} remaining)
                </Text>

                <ConfigProvider theme={{
                    components: {
                        Switch: {
                            handleBg: 'black',
                        },
                    },
                }}>
                    <Switch
                        size={isScreentLt450 ? "small" : undefined}
                        checked={isUseTicket}
                        style={{
                            background: isUseTicket ? 'linear-gradient(90deg, #68FFFF, #68FF9B)' : 'white'
                        }}
                        onChange={val => setIsUseTicket(val)}
                    />
                </ConfigProvider>

            </UseTicketBox>


            <AmountBoxWrapper>
                <AmountBoxTitle>
                    number of tickets
                </AmountBoxTitle>

                <AmountInputBox>
                    <AmountInputInDecrease onClick={deCreAmount}>
                        -
                    </AmountInputInDecrease>

                    <AmountInput type="number" min={0} value={amount} onChange={e => {
                        if (Number(e.target.value) > 100) setAmount(100);
                        else {
                            const num = Math.abs(Number(e.target.value))
                            setAmount(num)
                            e.target.value = num.toString()
                        }
                    }} />

                    <AmountInputInDecrease onClick={inCreAmount}>
                        +
                    </AmountInputInDecrease>
                </AmountInputBox>
                {
                    isUseTicket ? <>
                        {
                            ticketNftBalance < amount ?
                                <Text style={{ fontSize: '14px', color: '#FF1E56', float: 'right', marginTop: '12px' }}>Insufficient balance: {ticketNftBalance} Tickets</Text>
                                :
                                <Text style={{ fontSize: '14px', color: 'rgba(239, 239, 228, 0.50)', float: 'right', marginTop: '12px' }}>Balance: <span style={{ color: '#EFEFE4', fontFamily: 'Urbanist' }}>{ticketNftBalance} Tickets</span></Text>
                        }
                    </>
                        :
                        <>
                            {
                                musdBalance < ticketCost ?
                                    <Text className="balance_text" style={{ fontSize: '14px', color: '#FF1E56', float: 'right', marginTop: '12px' }}>Insufficient balance: {formatXpNumber(musdBalance)} MUSD</Text>
                                    :
                                    <Text className="balance_text" style={{ fontSize: '14px', color: 'rgba(239, 239, 228, 0.50)', float: 'right', marginTop: '12px' }}>Balance: <span style={{ color: '#EFEFE4', fontFamily: 'Urbanist' }}>{formatXpNumber(musdBalance)} MUSD</span></Text>
                            }
                        </>
                }
            </AmountBoxWrapper>

            <div style={{
                height: '1px',
                width: '100%',
                background: '#EFEFE4'
            }} />

            {
                sellTickets.length > 0 && !isUseTicket &&
                <TicketBox>
                    <TicketBoxTop >
                        <Text style={{ fontSize: '18px' }}>
                            Choose your odds <TextLinear style={{ fontFamily: 'Urbanist' }}>({selectedCnt}/{amount} <Text style={{ fontSize: '16px', cursor: 'pointer' }} onClick={onClickDeOrSelectAll}>{selectedCnt > 0 ? 'unselected' : 'select all'}</Text>)</TextLinear>
                        </Text>
                        <Text style={{ fontSize: '18px', cursor: 'pointer' }} onClick={manualRandom}>
                            <TextLinear style={{ fontFamily: 'Urbanist' }}>Randomize</TextLinear>
                        </Text>
                    </TicketBoxTop>
                    <TicketList>
                        {
                            displayTickets.map((item, index) => <div key={index + item.num} style={{ width: '100%' }}>
                                <TicketItem onClick={() => onClickChooseTicket(item.id, item.num)}>
                                    <img className="ticket_bg" src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/lottery/ticket_bg.png" alt="monoswap" />
                                    <TicketItemNumBox idDuplicatedNum={isDuplicatingEditNum(item.num)} onClick={e => e.stopPropagation()}>
                                        <TicketItemNum defaultValue={item.num[1]} step={1} type="number" min={0} onKeyUp={e => onUserTypeNumber(e, 1, item.id)} onChange={e => onUserChangeNumber(e.target, 1, item.id)} />
                                        <TicketItemNum defaultValue={item.num[2]} step={1} type="number" min={0} onKeyUp={e => onUserTypeNumber(e, 2, item.id)} onChange={e => onUserChangeNumber(e.target, 2, item.id)} />
                                        <TicketItemNum defaultValue={item.num[3]} step={1} type="number" min={0} onKeyUp={e => onUserTypeNumber(e, 3, item.id)} onChange={e => onUserChangeNumber(e.target, 3, item.id)} />
                                        <TicketItemNum defaultValue={item.num[4]} step={1} type="number" min={0} onKeyUp={e => onUserTypeNumber(e, 4, item.id)} onChange={e => onUserChangeNumber(e.target, 4, item.id)} />
                                        <TicketItemNum defaultValue={item.num[5]} step={1} type="number" min={0} onKeyUp={e => onUserTypeNumber(e, 5, item.id)} onChange={e => onUserChangeNumber(e.target, 5, item.id)} />
                                        <TicketItemNum defaultValue={item.num[6]} step={1} type="number" min={0} onKeyUp={e => onUserTypeNumber(e, 6, item.id)} onChange={e => onUserChangeNumber(e.target, 6, item.id)} />
                                    </TicketItemNumBox>

                                    <TicketItemNo hasChose={item.hasChose}>
                                        <span>#{item.no}</span>
                                    </TicketItemNo>
                                </TicketItem>
                                {
                                    isDuplicatingEditNum(item.num) && <p style={{ color: 'red', fontSize: '13px' }}>Your number is duplicated.</p>
                                }
                            </div>)
                        }
                    </TicketList>

                    <div className="my-claimed-xp" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Pagination defaultCurrent={currentPage}
                            total={amount}
                            pageSize={3}
                            showSizeChanger={false}
                            onChange={(page) => setCurrentPage(page)} />
                    </div>
                </TicketBox>
            }

            <EstimatePaymentBox>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Text style={{ fontSize: '16px' }}>Cost</Text>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Text style={{ fontSize: '16px' }}>{formatXpNumber(ticketCost)}</Text>
                        <img style={{ maxWidth: '20px', maxHeight: '20px' }} src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section3/musd-token.png" />
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Text style={{ fontSize: '16px' }}>You pay</Text>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Text style={{ fontSize: '16px' }}>{formatXpNumber(ticketCost - discountPrice)}</Text>
                        <img style={{ maxWidth: '20px', maxHeight: '20px' }} src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section3/musd-token.png" />
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Popover
                        overlayInnerStyle={{ padding: 0, borderRadius: 0 }}
                        arrow={true}
                        color="black"
                        placement={'topLeft'}
                        content={<div style={{ display: 'grid', gap: '10px', maxWidth: '340px', padding: '10px' }}>
                            <Text style={{ fontSize: '14px', fontWeight: 500 }}>Purchasing tickets in bulk offers a discount that linearly increases with the number of tickets bought, up to a maximum of 100 tickets:</Text>
                            <div style={{ display: 'grid', paddingLeft: '20px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    <div style={{ width: '3px', height: '3px', background: 'white', borderRadius: '50%', opacity: 0.8 }} />
                                    <Text style={{ fontSize: '14px', fontWeight: 500 }}>2 tickets: 0.05% discount</Text>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    <div style={{ width: '3px', height: '3px', background: 'white', borderRadius: '50%', opacity: 0.8 }} />
                                    <Text style={{ fontSize: '14px', fontWeight: 500 }}>50 tickets: 2.45% discount</Text>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    <div style={{ width: '3px', height: '3px', background: 'white', borderRadius: '50%', opacity: 0.8 }} />
                                    <Text style={{ fontSize: '14px', fontWeight: 500 }}>100 tickets: 4.95% discount</Text>
                                </div>
                            </div>
                        </div>}
                        zIndex={1050}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Text style={{ fontSize: '16px' }}>
                                {
                                    selectedCnt >= 2 ? `${discountPercent.toFixed(2)}% ` : ''
                                }
                                Discount</Text>
                            <svg style={{ cursor: 'pointer' }} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.81738 6.74945C6.99371 6.2482 7.34175 5.82553 7.79985 5.5563C8.25795 5.28707 8.79655 5.18865 9.32026 5.27848C9.84398 5.36831 10.319 5.64059 10.6612 6.0471C11.0034 6.4536 11.1907 6.96809 11.1899 7.49945C11.1899 8.99945 8.93988 9.74945 8.93988 9.74945M8.99982 12.7494H9.00732" stroke="#EFEFE4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <circle cx="9" cy="9" r="7.25" stroke="#EFEFE4" strokeWidth="1.5" />
                            </svg>
                        </div>
                    </Popover>

                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        {
                            selectedCnt >= 2 ?
                                <Text style={{ fontSize: '16px' }}>~ {discountPrice.toFixed(3)}</Text>
                                :
                                <Text style={{ fontSize: '16px' }}>0</Text>
                        }

                        <img style={{ maxWidth: '20px', maxHeight: '20px' }} src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/landingpage/section3/musd-token.png" />
                    </div>
                </div>

            </EstimatePaymentBox >

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                {
                    isUseTicket ? <>
                        {
                            hasApprovedNftContract ?

                                <NotchedButtonFill onClick={onClickBuyTicket} disabled={ticketNftBalance == 0 || ticketNftBalance < amount || amount == 0}>
                                    {
                                        loadingBuyTicket ? 'BUYING...' : 'BUY TICKET'
                                    }
                                </NotchedButtonFill>
                                :
                                <NotchedButtonFill onClick={ApproveNftContract}>
                                    {loadingApproveContract ? 'Approving...' : 'Approve'}
                                </NotchedButtonFill>
                        }
                    </>
                        : <>
                            {
                                hasApprovedContract ?
                                    <NotchedButtonFill onClick={onClickBuyTicket} disabled={musdBalance == 0 || selectedCnt == 0 || musdBalance < ticketCost}>
                                        {
                                            loadingBuyTicket ? 'BUYING...' : 'BUY TICKET'
                                        }
                                    </NotchedButtonFill>
                                    :
                                    <NotchedButtonFill onClick={ApproveMusdContract}>
                                        {loadingApproveContract ? 'Approving...' : 'Approve'}
                                    </NotchedButtonFill>
                            }
                        </>
                }

            </div>
        </ViewTicketModalWrapper >

    </>
}