import styled from "styled-components";
import LotteryNumberList from "./components/LotteryNumber";
import MonoPotSale from "./components/MonoPotSale";
import MonotPotGuide from "./components/MonotPotGuide";
import { useMonopotStore } from "hooks/useMonopot";
import { useWeb3React } from "@web3-react/core";
import { useEffect } from "react";
import { useCustomeContract } from "hooks/useContract";
import MONOBOT_ABI from "../../abis/monopot/monopot.json"
import { getContract } from "utils";    
import { ethers } from "ethers";
import { RPC_URLS } from "constants/networks";
import './monopotpage.css'
import WonTicketModal from "./components/WonTicketModal";
import { SupportedChainId } from "constants/chains";
import { BLAST_MONOPOT_ADDRESSES } from "constants/addresses";

const PageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 2386px;
    padding-top: 50px;    
`
const Img = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
`
export const PageTitleBox = styled.div`
    display: grid;
    justify-items: center;
    gap: 16px;
    position: relative;
    z-index: 1;
`
export const PageTitle = styled.h1`
    color: #EFEFE4;
    text-align: center;
    font-family: Righteous;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 60px */
    margin: 0;
`
export const TextLinear = styled.span`
    background: linear-gradient(90deg, #68FFFF, #68FF9B);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`
export const PageDesc = styled.span`
    color: #EFEFE4;
    text-align: center;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
`


export default function MonoPotPage() {
    const { account, chainId } = useWeb3React()
    const {
        currentChestInfo,
        loadCurrentChest,
        setContractWithSign,
        setContractWithNoSign,
        setAccount
    } = useMonopotStore()
    const monopotContractWithSign = useCustomeContract(BLAST_MONOPOT_ADDRESSES, MONOBOT_ABI, true)
    const monopotContractWithNoSign = getContract(BLAST_MONOPOT_ADDRESSES, MONOBOT_ABI, new ethers.providers.JsonRpcProvider(RPC_URLS[SupportedChainId.BLAST][3]))


    useEffect(() => {
        if (account) setAccount(account)
    }, [account, chainId])

    useEffect(() => {
        if (monopotContractWithSign)
            setContractWithSign(monopotContractWithSign)
    }, [monopotContractWithSign])

    useEffect(() => {
        setContractWithNoSign(monopotContractWithNoSign)
        loadCurrentChest()
    }, [])

    return <PageWrapper id="monopot">
        <Img src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/lottery/page_bg.png" />
        <PageTitleBox>
            <PageTitle>
                MONO<TextLinear>POT</TextLinear>
            </PageTitle>
            <PageDesc style={{ maxWidth: '433px' }}>
                Grab your chance, enhance your stance in MonoPot's dance.
                Play today!
            </PageDesc>
        </PageTitleBox>
        <MonoPotSale />
        <div style={{ margin: '100px auto', background: 'black' }}>
            <img style={{ margin: 'auto', display: 'block', width: '100%', maxWidth: '267px', position: 'relative', zIndex: 1 }} src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/hr-icon.png' />
        </div>

        <LotteryNumberList />

        <div style={{ margin: '100px auto', background: 'black' }}>
            <img style={{ margin: 'auto', display: 'block', width: '100%', maxWidth: '267px', position: 'relative', zIndex: 1 }} src='https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/xp/hr-icon.png' />
        </div>
        {
            currentChestInfo && <WonTicketModal />
        }

        <MonotPotGuide />
    </PageWrapper>
}