import styled from "styled-components"
import { RightOutlined, LeftOutlined } from "@ant-design/icons"
import { useEffect, useMemo, useState } from "react"
import { useMonopotStore } from "hooks/useMonopot"
import {  notification } from "antd"


const RoundPicketBox = styled.div`
    padding: 10px;
    max-width: 300px;
    border: 1px solid #e5e5df1d;
`
const RoundList = styled.div`
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

`
const RoundNum = styled.span<{
    isActived?: boolean
    disabled?: boolean
}>`
    color:  ${({ isActived, disabled }) => isActived ? 'black' : disabled ? '#e5e5dfb2' : '#E5E5DF'};
    background: ${({ isActived }) => isActived ? 'linear-gradient(90deg, #68FFFF, #68FF9B)' : 'unset'};;
    min-width: 24px;
    height: 24px;
    cursor: pointer;
    text-align: center;
    cursor: ${({ disabled }) => disabled ? 'no-drop' : 'pointer'};
    &:hover{
        background: ${({ disabled }) => disabled ? '' : 'linear-gradient(90deg, #68FFFF, #68FF9B)'};
        color: ${({ disabled }) => disabled ? '#e5e5dfb2' : 'black'};;
    }
`

const Text = styled.span`
    color: #FFF;
    text-align: center;
    font-family: Righteous;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 60px */
    position: relative;
    z-index: 1;
`

const ConfirmBtn = styled.button<{
    disabled?: boolean
}>`
    display: flex;
    color:  ${({ disabled }) => disabled ? '#E5E5DF' : 'black'};
    cursor: ${({ disabled }) => disabled ? 'no-drop' : 'pointer'};
`
export default function MonoPotRoundPicker({ round, setRound, onClose }: any) {
    const { currentChestInfo } = useMonopotStore()
    const [currentRound, setCurrentRound] = useState<number>(0)
    const [latestRound, setLatestRound] = useState<number>(0)
    const [goToRoundVal, setGoToRoundVal] = useState<string>()

    useEffect(() => {
        if (currentChestInfo) {
            setLatestRound(currentChestInfo.id)
        }
    }, [currentChestInfo])

    useEffect(() => {
        setCurrentRound(round)
        setGoToRoundVal('')
    }, [round])

    const roundDisplay = useMemo(() => {
        console.log('roundDisplay, ', currentRound);

        if (!currentRound) return []

        let startNum = currentRound - 25
        if (startNum < 1) startNum = 1
        let endNum = currentRound + 24
        const arr: number[] = []
        for (let index = startNum; index <= endNum; index++)
            arr.push(index)
        return arr
    }, [currentRound])

    return <RoundPicketBox>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Text>Lastest round is: #{latestRound}</Text>
        </div>
        <RoundList>
            {
                roundDisplay.map(i => <RoundNum key={i}
                    isActived={i == currentRound}
                    disabled={i > latestRound}
                    onClick={() => {
                        if (i != currentRound && i <= latestRound) {
                            setCurrentRound(i)
                            setGoToRoundVal('')
                        }
                    }}>#{i}</RoundNum>)
            }
        </RoundList>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
            <input value={goToRoundVal} onChange={({ target }) => {
                const inputVal = Math.floor(Number(target.value))
                setGoToRoundVal(inputVal.toString())
            }} style={{
                outline: 'none'
            }} type="number" placeholder="Go to round" />
            <ConfirmBtn onClick={() => {
                if (Number(goToRoundVal) > latestRound) {
                    notification.error({
                        message: 'Your typed round must less than the current'
                    })
                    return
                }
                if (Number(goToRoundVal) == round) {}
                else if (goToRoundVal && Number(goToRoundVal) != 0) setRound(Number(goToRoundVal))
                else setRound(currentRound)
                setGoToRoundVal('')
                onClose()
            }}>
                ok
            </ConfirmBtn>
        </div>
    </RoundPicketBox>
}