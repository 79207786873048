import { NotchedButtonFill } from "components/Button";
import { NotchedButtonBolderNew } from "components/Button/NotchedButton";
import styled from "styled-components"
import { BREAKPOINTS, CustomLightSpinner } from "theme";
import { useEffect, useMemo, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useToggleWalletModal } from "state/application/hooks";
import MyNftStats from "./MyNftStats"
import useCountdownTimer from "hooks/useCountdownTimer";
import dayjs from "dayjs";
import { ethers } from "ethers";
import Modal from "components/Modal";
import SuccessMintMonopassModal from "./SuccessMintMonopassModal";
import ERC721 from '../../../abis/erc721.json'
import { notification } from "antd";
import { DepositedMonopassType } from "hooks/useMonoPass";

const Circle = 'https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/assets/images/blue-loader.svg'

const Section1ContentWrapper = styled.section`
  margin-top: 180px;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin-top: 50px;
    flex-direction: column-reverse;
    align-items: center;
    padding-bottom: 0px;
  }
`;

const ContentLeft = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 56px;
  max-width: 600px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
  }
`;

const TitleWrapper = styled.div`
`;

const Title = styled.h1`
  color: #EFEFE4;
  font-family: Righteous;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px; 
  margin: 0;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    justify-content: center;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
  }
`;
const SubTitle = styled.h4`
  max-width: 370px;
  color: #EFEFE4;
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.2px;
  margin: 0;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin: auto;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
`;


const StatsWrapper = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 1px) calc(50% - 1px);
  max-width: 598px;
  height: 180px;
  gap: 8px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    height: 124px ;
  }
`;

const StatsItemBorder = styled.div<{
  clipPath: string
}>`
  width: 100%;
  height: 100%;
  background: #efefe480;
  clip-path: 100%;
  padding: 1px;
  clip-path: ${({ clipPath }) => clipPath};
  position: relative;
`;

const StatsItemBorderContent = styled.div<{
  clipPath: string
}>`
  width: 100%;
  height: 100%;
  background: #000000;
  margin: auto;
  clip-path: ${({ clipPath }) => clipPath};
  position: relative;
  display: flex;
`;

const StatsItem = styled.div`
  background-size: cover;
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  position: relative;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    max-height: 62px;
    gap: 5px;
  }
`;

const BgImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;
const StatsItemTitle = styled.h5`
  color: #EFEFE4;
  text-align: center;
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.5px; 
  opacity: 0.5;
  margin: 0;
  position: relative;
  z-index: 10;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 14px;
    line-height: 17.5px;
  }
`;
const StatsItemSubTitle = styled.h6`
  margin: 0;
  text-align: center;
  color: #EFEFE4;
  font-family: Righteous;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 24px */
  text-transform: uppercase;
  z-index: 10;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ButtonBox = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 12px) calc(50% - 12px);
  column-gap: 24px;
  box-sizing: content-box;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    row-gap: 24px;
    grid-template-columns: 100%;
  }
`;

const ContentRight = styled.div`
  width: 50%;
  max-width: 500px;
  height: 662px;
  background-color: #191919;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
    max-width: 363px;
    max-height: 480px;
  }
`;

const PrimaryTexy = styled.span`
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(180deg, rgb(103.96, 255, 255) 0%, rgb(103.96, 255, 155.31) 100%);
  background-clip: text;
  color: transparent;
  font-family: "Righteous";
`;

const StageInfoBox = styled.div`

`

const StageInfoBoxTop = styled.div`
  display: grid;
  gap: 9px;
  margin-top: 24px;
  margin-bottom: 42px;
  div{
    display: flex;
    gap: 8px;
    align-items: center;
  }
  img{
    width: 24px;
    height: 24px;
  }
  span{
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
      font-size: 14px;
    }
  }
`

const StageInfoBoxBottom = styled.div`
  .title{
    color: #FFB800;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 20px */
    margin-bottom: 24px;
    span{
      color: #FFB800;
      font-family: Urbanist;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 125%; /* 20px */
    }
  }
`
const RemainTimeBoxWrapper = styled.div`
    display: grid;
    justify-items: start;
    gap: 24px;
`
const RemainTimeBox = styled.div`
    display: flex;
    gap: 25px;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        gap: 10px;
    }
    .time_separator{
        margin-top: 20px;
        color: #EFEFE4;
        text-align: center;
        font-family: Urbanist;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 125%; /* 30px */
        opacity: 0.5;
        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
            margin-top: 7px;
        }
    }
`
const TimeBoxWrapper = styled.div`
    display: grid;
    gap: 12px;
    justify-items: center;
    min-width: 68px;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        min-width: 40px;
    }
`
const TimeBox = styled.div`
    width: 100%;
    max-width: 148px;
    display: grid;
    justify-content: center;
    align-items: center;
    justify-items: center;
    padding: 10px 15px;
    background: rgba(239, 239, 228, 0.15);
    font-weight: 700;
    font-size: 32px;
    font-family: Righteous;
    @media screen and (max-width: ${BREAKPOINTS.md}px){
        padding: 10px 20px;
    }
    @media screen and (max-width: ${BREAKPOINTS.sm}px){
        span{
            font-size: 16px;
        }
    }
`

const LinearText = styled.span`
    background: linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const Text = styled.span<{
  color?: string;
  fontSize?: string;
}>`
    color: ${({ color }) => color ?? 'rgba(239, 239, 228, 0.50)'};
    font-family: Urbanist;
    font-size: ${({ fontSize }) => fontSize ?? '16px'};;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 20px */
`

export default function MonoPassSection1({ monopassContext }: any) {
  const { account, chainId } = useWeb3React();
  const toggleWalletModal = useToggleWalletModal()

  const {
    monoPassSaleContract, monoPassSaleContractWithSign, monoPassStakeContractWithSign,
    epochEndTime, startPublicTime, startWhitelistTime,
    totalHolders, totalMinted, totalSold, mintPrice,
    stakeInfo, userStakeInfo
  } = monopassContext

  const [isWhilelistWallet, setIsWhilelistwallet] = useState<boolean>(false)
  const [isInvalidWhitelist, setIsInvalidWhitelist] = useState<boolean>(false)
  const [isCheckingWhitelist, setIsCheckingWhitelist] = useState<boolean>(false)

  const [isOpenSucessMintModal, setIsOpenSucessMintModal] = useState<boolean>(false)
  const [isMinting, setIsMinting] = useState<boolean>(false)
  const [isStakingAll, setIsStakingAll] = useState<boolean>(false)

  const [successMintInfo, setSuccessMintInfo] = useState<{
    hash: string
    id: number
  }>({
    hash: '',
    id: 0
  })

  const [endTime, setEndTime] = useState<number>(0)
  const { days, hours, minutes, seconds } = useCountdownTimer('DAY', endTime)

  const isEndtime = useMemo(() => epochEndTime < Date.now() / 1000, [epochEndTime])

  const whitelistTimeRange = useMemo(() => [
    dayjs.utc(startWhitelistTime * 1000),
    dayjs.utc((startPublicTime - 1) * 1000),
  ], [startWhitelistTime, startPublicTime])

  const publicMintTimeRange = useMemo(() => [
    dayjs.utc(startPublicTime * 1000),
    dayjs.utc(epochEndTime * 1000),
  ], [startPublicTime, epochEndTime])

  const whitelistSaleText = useMemo(() => {
    // 13.00 UTC, 14-15 June 2024
    const text = whitelistTimeRange[0].format('HH:mm UTC, DD-#xxx MMMM YYYY').replace('#xxx', whitelistTimeRange[1].format('DD'))
    return text
  }, [whitelistTimeRange])
  const publicSaleText = useMemo(() => {
    const text = publicMintTimeRange[0].format('HH:mm UTC, DD-#xxx MMMM YYYY').replace('#xxx', publicMintTimeRange[1].format('DD'))
    return text
  }, [publicMintTimeRange])

  const hasStarted = useMemo(() => whitelistTimeRange[0].isBefore(dayjs.utc()), [whitelistTimeRange])

  const currentStage = useMemo(() => {
    const currentDate = dayjs.utc()
    if (currentDate.isAfter(whitelistTimeRange[0]) && currentDate.isBefore(whitelistTimeRange[1])) {
      setEndTime(whitelistTimeRange[1].valueOf() / 1000)
      return 'whitelist'
    }
    else if (currentDate.isAfter(publicMintTimeRange[0]) && currentDate.isBefore(publicMintTimeRange[1])) {
      setEndTime(publicMintTimeRange[1].valueOf() / 1000)
      return 'publicmint'
    }
    setEndTime(epochEndTime)
    if (!hasStarted) setEndTime(whitelistTimeRange[0].valueOf() / 1000)
    return 'stake'
  }, [startPublicTime, startWhitelistTime])

  const isPublicMintStage = useMemo(() => currentStage == 'publicmint', [currentStage])


  const checkWhilelist = async () => {
    if (!account || isCheckingWhitelist || isWhilelistWallet) return
    try {
      setIsCheckingWhitelist(true)
      const checkRs = await monoPassSaleContract?.getWhitelist(account)
      if (checkRs.toNumber() == 0) throw 'invalid'

      const whitelistLocalCheck = {
        account,
        isWhitelist: true
      }
      localStorage.setItem('monopass', JSON.stringify(whitelistLocalCheck))
      setIsWhilelistwallet(true)
    }
    catch (err) {
      setIsInvalidWhitelist(true)
    }
    finally {
      setIsCheckingWhitelist(false)
    }
  }

  const mint = async () => {
    if (isMinting) return
    try {
      if (currentStage == 'whitelist' && userStakeInfo.isWhitelistMinted) {
        notification.error({
          message: 'You have reached the purchase monopass'
        })
        return
      }
      else if (currentStage == 'publicmint' && userStakeInfo.isMinted) {
        notification.error({
          message: 'You have reached the purchase monopass'
        })
        return
      }

      setIsMinting(true)
      const tx = await monoPassSaleContractWithSign?.purchase({
        value: ethers.utils.parseEther(String(mintPrice))
      })
      const txLog = await tx.wait()
      const eventLogs = txLog.events
      const monopassSaleABIInter = new ethers.utils.Interface(ERC721);

      let nftId = 0
      for (let logIndex = 0; logIndex < eventLogs.length; logIndex++) {
        const eventLog: any = eventLogs[logIndex];
        try {
          const parsedLog = monopassSaleABIInter.parseLog(eventLog);
          if (!parsedLog) continue;
          if (parsedLog.name == 'Transfer')
            nftId = parsedLog.args[2].toNumber()
        }
        catch (err) { }
      }
      setSuccessMintInfo({
        hash: tx.hash,
        id: nftId
      })
      setIsOpenSucessMintModal(true)

      monopassContext?.loadUserStakeInfo()
      monopassContext?.loadStakeInfo()
    }
    catch (err) {
      if (err?.error?.data?.message && String(err.error.data.message).indexOf('Sold out') != 0)
        notification.error({
          message: 'Monopass has sold out'
        })
      else if (err?.code == 'UNPREDICTABLE_GAS_LIMIT' || (err?.data?.message && String(err?.data?.message).indexOf('insufficient funds')))
        notification.error({
          message: 'Insufficient funds for gas'
        })
      else
        notification.error({
          message: 'Mint failed'
        })
    }
    finally {
      setIsMinting(false)
    }
  }

  const stakeAll = async () => {
    if (isStakingAll || userStakeInfo.monopassCnt.length == 0) return
    try {
      setIsStakingAll(true)
      const depositList = userStakeInfo.monopassCnt.map((item: DepositedMonopassType) => monoPassStakeContractWithSign?.interface.encodeFunctionData('deposit', [item.id]))

      const gasEstimate = await monoPassStakeContractWithSign?.estimateGas.multicall(depositList)
      const gasLimitCalc = Math.floor(gasEstimate ? gasEstimate.toNumber() * 1.5 : 200000)

      const tx = await monoPassStakeContractWithSign?.multicall(depositList, {
        gasLimit: gasLimitCalc
      })
      await tx.wait()

      monopassContext?.loadUserStakeInfo()
      monopassContext?.loadStakeInfo()

      notification.success({
        message: 'Stake successfull'
      })
    }
    catch (err) {
      console.log('Stake all err: ', err);

      notification.error({
        message: 'Stake failed'
      })
    }
    finally {
      setIsStakingAll(false)
    }
  }

  useEffect(() => {
    setIsWhilelistwallet(false)
    setIsInvalidWhitelist(false)
    if (account) {
      const whitelistLocalCheck = JSON.parse(localStorage.getItem('monopass') ?? '{}')
      if (whitelistLocalCheck.account == account && whitelistLocalCheck.isWhitelist)
        setIsWhilelistwallet(true)
      else
        localStorage.removeItem('monopass')
    }
  }, [account])

  return <Section1ContentWrapper id="mint-section">
    <ContentLeft >
      <TitleWrapper>
        <Title >
          <span style={{ color: '#68FFFF' }}>MONO</span>Pass
        </Title>

        <SubTitle>
          Access a suite of exclusive benefits designed just for MonoPass holders!
        </SubTitle>

        {
          !isEndtime &&
          <StageInfoBox>
            <StageInfoBoxTop>
              <div>
                <img src="/monopass/section1/icon1.png" alt="monoswap" />
                <span>Whitelist Sale: {whitelistSaleText}</span>
              </div>
              <div>
                <img src="/monopass/section1/icon2.png" alt="monoswap" />
                <span>Public Sale: {publicSaleText}</span>
              </div>
            </StageInfoBoxTop>

            <StageInfoBoxBottom>
              <div className="title">
                {
                  !hasStarted ? <span>Starting in</span> : <>
                    <span>Whitelist Mint </span> <span style={{ opacity: isPublicMintStage ? 1 : 0.6 }}>{' > '} Public Mint</span>
                  </>
                }
              </div>

              <RemainTimeBoxWrapper>
                <RemainTimeBox>
                  <TimeBoxWrapper>
                    <TimeBox>
                      <LinearText>{days < 0 ? '-' : days}</LinearText>
                    </TimeBox>
                    <Text className="time_suffix">day{days > 1 && ''}</Text>
                  </TimeBoxWrapper>
                  <Text className="time_separator">:</Text>
                  <TimeBoxWrapper>
                    <TimeBox>
                      <LinearText>{hours < 0 ? '-' : hours}</LinearText>
                    </TimeBox>
                    <Text className="time_suffix">hour{hours > 1 && 's'}</Text>
                  </TimeBoxWrapper>
                  <Text className="time_separator">:</Text>
                  <TimeBoxWrapper>
                    <TimeBox>
                      <LinearText>{minutes < 0 ? '-' : minutes}</LinearText>
                    </TimeBox>
                    <Text className="time_suffix">minute{minutes > 1 && 's'}</Text>
                  </TimeBoxWrapper>
                  <Text className="time_separator">:</Text>
                  <TimeBoxWrapper>
                    <TimeBox>
                      <LinearText>{seconds < 0 ? '-' : seconds}</LinearText>
                    </TimeBox>
                    <Text className="time_suffix">second{seconds > 1 && 's'}</Text>
                  </TimeBoxWrapper>

                </RemainTimeBox>
              </RemainTimeBoxWrapper>

            </StageInfoBoxBottom>
          </StageInfoBox>
        }

      </TitleWrapper>

      <StatsWrapper>
        <StatsItemBorder clipPath="polygon(27.3px 0, 100% 0, 100% calc(100% - 27.3px), calc(100% - 27.3px) 100%, 0 100%, 0 27.3px)">
          <StatsItemBorderContent clipPath="polygon(27px 0, 100% 0, 100% calc(100% - 27px), calc(100% - 27px) 100%, 0 100%, 0 27px)" >
            <StatsItem>
              <StatsItemTitle>No. of Owner</StatsItemTitle>
              <StatsItemSubTitle>
                {
                  stakeInfo.totalStaked == 0 ? <CustomLightSpinner src={Circle} alt="loader" size="16px" /> : totalHolders
                }
              </StatsItemSubTitle>
            </StatsItem>
          </StatsItemBorderContent>
        </StatsItemBorder>

        <StatsItemBorder clipPath="polygon(calc(100% - 27.3px) 0, 100% 27.3px, 100% 100%, 27.3px 100%, 0 calc(100% - 27.3px), 0 0)">
          <StatsItemBorderContent clipPath="polygon(calc(100% - 27px) 0, 100% 27px, 100% 100%, 27px 100%, 0 calc(100% - 27px), 0 0)" >
            <StatsItem >
              <StatsItemTitle>Total Staked</StatsItemTitle>
              <StatsItemSubTitle>
                {
                  stakeInfo.totalStaked == 0 ? <CustomLightSpinner src={Circle} alt="loader" size="16px" /> : stakeInfo.totalStaked
                }
              </StatsItemSubTitle>
            </StatsItem>
          </StatsItemBorderContent>
        </StatsItemBorder>

        <StatsItemBorder clipPath="polygon(calc(100% - 27.3px) 0, 100% 27.3px, 100% 100%, 27.3px 100%, 0 calc(100% - 27.3px), 0 0)">
          <StatsItemBorderContent clipPath="polygon(calc(100% - 27px) 0, 100% 27px, 100% 100%, 27px 100%, 0 calc(100% - 27px), 0 0)" >
            <StatsItem >
              <StatsItemTitle>
                Total Supply
              </StatsItemTitle>
              <StatsItemSubTitle>
                666
              </StatsItemSubTitle>
            </StatsItem>
          </StatsItemBorderContent>
        </StatsItemBorder>

        <StatsItemBorder clipPath="polygon(27px 0, 100% 0, 100% calc(100% - 27px), calc(100% - 27px) 100%, 0 100%, 0 27px)">
          <StatsItemBorderContent clipPath="polygon(27px 0, 100% 0, 100% calc(100% - 27px), calc(100% - 27px) 100%, 0 100%, 0 27px)" >
            <StatsItem >
              <StatsItemTitle>Mint Price</StatsItemTitle>
              <StatsItemSubTitle>{mintPrice} ETH</StatsItemSubTitle>
            </StatsItem>
          </StatsItemBorderContent>
        </StatsItemBorder>
      </StatsWrapper>
      {
        account ?
          <>
            {
              !hasStarted ? <div>
                <NotchedButtonBolderNew w="100%" h="45px"
                  borderColor={isInvalidWhitelist ? '#FF1E56' : "linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%)"}
                  bg="#0a090f"
                  cursor="pointer"
                  clipPath="polygon(10px 0,100% 0,100% 75%,calc(100% - 12px) 100%,0 100%,0 25%)"
                  disableHover={isInvalidWhitelist}
                >
                  <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', height: '100%', width: '100%' }} onClick={checkWhilelist}>
                    {
                      isInvalidWhitelist ? <span style={{ fontSize: '18px', fontFamily: 'Righteous', color: '#FF1E56', fontWeight: 700, display: 'flex', justifyContent: 'center', alignItems: 'center', textTransform: 'uppercase' }}>You are not eligible</span>
                        :
                        <PrimaryTexy style={{ fontSize: '18px', fontFamily: 'Righteous', fontWeight: 700, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', textTransform: 'uppercase' }}>
                          {isCheckingWhitelist ? 'WHITELIST CHECKING...' : isWhilelistWallet ? 'You are eligible' : 'WHITELIST CHECK'}
                        </PrimaryTexy>
                    }
                  </div>
                </NotchedButtonBolderNew>
              </div>
                :
                (isEndtime ?
                  (userStakeInfo.monopassCnt.length == 0 ? <></>
                    :
                    <NotchedButtonFill width={'100%'} padding='10px' onClick={stakeAll}>
                      {
                        isStakingAll ? 'STAKING...' : 'STAKE NOW'
                      }
                    </NotchedButtonFill>)
                  :
                  currentStage == 'whitelist'
                    ?
                    (
                      !isWhilelistWallet ? <>
                        <NotchedButtonBolderNew w="100%" h="45px"
                          borderColor={isInvalidWhitelist ? '#FF1E56' : "linear-gradient(90deg, #68FFFF 0%, #68FF9B 100%)"}
                          bg="#0a090f"
                          cursor="pointer"
                          clipPath="polygon(10px 0,100% 0,100% 75%,calc(100% - 12px) 100%,0 100%,0 25%)"
                          disableHover={isInvalidWhitelist}
                        >
                          <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', height: '100%', width: '100%' }}>
                            {
                              isInvalidWhitelist ? <span style={{ fontSize: '18px', fontFamily: 'Righteous', color: '#FF1E56', fontWeight: 700, display: 'flex', justifyContent: 'center', alignItems: 'center', textTransform: 'uppercase' }}>You are not eligible</span>
                                :
                                <PrimaryTexy style={{ fontSize: '18px', fontFamily: 'Righteous', fontWeight: 700, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', textTransform: 'uppercase' }} onClick={checkWhilelist}>
                                  {isCheckingWhitelist ? 'WHITELIST CHECKING...' : isWhilelistWallet ? 'You are eligible' : 'WHITELIST CHECK'}
                                </PrimaryTexy>
                            }
                          </div>
                        </NotchedButtonBolderNew>
                      </>

                        :
                        <NotchedButtonFill width={'100%'} padding='10px' onClick={mint} disabled={currentStage == 'whitelist' && totalSold == 200 || userStakeInfo.isWhitelistMinted}>
                          {
                            currentStage == 'whitelist' && totalSold == 200 ? <>SOLD OUT</>
                              :
                              <>
                                {
                                  userStakeInfo.isWhitelistMinted ? 'MINTED'
                                    : (isMinting ? 'MINTING...' : 'MINT NOW')
                                }
                              </>
                          }
                        </NotchedButtonFill>
                    )
                    :
                    <NotchedButtonFill width={'100%'} padding='10px' onClick={() => {
                      const stakeEl = document.getElementById("stake-list-box");
                      if (stakeEl)
                        if (window.screen.width <= 768)
                          window.scrollTo(stakeEl.getBoundingClientRect().left, stakeEl.getBoundingClientRect().top + 350)
                        else window.scrollTo(stakeEl.getBoundingClientRect().left, stakeEl.getBoundingClientRect().top)
                    }}>
                      SOLD OUT
                    </NotchedButtonFill>
                )
            }

          </>
          : <NotchedButtonFill width={'100%'} padding='10px' onClick={toggleWalletModal}>
            CONNECT WALLET
          </NotchedButtonFill>
      }
      {
        false && <MyNftStats endTime={epochEndTime} refCode={"1233213"} totalInvited={0} totalMinted={0} />
      }
    </ContentLeft>

    <ContentRight>
      <img style={{ width: '100%', height: '100%' }} loading="lazy" src="https://raw.githubusercontent.com/Monoswap/images-repo/main/monoswap/monopass/mint/nft-high.gif" alt="monoswap-nft" />
    </ContentRight>

    <Modal
      isOpen={isOpenSucessMintModal}
      onDismiss={() => { setIsOpenSucessMintModal(false); }} >
      <SuccessMintMonopassModal onClose={() => setIsOpenSucessMintModal(false)} monopassContext={monopassContext} hash={successMintInfo.hash} id={successMintInfo.id} />
    </Modal>
  </Section1ContentWrapper>
}