import { skipToken } from '@reduxjs/toolkit/query/react'
import { Currency, CurrencyAmount, TradeType } from '@uniswap/sdk-core'
import { Trade } from '@uniswap/v3-sdk'
import ms from 'ms.macro'
import { useMemo } from 'react'
import { useBlockNumber } from 'state/application/hooks'
import {RouterPreference, useGetQuoteQuery} from 'state/routing/sliceV2'
import { computeRoutes } from './computeRoutes'
import { V3TradeState } from './types'
import {IMetric, MetricLoggerUnit, setGlobalMetric} from "@uniswap/smart-order-router";
import {sendTiming} from "../../components/analytics";
import {AVERAGE_L1_BLOCK_TIME} from "../../constants/chainInfo";
import {useRoutingAPIArguments} from "../../lib/hooks/routing/useRoutingAPIArguments";

function useFreshData<T>(data: T, dataBlockNumber: number, maxBlockAge = 10): T | undefined {
  const localBlockNumber = useBlockNumber()

  if (!localBlockNumber) return undefined
  if (localBlockNumber - dataBlockNumber > maxBlockAge) {
    return undefined
  }

  return data
}

export function useRoutingAPITrade<TTradeType extends TradeType>(
  tradeType: TTradeType,
  amountSpecified: CurrencyAmount<Currency> | undefined,
  otherCurrency: Currency | undefined,
  routerPreference: RouterPreference

): { state: V3TradeState; trade: Trade<Currency, Currency, TTradeType> | null } {
  const [currencyIn, currencyOut]: [Currency | undefined, Currency | undefined] = useMemo(
    () =>
      tradeType === TradeType.EXACT_INPUT
        ? [amountSpecified?.currency, otherCurrency]
        : [otherCurrency, amountSpecified?.currency],
    [amountSpecified, otherCurrency, tradeType]
  )

  const queryArgs = useRoutingAPIArguments({
    tokenIn: currencyIn,
    tokenOut: currencyOut,
    amount: amountSpecified,
    tradeType,
    routerPreference,
  })

  const { isLoading, isError, data, currentData } = useGetQuoteQuery(queryArgs ?? skipToken, {
    // Price-fetching is informational and costly, so it's done less frequently.
    pollingInterval: routerPreference === RouterPreference.PRICE ? ms`2m` : AVERAGE_L1_BLOCK_TIME,
  })

  const quoteResult = useFreshData(data, Number(data?.blockNumber) || 0)

  const routes = useMemo(
    () => computeRoutes(currencyIn, currencyOut, quoteResult),
    [currencyIn, currencyOut, quoteResult]
  )

  return useMemo(() => {
    if (!currencyIn || !currencyOut) {
      return {
        state: V3TradeState.INVALID,
        trade: null,
      }
    }

    if (isLoading && !quoteResult) {
      // only on first hook render
      return {
        state: V3TradeState.LOADING,
        trade: null,
      }
    }

    const otherAmount =
      tradeType === TradeType.EXACT_INPUT
        ? currencyOut && quoteResult
          ? CurrencyAmount.fromRawAmount(currencyOut, quoteResult.quote)
          : undefined
        : currencyIn && quoteResult
          ? CurrencyAmount.fromRawAmount(currencyIn, quoteResult.quote)
          : undefined

    if (isError || !otherAmount || !routes || routes.length === 0 || !queryArgs) {
      return {
        state: V3TradeState.NO_ROUTE_FOUND,
        trade: null,
      }
    }

    const trade = Trade.createUncheckedTradeWithMultipleRoutes<Currency, Currency, TTradeType>({
      routes,
      tradeType,
    })

    return {
      // always return VALID regardless of isFetching status
      state: V3TradeState.VALID,
      trade,
    }
  }, [currencyIn, currencyOut, isLoading, quoteResult, isError, routes, queryArgs, tradeType])
}


// only want to enable this when app hook called
class GAMetric extends IMetric {
  putDimensions() {
    return
  }

  putMetric(key: string, value: number, unit?: MetricLoggerUnit) {
    sendTiming('Routing API', `${key} | ${unit}`, value, 'client')
  }
}

setGlobalMetric(new GAMetric())
